<template>
  <div class="headerInfo">
    <Socials        
      v-if="!$store.state.isMobile"
    />

    <Notices        
      v-if="!$store.state.isMobile"
    />
  </div>
</template>

<script>
import Socials from '@/components/Socials'
import Notices from '@/components/Notices'

export default {
  components: {
    Socials,
    Notices
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.headerInfo {
  display: flex;
  gap: 60px;
}

</style>