import { createRouter, createWebHistory } from "vue-router"

const routes = [
  {
    path: '/',
    name: 'youcamp',
    component: () => import('../views/YouCampHome.vue'),
    meta: {
      layout: 'base',
      title: 'YouCamp'
    }
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('../views/Auth/Login.vue'),
    meta: {
      layout: 'base',
      title: 'Авторизация',
      isAuth: true
    }
  },
  {
    path: '/auth/registration',
    name: 'registration',
    component: () => import('../views/Auth/Register.vue'),
    meta: {
      layout: 'base',
      title: 'Регистрация',
      auth: 'registration',
      isAuth: true
    }
  },
  {
    path: '/price_bidder',
    name: 'PBBasicProducts',
    component: () => import('../views/PriceBidder/PBBasicProducts.vue'),
    meta: {
      layout: 'main',
      title: 'Репрайсер',
      sidebarContent: 'navigation',
      header: 'PBBasicProductsHeader',
      hideProfile: true, //hide desktop profile component in mobile
    }
  },
  {
    path: '/price_bidder/product/:id',
    name: 'PBSingleProduct',
    component: () => import('../views/PriceBidder/PriceBidderSingleProduct/PBSingleProduct.vue'),
    meta: {
      backButtonSidebar: true,
      layout: 'main',
      title: 'Карточка товара',
      sidebarContent: 'productSetting',
      header: 'PBSingleProductHeader',
      hideProfile: true, //hide desktop profile component in mobile
    }
  },
  {
    path: '/account/team',
    name: 'team',
    component: () => import('../views/Account/Team/Team.vue'),
    meta: {
      backButtonSidebar: true,
      layout: 'main',
      title: 'Команда',
      sidebarContent: 'navigation'
    }
  },
  {
    path: '/account/balance',
    name: 'balance',
    component: () => import('../views/Account/Balance/Balance.vue'),
    meta: {
      backButtonSidebar: true,
      layout: 'main',
      title: 'Баланс',
      sidebarContent: 'navigation',
    }
  },
  {
    path: '/account/settings',
    name: 'settings',
    component: () => import('../views/Account/Settings/Settings.vue'),
    meta: {
      backButtonSidebar: true,
      layout: 'main',
      title: 'Настройки',
      header: 'settingsHeader',
      sidebarContent: 'navigation'
    }
  },
  {
    path: '/account/tickets',
    name: 'tickets',
    component: () => import('../views/Account/Tickets/Tickets.vue'),
    meta: {
      backButtonSidebar: true,
      layout: 'main',
      title: 'Поддержка',
      header: 'ticketsHeader',
      sidebarContent: 'navigation'
    }
  },
  {
    path: '/payment/success',
    name: 'paymentSuccess',
    component: () => import('../views/Payment/PaymentStatus.vue'),
    meta: {
      layout: 'base',
      title: 'Все получилось!',
      payment: true
    }
  },
  {
    path: '/payment/failure',
    name: 'paymentFailure',
    component: () => import('../views/Payment/PaymentStatus.vue'),
    meta: {
      layout: 'base',
      title: 'Оплата не прошла :(',
      payment: true
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/Admin/Admin.vue'),
    meta: {
      layout: 'admin',
      title: 'Админ панель'
    }
  },
  {
    path: '/crm',
    name: 'crm',
    component: () => import('../views/CRM/Crm.vue'),
    meta: {
      layout: 'main',
      title: 'CRM',
      sidebarContent: 'navigation',
    }
  },
  {
    path: '/crm/ad-items', component: () => import('../views/CRM/Crm.vue'),
    meta: {
            layout: 'main',
            title: 'Покупная реклама',
            sidebarContent: 'navigation'
    }
  },
  {
    path: '/crm/money-wb', component: () => import('../views/CRM/Crm.vue'),
    meta: {
            layout: 'main',
            title: 'Деньги WB',
            sidebarContent: 'navigation'
    }
  },
  {
    path: '/crm/ads-wb', component: () => import('../views/CRM/Crm.vue'),
    meta: {
        layout: 'main',
        title: 'Реклама',
        sidebarContent: 'navigation'
    }
  },
  {
    path: '/crm/orders', component: () => import('../views/CRM/Crm.vue'),
    meta: {
        layout: 'main',
        title: 'Заказы Отчет',
        sidebarContent: 'navigation'
    }
  },
  {
    path: '/crm/blogers', component: () => import('../views/CRM/Crm.vue'),
    meta: {
            layout: 'main',
            title: 'Блогеры',
            sidebarContent: 'navigation'
        }
  },
  {
        path: '/crm/cost',
        component: () => import('../views/CRM/Crm.vue'),
        meta: {
            layout: 'main',
            title: 'Себестоимость товара',
            sidebarContent: 'navigation'
        }
  },
  {
        path: '/crm/product-info',
        component: () => import('../views/CRM/Crm.vue'),
        meta: {
            layout: 'main',
            title: 'Общее о товарах',
            sidebarContent: 'navigation'
        }
  },
  {
        path: '/crm/unit-economics',
        component: () => import('../views/CRM/Crm.vue'),
        meta: {
            layout: 'main',
            title: 'Юнит Экономика',
            sidebarContent: 'navigation'
        }
  },
  {
        path: '/crm/product',
        component: () => import('../views/CRM/Crm.vue'),
        meta: {
            layout: 'main',
            title: 'Товары',
            sidebarContent: 'navigation'
        }
  },
  {
        path: '/crm/purchase-batches',
        component: () => import('../views/CRM/Crm.vue'),
        meta: {
            layout: 'main',
            title: 'Закуп партии',
            sidebarContent: 'navigation'
        }
  },
  {
        path: '/crm/seo',
        component: () => import('../views/CRM/Crm.vue'),
        meta: {
            layout: 'main',
            title: 'Выдача по СЕО',
            sidebarContent: 'navigation',
        }
  },
  {
        path: '/crm/dashboard',
        component: () => import('../views/CRM/Crm.vue'),
        meta: {
            layout: 'main',
            title: 'Дашбоард',
            sidebarContent: 'navigation'
        }
  },
  {
        path: '/crm/turnover',
        component: () => import('../views/CRM/Crm.vue'),
        meta: {
            layout: 'main',
            title: 'Оборачиваемость',
            sidebarContent: 'navigation'
        }
  },
  {
        path: '/crm/sale',
        component: () => import('../views/CRM/Crm.vue'),
        meta: {
            layout: 'main',
            title: 'Продажи',
            sidebarContent: 'navigation'
        }
  },
  {
    path: '/advertising_bidder',
    name: 'AdvertisingBidder',
    component: () => import('../views/AdvertisingBidder/AdvertisingBidder.vue'),
    meta: {
      layout: 'underDevelopment',
      title: 'Биддер рекламы',
      imageName: 'advertising',
      sidebarContent: 'navigation'
    }
  },
  {
    path: '/creative_bidder',
    name: 'CreativeBidder',
    component: () => import('../views/CreativeBidder/CreativeBidder.vue'),
    meta: {
      layout: 'underDevelopment',
      title: 'Биддер креативов',
      imageName: 'creative',
      sidebarContent: 'navigation'
    }
  },
  {
    path: '/seo_parser',
    name: 'SeoParser',
    component: () => import('../views/SeoParser/SeoParser.vue'),
    meta: {
      layout: 'underDevelopment',
      title: 'SEO парсер',
      imageName: 'seo',
      sidebarContent: 'navigation'
    }
  },
  {
    path: '/extension',
    name: 'Extension',
    component: () => import('../views/Extension/Extension.vue'),
    meta: {
      layout: 'underDevelopment',
      title: 'Расширение',
      imageName: 'extension',
      sidebarContent: 'navigation'
    }
  },
  {
    path: '/crm/ad-items', component: () => import('../views/CRM/Crm.vue'),
    meta: {
            layout: 'main',
            title: 'Покупная реклама',
            sidebarContent: 'mainMenu'
    }
  },
  {
    path: '/crm/money-wb', component: () => import('../views/CRM/Crm.vue'),
    meta: {
            layout: 'main',
            title: 'Деньги WB',
            sidebarContent: 'mainMenu'
    }
  },
  {
    path: '/crm/ads-wb', component: () => import('../views/CRM/Crm.vue'),
    meta: {
        layout: 'main',
        title: 'Реклама',
        sidebarContent: 'mainMenu'
    }
  },
  {
    path: '/crm/orders', component: () => import('../views/CRM/Crm.vue'),
    meta: {
        layout: 'main',
        title: 'Заказы Отчет',
        sidebarContent: 'mainMenu'
    }
  },
  {
    path: '/crm/blogers', component: () => import('../views/CRM/Crm.vue'),
    meta: {
            layout: 'main',
            title: 'Блогеры',
            sidebarContent: 'mainMenu'
        }
  },
  {
    path: '/crm/ad-items', component: () => import('../views/CRM/Crm.vue'),
    meta: {
            layout: 'main',
            title: 'Покупная реклама',
            sidebarContent: 'mainMenu'
    }
  },
  {
    path: '/crm/money-wb', component: () => import('../views/CRM/Crm.vue'),
    meta: {
            layout: 'main',
            title: 'Деньги WB',
            sidebarContent: 'mainMenu'
    }
  },
  {
    path: '/crm/ads-wb', component: () => import('../views/CRM/Crm.vue'),
    meta: {
        layout: 'main',
        title: 'Реклама',
        sidebarContent: 'mainMenu'
    }
  },
  {
    path: '/crm/orders', component: () => import('../views/CRM/Crm.vue'),
    meta: {
        layout: 'main',
        title: 'Заказы Отчет',
        sidebarContent: 'mainMenu'
    }
  },
  {
    path: '/crm/blogers', component: () => import('../views/CRM/Crm.vue'),
    meta: {
            layout: 'main',
            title: 'Блогеры',
            sidebarContent: 'mainMenu'
        }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/Error/404.vue'),
    meta: {
      title: 'Страница не найдена',
      pageNotFound: true,
      layout: 'base'
    }
  }
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior () {
    document.getElementById('app').scrollIntoView(({behavior: "smooth", block: "start", inline: "start"}))
  },
})

router.beforeEach((to, from, next) => {

  const token = localStorage.getItem('token')

  if(!token) {
    if(to.name === 'login' || to.name === 'registration') {
      return next()
    } else {
      return next ({
        name: 'login'
      })
    }
  }

  if(token && to.name === 'login' ||  to.name === 'registration') {
    return next({
      name: 'youcamp'
    })
  }

  next()

})

export default router
