<template>
  <BasePopup>
    <div class="RoleChangeConfirmPopup">
      <div class="RoleChangeConfirmPopup__content">
        <div class="RoleChangeConfirmPopup__contentTitle">
          Вы действительно хотите поменять роль данного пользователя?
        </div>
        <div class="RoleChangeConfirmPopup__contentActions">
          <button class="RoleChangeConfirmPopup__contentActions--btn"
            @click="changeRole"
          >
            Да
          </button>
          <button class="RoleChangeConfirmPopup__contentActions--btn"
            @click="closePopup"
          >
            Нет
          </button>
        </div>
      </div>
    </div>
  </BasePopup>
</template>
  
  <script>
  import {mapActions,mapState} from 'vuex'
  export default {
    name: 'RoleChangeConfirmPopup',
    props: {
      name:String,
    },
    computed: {
      ...mapState({
        userParams: (state) => state.adminUser.userParams,
        updateParams: (state) => state.adminUser.updateParams,
      })
    },
    methods: {
       ...mapActions({
          closePopup: "popup/closePopup",
       }),
       changeRole(){
        var data = {
          updateParams: this.updateParams,
          userParams: this.userParams,
        }
        this.$store.dispatch("adminUser/postUpdateUser", data);
        this.closePopup()
       },
    }
  };
  </script>
  
<style lang="scss" scoped>
  @import 'src/scss/utils/_mixins.scss';
  @import 'src/scss/base/project/_var.scss';
  
  .RoleChangeConfirmPopup {
    padding: 60px 100px 40px 100px;
  
    &__content {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      gap: 25px;
      &Title {
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        font-weight: 500;
      }
      &Actions {
        display: flex;
        gap: 20px;
  
        &--btn {
          border-radius: 43px;
          border: 1px solid $green-100;
          color: $green-100;
          padding: 15px 30px;
          font-size: 15px;
          line-height: 15px;
          font-weight: 400;
        }
      }
    }
  }
  </style>
  