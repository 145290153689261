<template>

    <div v-if="false" class="camp-table-cell-inner">
        <p class="camp-table-label" v-if="title">{{title}}</p>
        <div class="camp-status camp-status-date" style="background-color: #0073EA;">
            <span>{{value}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DateTotal",
        props: {

            group_id:0,
            item_id:Number,
            field:null,
            value:null,
            title:'',
        },
    }
</script>

<style scoped>

</style>
