<template>
  <div class="camp-table-cell camp-table-cell__empty" :style="hStyle()">
    <div class="camp-table-cell-inner">
      <div class="camp-table-cell-title">
        <div class="camp-table-cell-title__display">
          <div class="camp-table-cell-title__display-inner">
            <p class="camp-table-cell-title__display-text" :style="getColor()">{{title}}</p>
            <div class="camp-table-cell-title__edit" style="display: none;">
              <input type="text" value="Заголовок" :style="getColor()">
            </div>
          </div>
        </div>


      </div>


      <div class="camp-expand-btn" v-show="min_width!==width"
           @click="toggle_expand(this.groupd_cells_id)"
      >
        <Svg :img="'expand'"/>
      </div>

    </div>

    <div class="camp-table-cell-group-line" :style="getBackGround()"></div>
  </div>
</template>
<script>
import Svg from "@/components/Crm/UI/Svg.vue";
import {mapState} from "vuex";

export default {
  name: "HeaderColor",
  components: {Svg},
  props:{
    title:'',
    width:null,
    min_width:'120px',
    color:null,
    groupd_cells_id:null,
  },
  computed: {
    ...mapState({
      tableParams: state => state.crm.tableParams,

    }),
  },
  methods:{
    hStyle() {
      const groupExpanded=this.tableParams.groupCells[this.groupd_cells_id];
      if(groupExpanded) {
        return {width: this.width}
      }else {
        return {width: `120px`};
      }
    },
    getBackGround(){return {backgroundColor:this.color}},
    getColor(){return {color:this.color}},
    toggle_expand(groupd_cells_id) {

      //console.log("tableParams",this.tableParams,this.tableParams?.groupCells[groupd_cells_id])
      this.tableParams.groupCells[groupd_cells_id] = !this.tableParams?.groupCells[groupd_cells_id] ?? false;

      //console.log("tableParamsAfter",this.tableParams?.groupCells[groupd_cells_id])

      this.$store.commit('crm/setTableParams', this.tableParams);
    }
  }
}
</script>



<style scoped lang="scss">
.camp-table-cell p.camp-table-cell-title__display-text{
  font-size: 12px;
  word-break: break-word;
}

</style>