<template>
  <div :class="['auth', pageClass]">
    <div class="auth__wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    pageClass() {
      return `auth--${this.$route.name}`
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.auth {

  position: relative;

  &--login {
    @include backgroundImage('bg/login-bg.webp', center 50%);
    background-size: 1550px 444px;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      @include backgroundImage('bg/login-bg-2x.webp', center 50%);
    }
    @include breakpoint(xl) {
      background-size: 1165px 317px;
      @include backgroundImage('bg/login-bg-xl.webp', center center);
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @include backgroundImage('bg/login-bg-xl-2x.webp', center center);
      }
    }
  }

  &--registration {
    background-size: 1564px 448px;
    @include backgroundImage('bg/registration-bg.webp', center 54%);
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      @include backgroundImage('bg/registration-bg-2x.webp', center 54%);
    }
    @include breakpoint(xl) {
      background-size: 1164px 339px;
      @include backgroundImage('bg/registration-bg-xl.webp', center 54%);
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @include backgroundImage('bg/registration-bg-xl-2x.webp', center 54%);
      }
    }
  }

  @include breakpoint(md) {
    background-image: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: -30px;
    left: -17px;
    width: calc(100% + 34px);
    height: calc(100% + 60px);
    display: none;
    background-size: 100%;
    @include breakpoint(md) {
      @include backgroundImage('auth/auth-bg-md.webp', top center);
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @include backgroundImage('auth/auth-bg-md-2x.webp', top center);
      }
      display: block;
    }
    @include breakpoint(sm) {
      @include backgroundImage('auth/auth-bg-sm.webp', top center);
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @include backgroundImage('auth/auth-bg-sm-2x.webp', top center);
      }
    }
    @include breakpoint(xs) {
      top: -15px;
      height: calc(100% + 30px);
    }
  }

  &__wrapper {
    position: relative;
    max-width: 445px;
    width: 100%;
    background-color: $white;
    border-radius: 50px;
    box-shadow: 0px 8px 27px 0px rgba(0, 0, 0, 0.07);
    padding: 50px 57px;
    @include breakpoint(xl) {
      padding: 43px 59px;
    }
    @include breakpoint(md) {
      padding: 50px 40px;
      border-radius: 30px;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.07);
    }
    @include breakpoint(sm) {
      padding: 50px 25px;
    }
    @include breakpoint(xs) {
      padding: 30px;
    }
  }
}
</style>