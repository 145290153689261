<template>
  <header class="homeHeader">
    <a href="https://youcamp.pro">
        <img src="/images/logo.svg" alt="YouCamp" class="homeHeader__logo">
    </a>

    <Socials
      class="homeHeader__socials"
    />
  </header>
</template>

<script>
import Socials from '@/components/Socials'

export default {
  name: 'YouCampHeader',
  components: {
    Socials
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.homeHeader {

  display: flex;
  justify-content: space-between;
  padding: 0 40px 0;
  max-width: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;

  @include breakpoint(md) {
    padding-top: 35px;
  }
  @include breakpoint(sm) {
    justify-content: center;
  }
  @include breakpoint(xs) {
    padding: 0;
  }

  &__logo {
    @include breakpoint(md) {
      margin: 0 auto;
      width: 98px;
    }
    @include breakpoint(xs) {
      width: 81px;
    }
  }

  &__socials {
    @include breakpoint(md) {
      display: none;
    }
  }

  &--md-hidden {
    @include breakpoint(md) {
      display: none;
    }
  }

}
</style>
