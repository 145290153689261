<template>
  <div class="ticketsHeader">

    <BaseButton
      class="ticketsHeader__button button button--transparent"
      @click="openPopup({
        component: 'TicketsNewPopup'
      })"
    >
      + Новый тикет
    </BaseButton>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  methods: {
    ...mapActions({
      openPopup: 'popup/openPopup'
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.ticketsHeader {

  display: flex;
  align-items: center;
  justify-content: space-between;

  &__button {
    max-width: 169px;
    width: 100%;
    height: 30px;
    font-weight: 400;
    @include breakpoint(xl) {
      max-width: 152px;
    }
    @include breakpoint(md) {
      font-size: 13px;
      line-height: 10px;
      max-width: 172px;
      height: 40px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__supportResponse {
    font-size: 12px;
    background-color: $green-100;
    border-radius: 20px;
    color: $white;
    height: 28px;
    max-width: 156px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(xl) {
      height: 25px;
    }
  }
}

</style>