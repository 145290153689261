<template>
  <div class="adminSellersListByProduct">
    <AdminSellersListItemByProduct
      v-for="product in products"
      :product="product"
    />
  </div>
</template>

<script>
import AdminSellersListItemByProduct from "./AdminSellersListItemByProduct.vue";

export default {
  components: {
    AdminSellersListItemByProduct,
  },
  props: {
    products: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";
.adminSellersListByProduct {
  display: flex;
  flex-flow: column;
  gap: 5px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin-left: 40px;
  box-shadow: 0 5px 10px rgba($black, 0.07);
  @include mediaMaxWidth(1420) {
    width: fit-content;
  }
}
</style>
