<template>
  <div
    class="search"
    :class="classModificator ? `search--${classModificator}` : ''"
  >
    <div class="search__wrapper">
      <input
        type="text"
        class="search__control"
        placeholder="Название/артикул товара"
        :value="modelValue"
        @input="updateValue"
        @keyup="$emit('updateSearch')"
      >
      <BaseButton
        class="search__button"
        :classModificator="'button--bs'"
      >
        Искать
      </BaseButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSearch',
  props: {
    modelValue: {
      type: [String, Number],
      require: true
    },
    classModificator: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.search {

  background-color: $gray-100;
  padding: 5px;
  border-radius: 20px;

  &--searchMobile {
    background-color: $white;
    padding: 0;
  }

  &__wrapper {
    display: flex;
  }

  &__button {
    flex: 0 0 100px;
    height: 30px;
    margin-left: 10px;
    font-weight: 300;
    background-color: $white;
    @include breakpoint(xl) {
      height: 25px;
    }
    @include breakpoint(md) {
      height: 30px;
    }
  }

  &__control {
    width: 100%;
    border: none;
    text-align: center;
    font-size: 14px;
    padding: 0 30px;
    flex: 1;
    height: 30px;
    font-family: $font-primary;
    font-weight: 500;
    background-color: transparent;
    text-overflow: ellipsis;
    @include breakpoint(xl) {
      height: 25px;
      font-size: 13px;
    }
    @include breakpoint(md) {
      height: 30px;
    }
    &::placeholder{
      font-size: inherit;
      font-weight: 500;
      color: $gray-300;
    }
  }

  &--searchMobile &__control {
    height: 40px;
    border-radius: 20px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    color: #000;
    font-family: $font-primary;
    &::placeholder {
      color: $gray-300;
    }
    @include breakpoint(xs) {
      &::placeholder {
        font-size: 14px;
      }
    }
  }

}

</style>