<template>

        <div class="camp-table-row camp-row camp-row-nowrap">

            <TableCell
                v-for="(cellParams, index) in tableCellsParams"
                :key="index"
                :tableCellsParam="tableCellsParams[index]"
                :name="cellParams.name"
                :width="cellParams.width"
                :item_id="getItemId"
                :group_id="group_id"
                :rowIndex="rowIndex"
                :field="cellParams.field"
                :type="cellParams.type"
                :value="getCellValue(index, cellParams.field)"
                :withCheckbox="cellParams.withCheckbox"
                :withImg="cellParams.withImg"
                :imageId="getImgId(cellParams.withImg)"
                :imgUrl="getImgUrl(cellParams.withImg)"
                :isSticky="cellParams.isSticky"
                :isSubGroup="cellParams.isSubGroup"
                :color="color"
                :statustype="cellParams.statustype"
                :formula="cellParams.formula"
            />

        </div>



         <Subgroup v-show="tableCellsParams[0]?.isSubGroup"
            :groupIndex="rowIndex"
         />



</template>

<script>
    import TableCell from "./TableCell";
    import {mapActions, mapMutations,mapGetters} from 'vuex'
    import { calcFormula } from '@/components/Crm/helpers/dataHelpers';
    import Subgroup from "@/components/Crm/Desks/Custom/Subgroup.vue";
    /*
    * Компонент Строки таблицы
     */
    export default {
        name: "TableRow",
        components: {Subgroup, TableCell},
        props: {
            myParameter: Boolean,
            isFooter: Boolean,
            typeCollumn: {
                type: Number,
                default: 1
            },
            rowIndex:Number,
            color:String,
            group_id:0,
            key_field:String,
            rowData:null,
            tableCellsParams: {
               default: () => [],
            },
        },
        methods: {
            ...mapActions({
                fetchImageBlob: 'crm/fetchImageBlobById'
            }),
            /*
            * Получаем значение в ячейки таблице
            * index - номер ячейки слева на право от 0
            * field - название поля из структуры передаваемых данных
             */
            getCellValue(index, field) {

                if( this.tableCellsParams[index] && this.tableCellsParams[index].formula) {
                    //console.log("getCellValue", this.tableCellsParams[index].formula);

                    return calcFormula(this.rowData.item,this.tableCellsParams[index].formula);

                    //return this.tableCellsParams[index].formula;
                }
                else if (this.tableCellsParams[index] && this.tableCellsParams[index].dictionary) {
                    //Хранение для ЮнитЭкономики
                    if(this.tableCellsParams[index].field==='storage_for_30_days_per_item' || this.tableCellsParams[index].field==='storage_for_item')
                    {
                        //return this.tableCellsParams[index-1].field
                        const itmId=this.rowData.item['sklad'];
                        if(!itmId) {return 0;}
                        const sv=this.getStatusValues?.sklad ?? [];


                        const volume=this.rowData.item['p_volume']
                        const quantity=this.rowData.item['quantity']
                        const days=this.rowData.item['number_of_days'] ?? 1

                        //console.log("sv=",sv,"itmId=",itmId," volume=",volume);
                        if(sv[itmId+1])
                        {
                            const price = sv[itmId+1].price ?? 0;
                           // const price = parseFloat(sv[itmId+1].price.replace(',', '.')) ?? 0;
                            var resultStorage= (days*quantity*price*volume/1000).toFixed(2);
                            if(this.tableCellsParams[index].field==='storage_for_item')
                            {
                              resultStorage= (days*price*volume/1000).toFixed(2);

                              this.rowData.item[ this.tableCellsParams[index].field ]=resultStorage
                            }

                            return resultStorage;
                        }
                        //return this.rowData.item['sklad'];
                    }

                    //Доставка для ЮнитЭкономики
                    else if(this.tableCellsParams[index].field==='logistics_back' || this.tableCellsParams[index].field==='logistics_to' ||
                        this.tableCellsParams[index].field==='logistics_percentage' || this.tableCellsParams[index].field==='logistics_one'
                    )
                    {
                      const sv=this.getStatusValues?.sklad ?? [];
                      const volume=this.rowData.item['p_volume'];
                      const quantity=this.rowData.item['quantity'];



                      const itmId=this.rowData.item['sklad'];
                      if(!itmId) {return 0;}

                      const markup_percentage=this.rowData.item['markup_percentage'];

                      if(sv[itmId+1])
                      {
                        const price = sv[itmId+1].priceLogistic ?? 0;
                        const priceBack = 50;
                        
                        const logisticTo=(price* quantity).toFixed(0);
                        if(this.tableCellsParams[index].field==='logistics_to'){
                          return  logisticTo;
                        }

                        const backLogistic= ( priceBack* Math.round(quantity*((100-markup_percentage)/100)) ).toFixed(0);
                        const logisticAll = (parseFloat(logisticTo) + parseFloat(backLogistic)).toFixed(0);

                        if(this.tableCellsParams[index].field==='logistics_back')
                        {
                          if(!markup_percentage || markup_percentage<1 || markup_percentage>=100){
                            return 0;
                          }
                          this.rowData.item[ this.tableCellsParams[index].field]=backLogistic;
                          return backLogistic;
                        }
                        if(this.tableCellsParams[index].field==='logistics_percentage')
                        {
                          this.rowData.item[ this.tableCellsParams[index].field]=logisticAll;
                          return logisticAll;
                        }
                        if(this.tableCellsParams[index].field==='logistics_one')
                        {
                          const logisticOne=quantity>0 ? (logisticAll/quantity).toFixed(0) : 0
                          this.rowData.item[ this.tableCellsParams[index].field]=logisticOne;
                          return logisticOne;
                        }

                      }
                      return 0;
                    }


                }

                else if (this.rowData && this.rowData.item && this.rowData.item[field]) {
                    //console.log("getCellValue", this.tableCellsParams[index]);

                    return this.rowData.item[field];
                    //return this.rowData.item[field]+":"+field;
                    //return this.rowData.item[field];
                }

            },
            getImgUrl(withImg){
                if (withImg)
                {
                    return this.rowData.item?.photo;
                }
                return '';
            },
            getImgId(withImg){
                if (withImg)
                {
                    return this.rowData.item?.image_id;
                }
                return '';
            }
        },
        computed: {
            ...mapGetters({
                getUiAction: 'crm/getUiAction',
                getStatusValues: 'crm/getStatusValues',
            }),
            isVisible()
            {


                const uiact=this.getUiAction;
                if(uiact && uiact.group_id===this.group_id && uiact.item_id === this.rowData.item.id) {

                    return false;
                }
                //console.log("ui act",uiact,this.group_id,this.rowData.item.id);

                return true;
            },
            isParameterTrue() {
                return this.myParameter === true;
            },
            isFooterRow() {
                return this.isFooter === true;
            },
            /*
            * Получаем Id ключевого поля,в строке данных
            */
            getItemId()
            {
                if(this.rowData && this.rowData.item && this.rowData.item && this.rowData.item[this.key_field]) {
                    return this.rowData.item[this.key_field];
                }
                else {
                    return 0;
                }
            },
        },
        mounted()
        {
            //console.log("tableRow",this.rowData,this.key_field);
        },
        watch: {
            // getUiAction(newValue, oldValue) {
            //
            //     console.log("watchUiAction:",newValue, oldValue);
            //     if(this.groupID===newValue){
            //         console.log("Change this desk",this.groupID);
            //         this.newKey++;
            //     }
            // },

        },
    }
</script>

<style scoped>

</style>
