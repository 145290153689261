<template>

    <template v-if="imgUrl">
        <div class="camp-table-cell-img">

            <div class="camp-table-cell-img__display" @click="openImgPopup" data-tippy-placement="right" data-tippy-arrow="false">
                <img :src="imgUrl" width="24" height="24" alt="">


                <div
                    class="camp-table-cell-img__modal camp-modal"
                    :style="{ display: showPopup ? 'block' : 'none' }"
                    v-click-outside='closeImgPopup'
                >

                    <div class="camp-modal-overlay"></div>
                    <div class="camp-table-cell-img__modal-content camp-modal-body">
                        <button class="camp-btn-ic camp-btn-ic__close" type="button" aria-label="Закрыть" @click="closeImgPopup">
            <span class="camp-btn-ic__icon">

              <Svg :img="'close1'"/>
            </span>
                        </button>
                        <div class="camp-table-cell-img__modal-content_inner">
                            <img :src="imgUrl" width="300" height="300" alt="">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </template>
    <template v-else>

    <div v-if="isLoading" class="camp-table-cell-img camp-table-cell-img__loader camp__is-editable">
        <BasePreloader class="customLoader" />
    </div>
    <div v-else-if="currentImageId === null" class="camp-table-cell-img camp__is-editable">
        <label class="camp-table-cell-img__upload camp-tooltip" data-tippy-content="Загрузить фото">
            <input type="file" @change="handleSelectImage" accept="image/png, image/jpeg">
            <span class="camp-btn-ic camp-btn-ic__add">
                <span class="camp-btn-ic__icon">
                    <Svg :img="'inputFile'"/>
                </span>
            </span>
        </label>
    </div>
    <div v-else class="camp-table-cell-img camp__is-editable">
        <label class="camp-btn-ic camp-btn-ic__edit camp-tooltip" data-tippy-content="Редактировать">
            <input type="file" @change="handleSelectImage" accept="image/png, image/jpeg">
            <span class="camp-btn-ic__icon">
                <Svg :img="'pencil'" />
            </span>
        </label>
        <div class="camp-table-cell-img__display" @click="openImgPopup" data-tippy-placement="right" data-tippy-arrow="false">
            <img :src="this.image" width="24" height="24" alt="">


                <div
                    class="camp-table-cell-img__modal camp-modal"
                    :style="{ display: showPopup ? 'block' : 'none' }"
                    v-click-outside='closeImgPopup'
                >

                <div class="camp-modal-overlay"></div>
                <div class="camp-table-cell-img__modal-content camp-modal-body">
                    <button class="camp-btn-ic camp-btn-ic__close" type="button" aria-label="Закрыть" @click="closePopup">
            <span class="camp-btn-ic__icon">

              <Svg :img="'close1'"/>
            </span>
                    </button>
                    <div class="camp-table-cell-img__modal-content_inner">
                        <img :src="this.image" width="300" height="300" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
</template>

<script>
    import Svg from "../Svg";
    import { mapState, mapGetters,  mapActions } from "vuex";
    export default {
        name: "ImgInput",
        components: { Svg },
        data() {
            return {
                isLoading: false,
                showPopup: false, // Изначально popup скрыт
                image: null,
                currentImageId: null,
            };
        },
        props: {
            item_id:Number,
            group_id:0,
            image_src:null,
            imageId:null,
            type:0,
            imgUrl:'',
        },
        methods: {
            ...mapGetters({
                getUploadedImageId: 'crm/getUploadedImageId'
            }),
            ...mapActions({
                openPopup: 'popup/openPopup',
                closePopup: 'popup/closePopup',
                uploadImage: 'crm/uploadImage',
                editItem: 'crm/editItem',
            }),
            openImgPopup() {
                this.showPopup = true; // Открыть popup при клике
                let stickies = document.querySelectorAll('.camp-table-cell-sticky')
                let i = 1;
                stickies.forEach(sticky => {
                    sticky.style.zIndex = 1000 - i
                    i += 1
                })
                let contents = document.querySelectorAll('.camp-content-inner')
                contents.forEach(content => {
                    content.style.paddingBottom = '250px'
                })
            },
            closeImgPopup() {
                this.showPopup = false; // Закрыть popup
                let stickies = document.querySelectorAll('.camp-table-cell-sticky')
                stickies.forEach(sticky => {
                    sticky.style.zIndex = 70
                })
                let contents = document.querySelectorAll('.camp-content-inner')
                contents.forEach(content => {
                    content.style.paddingBottom = '30px'
                })
            },
            async handleSelectImage(e) {

                const file = e.target.files[0]

                if (file.size >= 2 * 1024 * 1024) {
                    console.log('Размер файла превышает допустимые 2 МБ');

                    this.openPopup(
                        {
                            component: 'SettingsAlertPopup',
                            props: {
                                text: 'Размер файла превышает допустимые 2 МБ'
                            }
                        }
                    )
                    return;
                }
                this.isLoading = true;

                const formData = new FormData();
                formData.append('image', file)
                formData.append('currentImageId', this.currentImageId)
                await this.uploadImage(formData).then(res => {
                    const uploadedImageId = this.getUploadedImageId()
                    this.currentImageId = uploadedImageId
                    if (uploadedImageId) {
                        this.editItem({
                            obj: {
                                group_id:this.group_id,
                                item_id:this.item_id,
                                key_field:'image_id',
                                action:this.item_id===0 ? 'add':'edit',
                                val: uploadedImageId,
                            }
                        }).then(r => {

                        })
                        .catch(() => {

                        })
                        .finally(() => {
                        })
                    }
                })
                this.isLoading = false;
            },
        },
        computed: {
            ...mapState({
                allImages: state => state.crm.images
            })
        },
        mounted() {
            console.log(this.imageId)
            if (this.allImages && this.imageId) {
                this.image = this.allImages[this.imageId]
                this.currentImageId = this.imageId
            }
        },
        watch: {
            allImages() {
                if (this.allImages && this.imageId) {
                    this.image = this.allImages[this.currentImageId]
                }
            },
            currentImageId() {
                this.image = this.allImages[this.currentImageId]
            }
        }
    };
</script>

<style scoped>
    /* Здесь ваши стили */
    .camp-table-cell-img__modal {
        z-index: 1001;
    }
    .customLoader {
        width: 25px;
        height: 25px;
        background-size: cover;
        position: relative;
    }
    .customLoader::before {
        top: -5px;
        left: -5px;
        width: 35px;
        height: 35px;
        background-size: cover;
    }
</style>
