<template>
  <div class="formTextarea">
    <textarea
      class="formTextarea__control"
      :placeholder="placeholder"
      :value="modelValue"
      @blur="stateBlur"
      @focus="stateFocus"
      @input="updateValue"
    ></textarea>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      require: true
    },
  },
  methods: {
    stateBlur(event) {
      if (event.target.value !== '') event.target.classList.add('formTextarea__control--filled')
    },
    stateFocus(event) {
      event.target.classList.remove('formTextarea__control--filled')
    },
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  }
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.formTextarea {
  &__control {
    display: block;
    background-color: $gray-100;
    min-height: 130px;
    border: none;
    box-shadow: $box-shadow-input inset;
    width: 100%;
    border-radius: 15px;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    padding: 10px;
    resize: none;

    @include breakpoint(xl) {
      font-size: 13px;
    }

    @include breakpoint(md) {
      font-size: 15px;
    }

    &--filled {
      box-shadow: $box-shadow-input;
      background-color: $white;
    }

    &::placeholder {
      font-family: $font-primary;
      font-weight: 500;
      font-size: 15px;
      color: $gray-400;
      text-align: center;
      line-height: 110px;
    }
  }
}
</style>