<template>
    <div class="camp-table-cell-inner">

        <p v-if="title" class="camp-table-label">{{title}}</p>
        <div class="camp-statuses-total"
             :style="{
                               backgroundColor: `#cacaca`,
                      }"
        >

                <template v-if="getStatuses.length<1 || !value">
                    <span class="camp-tooltip"

                          :style="{
                               backgroundColor: `#cacaca`,
                               width: `100%`,
                           }"
                    >
                    </span>
                </template>
                <template v-else>
                <span class="camp-tooltip"
                      v-for="(status, index) in getStatuses" :key="index"
                      :style="{
                           backgroundColor: status.color,
                           width: calcWidth(status.id),
                       }"
                    >
                </span>
                </template>


        </div>


    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {  calculatePercentageFromString} from '@/components/Crm/helpers/dataHelpers';

    export default {
        name: "StatusTotal",
        props: {
            statuses: {
                type: Array,
                required: false
            },
            group_id:0,
            item_id:Number,
            field:null,
            value:null,
            title:'',

        },
        methods:{
            calcWidth(statusid)
            {
                if(this.value) {

                    const prc = calculatePercentageFromString(this.value);
                    if(prc[statusid]) {
                        return  prc[statusid];
                    }
                }
                return '';
            },

        },
        computed:{
            ...mapGetters({
                getStatusValues: 'crm/getStatusValues',
            }),
            getStatuses() {

                //console.log("statuses",this.getStatusValues[this.field]);
                if (this.getStatusValues && this.field in this.getStatusValues) {

                    return this.getStatusValues[this.field];
                } else {
                    return [];
                }
            },

        },
        mounted(){
            if(this.value) {
                const prc = calculatePercentageFromString(this.value);

                //console.log("statuses field=", prc);
            }
        }
    }
</script>

<style scoped>

</style>
