<template>
  <BasePopup
    :cannotBeClosed="true"
  >
    <div class="prizeAfterRegistration">
      <div class="prizeAfterRegistration__titlePopup titlePopup">
        Юхууу! 🎉 <br>
        Добро пожаловать в CAMP!
      </div>
      <div class="prizeAfterRegistration__description">
        <p class="prizeAfterRegistration__text">Ты завершил регистрацию, а это значит, что умеешь доводить начатое до конца! 😉</p>
        <p class="prizeAfterRegistration__text">Мы ценим таких людей, поэтому переходи в настройки, добавляй магазин и забирай подарок!</p>
      </div>
      <BaseButton
        class="prizeAfterRegistration__button button button--primary"
        :preloader="isLoading"
        @click="prizeHandler"
      >
        Забрать подарок!
      </BaseButton>
    </div>
  </BasePopup>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'PrizeAfterRegistrationPopup',
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
      setInstructed: 'settings/setInstructed',
      getUser: 'auth/getUser',
      openPopup: 'popup/openPopup',
      getBalanceInfo: 'userBalance/getBalanceInfo'
    }),
    prizeHandler() {

      if(!this.isLoading) {
        this.isLoading = true

        this.setInstructed({gift: true})
        .then(() => {
          this.$router.push('/account/settings')
          this.getUser()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.prizeAfterRegistration {
  width: 556px;
  max-width: 100%;
  padding: 30px;
  position: relative;
  @include breakpoint(md) {
    width: 400px;
    padding: 130px 20px 30px;
    text-align: center;
  }

  @include breakpoint(sm) {
    width: 330px;
    padding: 112px 25px 40px;
  }

  @include breakpoint(xs) {
    width: 300px;
    padding: 86px 25px 30px;
  }

  &::before {
    content: '';
    width: 192px;
    height: 223px;
    background-size: 100%;
    @include verticalCenter();
    right: -40px;
    @include backgroundImage('popup/prizeAfterRegistrationPopup-image.webp', center);
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      @include backgroundImage('popup/prizeAfterRegistrationPopup-image-2x.webp', center);
    }
    @include breakpoint(md) {
      transform: translateX(-50%)  scaleX(-1);
      right: 0;
      left: 50%;
      top: -94px;
      width: 174px;
      height: 200px;
    }
    @include breakpoint(sm) {
      width: 140px;
      height: 162px;
      top: -70px;
    }
    @include breakpoint(xs) {
      width: 123px;
      height: 140px;
      top: -80px;
    }
  }

  &__titlePopup {
    text-align: left;
    @include breakpoint(md) {
      text-align: center;
      font-size: 18px;
      line-height: 18px;
    }
    @include breakpoint(sm) {
      font-size: 16px;
      line-height: 16px;
      margin: 0 -15px 20px;
    }
  }

  &__titlePopup, &__description {
    margin-bottom: 20px;
  }

  &__description {
    max-width: 350px;
  }

  &__text {
    margin-bottom: 10px;
    font-size: 15px;
    @include breakpoint(md) {
      font-size: 14px;
      line-height: 14px;
    }
    strong {
      font-weight: 600;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__button {
    max-width: 182px;
    height: 35px;
    border-radius: 10px;
    @include breakpoint(md) {
      max-width: 100%;
    }
  }

}

</style>