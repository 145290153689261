/*
* Сюда импортируются компоненты всех попап окон, которые автоматически регистрируются в main.js
*/

//auth
import LoginPasswordRecoveryPopup from '@/views/Auth/LoginPasswordRecoveryPopup'

//sidebar mobile
import SidebarMobile from '@/components/Sidebar/SidebarMobile'

//Prize after registr
import PrizeAfterRegistrationPopup from '@/components/PrizeAfterRegistrationPopup'
//Top up Alert
import TopUpAlertPopup from '@/components/TopUpAlertPopup'

//instuction
import FirstStepInstructionPopup from '@/components/Instruction/FirstStepInstructionPopup'
import SecondStepInstructionPopup from '@/components/Instruction/SecondStepInstructionPopup'
import FourthStepInstructionPopup from '@/components/Instruction/FourthStepInstructionPopup'
import FinalStepInstructionPopup from '@/components/Instruction/FinalStepInstructionPopup'
import DashboardInstructionPopup from '@/components/Crm/Desks/DashboardInstructionPopup'
import BloggersInstructionPopup from '@/components/Crm/Desks/BloggersInstructionPopup'

//price bidder
import PriceBidderPopup from '@/components/Popup/PriceBidderPopup'

//account
import AccountPopup from '@/components/Popup/AccountPopup'

//admin
import AdminPopup from '@/components/Popup/AdminPopup'

export default [
  LoginPasswordRecoveryPopup,
  SidebarMobile,
  PrizeAfterRegistrationPopup,
  TopUpAlertPopup,
  FirstStepInstructionPopup,
  SecondStepInstructionPopup,
  FourthStepInstructionPopup,
  FinalStepInstructionPopup,
  DashboardInstructionPopup,
  BloggersInstructionPopup,
  PriceBidderPopup,
  AccountPopup,
  AdminPopup
]