<template>
  <div
    class="notices"
    v-click-outside="close"
  >
    <div class="notices__wrapper">
      <div
        class="notices__inner"
        @click="dropdownOpen = !dropdownOpen"
      >
        <SvgUse    
          :width="18"     
          :height="20" 
          id="bellInActive"
          v-if="notifications.length"
        />
        <SvgUse    
          :width="18"     
          :height="20" 
          id="bell"
          v-else
        />
        <div
          class="notices__counter"
          v-if="notifications.length"
        >
          {{notifications.length}}
        </div>
      </div>
      <div
        class="notices__dropdown"
        v-if="dropdownOpen"
      >

        <div class="notices__list" v-if="notifications.length">
          <div class="notices__listInner">

            <div
              class="notices__listItem"
              v-for="notice in notifications" :key="notice.id"
            >
              <div
                class="notices__listItemInner"
                :class="{'notices__listItemInner--cursorPointer' : notice.additional_data ? notice.additional_data.includes('ticket_id') : false}"
                @click="notice.additional_data && notice.additional_data.includes('ticket_id') ? redirectToTicket(notice.id, notice.additional_data) : null"
              >
                <p class="notices__listItemMessage">{{notice.message}}</p>
                <div class="notices__listItemInfo">
                  <div class="notices__listItemInfoDate"> 
                    <span>{{notice.created_at}}</span>
                  </div>
                  <button
                    class="notices__listItemInfoButton"
                    @click="removeNotification(notice.id)"
                  >
                    Закрыть
                  </button>
                </div>
              </div>
            </div>

          </div>
          <button
            class="notices__listButton"
            @click="removeAllNotification"
          >
            Закрыть все
          </button>
        </div>

        <div class="notices__empty" v-else>Нет уведомлений</div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  data() {
    return {
      dropdownOpen: false,
      updateNotification: null,
    }
  },
  computed: {
    ...mapState({
      notifications: state => state.userNotification.notifications
    })
  },
  methods: {
    close() {
      this.dropdownOpen = false
    },
    redirectToTicket(id, ticketQueryParam) {
      this.$router.push(`/account/tickets?${ticketQueryParam}`)
      this.removeNotification(id)
      this.close()
    },
    ...mapActions({
      notificationList: 'userNotification/notificationList',
      markAsRead: 'userNotification/markAsRead',
      markAsAllRead: 'userNotification/markAsAllRead',
    }),
    removeNotification(id) {
      this.markAsRead(id)
    },
    removeAllNotification() {
      this.markAsAllRead()
    }
  },
  mounted() {
    this.notificationList()

    this.updateNotification = setInterval(() => {
      this.notificationList()
    }, 10000)
  },
  unmounted() {
    clearInterval(this.updateNotification)
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.notices {

  &__wrapper {
    position: relative;
  }
  
  &__inner {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    box-shadow: 0 2px 15px 0 rgba($black, .15);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  &__counter {
    position: absolute;
    left: 21px;
    top: 0;
    padding: 0 4px;
    height: 15px;
    min-width: 15px;
    text-align: center;
    border-radius: 100%;
    background-color: $red;
    font-size: 10px;
    line-height: 15px;
    font-weight: 700;
    color: $white;
  }

  &__dropdown {
    width: 290px;
    position: absolute;
    top: 33px;
    right: 20px;
    background-color: $white;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0px 5px 10px 0px rgba($black, .07);
    z-index: 9998;
    @include breakpoint(md) {
      top: 50px;
    }
    @include mediaMaxWidth(375) {
      width: 250px;
      right: 0;
    }
  }

  &__list {
    &Inner {
      max-height: 430px;
      overflow-y: auto;
      margin-bottom: 10px;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
    }
    &Item {
      margin-bottom: 5px;
      &:last-child {
          margin-bottom: 0;
        }
      &Inner {
        background-color: $gray-100;
        border-radius: 10px;
        padding: 10px;
        &--cursorPointer {
          cursor: pointer;
        }
      }
      &Message {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 10px;
      }
      &Info {
        display: flex;
        justify-content: space-between;
        color: $gray-400;
        font-size: 10px;
        line-height: 10px;
        &Date {
          span {
            &:first-child {
              margin-right: 5px;
            }
          }
        }
        &Button {
          color: inherit;
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
    &Button {
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      display: block;
      width: 100%;
    }
  }

  &__empty {
    padding: 20px 0;
    font-size: 12px;
    line-height: 14px;
    color: $gray-400;
    text-align: center;
    background-color: $gray-100;
    border-radius: 10px;
  }

}
</style>