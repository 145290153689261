<template>
    <BasePopup>
        <div class="adminLogsDeletePopup">
            <div class="adminLogsDeletePopup__content">
                <div class="adminLogsDeletePopup__contentTitle">
                Вы действительно хотите удалить аккаунт?
                </div>
                <div class="adminLogsDeletePopup__contentActions">
                <button 
                    class="adminLogsDeletePopup__contentActions--btn"
                    @click="handleDelete"
                >
                    Да
                </button>
                <button 
                    class="adminLogsDeletePopup__contentActions--btn"
                    @click="closePopup"
                >
                    Нет
                </button>
                </div>
            </div>
        </div>
    </BasePopup>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
    name: 'AdminLogDetailDeletePopup',
    props: {
        deleteLogs: Array,
        mode: String,
    },
    computed: {
        ...mapState({
            currentUser: (state) => state.auth.user,
            checkedLog: (state) => state.adminLog.checkedLog,
            selectedLogList: (state) => state.adminLog.selectedLogList,
            userParams: (state) => state.adminLog.userParams,
        }),
    },
    methods: {
        ...mapActions({
            closePopup: "popup/closePopup",
            deleteLog: "adminLog/postDeleteLog"
        }),
        ...mapMutations({
            setCheckedLog: 'adminLog/setCheckedLog',
        }),
        handleDelete() {
            var data = {
                'logs' : this.deleteLogs,
                'currentUser' : this.currentUser,
                'mode' : this.mode,
                'userParams' : this.userParams, 
            }
            this.deleteLog(data);
            this.closePopup();
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.adminLogsDeletePopup {
  padding: 60px 100px 40px 100px;

  &__content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    &Title {
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      font-weight: 500;
    }
    &Actions {
      display: flex;
      gap: 20px;

      &--btn {
        width: 120px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 43px;
        border: 1px solid $green-100;
        color: $green-100;
        font-size: 15px;
        line-height: 15px;
        font-weight: 400;
      }
    }
  }
}
</style>