<template>
  <BasePopup
  >
    <div class="PBSingleProductManualPricePriorityPopup">
      <div class="PBSingleProductManualPricePriorityPopup__sidebar">
        <div class="PBSingleProductManualPricePriorityPopup__sidebarTitlePrimary titlePrimary titlePrimary--small">
          Цена успешно установлена
        </div>
        <div class="PBSingleProductManualPricePriorityPopup__sidebarArticle">Артикул: <br> 102931782391</div>
        <div class="PBSingleProductManualPricePriorityPopup__sidebarPrice">Новая цена: <br> 300 ₽</div>
      </div>
      <div class="PBSingleProductManualPricePriorityPopup__content">
        <div class="PBSingleProductManualPricePriorityPopup__contentTitlePrimary titlePrimary titlePrimary--small">
          Оставить установленную вручную цену только до конца текущего автоматического цикла или бессрочно?
        </div>
        <p class="PBSingleProductManualPricePriorityPopup__contentText">
          При выборе «До конца цикла» контроль над установкой цены на данный товар вернется к автоматическому
          значению с началом нового цикла. Новый цикл начинается в 12.00 и в 00.00 по МСК
        </p>
        <div class="PBSingleProductManualPricePriorityPopup__contentControls">
          <button
            class="PBSingleProductManualPricePriorityPopup__contentButton button button--transparent button--green">До
            конца цикла</button>
          <button
            class="PBSingleProductManualPricePriorityPopup__contentButton button button--transparent button--green">Бессрочно</button>
        </div>
      </div>
    </div>
  </BasePopup>
</template>

<script>
import Keywords from '@/components/Keywords/Keywords'

export default {
  name: 'PBSingleProductManualPricePriorityPopup'
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.PBSingleProductManualPricePriorityPopup {

  max-width: 100%;
  width: 1050px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 20px;
  text-align: center;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    width: 322px;
    height: 100%;
    background-image: linear-gradient(334deg, #00D1C1 -42.54%, #00D488 73.71%);
    @include breakpoint(xl) {
      width: 283px;
    }
    @include breakpoint(md) {
      background-image: linear-gradient(348deg, #00D1C1 42.73%, #00D488 95.59%);
      width: 100%;
    }
  }
  @include breakpoint(xl) {
    width: 926px;
  }
  @include breakpoint(md) {
    flex-direction: column;
    width: 340px;
  }
  @include breakpoint(sm) {
    width: 322px;
  }
  @include breakpoint(xs) {
    width: 280px;
  }

  &__sidebar {
    flex: 0 0 270px;
    color: $white;
    position: relative;
    z-index: 1;
    @include breakpoint(xl) {
      flex: 0 0 236px;
    }
    @include breakpoint(md) {
      flex: 0 0 100%;
      padding: 30px 42px 42px;
    }
    @include breakpoint(xs) {
      padding: 20px 12px 40px;
    }
    &TitlePrimary, &Article {
      margin-bottom: 20px;
    }
    &TitlePrimary {
      @include breakpoint(xs) {
        margin-bottom: 10px;
      }
    }
    &Article {
      @include breakpoint(md) {
        margin-bottom: 10px;
      }
    }
    &Article, &Price {
      font-size: 15px;
      @include breakpoint(xl) {
        font-size: 13px;
      }
      @include breakpoint(md) {
        font-size: 15px;
        br {
          display: none;
        }
      }
    }
  }

  &__content {
    position: relative;
    background-color: $white;
    border-radius: 20px;
    padding: 50px 85px 50px;
    &::before {
      content: '';
      @include verticalCenter();
      left: -25px;
      width: 54px;
      height: 54px;
      background-image: url("data:image/svg+xml,%3Csvg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='27' cy='27' r='26' transform='rotate(-90 27 27)' fill='white' stroke='%2300D488' stroke-width='2'/%3E%3Cpath d='M13 25.5C12.1716 25.5 11.5 26.1716 11.5 27C11.5 27.8284 12.1716 28.5 13 28.5L13 25.5ZM42.0607 28.0607C42.6464 27.4749 42.6464 26.5251 42.0607 25.9393L32.5147 16.3934C31.9289 15.8076 30.9792 15.8076 30.3934 16.3934C29.8076 16.9792 29.8076 17.9289 30.3934 18.5147L38.8787 27L30.3934 35.4853C29.8076 36.0711 29.8076 37.0208 30.3934 37.6066C30.9792 38.1924 31.9289 38.1924 32.5147 37.6066L42.0607 28.0607ZM13 28.5L41 28.5L41 25.5L13 25.5L13 28.5Z' fill='%2300D488'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      @include breakpoint(md) {
        top: -25px;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
      }
    }
    @include breakpoint(xl) {
      padding: 42px 24px 42px 57px;
    }
    @include breakpoint(md) {
      padding: 56px 30px 30px;
    }
    @include breakpoint(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include breakpoint(xs) {
      padding: 40px 15px 18px;
    }
    &TitlePrimary {
      margin-bottom: 20px;
    }
    &Text {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 25px;
      @include breakpoint(md) {
        margin-bottom: 30px;
      }
    }
    &Controls {
      display: flex;
      justify-content: space-between;
      max-width: 380px;
      margin: 0 auto;
      @include breakpoint(md) {
        flex-direction: column;
        align-items: center;
      }
    }
    &Button {
      max-width: 180px;
      height: 30px;
      @include breakpoint(md) {
        &:first-child {
          margin-bottom: 10px;
        }
        height: 40px;
      }
      @include breakpoint(xs) {
        height: 30px;
      }
    }
  }

}

</style>
