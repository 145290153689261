<template>
  <div class="sidebarHeader">

    <div class="sidebarHeader__logo">

      <img
        src="/images/logo-sidebar.svg"
        alt="YouCamp"
        class="sidebarHeader__logoImage"
        v-show="!sidebarRollUp"
      >

    </div>

    <div class="sidebarHeader__txt">
      <div
        class="sidebarHeader__title title title--sidebar"
        v-show="!sidebarRollUp"
      >
        {{$route.meta.title}}
      </div>
      <ProductPrice    
        v-if="$route.name === 'PBSingleProduct'"    
        v-show="!sidebarRollUp"
        class="sidebarHeader__productPrice"
        :classModificator="'productPrice--sidebar'"
        :priceDiscount="singleProduct.min_price"
        :priceWithoutDiscount="singleProduct.max_price"
      />
    </div>
    
    <template v-if="$route.meta.backButtonSidebar && !this.$store.state.isMobile">
      <BaseSidebarBackButton       
        @click="backPage" 
      />
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ProductPrice from '@/components/ProductPrice'

export default {
  components: {
    ProductPrice    
  },
  data() {
    return {
      pageURL: null,
    }
  },
  computed: {
    ...mapState({
      sidebarRollUp: state => state.sidebarRollUp,
      singleProduct: state => state.priceBidderSingleProduct.singleProduct
    }),
  },
  methods: {
    backPage() {
      if(this.$route.name === 'PBSingleProduct') this.pageURL = '/price_bidder'
      if(!this.pageURL) this.$router.push({path: '/'})
      else this.$router.push({path: this.pageURL})
      this.backIconHover = false
    }
  },
  watch: {
    $route (newVal, oldVal) {
      if(!oldVal.fullPath.includes('/account') && newVal.fullPath.includes('/account')) this.pageURL = oldVal.fullPath
    },
  },
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.sidebarHeader {
  
  > div {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__logo {
    height: 29px;
  }

  &__productPrice {
    margin-top: 10px;
  }

  &__title {
    font-size: 24px;
    line-height: 24px;
    @include breakpoint(md) {
      font-size: 32px;
      line-height: 28px;
    }
    @include breakpoint(sm) {
      font-size: 24px;
      line-height: 21px;
    }
  }
}

</style>