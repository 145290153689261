export default {

  namespaced: true,

  state: () => ({
    isLoading: false,
    isRunConfirmed: false,
    isStatisticRunning: false,
    singleProduct: {},
    manualPrice: null,
    leaderData: [],
    graphData: [],
    graphData2: [],
    productBidder: {},
  }),

  getters: {
    
  },

  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload
    },
    setIsRunConfirmed(state, payload) {
      state.isRunConfirmed = payload
    },
    setIsStatisticRunning(state, payload) {
      state.isStatisticRunning = payload
    },
    setSingleProduct(state, payload) {
      state.singleProduct = payload.data.data.product
    },
    setSingleProductBidderManualPrice(state, payload) {
      state.manualPrice = Number(payload)
    },
    setSingleProductBidderDiscount(state, payload) {
      state.singleProduct.discount = Number(payload)
    },
    setSingleProductBidderMinPrice(state, payload) {
      state.singleProduct.bidder_min_price = payload
    },
    setSingleProductBidderMaxPrice(state, payload) {
      state.singleProduct.bidder_max_price = payload
    },
    setSingleProductBidderAutoPrice(state, payload) {
      state.singleProduct.auto = payload
    },
    setSingleProductBidderHasHistory(state, payload) {
      state.singleProduct.bidder_has_history = payload
    },
    setSingleProductBidder(state, payload) {
      state.singleProduct.bidder = payload
    },
    setSingleProductMinPrice(state, payload) {
      state.singleProduct.min_price = payload
    },
    setSingleProductMaxPrice(state, payload) {
      state.singleProduct.max_price = payload
    },
    setSingleProductKeywords(state, keyword) {
      state.singleProduct.keywords.push(keyword)
    },
    setSingleProductKeywordsRemove(state, removeKeyword) {
      state.singleProduct.keywords = state.singleProduct.keywords.filter(keyword => keyword !== removeKeyword)
    },
    setProductBidder(state, payload) {
      state.productBidder = payload.data.data.product.wb_bidder
    },
    updateProductBidder(state, bidderData) {
      state.productBidder = bidderData
    },
    setLeaderData(state, payload) {
      state.leaderData = payload
    },
    setGraphData(state, payload) {
      state.graphData = payload?.data?.data ?? []
    },
    setGraphData2(state, payload) {
      state.graphData2 = payload?.data?.data ?? []
    }
  },

  actions: {

    updateBidderData({state, commit}) {

      const data = {
        product_id: state.singleProduct.id,
        keywords: state.singleProduct.keywords ? state.singleProduct.keywords.join(", ") : null,
        is_auto: state.singleProduct.auto ? true : false,
        max_price: state.singleProduct.bidder_max_price,
        min_price: state.singleProduct.bidder_min_price,
      }

      return new Promise((resolve => {
        YC.priceBidderApi.updateBidderForProduct(data)
          .then(products => {
            resolve()
          })
          .finally(() => {
            commit('setIsLoading', false)
          })
      }))
    },

    runBidder({state, commit}) {

      return new Promise((resolve => {
        YC.priceBidderApi.runBidderForProduct({product_id: state.singleProduct.id})
          .then(results => {
            resolve()
          })
          .catch(results => {
            if(results?.response?.data?.error) {
              console.log(results?.response?.data?.error)
            }
          })
      }))
    },

    stopBidder({state, commit}) {

      return new Promise((resolve => {
        YC.priceBidderApi.stopBidderForProduct({product_id: state.singleProduct.id})
          .then(results => {
            resolve()
          })
          .catch(results => {
            if(results?.response?.data?.error) {
              console.log(results?.response?.data?.error)
            }
          })
      }))
    },

    searchLeader({commit}, product_id) {
          commit('setIsLoading', true)
          const days=30;
          return new Promise((resolve => {
              YC.priceBidderApi.runSearchLeader(product_id,days)
                  .then(products => {
                      //commit('setProducts', products)

                      resolve()
                  })
                  .finally(() => {
                      commit('setIsLoading', false)
                  })
          }))
    },

    getSingleProduct({commit}, product_id) {
      return new Promise(((resolve,reject) => {
        YC.priceBidderApi.getSingleProduct(product_id)
          .then(product => {
            commit('setSingleProduct', product)
            commit('setSingleProductBidderManualPrice', product.data.data.product.max_price)
            commit('setProductBidder', product)
            resolve()
          })
          .catch(error => {
            console.error('Ошибка при получении продукта:', error)
            reject();
          })
      }))

    },

    getDiagram({commit}, product_id) {

      return new Promise((resolve => {
        YC.priceBidderApi.getProductDiagram(product_id)
          .then(graphData => {
              commit('setGraphData', graphData)

            resolve()
          })
          .finally(() => {

          })
      }))
    },

    getDiagram2({commit}, product_id) {

      return new Promise((resolve => {
        YC.priceBidderApi.getProductDiagram2(product_id)
          .then(graphData => {
              commit('setGraphData2', graphData)

            resolve()
          })
          .finally(() => {

          })
      }))
    },

    searchAllLeaders({commit}, payload) {
      return new Promise((resolve, reject) => {
        YC.priceBidderApi.searchAllLeaders(payload)
          .then(response => {
            resolve()
          })
          .catch((error) => {
            reject()
          })
      })
    },

    getLeadersByDay({commit}, payload) {
      return new Promise((resolve, reject) => {
        YC.priceBidderApi.get('wbbidder/leaders-v2/'+payload.product_id+'/'+payload.countdays)
          .then(response => {
            commit('setLeaderData', response?.data ?? null)
            resolve()
          })
          .catch((error) => {
            reject()
          })
      })
    },

    runStatisticService({commit}, payload) {
      return new Promise((resolve, reject) => {
          YC.priceBidderApi.get('wbbidder/get-one-product-leader/'+payload.product_id)
              .then(response => {
                  resolve(response)
              })
              .catch((error) => {
                  reject(error)
              })
      })
    },

    setProductPrice({state, commit}) {

      const data = {
        product_id: state.singleProduct.id,
        new_price: state.manualPrice,
        discount: state.singleProduct.discount
      }

      return new Promise((resolve, reject) => {
        YC.priceBidderApi.post('wbbidder/set-product-price/' + data.product_id, data)
          .then(response => {
            console.log(response.data)
            commit('setSingleProductMinPrice', response.data.min_price)
            commit('setSingleProductMaxPrice', response.data.max_price)
            resolve()
          })
          .catch((error) => {
            reject()
          })
          .finally(() => {

          })
      })
    },

      setPriceFromLeader(store, payload) {

          return new Promise((resolve, reject) => {
              YC.priceBidderApi.post('wbbidder/set-leader-price/'+payload.product_id,payload)
                  .then(response => {
                      let editSingleProductData=store?.state?.singleProduct
                      console.log( "singleProduct" , editSingleProductData )
                      console.log( "response" , response )

                      // if(editSingleProductData?.min_price && editSingleProductData?.max_price)
                      // {
                      //     editSingleProductData.min_price=parseInt(response?.data?.min_price) ?? 0
                      //     editSingleProductData.max_price=parseInt(response?.data?.max_price) ?? 0
                      //     store.state.singleProduct=editSingleProductData
                      //
                      // }
                      resolve(response)
                  })
                  .catch((error) => {
                      reject()
                  })
                  .finally(() => {

                  })
          })
      },
  }

}