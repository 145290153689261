<template>
  <BasePopup
    :classModifier="'team-confirm'"
  >
    <div class="teamConfirmPopup">
      <div class="teamConfirmPopup__title">
        Вы действительно хотите удалить сотрудника?
      </div>

      <div class="teamConfirmPopup__controls">
        <button class="teamConfirmPopup__button button button--transparent button--green">Да</button>
        <button class="teamConfirmPopup__button button button--transparent button--green">Нет</button>
      </div>
    </div>
  </BasePopup>
</template>

<script>

export default {
  name: 'TeamConfirmPopup'
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.teamConfirmPopup {

  text-align: center;
  padding: 60px 0 40px;
  @include breakpoint(xs) {
    padding: 40px 0 30px;
  }

  &__title {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 25px;
    @include breakpoint(md) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__controls {
    display: flex;
    justify-content: center;
  }

  &__button {
    flex: 0 0 120px;
    height: 30px;
    &:first-child {
      margin-right: 20px;
    }
    @include breakpoint(md) {
      flex: 0 0 120px;
      height: 40px;
    }
  }

}

</style>