<template>
  <div
    class="userStore__listItem"
  >
    <div class="userStore__listItemName">{{store.legalName}}</div>
    <BaseSwitcher    
      class="userStore__switcher"  
      :classModificator="'switcher--userStore'"    
      :active="store.active"
      @click="handlerSwitcher(store)"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  props: {
    store: {
      type: Object
    }
  },
  data() {
    return {
      userStoreIsOpen: false,
      test: null
    }
  },
  computed: {
    ...mapState({
      currentStore: state => state.userStore.currentStore,
      storeList: state => state.userStore.storeList,
      params: state => state.priceBidderSingleProduct.params,
    })
  },
  methods: {
    ...mapActions({
      switchStore: 'userStore/switchStore',
      getCurrentStore: 'userStore/getCurrentStore',
      productList: 'priceBidderBasicProducts/productList'
    }),
    handlerSwitcher(store) {
      if(!store.active) { 
        this.switchStore(store.id)
        .then(() => {
          this.getCurrentStore()
          if(this.$route.name === 'PBBasicProducts') {
            this.productList(this.params)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.userStore {
  font-size: 15px;
  line-height: 12px;
  @include breakpoint(xl) {
    font-size: 13px;
    line-height: 10px;
  }
  @include breakpoint(md) {
    font-size: 14px;
    line-height: 11px;
  }
  &__title {
    margin-bottom: 10px;
    cursor: pointer;
  }
  &__list {
    background-color: $gray-100;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &Item {
      display: flex;
      align-items: center; 
      justify-content: space-between;
      &Name {
        flex: 0 0 170px;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &__switcher {
        flex: 1;
      }
    }
  }
}

</style>