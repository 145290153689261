<template>
  <button
    @mouseover="flag = true"
    @mouseleave="flag = false"
    v-click-outside="close"
  >
    <SvgUse :width="15" :height="15" id="questionMark" />
  </button>
  <div class="questionTooltipContent" v-if="flag">
    <p class="questionTooltipContent__text">
      Статус - имеет разные цвета в зависимости от активности пользователя.
    </p>
    <p class="questionTooltipContent__text">
      Если человек заходил в течение недели статус -
      <span class="questionTooltipContent__textSuccess">Активен</span>
    </p>
    <p class="questionTooltipContent__text">
      Если человек зарегистрировался и больше не заходил -
      <span class="questionTooltipContent__textBlock"> активен</span>
    </p>
    <p class="questionTooltipContent__text">
      Если человек не заходил больше месяца -
      <span class="questionTooltipContent__textWarning">Пингануть</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "QuestionIconButton",
  data() {
    return {
      flag: false,
    };
  },
  methods: {
    close() {
      this.flag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";

button {
  min-width: 15px;
  display: flex;
  align-items: center;
}

.questionTooltipContent {
  position: absolute;
  width: 368px;
  top: 35px;
  margin-left: 290px;
  z-index: 9999;
  padding: 20px 30px;
  background-color: white;
  border-radius: 0 20px 20px 20px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-flow: column;
  text-align: start;
  gap: 15px;

  &__text {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    white-space: wrap;
    &Success {
      color: #00d488;
    }
    &Block {
      color: #ff5959;
    }
    &Warning {
      color: #ff9900;
    }
  }
}
</style>
