<template>
  <div class="general">
    <div class="general__content">

      <div class="general__entry">Вход выполнен</div>
      <div class="general__user">Привет, {{user.username}}</div>

      <HomeNavigation
        class="general__homeNavigation"
        :class="{'general__homeNavigation--center' : user.role !== 'Admin'}"
      />

      <a
        href="#"
        @click.prevent="logout"
        class="general__logout"
      >
        Выйти
      </a>

    </div>
  </div>
</template>

<script>
import HomeNavigation from '@/views/YouCampHomeNavigation'
import {mapActions, mapState} from 'vuex'

export default {
  components: {
    HomeNavigation
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.general {

  font-family: $font-secondary;
  font-weight: 400;

  &__content {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    @include breakpoint(xl) {
      max-width: 1300px;
    }
    @include breakpoint(md) {
      max-width: 460px;
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__entry {
    margin-bottom: 10px;
    line-height: 14px;
    @include breakpoint(xl) {
      font-size: 14px;
    }
    @include breakpoint(md) {
      margin-bottom: 20px;
      font-size: 16px;
    }
    @include breakpoint(xs) {
      font-size: 14px;
      line-height: 12px;
      margin-bottom: 10px;
    }
  }

  &__user {
    font-size: 32px;
    margin-bottom: 60px;
    line-height: 24px;
    @include breakpoint(xl) {
      font-size: 24px;
      margin-bottom: 40px;
    }
    @include breakpoint(md) {
      font-size: 24px;
      margin: 0 auto 80px;
    }
    @include breakpoint(xs) {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 40px;
    }
  }

  &__homeNavigation {
    margin: 0 -10px 186px;
    display: flex;
    margin-bottom: 186px;
    @include mediaMaxHeight(900) {
      margin-bottom: 100px;
    }
    @include breakpoint(xl) {
      margin-bottom: 90px;
    }
    @include breakpoint(md) {
      flex-direction: column;
      margin-bottom: 40px;
    }
    @include breakpoint(xs) {
      margin-bottom: 40px;
    }
    &--center {
      justify-content: center;
    }
  }

  &__logout {
    color: $dark-green-400;
    line-height: 14px;
    display: block;
    @include breakpoint(md) {
      padding-bottom: 90px;
    }
    @include breakpoint(xs) {
      line-height: 12px;
    }
  }

}
</style>
