<template>
  <BasePopup
    :classModifier="'account'"    
  >

    <div class="balanceTopUpPopup">

      <div class="balanceTopUpPopup__titlePopup titlePopup">Пополнить баланс</div>
      <FormInput 
        type="number"
        class="balanceTopUpPopup__formInput"
        :placeholder="'Сумма пополнения*'"
        :note="'*Минимальная сумма пополнения 1000 руб.'"
        v-model.trim="depositAmount"
        @inputValueUpdate="removeZeroAtStartPosition(depositAmount)"
        :validations="[
          {
            rule: $validations.isStringNotEmpty(depositAmount),
            message: 'Укажите сумму пополнения'
          },
          {
            rule: !$validations.isStringNotEmpty(depositAmount) || $validations.isStringLengthBiggerThan(depositAmount, 3),
            message: 'Минимальная сумма пополнения 1000 руб.'
          },
          {
            rule: !requestIsFailed,
            message: 'Что-то пошло не так...'
          }
        ]"
      />

      <div class="balanceTopUpPopup__note">
        <div class="balanceTopUpPopup__noteTitle">Примечание</div>
        <div class="balanceTopUpPopup__noteTextWrap">
          <p class="balanceTopUpPopup__noteText">Баланс в CAMP ≠ Баланс на Wildberries</p>
          <p class="balanceTopUpPopup__noteText">За показ рекламы деньги списываются с личного кабинета поставщика на Wildberries. CAMP списывает только за Биддер рекламы — 10% от бюджета кампании.</p>
        </div>
      </div>

      <BaseButton
        class="balanceTopUpPopup__button button--primary"
        @click="sendDeposit"
        :preloader="isLoading"
      >
        Пополнить
      </BaseButton>
    </div>

  </BasePopup>
</template>

<script>
import FormInput from '@/components/Form/FormInput'
import BasePopup from '../../../components/Global/BasePopup.vue'
import { mapActions } from 'vuex';

export default {
  name: 'BalanceTopUpPopup',
  data() {
    return {
      depositAmount: '',
      isLoading: false,
      requestIsFailed: false
    }
  },
  methods: {
    ...mapActions({
      getBalanceInfo: 'userBalance/getBalanceInfo',
      postCashInBalance: 'userBalance/postCashInBalance',
      closePopup: 'popup/closePopup'
    }),
    sendDeposit() {
      YC.EventBus.emit('validations')

      if(this.depositAmountIsValid) {
        this.isLoading = true
        this.postCashInBalance({amount: this.depositAmount})
        .then(() => {
          this.getBalanceInfo()
          this.closePopup()
          this.depositAmount = ''
        })
        .catch(() => {
          this.requestIsFailed = true
          setTimeout(() => {
            YC.EventBus.emit('validations')
          })
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    },
    removeZeroAtStartPosition(string) {
      const regexp = /^[1-9][0-9]*$/
      this.requestIsFailed = false
      if(!regexp.test(string)) {
        this.depositAmount = ''
      }
    }
  },
  components: {
    FormInput,
    BasePopup
  },
  computed: {
    depositAmountIsValid() {
      return this.$validations.isStringNotEmpty(this.depositAmount) && this.$validations.isStringLengthBiggerThan(this.depositAmount, 3)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.balanceTopUpPopup {

  &__note, &__formInput {
    margin-bottom: 30px;
    @include breakpoint(sm) {
      margin-bottom: 20px;
    }
  }

  &__note {
    &Title {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 10px;
    }
    &Text {
      font-size: 12px;
      line-height: 13px;
      display: block;
    }
  }

  &__button {
    height: 50px;
    max-width: 175px;
    margin: 0 auto;
  }

}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}

</style>