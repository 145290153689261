<template>
    <div class="camp-table-header" style="top: 50px">
        <div class="camp-table-row camp-row camp-row-nowrap">
            <div class="camp-table-cell camp-table-cell__name camp-table-cell-sticky" style="width: 520px;  left: 90px">

                <div class="camp-table-left-indicator" style="background-color: #0097EC;"></div>

                <div class="camp-table-stub"></div>


                <div class="camp-table-cell-checkbox">
                    <label class="camp-form-checkbox__label">
                        <input type="checkbox" name="">
                        <span v-show="false" class="camp-form-checkbox" aria-label="Отметить"><span class="camp-form-checkbox__ic"></span></span>
                    </label>
                </div>

                <div class="camp-table-cell-inner">
                    <div class="camp-table-cell-title">
                        <div class="camp-table-cell-title__display">
                            <div class="camp-table-cell-title__display-inner">
                                <p class="camp-table-cell-title__display-text">Название ключа</p>
                                <div class="camp-table-cell-title__edit" style="display: none;">
                                    <input type="text" value="Название ключа">
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Cell menu -->
                    <div class="camp-table-cell-menu ">
                        <button class="camp-btn-ic camp-btn-ic__sorting camp-tooltip camp__is-active" type="button" aria-label="Сортировать по возрастанию" data-tippy-content="Сортировать по возрастанию">
		<span class="camp-btn-ic">
			<!-- sorting-up.svg -->
			<span class="camp-btn-ic__icon">
				<svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
	<path d="M8.70711 1.17375C8.31658 0.783228 7.68342 0.783228 7.29289 1.17375L0.928932 7.53771C0.538408 7.92824 0.538408 8.5614 0.928932 8.95193C1.31946 9.34245 1.95262 9.34245 2.34315 8.95193L8 3.29507L13.6569 8.95193C14.0474 9.34245 14.6805 9.34245 15.0711 8.95193C15.4616 8.5614 15.4616 7.92824 15.0711 7.53771L8.70711 1.17375ZM9 13.8809L9 1.88086L7 1.88086L7 13.8809L9 13.8809Z"></path>
</svg>

			</span>
		</span>
                        </button>

                        <button class="camp-btn-ic camp-btn-ic__sorting camp-tooltip" type="button" aria-label="Сортировать по убыванию" data-tippy-content="Сортировать по убыванию">
		<span class="camp-btn-ic">
			<!-- sorting-down.svg -->
			<span class="camp-btn-ic__icon">
				<svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
	<path d="M7.29289 13.588C7.68342 13.9785 8.31658 13.9785 8.70711 13.588L15.0711 7.224C15.4616 6.83348 15.4616 6.20032 15.0711 5.80979C14.6805 5.41927 14.0474 5.41927 13.6569 5.80979L8 11.4666L2.34315 5.80979C1.95262 5.41927 1.31946 5.41927 0.928932 5.80979C0.538408 6.20032 0.538408 6.83348 0.928932 7.22401L7.29289 13.588ZM7 0.880859L7 12.8809L9 12.8809L9 0.880859L7 0.880859Z"></path>
</svg>

			</span>
		</span>
                        </button>

                    </div>
                    <!-- //Cell menu -->
                </div>

                <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>
            </div>
            <!-- //tcell -->


            <template  v-for="n in 15" :key="n">
            <div class="camp-table-cell" style="width: 120px;">
                <div class="camp-table-cell-inner">
                    <div class="camp-table-cell-title">
                        <div class="camp-table-cell-title__display">
                            <div class="camp-table-cell-title__display-inner">
                                <p class="camp-table-cell-title__display-text">Страница</p>
                                <div class="camp-table-cell-title__edit" style="display: none;">
                                    <input type="text" value="Текст">
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Cell menu -->
                    <div class="camp-table-cell-menu ">
                        <button class="camp-btn-ic camp-btn-ic__sorting camp-tooltip camp__is-active" type="button" aria-label="Сортировать по возрастанию" data-tippy-content="Сортировать по возрастанию">
		<span class="camp-btn-ic">
			<!-- sorting-up.svg -->
			<span class="camp-btn-ic__icon">
				<svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
	<path d="M8.70711 1.17375C8.31658 0.783228 7.68342 0.783228 7.29289 1.17375L0.928932 7.53771C0.538408 7.92824 0.538408 8.5614 0.928932 8.95193C1.31946 9.34245 1.95262 9.34245 2.34315 8.95193L8 3.29507L13.6569 8.95193C14.0474 9.34245 14.6805 9.34245 15.0711 8.95193C15.4616 8.5614 15.4616 7.92824 15.0711 7.53771L8.70711 1.17375ZM9 13.8809L9 1.88086L7 1.88086L7 13.8809L9 13.8809Z"></path>
</svg>

			</span>
		</span>
                        </button>

                        <button class="camp-btn-ic camp-btn-ic__sorting camp-tooltip" type="button" aria-label="Сортировать по убыванию" data-tippy-content="Сортировать по убыванию">
		<span class="camp-btn-ic">
			<!-- sorting-down.svg -->
			<span class="camp-btn-ic__icon">
				<svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
	<path d="M7.29289 13.588C7.68342 13.9785 8.31658 13.9785 8.70711 13.588L15.0711 7.224C15.4616 6.83348 15.4616 6.20032 15.0711 5.80979C14.6805 5.41927 14.0474 5.41927 13.6569 5.80979L8 11.4666L2.34315 5.80979C1.95262 5.41927 1.31946 5.41927 0.928932 5.80979C0.538408 6.20032 0.538408 6.83348 0.928932 7.22401L7.29289 13.588ZM7 0.880859L7 12.8809L9 12.8809L9 0.880859L7 0.880859Z"></path>
</svg>

			</span>
		</span>
                        </button>

                    </div>
                    <!-- //Cell menu -->
                </div>

                <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>
            </div>
            <!-- //tcell -->

            <div class="camp-table-cell " style="width: 120px;">
                <div class="camp-table-cell-inner">
                    <div class="camp-table-cell-title">
                        <div class="camp-table-cell-title__display">
                            <div class="camp-table-cell-title__display-inner">
                                <p class="camp-table-cell-title__display-text">Позиция</p>
                                <div class="camp-table-cell-title__edit" style="display: none;">
                                    <input type="text" value="Текст">
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Cell menu -->
                    <div class="camp-table-cell-menu ">
                        <button class="camp-btn-ic camp-btn-ic__sorting camp-tooltip camp__is-active" type="button" aria-label="Сортировать по возрастанию" data-tippy-content="Сортировать по возрастанию">
		<span class="camp-btn-ic">
			<!-- sorting-up.svg -->
			<span class="camp-btn-ic__icon">
				<svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
	<path d="M8.70711 1.17375C8.31658 0.783228 7.68342 0.783228 7.29289 1.17375L0.928932 7.53771C0.538408 7.92824 0.538408 8.5614 0.928932 8.95193C1.31946 9.34245 1.95262 9.34245 2.34315 8.95193L8 3.29507L13.6569 8.95193C14.0474 9.34245 14.6805 9.34245 15.0711 8.95193C15.4616 8.5614 15.4616 7.92824 15.0711 7.53771L8.70711 1.17375ZM9 13.8809L9 1.88086L7 1.88086L7 13.8809L9 13.8809Z"></path>
</svg>

			</span>
		</span>
                        </button>

                        <button class="camp-btn-ic camp-btn-ic__sorting camp-tooltip" type="button" aria-label="Сортировать по убыванию" data-tippy-content="Сортировать по убыванию">
		<span class="camp-btn-ic">
			<!-- sorting-down.svg -->
			<span class="camp-btn-ic__icon">
				<svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
	<path d="M7.29289 13.588C7.68342 13.9785 8.31658 13.9785 8.70711 13.588L15.0711 7.224C15.4616 6.83348 15.4616 6.20032 15.0711 5.80979C14.6805 5.41927 14.0474 5.41927 13.6569 5.80979L8 11.4666L2.34315 5.80979C1.95262 5.41927 1.31946 5.41927 0.928932 5.80979C0.538408 6.20032 0.538408 6.83348 0.928932 7.22401L7.29289 13.588ZM7 0.880859L7 12.8809L9 12.8809L9 0.880859L7 0.880859Z"></path>
</svg>

			</span>
		</span>
                        </button>

                    </div>
                    <!-- //Cell menu -->
                </div>
                <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>
            </div>
            <!-- //tcell -->
            </template>


        </div>
    </div>

</template>

<script>
export default {
    name: "SubgroupHeaderRow"
}
</script>

<style scoped lang="scss">

</style>
