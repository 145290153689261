export default {
    namespaced: true,

    state: () => ({
        isLoading: true,
        negativeLists: [],
        columnTitles: {
            negatives: [
                {
                    sortKey: 'user_id',
                    label: 'ID Пользователя',
                    sortState: false
                },
                {
                    sortKey: 'Amount',
                    label: 'Сумма',
                    sortState: false
                },
                {
                    sortKey: 'Product',
                    label: 'Товар',
                    sortState: false
                },
                {
                    sortKey: 'Product ID',
                    label: 'ID Товара',
                    sortState: false
                },
                {
                    sortKey: 'Product MP SKU',
                    label: 'Артикул',
                    sortState: false
                },
                {
                    sortKey: 'Product Price',
                    label: 'Цена Товара',
                    sortState: false
                },
                {
                    sortKey: 'Date',
                    label: 'Дата',
                    sortState: false
                }
            ]
        },
        selectedSortKey: 'Date',
        selectedSortDirection: false,
    }),

    mutations: {
        setIsLoading(state, payload) {
            state.isLoading = payload;
        },
        setNegativeLists(state, payload) {
            state.negativeLists = payload
        },
        setSelectedSortKey(state, payload) {
            state.selectedSortKey = payload
        },
        setSelectedSortDirection(state, payload) {
            state.selectedSortDirection = payload
        },
        setNegativeColumnTitles(state, payload) {
            state.columnTitles.negatives = payload
        },
        setSortNegativeLists(state) {
            let data = state.negativeLists
            const selectedSortKey = state.selectedSortKey
            const selectedSortDirection = state.selectedSortDirection
            data.sort(function(a, b) {
                if (selectedSortKey === "Product" || selectedSortKey === "Product MP SKU" || selectedSortKey === "Date") {
                    if (!selectedSortDirection){
                        return b[selectedSortKey].localeCompare(a[selectedSortKey]);
                    } else {
                        return a[selectedSortKey].localeCompare(b[selectedSortKey]);
                    }
                } else {
                    if (!selectedSortDirection){
                        return b[selectedSortKey]-a[selectedSortKey];
                    } else {
                        return a[selectedSortKey]-b[selectedSortKey];
                    }
                }
            })

            state.negativeLists = data
        }
    },

    getters: {

    },

    actions: {
        getNegativeBalanceLists({commit}) {
            return new Promise((resolve) => {
                YC.adminApi.get('balance/negative')
                    .then((response) => {
                        console.log(response)
                        commit("setIsLoading", false);
                        if (response.data.data.length !== 0) {
                            response.data.data.sort(function(a, b) {
                                return b.Date.localeCompare(a.Date)
                            })
                            commit('setNegativeLists', response.data.data)
                        }
                        resolve()
                    })
                    .finally(() => {

                    })
            })
        }
    }
}