<template>
  <BasePopup>
    <div class="adminLogDetailPopup">
      <div class="adminLogDetailPopupTable">
        <div class="adminLogDetailPopupTableHeader">
          <div class="adminSellersDetailPopupTableHeader__title">
            Журнал действий
          </div>
          <FilterDropdown
            :label="'Искать за период'"
            :customStyle="'nowrap'"
            :page="'adminLogs'"
          />
        </div>
        <div class="adminLogDetailPopupTableContent">
          <!-- Table Header -->
          <div class="adminLogDetailPopupTableContent__header">
            <div class="adminLogDetailPopupTableContent__headerBottom">
              <div class="adminLogDetailPopupTableContent__headerBottomCol"></div>
              <div class="adminLogDetailPopupTableContent__headerBottomCol"></div>
              <div class="adminLogDetailPopupTableContent__headerBottomCol"></div>
              <div class="adminLogDetailPopupTableContent__headerBottomCol"></div>
              <div class="adminLogDetailPopupTableContent__headerBottomCol"></div>
              <div class="adminLogDetailPopupTableContent__headerBottomCol">
                Страница
              </div>
              <div class="adminLogDetailPopupTableContent__headerBottomCol">
                Вид изменений
              </div>
              <div class="adminLogDetailPopupTableContent__headerBottomCol">
                Было
              </div>
              <div class="adminLogDetailPopupTableContent__headerBottomCol">
                Стало
              </div>
              <div class="adminLogDetailPopupTableContent__headerBottomCol">

              </div>
            </div>
          </div>
          <!-- Table Content -->
          <div class="adminLogDetailPopupTableContent__content">
            <div class="adminLogDetailPopupTableContent__contentList">
              <AdminLogDetailListItem
                v-for="log in selectedLogList"
                :log="log"
                :key="log.id"
              />
            </div>
          </div>
        </div>
        <div class="adminLogDetailPopupTable__action" v-bind:class="{'adminLogDetailPopupTable__actionChecked': this.checkedLog.length}">
          <div class="adminLogDetailPopupTable__actionCheckedContent" v-if="this.checkedLog.length">
            <div class="adminLogDetailPopupTable__actionCheckedContent--content">
              <div class="adminLogDetailPopupTable__actionCheckedContent--contentCount">
                <div class="adminLogDetailPopupTable__actionCheckedContent--contentCountNumber">
                  {{ this.checkedLog.length }}
                </div>
                <div class="adminLogDetailPopupTable__actionCheckedContent--contentCountText">
                  Действий выбрано
                </div>
              </div>
              <div class="adminLogDetailPopupTable__actionCheckedContent--contentBtn">
                <button 
                  class="adminLogDetailPopupTable__actionCheckedContent--contentBtnDelete"
                  @click="openPopup({
                    component: 'AdminLogDetailDeletePopup',
                    props: {
                      deleteLogs: this.checkedLog,
                      mode: 'multiple',
                    }
                  })"
                >
                  Удалить
                </button>
                <button
                  class="adminLogDetailPopupTable__actionCheckedContent--contentBtnCancel"
                  @click="handleCancel"
                >
                  Отмена
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BasePopup>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FilterDropdown from '@/views/Admin/UIComponents/FilterDropdown.vue';
import AdminLogDetailListItem from '@/views/Admin/AdminLog/AdminLogDetailListItem.vue';
export default {
  name: 'AdminLogDetailPopup',
  components: {
    FilterDropdown,
    AdminLogDetailListItem,
  },
  computed: {
    ...mapState({
      selectedLogList: (state) => state.adminLog.selectedLogList,
      checkedLog: (state) => state.adminLog.checkedLog,
    })
  },
  methods: {
    ...mapActions({
      openPopup: "popup/openPopup",
    }),
    handleCancel() {
      var checkBoxs = document.getElementsByClassName('adminLogDetailListItem__bottomCol--checkbox');
      for(var i = 0; i < checkBoxs.length; i++) {
        if(checkBoxs[i].checked === true) {
          checkBoxs[i].click();
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/_mixins.scss';
@import 'src/scss/base/project/_var.scss';

.adminLogDetailPopup {
  width: 98vw;
  height: 90vh;
  margin-bottom: 30px;
  overflow: auto;

  &Table {
    &::before {
      content: "";
      display: block;
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: $white;
      box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.15);
      height: 120px;
    }

    &Header {
      position: relative;
      margin-top: -120px;
      padding: 30px 60px 20px;
      display: flex;
      align-items: center;
      gap: 60px;
      &__title {
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        white-space: nowrap;
        font-family: $font-primary;
      }

      @include breakpoint(xl) {
        font-size: 16px;
        line-height: 16px;
      }

      @include breakpoint(md) {
        font-size: 20px;
        line-height: 20px;
      }

      @include mediaMaxWidth(710) {
        padding: 30px 20px 5px;
        flex-direction: column;
        align-items: start;
        gap: 10px;
      }

      @include breakpoint(sm) {
        font-size: 14px;
        line-height: 14px;
      }
    }
    &Content {
      position: relative;
      overflow: auto;
      // overflow-y: hidden;
      &__header {
        position:absolute;
        padding: 10px 20px;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;
        font-family: $font-primary;
        position: relative;
        background-color: transparent;

        @include breakpoint(xl) {
          font-size: 14px;
          line-height: 14px;
          width: 1780px;
        }

        @include mediaMaxWidth(1430) {
          font-size: 15px;
          line-height: 15px;
        }

        @include breakpoint(sm) {
          font-size: 14px;
          line-height: 14px;
        }
        &Bottom {
          display: flex;
          align-items: center;
          padding: 0 10px;
          &Col {
            height: 35px;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            flex: 1 1 211px;
            white-space: nowrap;
            padding: 10px 0 0 10px;
            vertical-align: middle;

            &:first-child {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;
            }
            &:nth-child(2) {
              flex-basis: 100px;
            }
            &:nth-child(3) {
              flex-basis: 150px;
            }
            &:nth-child(4) {
              flex-basis: 300px;
            }
            &:last-child {
              width: 50px;
              min-width: 50px;
              max-width: 50px;
            }
          }
        }
      }
      &__content {
        padding: 20px;
        height: 70vh;
        &List {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
    &__action {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 20px;
      background-color: $white;
      border-radius: 0 0 20px 20px;
      box-shadow: 0 0 15px 0 rgba($black, 0.15);
      transition: max-height 0.25s ease-out;
      transition-property: height;
      &Checked {
        transition: max-height 0.25s ease-out;
        height: 100px;
        transition-duration: 1s;
        transition-property: height;
        overflow: hidden;
        &Content {
          display: flex;
          justify-content: center;
          padding: 20px;
          &--content {
            display: flex;
            align-items: center;
            gap: 40px;
            height: 60px;
            &Count {
              display: flex;
              align-items: center;
              gap: 20px;
              &Number{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 95px;
                height: 60px;
                background-color: $gray-100;
                border-radius: 20px;
                font-size: 20px;
                line-height: 16px;
                font-weight: 500;
                font-family: $font-secondary;
              }
              &Text {
                font-size: 20px;
                line-height: 20px;
                font-weight: 500;
                white-space: nowrap;
                font-family: $font-primary;
              }
            }
            &Btn {
              display: flex;
              align-items: center;
              gap: 20px;
              &Delete {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;
                height: 30px;
                background-color: $green-100;
                border: 1px solid $green-100;
                border-radius: 43px;
                box-shadow: 0 5px 10px 0 rgba($black, 0.07);
                color: $white;
                font-size: 15px;
                line-height: 12px;
                font-weight: 400;
                font-family: $font-secondary;
              }
              &Cancel {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;
                height: 30px;
                background-color: $white;
                border: 1px solid $green-100;
                border-radius: 43px;
                box-shadow: 0 5px 10px 0 rgba($black, 0.07);
                color: $green-100;
                font-size: 15px;
                line-height: 12px;
                font-weight: 400;
                font-family: $font-secondary;
              }
            }
          }
        }
      }
    }
  }
}
</style>
