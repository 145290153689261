import {isNumber} from "chart.js/helpers";

export function getGroupData(data) {

}
/*
*   Получаем экземпляр объекта группы(таблицы) из списка
 */
export function getGroupById(tablesData,group_id) {
    //console.log("getGroupById=",tablesData,group_id);
    let found=[];
    tablesData.forEach((tableData, index) => {
        if(tableData.id===group_id) {
            found= tablesData[index];
        }
    });
    return found;
}


export function setGroupData(tablesData, group_id, data) {
    tablesData.forEach((tableData, index) => {
        if (tableData.id === group_id) {
            // Обновляем только те поля, которые есть в обоих объектах
            Object.keys(data).forEach(key => {
                if (tableData.hasOwnProperty(key)) {
                    tableData[key] = data[key];
                }
            });
        }
    });
    return tablesData;
}

export function getTimeStamp() {
    return Date.now();
}

export function findObjectByField(array, fieldToFind) {
    return array.find(obj => obj.field === fieldToFind);
}

export function calcResult(tableData,objbyfield,field) {

    if(!tableData || !objbyfield || !field)
    {
        return 0;
    }
    let total = 0;
    if(objbyfield){

        if(objbyfield.type==='status') {
            total = '';
        }
        if(objbyfield.calc && objbyfield.calc==='nototal') {
            return  '';
        }
    }
    //console.log("this.tableCellsParams=",this.tableCellsParams);
    tableData.forEach((itm, index) => {
        if(itm[field]) {

            if(objbyfield && objbyfield.type==='status')
            {
                total += ',' + itm[field];
                if(parseInt(itm[field])<1) {
                    //total += ',' + itm[field];
                }

                //total.push(itm[field]);
                // console.log("footer-cellValue:",index,field, itm[field],total);
            }
            else if (objbyfield && objbyfield.type === 'date') {

                total = '' + itm[field];

            }
            else {
                total += parseInt(itm[field]);
            }
        }
    });
    return total.toString();

}
export function calcTotalData(tableCellsParams, tablesData) {
    const summary = {
        totalPrices: 0,
        statusCounts: {}
    };

    // Проходим по массиву tablesData
    tablesData.forEach(record => {
        // Обработка поля price
        if (record.hasOwnProperty('items')) {
            record.items.forEach(item => {
                if (item.price) {
                    summary.totalPrices += parseFloat(item.price);
                }
            });
        }

        // Обработка поля status
        if (record.hasOwnProperty('items')) {
            record.items.forEach(item => {
                if (item.status) {
                    summary.statusCounts[item.status] = (summary.statusCounts[item.status] || 0) + 1;
                }
            });
        }
    });

    // Вычисляем процентное соотношение для каждого статуса
    const totalCount = tablesData.reduce((total, record) => {
        if (record.hasOwnProperty('items')) {
            return total + record.items.length;
        } else {
            return total;
        }
    }, 0);

    for (const status in summary.statusCounts) {
        const count = summary.statusCounts[status];
        summary.statusCounts[status] = ((count / totalCount) * 100).toFixed(2) + '%';
    }

    return summary;
}

const calculatePercentageFromString = (str) => {

    //const numbers = str.split(',').map(numStr => parseInt(numStr.trim()));
    const numbers = str.split(',')
        .map(numStr => parseInt(numStr.trim()))
        .filter(num => !isNaN(num));

    // Используем ранее описанный метод для расчета процентного содержания
    return calculatePercentage(numbers);
};

const calculatePercentage=(array)=> {
    const counts = {};
    const totalCount = array.length;

    // Подсчитываем количество вхождений каждого числа
    array.forEach(num => {
        counts[num] = (counts[num] || 0) + 1;
    });

    // Вычисляем процентное содержание каждого числа
    const percentages = {};
    for (const num in counts) {
        percentages[num] = ((counts[num] / totalCount) * 100).toFixed(2) + '%';
    }

    return percentages;
};


export function calculateSumBySelector(selector) {
    //const elements = document.querySelectorAll('.f_lead_price_9');
    const elements = document.querySelectorAll(selector);

    let sum = 0;
    elements.forEach(element => {


        const val = parseInt(element.getAttribute('data-val')); // Получаем значение атрибута data-val и преобразуем его в число
        if (!isNaN(val)) {
            sum += val; // Прибавляем значение к сумме, если оно является числом
        }
    });


    return sum;
}

export  {calculatePercentageFromString , calculatePercentage};

function getVal(rowData,valname,type="int")
{
    var value
    if(!isNaN(rowData[valname]) ) {

        if (type === 'int') {
            value = parseInt(rowData[valname]);
        } else if (type === 'float') {
            value =  parseFloat(rowData[valname]);
        }

        return value;
    }
    else {
        return 0;
    }


}


function uniteconomicsCalc(rowData)
{
    var p_heigh=!isNaN(rowData['p_heigh']) ? parseFloat(rowData['p_heigh']) : 0;
    var p_lenght=!isNaN(rowData['p_lenght']) ? parseFloat(rowData['p_lenght']) : 0;
    var p_width=!isNaN(rowData['p_width']) ? parseFloat(rowData['p_width']) : 0;

    var p_volume=p_heigh*p_lenght*p_width;

    var batch_price=!isNaN(rowData['batch_price']) ? parseFloat(rowData['batch_price']) : 0;


    //var unit_price = !isNaN(rowData['unit_price']) ? parseFloat(rowData['unit_price']) : 0;
    var sale_price = !isNaN(rowData['sale_price']) ? parseFloat(rowData['sale_price']) : 0;
    var vb_commission_percentage = !isNaN(rowData['vb_commission_percentage']) ? parseFloat(rowData['vb_commission_percentage']) : 0;
    var logistics_to = !isNaN(rowData['logistics_to']) ? parseFloat(rowData['logistics_to']) : 0;
    var advertising_percentage = !isNaN(rowData['advertising_percentage']) ? parseFloat(rowData['advertising_percentage']) : 0;
    var quantity = !isNaN(rowData['quantity']) ? parseFloat(rowData['quantity']) : 0;
    var storage_per_day_per_item = !isNaN(rowData['storage_per_day_per_item']) ? parseFloat(rowData['storage_per_day_per_item']) : 0;
    var number_of_days = !isNaN(rowData['number_of_days']) ? parseFloat(rowData['number_of_days']) : 0;
    var tax_percentage = !isNaN(rowData['tax_percentage']) ? parseFloat(rowData['tax_percentage']) : 0;

    var markup_percentage = !isNaN(rowData['markup_percentage']) ? parseFloat(rowData['markup_percentage']) : 0;

    var logistics_back = !isNaN(rowData['logistics_back']) ? parseFloat(rowData['logistics_back']) : 0;
    var storage_for_item = !isNaN(rowData['storage_for_item']) ? parseFloat(rowData['storage_for_item']) : 0;

    var unit_price= quantity ? (batch_price / quantity) : 0;
    // Calculate Себестоимость от цены
    var cost_percentage = sale_price ? (unit_price / sale_price) : 0;

    // Calculate vb_commission_rub
    var vb_commission_rub = sale_price * (vb_commission_percentage / 100);
    //(Цена продажи * кол-во шт) - Комиссия WB в %
    var vb_commission_batch=vb_commission_percentage ? vb_commission_rub*quantity : 0;
    // Calculate remaining_after_commission
    var remaining_after_commission = sale_price - vb_commission_rub;

    // Calculate logistics_percentage
    var logistics_percentage = !isNaN(rowData['logistics_percentage']) ? parseFloat(rowData['logistics_percentage']) : 0;// sale_price ? (logistics_to / sale_price) : 0;

    // Calculate remaining_after_logistics
    var remaining_after_logistics = remaining_after_commission - logistics_to;// + (sale_price * logistics_percentage / 100));

    // Calculate  Наценка
    //var markup_percentage = unit_price ? (((sale_price / unit_price) * 100) - 100) : 0;

    // Calculate Валовая маржа
    var gross_margin_percentage = sale_price ? (((sale_price - unit_price) / sale_price) * 100) : 0;

    // Calculate Маржа в %
    var net_margin_percentage = (gross_margin_percentage - cost_percentage - (vb_commission_percentage ) - logistics_percentage);

    // Calculate advertising_rub_per_item
    var advertising_rub_per_item = sale_price * (advertising_percentage / 100);

    // Calculate advertising_rub_total
    var advertising_rub_total = advertising_rub_per_item * quantity;

    // Calculate storage_for_30_days_per_item
    var storage_for_30_days_per_item = storage_for_item *quantity;

    // Calculate storage_percentage
    var storage_percentage = sale_price ? ((storage_for_30_days_per_item / sale_price) * 100) : 0;

    // Calculate profitability_percentage
    cost_percentage *=100;
    logistics_percentage *=100;

    const ue_sale_prc=markup_percentage ?? 0;
    //(logistics_to+((100-ue_sale_prc)/100)*50)/(1-((100-ue_sale_prc)/100));
    //const ue_logistics_all=logistics_to+ ue_sale_prc


    //=((заказ шт-(заказ шт/100*процент выкупа))*логистика туда)+((заказ шт-(заказ шт/100*процент выкупа))*логистика обратно)

    //const ue_logistics_all=ue_sale_prc>0 ? ((logistics_to+((100-ue_sale_prc)/100)*50)/(1-((100-ue_sale_prc)/100))).toFixed(2) :logistics_to.toFixed(2);



    //const ue_logistics_all = ue_sale_prc>0 ? (((quantity-(quantity/100*ue_sale_prc))*logistics_to)+((quantity-(quantity/100*ue_sale_prc))*logistics_back)).toFixed(2) : 0;
    if(ue_sale_prc>=100){logistics_back=0;}
    const ue_logistics_all =  !isNaN(rowData['logistics_percentage']) ? parseFloat(rowData['logistics_percentage']) : 0;// (logistics_to*quantity + logistics_back).toFixed(2);

    //console.log("Logistic ",ue_sale_prc,logistics_to , logistics_back, ue_logistics_all);

    //const ue_logistics_one=quantity>0 ? (ue_logistics_all/quantity).toFixed(2) : 0;
    const ue_logistics_one= !isNaN(rowData['logistics_one']) ? parseFloat(rowData['logistics_one']) : 0



    const ue_storage=0;
    const ue_cost_all=0;


    var tax_rub=sale_price*tax_percentage/100;
    var advertising_rub=sale_price*advertising_percentage/100;

    const ue_cost_total=quantity?(ue_cost_all/quantity):0;
    //const ue_margin_percentage= sale_price-vb_commission_rub-ue_logistics_all-ue_storage-advertising_rub_total-ue_cost_total;
    const prc=100-tax_percentage-vb_commission_percentage-advertising_percentage;
    // Цена продажи - Цена 1шт., ₽ - Комиссия WB в руб - Логистика с учетом % выкупа - Хранение в день, руб - Налог (перевести в руб) - ДРР (перевести в руб)

   // const ue_margin_percentage= quantity!==0 ? ((sale_price*quantity) * (prc/100) -batch_price- ue_logistics_all*quantity)  :0


    const ue_margin_percentage= quantity!==0 ? (((sale_price*quantity) * (prc/100) -batch_price- ue_logistics_all*quantity)).toFixed(2)  :0


    var ue_tax_batch = tax_percentage>0 ? tax_rub*quantity:0;

    //var drr_batch= advertising_percentage>0 ? (sale_price*quantity)-(((sale_price*quantity))*advertising_percentage/100) : 0
    var drr_batch= advertising_percentage>0 ? advertising_rub*quantity : 0

    //(цена товара*кол-во) - закуп итого - комиссия парт - логистика парт - хранение парт  - налог парт - ДРР парт
//: (Цена продажи * кол-во) - Себестоимость партия - Комиссия Вб руб / парт  - Логистика с учетом % выкупа - Хранение в день, руб - Налог руб/ партия - ДРР за партию руб
    var ue_profit_batch= (sale_price*quantity) - batch_price - vb_commission_batch-ue_logistics_all - storage_for_30_days_per_item - ue_tax_batch -  drr_batch;

    //var ue_profit_batch=(sale_price*quantity) - batch_price - ue_logistics_all

//Цена продажи- Себестоимость 1 шт - Комиссия Вб руб / шт  - Логистика за 1 шт - Хранение в день за 1 шт, руб - Налог руб / шт. - ДРР руб
    var ue_profit_itm=quantity ? ue_profit_batch/quantity :0;



    //var profitability_percentage = (sale_price*quantity) ? 100 - advertising_percentage - storage_percentage - tax_percentage :0;
    //= (Прибыль парт / (цена продажи*кол-во))*100%
    var profitability_percentage = (sale_price*quantity) ? ue_profit_batch/(sale_price*quantity)*100  :0;
    profitability_percentage=!isNaN(profitability_percentage) ? parseFloat(profitability_percentage) : 0;


    // Format numbers to 2 decimal places
    p_volume =  (p_volume/1000).toFixed(3);

    unit_price =  (unit_price).toFixed(2);
    cost_percentage =  cost_percentage.toFixed(2);
    vb_commission_rub = vb_commission_rub.toFixed(2);
    vb_commission_batch = vb_commission_batch.toFixed(0);
    remaining_after_commission = remaining_after_commission.toFixed(2);
    logistics_percentage = logistics_percentage.toFixed(2);
    remaining_after_logistics = remaining_after_logistics.toFixed(2);
    markup_percentage = markup_percentage.toFixed(2);
    gross_margin_percentage = gross_margin_percentage.toFixed(2);
    net_margin_percentage = net_margin_percentage.toFixed(2);
    advertising_rub_per_item = advertising_rub_per_item.toFixed(2);
    advertising_rub_total = advertising_rub_total.toFixed(2);
    storage_for_30_days_per_item = storage_for_30_days_per_item.toFixed(2);
    storage_percentage = storage_percentage.toFixed(2);
    profitability_percentage = profitability_percentage.toFixed(2);

    tax_rub = tax_rub.toFixed(2);
    ue_tax_batch = ue_tax_batch.toFixed(2);

    advertising_rub = advertising_rub.toFixed(2);
    drr_batch = drr_batch.toFixed(2);
    ue_profit_batch = ue_profit_batch.toFixed(2);
    ue_profit_itm = ue_profit_itm.toFixed(2);

    return {
        p_volume: p_volume,

        unit_price: unit_price,
        cost_percentage: cost_percentage,
        vb_commission_rub: vb_commission_rub,
        vb_commission_batch: vb_commission_batch,
        remaining_after_commission: remaining_after_commission,
        logistics_percentage: logistics_percentage,
        ue_logistics_one:ue_logistics_one,
        remaining_after_logistics: remaining_after_logistics,
        markup_percentage: markup_percentage,
        gross_margin_percentage: gross_margin_percentage,
        net_margin_percentage: net_margin_percentage,
        advertising_rub_per_item: advertising_rub_per_item,
        advertising_rub_total: advertising_rub_total,
        storage_for_30_days_per_item: storage_for_30_days_per_item,
        storage_percentage: storage_percentage,
        profitability_percentage: profitability_percentage,

        ue_margin_percentage:ue_margin_percentage,
        ue_logistics_all:ue_logistics_all,
        tax_rub:tax_rub,
        ue_tax_batch:ue_tax_batch,
        advertising_rub:advertising_rub,
        ue_drr_batch:drr_batch,
        ue_profit_batch:ue_profit_batch,
        ue_profit_itm:ue_profit_itm,

    };
}

function purchaseBatchesCalc(rowData){
    var quantity = getVal(rowData,'quantity',"int");

    var batch_price = getVal(rowData,'batch_price',"int") ?? 0;
    var packaging = getVal(rowData,'packaging',"int") ?? 0;
    var logistics = getVal(rowData,'logistics',"int") ?? 0;

    batch_price=isNaN(batch_price) ? 0 : batch_price;
    packaging=isNaN(packaging) ? 0 : packaging;
    logistics=isNaN(logistics) ? 0 : logistics;

    let purchase_batches_total=  batch_price+packaging+logistics;
    let price_per_unit=quantity>0? batch_price/quantity : 0;

    return  {
        purchase_batches_total:purchase_batches_total,
        price_per_unit:price_per_unit.toFixed(2),
    }
}


/*
* Расчет значения в ячейке по приведенной формуле.
 */
export function calcFormula(rowData,  ftype) {
    var sum = 0;
    // 	Итого расход
    if(ftype===1)  {

        // Проходим по всем ключам объекта rowData
        for (const key in rowData) {
            // Проверяем, начинается ли ключ с "mn" и за ним идут цифры

                if (/^mn\d+$/.test(key)) {

                    // Если да, добавляем значение поля к сумме

                    const val = parseInt(rowData[key]);
                   // console.log("key -OK", key, val);
                    if (val > 0) {
                     //   console.log("+", sum, key, val)
                        sum += parseInt(val);
                    }
                }


        }
        if(!isNaN(rowData['expense_additional_services'])) {
            sum += parseInt(rowData['expense_additional_services']);
        }
        if(!isNaN(rowData['additional_advertisement'])) {
            sum += parseInt(rowData['additional_advertisement']);
        }
        if (isNaN(sum)) {   return 0;   }
        return sum;

    }
    else if(ftype===2){

        // Проходим по всем ключам объекта rowData
        for (const key in rowData) {
            // Проверяем, начинается ли ключ с "mn" и за ним идут цифры

            if (/^mn\d+$/.test(key)) {

                // Если да, добавляем значение поля к сумме

                const val = parseInt(rowData[key]);
                // console.log("key -OK", key, val);
                if (val > 0) {
                    //   console.log("+", sum, key, val)
                    sum += parseInt(val);
                }
            }


        }

        if(!isNaN(rowData['expense_additional_services'])) {
            sum += parseInt(rowData['expense_additional_services']);
        }
        if(!isNaN(rowData['additional_advertisement'])) {
            sum += parseInt(rowData['additional_advertisement']);
        }
        const ordersQuantityVal= parseInt(rowData['orders_quantity']);

        if (isNaN(sum)) {   return 0;   }

        if(sum>0 && ordersQuantityVal>0) {
            return (sum /ordersQuantityVal).toFixed(2);
        }
        else{
            return 0;
        }
    }
    else if(ftype===3) { // Доска Себестоимость (Итоговая себестоимость)



        //Итоговая себестоимость
        if(!isNaN(rowData['total_expense']) && !isNaN(rowData['quantity'])  && parseInt(rowData['quantity'])!==0 ) {

            const total_expense=parseInt(rowData['total_expense']);
            const quantity=parseInt(rowData['quantity']);

            sum=total_expense/quantity;
            //$model->average_cost = !empty($model->quantity) ? $model->total_expense / $model->quantity : 0;
            return sum.toFixed(2);
        }
        else{

            return 0;
        }
    }
    else if(ftype===4) { // Доска Себестоимость (	Реальная себестоимость с учетом брака )

        // $model->defect_quantity = $model->quantity - ($model->quantity * ($model->defect_percentage / 100));
        // $model->real_cost = !empty($model->defect_quantity) ? $model->total_expense / $model->defect_quantity : 0;


        var total_expense=!isNaN(rowData['total_expense']) ? parseInt(rowData['total_expense']): 0;
        var defect_percentage=!isNaN(rowData['defect_percentage']) ? parseInt(rowData['defect_percentage']): 0;
        var quantity=!isNaN(rowData['quantity']) ? parseInt(rowData['quantity']): 0;

        var defect_quantity= quantity-(quantity*(defect_percentage/100));

        if(isNaN(defect_quantity) || defect_quantity===0) {    return 0;    }

        return (total_expense/defect_quantity).toFixed(2);
    }
    else if(ftype===80) {        return  uniteconomicsCalc(rowData).cost_percentage;    }
    else if(ftype===81) {        return  uniteconomicsCalc(rowData).unit_price;    }
    else if(ftype==='ue_vb_commission_rub') {        return uniteconomicsCalc(rowData).vb_commission_rub;    }
    else if(ftype==='ue_vb_commission_batch') {        return uniteconomicsCalc(rowData).vb_commission_batch;    }
    else if(ftype===83) {        return uniteconomicsCalc(rowData).remaining_after_commission;    }
    else if(ftype===84) {        return uniteconomicsCalc(rowData).logistics_percentage;    }
    else if(ftype===85) {        return uniteconomicsCalc(rowData).remaining_after_logistics;    }
    else if(ftype===86) {        return uniteconomicsCalc(rowData).markup_percentage;    }
    else if(ftype===87) {        return uniteconomicsCalc(rowData).gross_margin_percentage;    }
    else if(ftype===88) {        return uniteconomicsCalc(rowData).net_margin_percentage;    }
    else if(ftype===89) {        return uniteconomicsCalc(rowData).advertising_rub_per_item;    }
    else if(ftype===801) {        return uniteconomicsCalc(rowData).advertising_rub_total;    }
    else if(ftype===802) {        return uniteconomicsCalc(rowData).storage_for_30_days_per_item;    }
    else if(ftype===803) {        return uniteconomicsCalc(rowData).storage_percentage;    }
    else if(ftype===804) {        return uniteconomicsCalc(rowData).profitability_percentage;    }
    else if(ftype==='ue_margin_percentage') {        return uniteconomicsCalc(rowData).ue_margin_percentage;    }
    else if(ftype==='ue_logistics_all') {        return uniteconomicsCalc(rowData).ue_logistics_all;    }
    else if(ftype==='ue_logistics_one') {        return uniteconomicsCalc(rowData).ue_logistics_one;    }
    else if(ftype==='ue_tax_rub') {        return uniteconomicsCalc(rowData).tax_rub;    }
    else if(ftype==='ue_tax_batch') {        return uniteconomicsCalc(rowData).ue_tax_batch;    }
    else if(ftype==='ue_profit_itm') {        return uniteconomicsCalc(rowData).ue_profit_itm;    }
    else if(ftype==='ue_profit_batch') {        return uniteconomicsCalc(rowData).ue_profit_batch;    }
    else if(ftype==='ue_advertising_rub') {   return uniteconomicsCalc(rowData).advertising_rub;    }
    else if(ftype==='ue_drr_batch') {   return uniteconomicsCalc(rowData).ue_drr_batch;    }
    else if(ftype==="ue_volume") {
        return uniteconomicsCalc(rowData).p_volume;
    }
    else if(ftype==="pb_price_per_unit") {
        return purchaseBatchesCalc(rowData).price_per_unit;
    }
    else if(ftype==="pb_purchase_batches_total")   {
        return purchaseBatchesCalc(rowData).purchase_batches_total;
    }


}

export function getExtraType(type)
{
    const extraTypes=['','text', 'date','price','status'];
    return extraTypes[type];

}

export const TypeView = {
    DEFAULT: 0,
    ADD: 1,
};
