export default class BaseApi {

  /**
   * @constructor
  */
  constructor() {

    this.apiUrl = process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_URL_PRODUCTION + '/api/'
    : process.env.VUE_APP_URL_DEVELOP + '/api/'
 // this.apiUrl = process.env.NODE_ENV === 'production'
 //    ? 'http://127.0.0.1:8000/api/'
 //    : 'http://127.0.0.1:8000/api/'

    console.log(this.apiUrl)

    this.config = {}

  }

  /**
   * Prepare request headers
   */
  prepareRequest() {
    this.config.headers = {
      Authorization: `Bearer ${YC.store.getters['auth/getToken']}`
    };
  }

  /**
   * GET request
   * @method get
   * @param {string} endpoint - URL
   */
  get(endpoint, queryParams) {
    this.prepareRequest()
    return new Promise((resolve, reject) => {
      axios.get(this.apiUrl + endpoint, {...this.config, params: {...queryParams}})
        .then(response => {
          resolve(response)
        })
        .catch(response => {
          reject(response)
          this.requestRejected(response)
        })
    })
  }

  /**
   * POST request
   * @method post
   * @param {string} endpoint - URL
   * @param {object} payload - request payload
   */
  post(endpoint, payload) {
    this.prepareRequest()
    return new Promise((resolve, reject) => {
      axios.post(this.apiUrl + endpoint, payload, this.config)
      .then(response => {
        resolve(response);
      })
      .catch(response => {
        reject(response);
        this.requestRejected(response)
      });
    })
  }

  /**
   * PUT request
   * @method post
   * @param {string} endpoint - URL
   * @param {object} payload - request payload
   */
  put(endpoint, payload) {
    this.prepareRequest()
    return new Promise((resolve, reject) => {
      axios.put(this.apiUrl + endpoint, payload, this.config)
      .then(response => {
        resolve(response);
      })
      .catch(response => {
        reject(response);
        this.requestRejected(response)
      });
    })
  }

  requestRejected(error) {
    if (typeof error.response.status !== 'undefined') {

      if (error.response.status === 401) {
          YC.store.dispatch('auth/logout');
      }

    }
  }

}