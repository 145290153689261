<template>
  <BasePopup>
    <div class="adminUsersDeletePopUp">
      <div class="adminUsersDeletePopUp__content">
        <div class="adminUsersDeletePopUp__contentTitle">
          Вы действительно хотите удалить аккаунт {{name}} ?
        </div>
        <div class="adminUsersDeletePopUp__contentActions">
          <button class="adminUsersDeletePopUp__contentActions--btn"
            @click="deleteUser"
          >
            Да
          </button>
          <button class="adminUsersDeletePopUp__contentActions--btn"
            @click="closePopup"
          >
            Нет
          </button>
        </div>
      </div>
    </div>
  </BasePopup>
</template>

<script>
import {mapActions,mapState} from 'vuex'
export default {
  name: 'AdminUsersDeletePopUp',
  props: {
    name:String,
    user_id:Number,
  },
 computed: {
	...mapState({
    	 userList: (state) => state.adminUser.userList,
	}),
 },
  methods: {
	 ...mapActions({
		closePopup: "popup/closePopup",
		deleteUserApi: "adminUser/postDeleteUser",
	 }),
	 deleteUser(){

	    this.deleteUserApi(this.user_id)
	    this.closePopup()
	    let newUserList=this.userList
        const index = newUserList.findIndex(item => item.id === this.user_id);
        if (index !== -1) {
         newUserList.splice(index, 1);
        }
        this.$store.commit("admin/setUserList", newUserList);

	 },
  }
};
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/_mixins.scss';
@import 'src/scss/base/project/_var.scss';

.adminUsersDeletePopUp {
  padding: 60px 100px 40px 100px;

  &__content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    &Title {
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      font-weight: 500;
    }
    &Actions {
      display: flex;
      gap: 20px;

      &--btn {
        border-radius: 43px;
        border: 1px solid $green-100;
        color: $green-100;
        padding: 15px 30px;
        font-size: 15px;
        line-height: 15px;
        font-weight: 400;
      }
    }
  }
}
</style>
