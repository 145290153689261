import baseApi from '@/api/baseApi'

export default class userNotificationApi extends baseApi {

  /*
  * User notifications
  */
 
  getNotificationList() {
    return this.get("user-notification/list");
  }
  postMarkAsRead(id) {
    return this.post("user-notification/mark-as-read/" + id);
  }
  postMarkAllAsRead() {
    return this.post("user-notification/mark-all-as-read");
  }
  
}