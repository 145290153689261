<template>
  <button class="sortButton" @click="handleClick">
    {{ label }}
    <div class="sortButton__icon">
      <SvgUse
        :width="10"
        :height="8"
        id="arrowDownBlack"
        class="adminUsersIcon"
        v-bind:class="{ sortButton__iconReverse: svgState }"
      />
    </div>
  </button>
</template>

<script>
export default {
  name: "SortButton",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      svgState: false,
    };
  },
  methods: {
    handleClick() {
      this.svgState = !this.svgState;
    },
  },
};
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";

.sortButton {
  display: flex;
  width: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  font-family: "Montserrat";

  @include breakpoint(xl) {
    font-size: 14px;
    line-height: 14px;
  }
  @include breakpoint(md) {
    font-size: 15px;
    line-height: 15px;
  }
  @include breakpoint(xs) {
    font-size: 14px;
    line-height: 14px;
  }
  &__icon {
    min-width: 15px;
  }
}
</style>
