<template>
    <div class="camp-table-cell-toggler">
        <button class="camp-table-cell-toggler__btn camp-tooltip "
                :class="getClass"
                @click="toggleSubgroup"
                type="button"
                data-tippy-content="Раскрыть подгруппу"
        >
				<span class="camp-table-cell-toggler__btn-inner">
					<!-- arrow.svg -->
                    <Svg :img="'arrow'"/>

				</span>
        </button>
    </div>
</template>

<script>
import Svg from "@/components/Crm/UI/Svg.vue";
import {mapState} from "vuex";

export default {
        name: "SubgroupToggle",
        components: {Svg},
        data() {
            return {
                inputValue: this.value
            };
        },
        props: {
            value: {
                type: String,
            },
            rowIndex:Number,
        },
        computed:{
            ...mapState({
                subGroups:state => state.crm.subgroups,
            }),
            getClass(){
              return this.subGroups[this.rowIndex]?.show ? 'camp__is-active' : ''
            },
        },
        methods:{
            toggleSubgroup(){
                if(!this.subGroups[this.rowIndex]){
                    this.subGroups[this.rowIndex]={show: false};
                }
                this.subGroups[this.rowIndex].show=!(this.subGroups[this.rowIndex]?.show || false)
                this.$store.commit('crm/setSubgroups', this.subGroups);
            }
        },

    }
</script>

<style scoped>

</style>
