<template>
  <div
    class="sidebarBackButton"
    :class="{'sidebarBackButton--rollUp' : sidebarRollUp}"
    @mouseover="backIconHover = true"
    @mouseout="backIconHover = false"
  >
    <SvgUse 
      :width="30"
      :height="30"
      id="arrowLeftLightActive" 
      v-if="backIconHover"
    />
    <SvgUse 
      :width="30"
      :height="30"
      id="arrowLeftLight" 
      v-else
    />
    <span v-show="!sidebarRollUp">{{text}}</span>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'BaseSidebarBackButton',
  props: {
    text: {
      type: String,
      default: 'Назад'
    }
  },
  data() {
    return {
      backIconHover: false,
    }
  },
  computed: {
    ...mapState({
      sidebarRollUp: state => state.sidebarRollUp
    }),
  },
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.sidebarBackButton {
  font-size: 16px;
  line-height: 14px;
  max-width: max-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
  &--rollUp {
    svg {
      margin-right: 0;
    }
  }
}

</style>