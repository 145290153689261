<template>
  <BaseInstructionPopup
    :classModifier="'secondStepInstructionPopup'"
  >
    <div class="secondStepInstructionPopup">
      <BaseButton
        class="secondStepInstructionPopup__button button button--primary"
        @click="openPopupHandler"
      >
        Добавить маркетплейс
      </BaseButton>
      <p class="secondStepInstructionPopup__text">Нажмите на кнопку <span>"Добавить маркетплейс"</span> и выберете из списка тот маркетплейс, с которым планируете в дальнейшем работать</p>
      
      <div
        class="secondStepInstructionPopup__dontShow"
        @click="dontShow"
      >
        Не показывать больше
      </div>
    </div>
  </BaseInstructionPopup>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'SecondStepInstructionPopup',
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
      setInstructed: 'settings/setInstructed',
      openPopup: 'popup/openPopup',
      getUser: 'auth/getUser'
    }),
    dontShow() {
      this.closePopup()
      this.setInstructed({integration: true})
      .then(() => {
        this.getUser()
      })
    },
    openPopupHandler() {
      this.closePopup()
      this.openPopup({
        component: 'SettingsCreateMarketplacePopup'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.secondStepInstructionPopup {
  position: relative;
  padding: 20px;
  width: 500px;
  max-width: 100%;
  &::before {
    content: '';
    position: absolute;
    height: 98px;
    width: 249px;
    left: -260px;
    top: -20px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='262' height='88' viewBox='0 0 262 88' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M260.642 85.3601C262.498 83.0727 262.148 79.714 259.86 77.8582C257.573 76.0025 254.214 76.3524 252.358 78.6398C250.503 80.9272 250.853 84.2859 253.14 86.1417C255.427 87.9974 258.786 87.6475 260.642 85.3601ZM1.73438 1.00262C1.18359 1.0431 0.769887 1.52243 0.810376 2.07323L1.47005 11.049C1.51053 11.5998 1.98986 12.0135 2.54066 11.973C3.09145 11.9325 3.50516 11.4532 3.46467 10.9024L2.87829 2.92394L10.8568 2.33756C11.4076 2.29708 11.8213 1.81775 11.7808 1.26696C11.7403 0.71615 11.261 0.302464 10.7102 0.342943L1.73438 1.00262ZM256.5 80.9999C175.668 81.0002 112.138 61.0423 68.8223 41.0918C47.1641 31.1164 30.5608 21.1431 19.3778 13.6686C13.7865 9.93143 9.55052 6.81919 6.71572 4.64408C5.29833 3.55654 4.23126 2.70331 3.52024 2.12342C3.16472 1.83348 2.89822 1.61187 2.72145 1.46349C2.63307 1.38931 2.56712 1.33342 2.52369 1.29645C2.50198 1.27797 2.48589 1.26421 2.47545 1.25526C2.47023 1.25079 2.46641 1.2475 2.46401 1.24544C2.46282 1.24442 2.46208 1.24378 2.46148 1.24326C2.46109 1.24292 2.46105 1.24289 1.80768 1.99993C1.15431 2.75697 1.15498 2.75755 1.15601 2.75843C1.15682 2.75913 1.15821 2.76033 1.15983 2.76172C1.16308 2.76452 1.16776 2.76853 1.17384 2.77375C1.186 2.78417 1.20382 2.79941 1.22727 2.81937C1.27419 2.85931 1.34366 2.91818 1.43561 2.99535C1.61948 3.1497 1.89326 3.37733 2.2562 3.67333C2.98207 4.26532 4.06465 5.13085 5.49823 6.23082C8.36536 8.43073 12.6367 11.5685 18.2665 15.3314C29.5258 22.857 46.2207 32.8838 67.9856 42.9084C111.517 62.9581 175.332 83.0002 256.5 82.9999L256.5 80.9999Z' fill='white'/%3E%3C/svg%3E%0A");
    transform: rotate(3deg);
    @include breakpoint(xl) {
      width: 35px;
      height: 151px;
      background-image: url("data:image/svg+xml,%3Csvg width='42' height='159' viewBox='0 0 42 159' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36.5594 158.07C39.505 158.07 41.8928 155.682 41.8928 152.736C41.8928 149.791 39.505 147.403 36.5594 147.403C33.6139 147.403 31.2261 149.791 31.2261 152.736C31.2261 155.682 33.6139 158.07 36.5594 158.07ZM17.4522 0.791012C17.3369 0.250881 16.8056 -0.0935095 16.2654 0.0217859L7.46372 1.90051C6.9236 2.01581 6.57921 2.54712 6.6945 3.08723C6.80979 3.62735 7.3411 3.97174 7.88122 3.85646L15.705 2.18647L17.375 10.0102C17.4902 10.5504 18.0216 10.8947 18.5617 10.7794C19.1018 10.6642 19.4462 10.1329 19.3309 9.59273L17.4522 0.791012ZM37.336 152.106C4.59967 111.755 -0.358788 74.0945 2.82116 46.5506C4.4127 32.7651 8.04556 21.4965 11.2782 13.6787C12.8943 9.77037 14.4095 6.72665 15.5177 4.66456C16.0718 3.6336 16.524 2.84825 16.8361 2.32316C16.9921 2.06062 17.1131 1.86317 17.1942 1.73265C17.2348 1.66739 17.2654 1.61885 17.2855 1.58728C17.2955 1.57148 17.3029 1.55995 17.3075 1.55267C17.3099 1.54902 17.3115 1.54646 17.3125 1.54496C17.313 1.5442 17.3132 1.54388 17.3134 1.54351C17.3135 1.54345 17.3133 1.54367 16.4742 0.999752C15.6351 0.455838 15.6346 0.456585 15.6339 0.457608C15.6335 0.458309 15.6326 0.459606 15.6317 0.460995C15.6299 0.463803 15.6274 0.467678 15.6242 0.472622C15.6179 0.482525 15.6088 0.496716 15.5971 0.515164C15.5737 0.552075 15.5397 0.606015 15.4957 0.67677C15.4077 0.818295 15.2798 1.02711 15.1168 1.30136C14.7908 1.84983 14.3244 2.6602 13.756 3.71777C12.6194 5.83278 11.0745 8.93745 9.43 12.9144C6.14155 20.8672 2.45118 32.3167 0.834354 46.3212C-2.40246 74.3576 2.6817 112.565 35.7829 153.366L37.336 152.106Z' fill='white'/%3E%3C/svg%3E%0A");
      transform: rotate(310deg);
      top: -50px;
      left: -90px;
    }
    @include breakpoint(md) {
      width: 54px;
      height: 106px;
      transform: rotate(-10deg);
      top: -54px;
      left: -64px;
      background-image: url("data:image/svg+xml,%3Csvg width='54' height='106' viewBox='0 0 54 106' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.7303 105.41C32.7848 105.41 30.397 103.022 30.397 100.077C30.397 97.1311 32.7848 94.7433 35.7303 94.7433C38.6758 94.7433 41.0636 97.1311 41.0636 100.077C41.0636 103.022 38.6758 105.41 35.7303 105.41ZM53.5332 6.87244C53.9348 7.25159 53.9529 7.88449 53.5738 8.28607L47.3952 14.8301C47.016 15.2317 46.3831 15.2499 45.9815 14.8707C45.58 14.4916 45.5618 13.8587 45.9409 13.4571L51.433 7.64016L45.6161 2.14805C45.2145 1.7689 45.1964 1.13599 45.5755 0.734413C45.9547 0.33284 46.5876 0.314659 46.9891 0.69381L53.5332 6.87244ZM35.3714 101.01C20.1975 95.1746 10.3986 86.4601 5.10501 76.5729C-0.187627 66.6875 -0.927965 55.7188 1.85747 45.4714C7.42067 25.0048 27.0643 7.33967 52.818 6.59997L52.8754 8.59914C28.0294 9.31278 9.12661 26.3537 3.78744 45.996C1.12169 55.8031 1.84118 66.2396 6.8682 75.6289C11.8943 85.0165 21.2749 93.4461 36.0892 99.1432L35.3714 101.01Z' fill='white'/%3E%3C/svg%3E%0A");
    }
    @include breakpoint(sm) {
      display: none;
    }
  }
  @include breakpoint(xl) {
    width: 400px;
  }
  @include breakpoint(md) {
    width: 500px;
  }
  @include breakpoint(sm) {
    width: 360px;
  }
  &__text {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    padding-right: 20px;
    @include breakpoint(xs) {
      font-size: 13px;
      line-height: 13px;
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
    span {
      font-weight: 600;
      color: $dark-green-400;
    }
  }
  &__dontShow {
    font-size: 12px;
    line-height: 12px;
    color: $gray-450;
    cursor: pointer;
  }
  &__button {
    max-width: 234px;
    height: 30px;
    position: absolute;
    left: -373px;
    top: -80px;
    @include breakpoint(xl) {
      max-width: 220px;
      top: -78px;
      left: -233px
    }
    @include breakpoint(md) {
      top: -70px;
      left: -14px;
    }
    @include breakpoint(sm) {
      max-width: 234px;
      top: auto;
      bottom: -39px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

</style>