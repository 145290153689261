<template>
  <BasePopup 
    :classModifier="'account'"
  >
    <div class="changeRolePopup">
      <div class="changeRolePopup__titlePopup titlePopup">Сменить роль</div>
      <div class="changeRolePopup__target">Вахрушева Екатерина, менеджер по рекламе</div>
      <button
        class="changeRolePopup__buttonDelete"
        @click="openPopup({
          component: 'TeamConfirmPopup'
        })"
      >
        Удалить сотрудника
      </button>

      <div class="changeRolePopup__selectWrap">
        <BaseMultiSelect       
          :label="'Роль в магазине'"   
          class="changeRolePopup__selectWrapMultiSelect"
          :options="options"
          :customOptions="customOptions"
        />
      </div>

      <BaseButton
        class="changeRolePopup__buttonMain button--primary"
      >
        Изменить
      </BaseButton>

    </div>
  </BasePopup>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'TeamChangeRolePopup',
  data() {
    return {
      options: [
        {name: 'Администратор', subname: 'Доступны все доски и все страницы CAMP'},
        {name: 'Менеджер по рекламе', subname: 'Видит только отчеты по рекламе, заказам + Репрайсер'},
        {name: 'Менеджер по продажам', subname: 'Доступны все доски и все страницы CAMP'}
      ],
      customOptions: [
        {
          name: 'Система аналитики', 
          multiple: [
            {name: 'Товары', checked: false},
            {name: 'Реклама', checked: false},
            {name: 'Отчеты', checked: false},
            {name: 'Финансы', checked: false}
          ],
          checked: false
        },
        {name: 'Репрайсер', checked: false},
        {name: 'Биддер рекламы', checked: false},
        {name: 'SEO парсер', checked: false},
        {name: 'Биддер креативов', checked: false},
      ]
    }
  },
  methods: {
    ...mapActions({
      openPopup: 'popup/openPopup',
    }),
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.changeRolePopup {

  text-align: center;

  &__titlePopup {
    margin-bottom: 5px;
  }

  &__target {
    color: $gray-400;
    margin-bottom: 15px;
  }

  &__target, &__buttonDelete {
    font-size: 12px;
    line-height: 12px;
  }

  &__selectWrap {
    margin-bottom: 30px;
  }

  &__buttonDelete {
    color: $red;
    margin-bottom: 30px;
  }

  &__buttonMain {
    max-width: 172px;
    height: 50px;
    margin: 0 auto;
  }

}

</style>