import baseApi from '@/api/baseApi'

export default class authApi extends baseApi {

  /*
  * authorisation, registration, retrieval of user data
  */

  postLogin(login, password) {
    return this.post('auth/login', {
      login: login,
      password: password
    })
  }

  postRegistration(payload){
    return this.post('auth/register', payload)
  }

  postSendCode(phone) {
    return this.post('auth/get-code', {
      phone: phone
    })
  }

  postConfirmCode(payload) {
    return this.post('auth/check-code', payload)
  }

  postRestorePasswordRequest(payload) {
    return this.post('auth/restore-password-request', payload)
  }

  postRestorePasswordCheckCode(payload) {
    return this.post('auth/restore-password-check-code', payload)
  }

  postRestorePassword(payload) {
    return this.post('auth/restore-password', payload)
  }

  getUser() {
    return this.get('auth/user')
  }

}