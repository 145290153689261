<template>
  <div class="filterDropDown" @click.stop v-click-outside="close">
    {{ label }}
    <button
      class="filterDropDown__button"
      @click="dropDownIsOpen = !dropDownIsOpen"
    >
      <SvgUse
        :width="20"
        :height="20"
        id="arrowDownInActive"
        v-if="!dropDownIsOpen"
      />
      <SvgUse :width="20" :height="20" id="arrowDownActive" v-else />
    </button>
    <div class="filterDropDown__content" v-if="dropDownIsOpen">
      <button class="filterDropDown__content--button">Сегодня</button>
      <button class="filterDropDown__content--button">Вчера</button>
      <button class="filterDropDown__content--button">Текущая неделя</button>
      <button class="filterDropDown__content--button">Прошлая неделя</button>
      <button class="filterDropDown__content--button">Текущий месяц</button>
      <button class="filterDropDown__content--button">Прошлый месяц</button>
      <button
        class="filterDropDown__content--button"
        @click="calendarisOpen = !calendarisOpen"
      >
        Свой период
      </button>
      <div v-if="calendarisOpen">
        <VueDatePicker
          v-model="date"
          inline
          range
          locale="ru"
          :enable-time-picker="false"
          auto-apply
          disable-year-select
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "FilterDropdown",
  components: { VueDatePicker },
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dropDownIsOpen: false,
      calendarisOpen: false,
      date: null,
    };
  },
  methods: {
    handleClick() {
      this.dropDownIsOpen = !this.dropDownIsOpen;
    },
    close() {
      this.dropDownIsOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";

.filterDropDown {
  position: relative;
  font-size: 15px;
  line-height: 12px;
  display: flex;
  align-items: center;
  gap: 10px;

  @include breakpoint(xl) {
    font-size: 14px;
    line-height: 11.2px;
  }

  @include breakpoint(md) {
    font-size: 15px;
    line-height: 12px;
  }

  @include breakpoint(sm) {
    text-align: center;
    font-size: 12px;
    line-height: 9.6px;
  }

  @include mediaMaxWidth(710) {
    flex-flow: column;
  }

  &__content {
    position: absolute;
    top: 25px;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $gray-300;
    display: flex;
    flex-flow: column;
    gap: 20px;
    z-index: 9999;
    background-color: $white;
    &--button {
      width: fit-content;
      font-size: 15px;
      line-height: 15px;
      text-align: start;
      white-space: nowrap;
    }
  }
}
</style>
