<template>
  <BasePopup
  >
  <div class="PBBasicProductEditPopupKeywords">
    <Keywords        
      class="PBBasicProductEditPopupKeywords__keywords"  
      :keywords="singleProduct.keywords"
      @add="addKeyword"
      @remove="removeKeyword"
    />
    <BaseButton
      class="button--primary PBBasicProductEditPopupKeywords__button"
      @click="closePopup"
    >
      Сохранить
    </BaseButton>
  </div>
  </BasePopup>
</template>


<script>
import Keywords from '@/components/Keywords/Keywords'
import {mapState, mapMutations, mapActions} from 'vuex'

export default {
  name: 'PBBasicProductEditPopupKeywords',
  components: {
    Keywords
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup'
    }),
    ...mapMutations({
      setSingleProductKeywords: 'priceBidderSingleProduct/setSingleProductKeywords',
      setSingleProductKeywordsRemove: 'priceBidderSingleProduct/setSingleProductKeywordsRemove'
    }),
    addKeyword(newKeyword) {
      console.log(newKeyword)
      this.setSingleProductKeywords(newKeyword.value)
    },
    removeKeyword(value) {
      this.setSingleProductKeywordsRemove(value)
    }
  },
  computed: {
    ...mapState({
      singleProduct: state => state.priceBidderSingleProduct.singleProduct
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins';
@import 'src/scss/base/project/var';

.PBBasicProductEditPopupKeywords {

  max-width: 480px;
  padding: 40px;
  display: none;

  @include breakpoint(md) {
    display: block;
  }

  @include breakpoint(sm) {
    max-width: 380px;
    padding: 40px 25px;
  }

  @include mediaMaxWidth(375) {
    max-width: 314px;
  }

  @include breakpoint(xs) {
    padding: 20px 12px;
    max-width: 314px;
  }

  &__keywords {
    .keywords__area {
      box-shadow: none;
      padding: 0;
    }
  }

  &__button{
    border-radius: 43px;
    height: 40px;
    width: 220px;
    margin: 20px auto 0;
  }

}
</style>