import SvgUse from '@/components/Global/SvgUse'
import BaseFavoriteIcon from '@/components/Global/BaseFavoriteIcon'
import BaseRadioButton from '@/components/Global/BaseRadioButton'
import BaseSwitcher from '@/components/Global/BaseSwitcher'
import BaseButton from '@/components/Global/BaseButton'
import BaseTooltip from '@/components/Global/BaseTooltip'
import BaseSearch from '@/components/Global/BaseSearch'
import BaseSearchMobile from '@/components/Global/BaseSearchMobile'
import BaseSorting from '@/components/Global/BaseSorting'
import BaseFilterDropDown from '@/components/Global/BaseFilterDropDown'
import BasePopup from '@/components/Global/BasePopup'
import BaseInstructionPopup from '@/components/Global/BaseInstructionPopup'
import BaseCloseButton from '@/components/Global/BaseCloseButton'
import BaseInputEdit from '@/components/Global/BaseInputEdit'
import BaseMultiSelect from '@/components/Global/BaseMultiSelect'
import BaseButtonLoader from '@/components/Global/BaseButtonLoader'
import BaseSettingField from '@/components/Global/BaseSettingField'
import BaseSettingFieldWithPopup from '@/components/Global/BaseSettingFieldWithPopup'
import BasePreloader from '@/components/Global/BasePreloader'
import BaseButtonBack from '@/components/Global/BaseButtonBack'
import BaseSidebarBackButton from '@/components/Global/BaseSidebarBackButton'
import BaseFieldFilled from '@/components/Global/BaseFieldFilled'
import SortButton from '@/components/Global/SortButton.vue';
import QuestionIconButton from '@/components/Global/QuestionIconButton.vue';
import BaseDropDown from '@/components/Global/BaseDropDown.vue';
import FilterDropdown from './FilterDropdown.vue';
import SortDropdown from './SortDropdown.vue';
import SearchDropdown from './SearchDropdown.vue';

export default [
  SvgUse,
  BaseFavoriteIcon,
  BaseRadioButton,
  BaseSwitcher,
  BaseButton,
  BaseTooltip,
  BaseSorting,
  BaseFilterDropDown,
  BaseSearch,
  BaseSearchMobile,
  BasePopup,
  BaseInstructionPopup,
  BaseCloseButton,
  BaseInputEdit,
  BaseMultiSelect,
  BaseButtonLoader,
  BaseSettingField,
  BaseSettingFieldWithPopup,
  BasePreloader,
  BaseButtonBack,
  FilterDropdown,
  SortDropdown,
  SearchDropdown,
  SortButton,
  QuestionIconButton,
  BaseDropDown,
  BaseSidebarBackButton,
  BaseFieldFilled
]