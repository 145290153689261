<template>
  <svg
    
    :width="width"
    :height="height">
    <use
        :xlink:href="getXlinkHref">
    </use>
  </svg>
</template>

<script>
    export default {
        name: 'SvgUse',
        props: {
            width: {
                type: Number,
                default: 24
            },
            height: {
                type: Number,
                default: 24
            },
            id: {
                type: String,
                default: ''
            }
        },
        computed: {
            getXlinkHref() {
                return '/images/icons.svg#' + this.id
            }
        }
    };
</script>
