<template>
  <div class="multiSelect" v-click-outside="close">
    <div
      class="multiSelect__current"
      @click="optionsIsOpen = !optionsIsOpen"
    >
      <div
        class="multiSelect__default"
        v-if="!selectedOpt.name && !selectedOpt.custom"
      >
        {{label}}
      </div>
      <div
        class="multiSelect__selected"
        v-else
      >
        <template v-if="selectedOpt.name">
          <span
            class="multiSelect__selectedName"
          >
            {{selectedOpt.name}}
          </span>
          <span
            class="multiSelect__selectedSubName"
            v-if="selectedOpt.subname"
          >
            {{selectedOpt.subname}}
          </span>
        </template>
        <template v-else>
          <span
            class="multiSelect__selectedSubName multiSelect__selectedSubName--fw-500"
          >
            {{selectedOpt.custom}}
          </span>
        </template>
      </div>
      <SvgUse 
        :width="20"
        :height="20"
        id="arrowDownActive" 
      />
    </div>
    
    <div
      class="multiSelect__options"
      v-if="optionsIsOpen"
    >
      <div
        class="multiSelect__optionsItem"
        v-for="option of options" :key="option.name"
        @click="changeOpt(option.name, option.subname, option.value)"
      >
        <span>{{option.name}}</span>
        <span>{{option.subname}}</span>
      </div>

      <div
        class="multiSelect__customOptions"
        v-if="customOptions.length"
        @click="customOptionsIsOpen = !customOptionsIsOpen"
      >
        <span>Свое значение</span>
        <div
          class="multiSelect__customOptionsSelect"
          v-if="customOptionsIsOpen"
          @click.stop
        >
          <div
            class="multiSelect__customOptionsItem"
            v-for="option of customOptions" :key="option.name"
            @click.stop
          >
            <div
              class="multiSelect__customOptionsItemSingle"
              v-if="!option.multiple"
              @click="option.checked = !option.checked"
            >
              <SvgUse 
                :width="20"
                :height="20"
                id="checkboxActive" 
                v-if="option.checked"
              />
              <SvgUse 
                :width="20"
                :height="20"
                id="checkboxInActive" 
                v-else
              />
              {{option.name}}
            </div>
            <div
              class="multiSelect__customOptionsItemMulti"
              @click="customOptionsMultiIsOpen = !customOptionsMultiIsOpen"
              v-else
            >
              <div
                class="multiSelect__customOptionsItemMultiSingle"
              >
                <SvgUse 
                  :width="20"
                  :height="20"
                  id="checkboxActive" 
                  v-if="option.checked"
                />
                <SvgUse 
                  :width="20"
                  :height="20"
                  id="checkboxInActive" 
                  v-else
                />
                {{option.name}}
              </div>
              <div
                class="multiSelect__customOptionsItemMultiSelect"
                v-if="customOptionsMultiIsOpen"
              >
                <div
                  class="multiSelect__customOptionsItemMultiSelectItem"
                  v-for="option of option.multiple" :key="option.name"
                  @click.stop="option.checked = !option.checked"
                >
                  <SvgUse 
                    :width="20"
                    :height="20"
                    id="checkboxActive" 
                    v-if="option.checked"
                  />
                  <SvgUse 
                    :width="20"
                    :height="20"
                    id="checkboxInActive" 
                    v-else
                  />
                  {{option.name}}
                </div>
              </div>
            </div>
          </div>
          
          <BaseButton
            class="multiSelect__customOptionsButton button--primary"
            @click="applySelectedOpt"           
          >
            Применить
          </BaseButton>

        </div>
      </div>

    </div>

    <div
      class="multiSelect__notice"
      v-if="hasValidationErrors"
    >
      <span        
        v-for="validation in validations" :key="validation.message"
        v-show="!validation.rule"
      >
        {{validation.message}}
      </span>
    </div>

  </div>
</template>

<script>
import BaseButton from './BaseButton.vue'
export default {
  components: { BaseButton },
  name: 'BaseMultiSelect',
  props: {
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    customOptions: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: [String, Number]
    },
    validations: {
      type: Array,
      default() {
          return [];
      }
    }
  },
  data() {
    return {
      selectedOpt: {
        name: '',
        subname: '',
        value: ''
      },
      optionsIsOpen: false,
      customOptionsIsOpen: false,
      customOptionsMultiIsOpen: false,
      hasValidationErrors: false,
    }
  },
  methods: {
    changeOpt(name, subname, value) {
      this.selectedOpt.name = name
      if(subname) this.selectedOpt.subname  = subname
      this.$emit('update:modelValue', value)
      this.optionsIsOpen = false
    },
    applySelectedOpt() {

      let customValues = ''
      let customMultiValues = ''
      this.customOptions.forEach((option,i) => {
        if(i === 0 && option.checked) {
          for(let i = 0; i < option.multiple.length; i++) {
            if(option.multiple[i].checked) customMultiValues += option.multiple[i].name + ', '
          }
        } else {
          if(option.checked) {
            customValues += option.name + '; '
          }
        }
      })

      const setCustomValues = customMultiValues.length ? customMultiValues.slice(0, -2) + '; ' : ''
      const setCustomMultiValues =  customValues.length ? customValues.slice(0, -2) + '' : ''
      const setValues = setCustomValues + setCustomMultiValues

      this.selectedOpt = {
        name: '',
        subname: '',
        custom: setValues
      }

      this.optionsIsOpen = false
      this.customOptionsIsOpen = false
      this.customOptionsMultiIsOpen = false
    },
    close() {
      this.optionsIsOpen = false
      this.customOptionsIsOpen = false
      this.customOptionsMultiIsOpen = false
    },
    checkValidationErrors() {
      this.hasValidationErrors = false
      this.validations.forEach(validation => {
        if (!validation.rule) {
          this.hasValidationErrors = true;
        }
      })
    }
  },
  mounted() {
    YC.EventBus.on('validations', this.checkValidationErrors)
  },
  watch: {
    customOptions: {
      handler(customOptions) {
        const oneSelectedOpt = customOptions[0].multiple.some((option) => option.checked)
        if(oneSelectedOpt) this.customOptions[0].checked = true
        else this.customOptions[0].checked = false
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.multiSelect {

  text-align: left;
  cursor: pointer;
  position: relative;
  font-size: 15px;

  &__default {
    color: $gray-400;
  }

  &__current {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0, 0.05);
    padding: 0 20px;
    svg {
      flex: 0 0 20px;
    }
    @include breakpoint(xl) {
      height: 50px;
    }
    @include breakpoint(md) {
      height: 60px;
    }
    @include breakpoint(xs) {
      height: 50px;
    }
  }

  &__options {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0, 0.05);
    padding: 20px;
    background-color: $white;
    &Item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        display: block;
      }
      span:first-child {
        line-height: 18px;
      }
      span:last-child {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
      }
    }
  }

  &__selected {
    margin-bottom: 0;
    padding-right: 50px;
    &Name {
      font-size: 15px;
      line-height: 18px;
      display: block;
    }
    &SubName {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      display: block;
      &--fw-500 {
        font-weight: 500;
      }
    }
  }

  &__customOptions {
    &Select {
      cursor: default;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $white;
      width: 100%;
      min-height: 100%;
      border-radius: 10px;
      padding: 20px;
    }
    &Item {
      margin-bottom: 10px;
      &:nth-child(5) {
        margin-bottom: 20px;
      }
      &Single {
        cursor: pointer;
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
        }
        
      }
      
      &Multi {
        cursor: pointer;
        &Single {
          @extend .multiSelect__customOptionsItemSingle;
          margin-bottom: 5px;
        }
        &Select {
          padding-left: 20px;
          margin-bottom: 10px;
          &Item {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            svg {
              margin-right: 5px;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

    }
    &Button {
      height: 40px;
    }
  }

  &__notice {
    font-size: 12px;
    color: $red;
    margin-top: 5px;
  }
  
}
</style>