<template>
  <BasePopup
    :classModifier="!this.$store.state.auth.user.is_instructed.integration ? 'thirdStepInstructionPopup' : ''"
  >

    <ThirdStepInstructionPopup   
      class="settingsEnterApi__thirdStepInstructionPopup"   
      v-if="!this.$store.state.auth.user.is_instructed.integration"
    />
    <div class="settingsEnterApi">
      <div class="titlePopup">Впишите данные по АПИ</div>

      <div class="settingsEnterApi__description">
        <p class="settingsEnterApi__descriptionText">
          Чтобы товары из Wildberries корректно загрузились в системе, при создании API токена на стороне Wildberries разрешите доступ к методам:
        </p>
        <div class="settingsEnterApi__descriptionItems">  
          <div class="settingsEnterApi__descriptionItem">Контент</div>
          <div class="settingsEnterApi__descriptionItem">Маркетплейс</div>
          <div class="settingsEnterApi__descriptionItem">Статистика</div>
          <div class="settingsEnterApi__descriptionItem">Аналитика</div>
          <div class="settingsEnterApi__descriptionItem">Продвижение</div>
          <div class="settingsEnterApi__descriptionItem">Рекомендации</div>
          <div class="settingsEnterApi__descriptionItem">Вопросы и отзывы</div>
          <div class="settingsEnterApi__descriptionItem">Цены и скидки</div>
        </div>
        <a
          href="https://www.youtube.com/watch?v=0UxCeIxlI_g"
          target="_blank"
          class="settingsEnterApi__descriptionLink"
        >
          Инструкция по созданию токена
        </a>
      </div>

        <FormInput   
          class="settingsEnterApi__formInput"     
          :placeholder="'...'"
          v-model.trim="token"
          @inputValueUpdate="tokenIsFailed = false"
          :validations="[
            {
              rule: $validations.isStringNotEmpty(token),
              message: 'Введите токен'
            },
            {
              rule: !$validations.isStringNotEmpty(token) || !this.tokenIsFailed,
              message: this.errorMessageText
            },
          ]"
        />

      <BaseButton
        class="settingsEnterApi__button button--primary"
        @click="createMarketplace"
        :preloader="isLoading"
      >
        Добавить компанию
      </BaseButton>

    </div>
  </BasePopup>
</template>

<script>
import FormInput from '@/components/Form/FormInput'
import SettingsStoreApiEdit from '@/views/Account/Settings/SettingsStore/SettingsStoreEdit'
import {mapActions} from 'vuex'
import ThirdStepInstructionPopup from '@/components/Instruction/ThirdStepInstructionPopup'


export default {
  name: 'SettingsEnterApiPopup',
  props: {
    marketplaceId: {
      type: Number
    }
  },
  data() {
    return {
      token: '',
      errorMessageText:'Магазин с данным токеном уже был добавлен',
      isLoading: false,
      tokenIsFailed: false
    }
  },
  components: {
    SettingsStoreApiEdit,
    FormInput,
    ThirdStepInstructionPopup    
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
      openPopup: 'popup/openPopup',
      storeCreate: 'userStore/storeCreate',
      getStoreList: 'userStore/getStoreList',
      getCurrentStore: 'userStore/getCurrentStore',
      getBalanceInfo: 'userBalance/getBalanceInfo',
      setInstructed: 'settings/setInstructed',
      getUser: 'auth/getUser'
    }),
    createMarketplace() {
      YC.EventBus.emit('validations')

      if(this.tokenIsValid) {
        this.isLoading = true
        this.storeCreate({marketplace: this.marketplaceId, key: this.token})
        .then(() => {
          this.getStoreList()
          this.getCurrentStore()
          this.getBalanceInfo()
          this.closePopup()
          this.openPopup({
            component: 'FourthStepInstructionPopup'
          })
        })
        .catch(response => {
          this.tokenIsFailed = true
          console.error("responseError",response.response?.data);
          if(response.response?.data?.error)
          {
              this.errorMessageText=response.response?.data?.error
          }
          else if (response.response?.data?.message)
          {
              this.errorMessageText=response.response?.data?.message
          }


          setTimeout(() => {
            YC.EventBus.emit('validations')
          })
        })
        .finally(() => {
          this.isLoading = false
        })
      }

    }
  },
  computed: {
    tokenIsValid() {
      return this.$validations.isStringNotEmpty(this.token)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.settingsEnterApi {

  padding: 40px;
  width: 760px;
  max-width: 100%;
  @include breakpoint(xl) {
    width: 730px;
    padding: 30px 40px;
  }
  @include breakpoint(md) {
    width: 600px;
    padding: 40px 40px 30px;
  }
  @include breakpoint(sm) {
    width: 380px;
    padding: 30px 20px;
  }
  @include breakpoint(xs) {
    width: 310px;
  }

  &__description {
    margin-bottom: 30px;
    background-color: $gray-100;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 14px;
    @include breakpoint(sm) {
      margin-bottom: 20px;    
    }
    &Text {
      margin-bottom: 10px;
    }
    &Items {
      display: flex;
      flex-flow: row wrap;
      gap: 5px;
      margin-bottom: 20px;
    }
    &Item {
      border: 1px solid $black;
      border-radius: 10px;
      padding: 5px 15px;
      display: inline-block;
    }
    &Link {
      color: $dark-green-400;
      text-decoration: underline;
      font-size: 12px;
      line-height: 12px;
      max-width: fit-content;
    }
  }

  &__formInput {
    margin-bottom: 30px;
    @include breakpoint(xl) {
      margin-bottom: 20px;
    }
    @include breakpoint(md) {
      margin-bottom: 30px;
    }
    @include breakpoint(sm) {
      margin-bottom: 20px;
    }
  }

  &__button {
    height: 50px;
    max-width: 292px;
    margin: 0 auto;
    @include breakpoint(xl) {
      height: 40px;
      max-width: 267px;
    }
    @include breakpoint(md) {
      height: 50px;
    }
  }

  &__thirdStepInstructionPopup {
    @include horizontalCenter();
    top: -215px;
    @include breakpoint(xl) {
      top: -201px;
    }
    @include breakpoint(sm) {
      top: auto;
      bottom: -242px;
    }
    @include breakpoint(xs) {
      bottom: -280px;
    }
  }

}

</style>