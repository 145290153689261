<template>
  <div class="globalPreloader" ref="globalPreloader">

    <div class="globalPreloader__container">

      <div
        class="globalPreloader__desktop"
        v-if="!$store.state.isMobile"
      >
        <div class="globalPreloader__desktopIcon"></div>
        <div
          class="globalPreloader__desktopText"
        >
          YOU CONTROL ALL MARKETPLACES
        </div>
        <div
          class="globalPreloader__desktopText globalPreloader__desktopText--overlay"
        >
          YOU CONTROL ALL MARKETPLACES
        </div>
      </div>

      <div
        v-else
        class="globalPreloader__mobile"
      >
        <div class="globalPreloader__mobileIcon"></div>
        <div class="globalPreloader__mobileIconBg"></div>
      </div>

    </div>
    
  </div>
</template>

<script>
export default {
  props: {
    startTextAnimation: {
      type: Boolean
    }
  },
  mounted() {
    if(this.startTextAnimation) {
      setTimeout(() => {
        this.$refs.globalPreloader.classList.add('globalPreloader--active')
      }, 100)
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.globalPreloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: $white;
  z-index: 10001;

  &--active {
    .globalPreloader__desktopText--overlay {
      background-position: 0;
    }
  }

  &__container {
    position: relative;
    margin: auto;
  }

  &__desktopIcon {
    filter: drop-shadow(0px 5px 10px rgba(0,0,0,.1));
    width: 52px;
    height: 63px;
    position: absolute;
    top: -73px;
    left: 50%; 
    transform: translateX(-50%) rotate(10.7deg);
    opacity: 0;
    animation: icon .5s ease-in-out;
    animation-delay: 2.3s;
    animation-fill-mode: forwards;
    background-size: 100%;
    @include backgroundImage('preloader/main/fire.webp', center);
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      @include backgroundImage('preloader/main/fire-2x.webp', center);
    }
    @keyframes icon {
      from {
        opacity: 0;
        transform: translateX(-50%) rotate(10.7deg) scale(.5)
      }
      70% {
        transform: translateX(-50%) rotate(10.7deg) scale(1.2);
      }
      to {
        opacity: 1;
        transform: translateX(-50%) rotate(10.7deg) scale(1);
      }
    }
  }
  &__desktopText {
    font-family: $font-secondary;
    font-weight: 900;
    text-shadow: 0 4px 10px rgba(0,0,0, .1);
    color: $white;
    margin: auto;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: .7px;
    &--overlay {
      width: 100%;
      text-shadow: none;
      position: absolute;
      top: 50%;
      left: 50%;
      background-size: 200% 100%;
      background-position: 100%;
      transform: translate(-50%, -50%);
      background-image: linear-gradient(271.7deg, #fff 50%, $green-200 1.3%, $dark-green-300 98.7% 50%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-repeat: no-repeat;
      transition: all 2.3s ease-in-out;
    }
  }

  &__mobile {
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(xs) {
      width: 126px;
      height: 126px;
    }
    &Icon {
      width: 109px;
      height: 132px;
      background-size: 100%;
      filter: drop-shadow(0px 5px 10px rgba(0,0,0,.1));
      animation: iconMobile .5s ease-in-out forwards;
      animation-delay: 2.3s;
      opacity: 0;

      @include backgroundImage('preloader/main/fire-md.webp', center);
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @include backgroundImage('preloader/main/fire-md-2x.webp', center);
      }

      @include breakpoint(xs) {
        width: 77px;
        height: 98px;
        @include backgroundImage('preloader/main/fire-xs.webp', center);
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          @include backgroundImage('preloader/main/fire-xs-2x.webp', center);
        }
      }

      @keyframes iconMobile {
      from {
        opacity: 0;
        transform: scale(.5)
      }
      70% {
        transform: scale(1.2);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
      &Bg {
        z-index: 2;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: 100%;

        @include backgroundImage('preloader/main/icon-md.webp', center);
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          @include backgroundImage('preloader/main/icon-md-2x.webp', center);
        }

        @include breakpoint(xs) {
          @include backgroundImage('preloader/main/icon-xs.webp', center);
          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            @include backgroundImage('preloader/main/icon-xs-2x.webp', center);
          }
        }

        animation: mobileIconBg 2.3s linear forwards;
        @keyframes mobileIconBg {
          from {
            opacity: 0;
            transform: rotate(0)
          }
          15% {
            opacity: 1
          }
          90% {
            transform: rotate(200deg);
          }
          to {
            transform: rotate(210deg)
          }
        }
      }
    }
  }
}

</style>