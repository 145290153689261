<template>

    <template  v-if="isShow" >
        <div class="camp-table-cell-inner camp-tooltip" data-tippy-content="Добавить описание">

        <div class="camp-table-cell-title">
            <div class="camp-table-cell-title__edit">
                <input type="text"
                        :value="initValue"
                       @change="change"
                       @blur="handleBlur"
                       class="camp-tooltip camp-input-text"

                >
                <button class="camp-btn-ic camp-btn-ic__close"
                        @click="click1"
                        type="button"
                        aria-label="">
                        <span class="camp-btn-ic__icon">
                            <Svg :img="'close'"/>
                        </span>
                </button>
            </div>

        </div>
    </div>
    </template>

    <template v-else>

        <div class="camp-table-cell-inner camp-tooltip " data-tippy-content="Добавить описание">

        <template v-if="initValue">
            <div class="camp-table-cell-title camp-table-cell__text-editor camp__is-visible">
                <div class="camp-table-cell-title__edit camp-table-cell-title__edit-link">
                    <p>
                        <a :href="initValue" target="_blank"
                            >{{initValue}}</a>
                    </p>
                    <button class="camp-btn-ic camp-btn-ic__close" type="button" aria-label="Очистить поле"
                        @click="openPopup"
                        >
                          <span class="camp-btn-ic__icon">
                             <Svg :img="'close'"/>
                          </span>
                    </button>
                </div>
            </div>

        </template>
        <template v-else>
            <div class="camp-table-cell__text-editor ">
                <div class="camp-table-cell__text-editor_display">
                    <div class="camp-table-cell__text-editor_field">
                        <input
                            ref="inputRef"
                            type="text"
                            @focus="handleFocus"
                            @blur="handleBlur"
                            @change="change"
                        >
                        <span class="camp-btn-ic camp-btn-ic__add">
                                            <span class="camp-btn-ic__icon">


                                              <Svg :img="'close'"/>
                                           </span>
                                          </span>

                        <button class="camp-btn-ic camp-btn-ic__close camp-tooltip"
                            @click="setEmpty"
                            type="button" aria-label="Очистить поле"
                            data-tippy-content="Очистить поле">
                                            <span class="camp-btn-ic__icon">
                                              <Svg :img="'close'"/>
                                          </span>
                        </button>
                    </div>
                </div>
            </div>
        </template>
        </div>
    </template>

</template>

<script>
    import Svg from "../Svg";
    import {mapActions, mapMutations,mapGetters} from 'vuex'
    import { ref } from 'vue';
    export default {
        name: "LinkEditor",
        components: {Svg},
        data() {
            return {
                inputRef: ref(null),
                isShow:false,
                initValue: this.value,
                isFocused: false

            }
        },
        props: {
            value: String,
            item_id:Number,
            group_id:0,
            viewtype:{
                type: Number,
                defalut:0,
            },
            field:String,
           // linkClassDef :'camp-table-cell-inner camp-tooltip camp-dropdown__wrapper camp__js-dropdown',
            //linkClassOpen:'camp-table-cell-inner camp-tooltip camp-dropdown__wrapper camp__js-dropdown camp__is-opened camp__is-active ',
        },
        methods:{
            handleFocus() {
                this.isFocused = true;
            },
            handleBlur() {
                this.isFocused = false;
                this.isShow =false;
            },
            openPopup(){
                this.isShow = false;
                // if (this.inputRef?.value) {
                //     this.inputRef?.value.focus();
                // }
                this.initValue=''
                console.log("show=",this.isShow);
            },
            handleApply()
            {
                this.isShow=!this.isShow
            },
            handleSetValue() {
                this.editItem({
                    obj: {
                        group_id:this.group_id,
                        item_id:this.item_id,
                        key_field:this.field,
                        action:this.item_id  ? 'edit': 'add',
                        val: this.initValue,
                    }
                }).then((r) => {
                    console.log("response Input",r);
                })
            },
            change(event) {
                const value = event.target.value;
                console.log('Значение поля ввода:', value);
                this.initValue=value
                this.isShow=false

                this.handleSetValue()
            },
            click1()
            {
                this.initValue=''
                this.isShow=!this.isShow
                console.log("click1=",this.isShow);
            },
            setEmpty()
            {
                this.initValue=''
                console.log("setEmpty=",this.isShow);
                //this.isShow=!this.isShow
            },
            ...mapActions({
                editItem: 'crm/editItem',
            }),
        },
        computed:{
            // getLinkClass(){
            //     if(this.isShow)
            //     {
            //         return this.linkClassOpen;
            //     }
            //     else{
            //         return this.linkClassDef;
            //     }
            //
            // }
        },
        mounted(){
            let cellsTextEditors = document.querySelectorAll('.camp-table-cell__text-editor input');

            if (cellsTextEditors) {
                cellsTextEditors.forEach(function(cellsTextEditor) {

                    let cell = cellsTextEditor.closest('.camp-table-cell__text-editor');

                    cellsTextEditor.addEventListener('focus', function() {
                        cell.classList.add('camp__is-visible');
                    });

                    cellsTextEditor.addEventListener('blur', function() {
                        let val = this.value;

                        if (!val && val === '') {
                            cell.classList.remove('camp__is-visible');
                        }

                    });
                });
            }
        }

    }
</script>

<style scoped>

</style>
