<template>
    <div class="customTooltip">
        <slot class="customTooltipSlot" />

        <div class="customTooltipContent">
            <div class="customTooltipContent__text">
                {{ text ? text.substr(0, 10) + ' ' + text.substr(11, 8): '' }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
        }
    }
}
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";

.customTooltip {
    // position: relative;
    // display: inline-block;
    &:hover &Content{
        display: block;
        opacity: 1;
    }

    &Content {
        display: none;
        color: $black;
        text-align: center;
        padding: 20px 30px;
        border-radius: 20px 20px 20px 0;
        top: -40px;
        opacity: 0;
        transition: opacity 1s;
        position: absolute;
        z-index: 1;
        background-color: $white;
        box-shadow: 0 5px 10px 0 rgba($black, 0.07);

        &__text {
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;
            font-family: $font-primary;
        }
    }
}
</style>