<template>
  <div class="pageNotFound">

    <BaseButton
      class="pageNotFound__button"    
			v-if="!$store.state.isMobile" 
			@click="$router.push('/crm/dashboard')"
    >
      <SvgUse 
        :width="20"
        :height="20"
        id="arrowLeft" 
      />
      На главную
    </BaseButton>

    <BaseButtonBack
      v-else
      class="pageNotFound__buttonBack"
      @click="$router.push('/crm/dashboard')"
    >
    </BaseButtonBack>

    <div class="pageNotFound__logo">404</div>

    <div class="pageNotFound__text">Страница не найдена</div>

  </div>
</template>

<script>
export default {
  name: '404'
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.pageNotFound {

  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
  @include breakpoint(xl) {
    max-width: 500px;
  }
  @include breakpoint(sm) {
    max-width: 300px;
  }
  @include breakpoint(xs) {
    max-width: 250px;
  }

  &__buttonBack {
    position: absolute;
    top: 60px;
    left: 34px;
    @include breakpoint(sm) {
      left: 17px;
    }
    @include breakpoint(xs) {
      top: 10px;
      left: 15px;
    }
  }

  &__logo {
    font-family: $font-secondary;
    font-size: 255px;
    line-height: 203px;
    font-weight: 900;
    margin-bottom: 30px;
    background: linear-gradient(358.08deg, $green-200 5.89%, $dark-green-300 80.04%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
    filter: drop-shadow(0px 5px 10px rgba($black, 0.15));
    @include breakpoint(xl) {
      font-size: 187px;
      line-height: 148px;
    }
    @include breakpoint(sm) {
      font-size: 109px;
      line-height: 87px;
    }
    @include breakpoint(xs) {
      font-size: 92px;
      line-height: 75px;
    }
  }

  &__text {
    font-size: 24px;
    line-height: 19px;
    font-weight: 600;
    @include breakpoint(xl) {
      font-size: 20px;
      line-height: 16px;
    }
    @include breakpoint(xs) {
      font-size: 16px;
      line-height: 13px;
    }
  }

  &__button {
    font-weight: 600;
    margin-bottom: 40px;
    align-self: flex-start;
    max-width: fit-content;
    overflow: visible;
    svg {
      margin-right: 5px;
    }
  }

}

</style>