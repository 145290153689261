<template>
  <button class="buttonBack" type="button">
    <span>
      <SvgUse
        :width="23"
        :height="16"
        id="arrowLeft"
        v-if="$store.state.isMobile"
      />
      <SvgUse
        :width="13"
        :height="16"
        id="arrowLeft_2"
        v-if="!$store.state.isMobile"
      />
    </span>
    <slot 
      v-if="!$store.state.isMobile"
    ></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButtonBack',
}
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";

.buttonBack {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  @include breakpoint(xl) {
    font-size: 14px;
  }
  span {
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
    width: 30px;
    height: 30px;
    display: block;
    background-color: $white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    @include breakpoint(md) {
      width: 40px;
      height: 40px;
      margin-right: 0;
    }
  }
}
</style>
