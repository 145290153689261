export default {
  namespaced: true,

  state: () => ({
    isLoading: true,
    userListForLog: [],
    checkedUser: {
      'data' : [],
      'role' : '', 
      'currentUser': '',
    },
    selectedLogList: [],
    checkedLog: [],
    userParams: {
      limit:100,
      skip: 0,
      date_from: '',
      date_to: '',
      last_login: true,
      role: '',
      status: ''
    },
    sortBtnState: {
      name: false,
      phone: false,
      email: false,
      role: false,
      created_at: false,
      last_login: false,
      birthday: false,
      timezone: false,
    }, 
    logParams: {
      user_id: '',
      limit: 100,
      skip: 0,
      period: '',
      date_from: '',
      date_to: '',
    },
    updateParams: {
      user_id: '',
      currentUser: '',
      birthday: '',
      timezone: '',
    }
  }),
  
  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    setUserParams(state, payload) {
      state.userParams = payload
    },
    setLogParams(state, payload) {
      state.logParams = payload
    },
    setSortBtnState(state, payload) {
      state.sortBtnState = payload
    },
    setSortUserList(state, payload) {
      state.userListForLog = payload
    },
    setUserListForLog(state, payload) {
      state.userListForLog = payload;
    },
    setCheckedUser(state, payload) {
      state.checkedUser = payload;
    },
    setLogList(state, payload) {
      state.selectedLogList = payload;
    },
    setCheckedLog(state, payload) {
      state.checkedLog = payload;
    },
    setUpdateParams(state, payload) {
      state.updateParams = payload
    }
  },

  getters: {

  },

  actions: {
    getUsersForLog({ commit }, userParams) {
      commit("setIsLoading", true);
      return new Promise((resolve) => {
        YC.adminApi.post('admin/logs/users/list', userParams)
          .then((usersList) => {
            var data = usersList.data.data;
            for (let i = 0; i < data.length; i++) {
              data[i]['email'] === null ? data[i]['email'] = '' : '';
            }
            commit("setUserListForLog", data);
            var checkedData = {
              'data' : [],
              'role' : '',
              'currentUser' : '', 
            }
            commit("setCheckedUser", checkedData);
            resolve();
          })
          .finally(() => {
            commit("setIsLoading", false);
          })
      });
    },
    getLogsByUserId({ commit }, logParams) {
      return new Promise((resolve) => {
        YC.adminApi.post('admin/logs/list/', logParams)
          .then((response) => {
            commit('setLogList', response.data.data);
            console.log(response)
            resolve();
          })
          .finally(() => {
          })
      });
    },
    updateRole({commit}, data) {
      return new Promise((resolve) => {
        YC.adminApi.post('admin/logs/users/updateRole', data)
          .then((response) => {
            console.log(response);
            resolve();
          })
          .finally(() => {
          })
      });
    },
    postDeleteUserForLog({ commit }, data) {
      return new Promise((resolve) => {
        YC.adminApi.post('admin/logs/users/delete', data)
          .then((response) => {
            console.log(response);

            resolve();
          })
          .finally(() => {
          })
      });
    },
    postDeleteLog({ commit }, data) {
      return new Promise((resolve) => {
        YC.adminApi.post('admin/logs/delete', data)
          .then((response) => {
            commit('setLogList', response.data.logs);
            if (data.mode === 'multiple') {
              var temp = [];
              commit('setCheckedLog', temp);
            }
            resolve();
          })
          .finally(() => {
            return new Promise((resolve) => {
              YC.adminApi.post('admin/logs/users/list', data.userParams)
                .then((usersList) => {
                  var data = usersList.data.data;
                  for (let i = 0; i < data.length; i++) {
                    data[i]['email'] === null ? data[i]['email'] = '' : '';
                  }
                  commit("setUserListForLog", data);
                  var checkedData = {
                    'data' : [],
                    'role' : '',
                    'currentUser' : '', 
                  }
                  commit("setCheckedUser", checkedData);
                  resolve();
                })
                .finally(() => {
                })
            });
          })
      });
    },
    setSortUser({commit}, data) {
      console.log(data);
      const userlist = data.userlist
      const sort_item = data.sort_item
      const sort_direction = data.sort_direction

      userlist.forEach(list => {
        list.name =  !list.name ? '' : list.name;
        list.email = !list.email ? '' : list.email;
        list.last_login = !list.last_login ? '' : list.last_login;
        list.created_at = !list.created_at ? '' : list.created_at;
        list.phone = !list.phone ? '' : list.phone;
        list.role = !list.role ? '' : list.role;
      });

      userlist.sort(function (a, b) {
        if(sort_item === 'name') {
          if (sort_direction === true) {
            return b.name.localeCompare(a.name);
          } else {
            return a.name.localeCompare(b.name);
          }
        } else if (sort_item === 'phone') {
          if (sort_direction === true) {
            return b.phone - a.phone;
          } else {
            return a.phone - b.phone;
          }
        } else if(sort_item === 'email') {
          if (sort_direction === true) {
            return b.email.localeCompare(a.email);
          } else {
            return a.email.localeCompare(b.email);
          }
        } else if(sort_item === 'created_at') {
          if (sort_direction === true) {
            return b.created_at.localeCompare(a.created_at);
          } else {
            return a.created_at.localeCompare(b.created_at);
          }
        } else if(sort_item === 'last_login') {
          if (sort_direction === true) {
            return b.last_login.localeCompare(a.last_login);
          } else {
            return a.last_login.localeCompare(b.last_login);
          }
        } else if(sort_item === 'role') {
          if (sort_direction === true) {
            return b.role.localeCompare(a.role);
          } else {
            return a.role.localeCompare(b.role);
          }
        }
      })
      commit('setSortUserList', userlist)
    },
    postUpdate({commit}, data) {
      return new Promise((resolve) => {
        YC.adminApi.post('admin/logs/update', data.updateParams)
          .then((response) => {
            console.log(response);
            resolve();
          })
          .finally(() => {
            return new Promise((resolve) => {
              YC.adminApi.post('admin/logs/users/list', data.userParams)
                .then((usersList) => {
                  var data = usersList.data.data;
                  for (let i = 0; i < data.length; i++) {
                    data[i]['email'] === null ? data[i]['email'] = '' : '';
                  }
                  commit("setUserListForLog", data);
                  var checkedData = {
                    'data' : [],
                    'role' : '',
                    'currentUser' : '', 
                  }
                  commit("setCheckedUser", checkedData);
                  resolve();
                })
                .finally(() => {
                })
            });
          })
      });
    }
  }
}