<template>
  <div class="keywordAdd">
    <div class="keywordAdd__action" v-show="actionFieldVisible">
      <span class="keywordAdd__controlText" ref="controlText"></span>
      <input
        type="text"
        class="keywordAdd__control"
        @input="resizeInput"
        @keypress="pressEnter"
        v-model="value"
        ref="control"
      >
    </div>
    
    <span
      @click="actionField"
      class="keywordAdd__remove"
    >
      <SvgUse 
        :width="17"
        :height="17"
        id="iconPlus"
      />
    </span>
  </div>
</template>

<script>

export default {
  data() {
    return {
      actionFieldVisible: false,
      value: '',
      focused: false,
      keyword: {
        value: ''
      }
    }
  },
  methods: {
    pressEnter(e) {
      if(e.keyCode === 13) this.actionField()
    },
    actionField() {
      if(this.actionFieldVisible && this.value !== '') {

        this.keyword.value = this.value
        this.$emit('add', this.keyword)

        this.actionFieldVisible = true
        this.$nextTick(() => this.$refs.control.focus())
        this.deleteDataActionField()

      } else {
        this.actionFieldVisible = true
        this.$nextTick(() => this.$refs.control.focus())
      }
    },
    hideActionField(e) {
      
      if(!this.$el.contains(e.target)) {
        this.deleteDataActionField()
        this.actionFieldVisible = false
      }
    },
    deleteDataActionField() {
      this.$refs.controlText.textContent = ''
      this.value = ''
      this.keyword = {
        value: ''
      }
    },
    resizeInput(e) {
      this.$refs.controlText.textContent = e.target.value
    }
  },
  mounted() {
    document.addEventListener('click', this.hideActionField, true)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.hideActionField, true)
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.keywordAdd {
  padding: 0 15px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 11px;
  border-radius: 10px;
  font-weight: 600;
  color: $white;
  background-color: $dark-green-200;
  display: flex;
  align-items: center;
  @include breakpoint(xl) {
    font-size: 13px;
    line-height: 10px;
    height: 25px;
  }
  @include breakpoint(md) {
    height: 30px;
  }
  &__action {
    position: relative;
  }
  &__control, &__controlText {
    border: none;
    background-color: transparent;
    outline: none;
    color: $white;
    font-size: 14px;
    font-family: $font-primary;
    font-weight: 600;
    margin-right: 3px;
    line-height: 18px;
    height: 18px;
    min-width: 10px;
    margin-right: 5px;
  }
  &__control {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &__controlText {
    display: inline-block;
    visibility: hidden;
    white-space: pre;
  }
  &__remove {
    cursor: pointer;
  }
}
</style>