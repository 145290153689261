<template>
    <div class="camp-table-left-indicator" :style="{ backgroundColor: `${color}` }"></div>

    <div class="camp-table-stub"></div>
    <template v-if="!nodelete">
    <button @click="onDelClick" type="button" aria-label="Удалить строку" class="camp-btn-delete ">
            <span class="camp-btn-delete__ic camp-tooltip" data-tippy-content="Удалить строку">
                 <Svg :img="'trash'"/>
            </span>
    </button>
    </template>
    <div class="camp-table-cell-checkbox">
        <label class="camp-form-checkbox__label" v-if="!nodelete">
            <input
                v-model="dataChecked"
                type="checkbox"
                class="action-checkbox"
                :data-id="item_id"
                :class="getExtraClass()"
                @change="handleChange"
                name="">

            <span class="camp-form-checkbox" aria-label="Отметить">
                <span class="camp-form-checkbox__ic"></span>
            </span>
        </label>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import Svg from "./Svg";
    export default {
        name: "CheckboxCell",
        components: {Svg},
        data() {
            return {
                dataChecked: false
            };
        },
        props: {
            group_id:0,
            item_id:Number,
            color:String,
            type:0,
            nodelete:false,
            isChecked: false
        },
        methods:{

            onDelClick()
            {
                 if(confirm("Удалить элемент ?")) {
                     this.handleDeleteItem(this.item_id, this.item_id);
                 }
            },
            getExtraClass(){
                let extraClass='';
                if(this.item_id>0) {
                    extraClass=this.group_id > 0 ? 'group_' + this.group_id : '';
                }
                else {
                    extraClass=this.group_id > 0 ? 'parent_' + this.group_id : '';
                }
                return extraClass;
            },
            handleChange(event) {

                const selectedCheckboxes = this.getSelectedCheckboxes;
                if(this.item_id>0) {
                    const ch_item=parseInt(this.item_id)
                    if (event.target.checked) {

                        if (!selectedCheckboxes.includes(ch_item)) {

                            selectedCheckboxes.push(ch_item);
                        }
                    } else {
                        const index = selectedCheckboxes.indexOf(ch_item);
                        selectedCheckboxes.splice(index, 1);
                    }

                    //console.log("selectedCheckboxes",selectedCheckboxes)

                }
                else {
                    const groupClass = `.group_${this.group_id}`;
                    const checkboxes = document.querySelectorAll(groupClass);



                    checkboxes.forEach(checkbox => {
                        checkbox.checked = event.target.checked;
                        const checkbox_item_id = parseInt(checkbox.getAttribute('data-id'));
                        if (event.target.checked) {


                            if (!selectedCheckboxes.includes(checkbox_item_id)) {

                                selectedCheckboxes.push(checkbox_item_id);
                            }
                        } else {
                            const index = selectedCheckboxes.indexOf(checkbox_item_id);
                            selectedCheckboxes.splice(index, 1);
                        }

                    });

                }
                this.$store.commit('crm/updateSelectedCheckboxes', selectedCheckboxes);
            },
            handleDeleteItem(itemid,value) {

                this.editItem({
                    obj: {
                        group_id:this.group_id,
                        item_id:itemid,
                        key_field:this.field,
                        action: 'delete',
                        val: value,
                    }
                }).then((r) => {
                    console.log("response Input",r);
                })
                .catch(() => {

                })
                .finally(() => {

                })
            },
            ...mapActions({
                editItem: 'crm/editItem',
            }),
        },
        computed: {

            ...mapGetters({
                getSelectedCheckboxes: 'crm/getSelectedCheckboxes',
            }),
        },
    }
</script>

<style scoped>
.camp-table-stub{
    height: 103%;
}
</style>
