export default {

  namespaced: true,

  state: () => ({
    modalQueue: [],
    internalStepsInstructionOnMobile: 0
  }),

  getters: {
    checkModalQueue(state) {
      return state.modalQueue.length
    }
  },

  mutations: {
    setInternalStepsInstructionOnMobile(state, payload) {
      state.internalStepsInstructionOnMobile = payload
    },
    addModalQueue(state, payload) {
      state.modalQueue.push(payload)
    },
    removeModalQueue(state, payload) {
      if(payload === 0) state.modalQueue.shift()
      else state.modalQueue.pop()
    },
    cleanQueue(state) {
      state.modalQueue.length = 0
    }
  },

  actions: {
    openPopup({commit}, component) {
      commit('addModalQueue', component)
    },

    closePopup({commit}, payload) {
      commit('removeModalQueue', payload)
    },

    addSetInternalStepsInstructionOnMobile({commit}, payload) {
      commit('setInternalStepsInstructionOnMobile', payload)
    }
  }

}