<template>
  <BasePopup 
    :classModifier="'account'"
  >
    <div class="teamAddMemberPopup">
      <div class="titlePopup">Добавить участника</div>

      <div class="teamAddMemberPopup__fields">
        <FormInput          
          :placeholder="'E-mail'"
          :type="'email'"
          class="teamAddMemberPopup__fieldsInput"
        />
        <FormInput          
          :placeholder="'Имя'"
          class="teamAddMemberPopup__fieldsInput"
        />
        <FormInput          
          :placeholder="'Фамилия'"
          class="teamAddMemberPopup__fieldsInput"
        />
        <FormInput          
          :placeholder="'Телефон'"
          :type="'tel'"
          class="teamAddMemberPopup__fieldsInput"
        />
        <FormInput          
          :placeholder="'Должность'"
          class="teamAddMemberPopup__fieldsInput"
        />
      </div>

      <div class="teamAddMemberPopup__selectWrap">
        <BaseMultiSelect       
          :label="'Роль в магазине'"   
          class="teamAddMemberPopup__selectWrapMultiSelect"
          :options="options"
          :customOptions="customOptions"
        />
      </div>

      <BaseButton
        class="teamAddMemberPopup__button button--primary"
      >
        Пригласить
      </BaseButton>
    </div>
  </BasePopup>
</template>

<script>
import FormInput from '@/components/Form/FormInput'

export default {
  name: 'TeamAddMemberPopup',
  components: {
    FormInput
  },
  data() {
    return {
      options: [
        {name: 'Администратор', subname: 'Доступны все доски и все страницы CAMP'},
        {name: 'Менеджер по рекламе', subname: 'Видит только отчеты по рекламе, заказам + Биддер цен'},
        {name: 'Менеджер по продажам', subname: 'Доступны все доски и все страницы CAMP'}
      ],
      customOptions: [
        {
          name: 'Система аналитики', 
          multiple: [
            {name: 'Товары', checked: false},
            {name: 'Реклама', checked: false},
            {name: 'Отчеты', checked: false},
            {name: 'Финансы', checked: false}
          ],
          checked: false
        },
        {name: 'Биддер цен', checked: false},
        {name: 'Биддер рекламы', checked: false},
        {name: 'SEO парсер', checked: false},
        {name: 'Биддер креативов', checked: false},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.teamAddMemberPopup {

  &__fields {
    margin-bottom: 30px;
    &Input {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__selectWrap {
    &MultiSelect {
      margin-bottom: 30px;
    }
  }

  &__button {
    max-width: 190px;
    height: 50px;
    margin: 0 auto;
  }

}
</style>