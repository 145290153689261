<template>
  <BasePopup :classModifier="'tickets-chat'">
    <div class="ticketAssessSupport">
      <div class="ticketAssessSupport__titlePopup titlePopup">Оцените, пожалуйста, работу поддержки</div>

      <div class="ticketAssessSupport__rating">
        <div
          class="ticketAssessSupport__ratingItem"
          v-for="rating in ratings"
          :key="rating.value"
          @click="selectRating(rating)"
        >
          <picture class="ticketAssessSupport__ratingItemPicture">
            <source
              type="image/webp"
              :srcset="`${rating.iconSrc}-2x.webp 2x, ${rating.iconSrc}.webp 1x`"
            >
            <img
              :src="rating.iconSrc"
              :alt="rating.name"
              class="ticketAssessSupport__ratingItemIcon"
              :class="{ 'ticketAssessSupport__ratingItemIcon--selected': selectedRating === rating.value }"
            >
          </picture>
          <div class="ticketAssessSupport__ratingItemText">{{ rating.name }}</div>
        </div>
      </div>

      <div
        class="ticketAssessSupport__feedback"
        v-if="feedbackOpen"
      >
        <FormTextarea
          :placeholder="'Расскажите, что нам нужно улучшить'"
          class="ticketAssessSupport__formTextarea"
          v-model:modelValue="feedBackText"
        />
        <BaseButton
          class="ticketAssessSupport__button button--primary"
          @click="sendRating"
        >
          Отправить
        </BaseButton>
      </div>

    </div>
  </BasePopup>

</template>

<script>
import FormTextarea from '@/components/Form/FormTextarea'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'TicketsAssessSupportPopup',
  components: {
    FormTextarea
  },
  data() {
    return {
      ratings: [
        { name: 'Ужас!', value: 1, iconSrc: '/images/rating/1' },
        { name: 'Не очень!', value: 2, iconSrc: '/images/rating/2' },
        { name: 'Нормально!', value: 3, iconSrc: '/images/rating/3' },
        { name: 'Хорошо!', value: 4, iconSrc: '/images/rating/4' },
        { name: 'Превосходно!', value: 5, iconSrc: '/images/rating/5' }
      ],
      feedbackOpen: false,
      selectedRating: null,
      feedBackText: ''
    }
  },
  computed: {
    ...mapState({
      selectedUserTicket: (state) => state.userTickets.selectedUserTicket,
    })
  },
  methods: {
    ...mapMutations({
      closePopups: 'popup/cleanQueue'
    }),
    ...mapActions({
      sendTicketRating: 'userTickets/sendTicketRating',
      openPopup: 'popup/openPopup',
    }),
    selectRating(rating) {
      const ratingValue = rating.value

      if (ratingValue < 4) {
        this.feedbackOpen = true
        this.selectedRating = rating
      }
      else {
        this.feedbackOpen = false
        this.selectedRating = rating
        this.sendTicketRating({ id: this.selectedUserTicket.id, rating: { rate: ratingValue } })
        this.closePopups()
        this.openPopup({
          component: 'TicketsThanksPopup',
          props: {
            rating: this.selectedRating
          }
        })
        
      }
    },
    sendRating() {
      this.sendTicketRating({ id: this.selectedUserTicket.id, rating: { rate: this.selectedRating.value, rate_message: this.feedBackText } })
      this.closePopups()
      this.openPopup({
        component: 'TicketsThanksPopup',
        props: {
          rating: this.selectedRating
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.ticketAssessSupport {
  width: 915px;
  max-width: 100%;
  padding: 50px 80px;
  font-size: 15px;

  @include breakpoint(xl) {
    width: 875px;
    padding: 40px 60px;
    font-size: 13px;
  }

  @include breakpoint(md) {
    padding: 30px;
    font-size: 15px;
    width: 735px;
  }

  &__titlePopup {
    margin-bottom: 50px;

    @include breakpoint(xl) {
      margin-bottom: 40px;
    }
  }

  &__rating {
    display: flex;
    justify-content: space-between;
    margin-bottom: 27px;

    &Item {
      text-align: center;
      flex: 0 0 100%;
      max-width: 135px;
      cursor: pointer;

      &Icon {
        margin-bottom: 5px;
        filter: grayscale(1);
        transition: filter $transition-duration ease;

        &:hover {
          filter: none;
        }

        &--selected {
          filter: none;
        }
      }

      &Text {
        line-height: 18px;

      }
    }
  }

  &__formTextarea {
    margin-bottom: 30px;

    @include breakpoint(xl) {
      margin-bottom: 20px;
    }
  }

  &__button {
    max-width: 270px;
    height: 50px;
    margin: 0 auto;
  }

}
</style>