<template>
  <div class="keywordItem">
    <span>{{keyword}}</span>
    <span
      class="keywordItem__remove"
      @click="$emit('remove', keyword)"
    >
      <SvgUse
        :width="13"
        :height="12"
        id="iconCloseVar2"
      />
    </span>
  </div>
</template>

<script>

export default {
  props: {
    keyword: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.keywordItem {
  padding: 0 15px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 11px;
  border-radius: 10px;
  font-weight: 600;
  color: $white;
  background-color: $dark-green-200;
  display: flex;
  align-items: center;
  @include breakpoint(xl) {
    font-size: 13px;
    line-height: 10px;
    height: 25px;
  }
  @include breakpoint(md) {
    height: 30px;
  }
  &__remove {
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
