<template>
  <div class="formInputSelect" v-click-outside="close">
    <div class="formInputSelect__wrap">

      <div
        class="formInputSelect__select"
        @click="dropDownHandler"
      >
        <div class="formInputSelect__selectCurrent">
          <span>{{countryCode}}</span>
          <SvgUse            
            :width="24"
            :height="24"
            id="arrowDownInputSelect"
          />
        </div>
        <div
          class="formInputSelect__selectList"
          v-if="codeListIsOpen"
        >
          <div
            class="formInputSelect__selectListItem"
            v-for="code in countryCodes" :key="code.value"
            @click="selectCountryCode(code.value)"
          >
            <span>{{code.value}}</span>
            <img :src="`/images/flag/flag-${code.name}.png`" :alt="code.name">
          </div>
        </div>
      </div>

      <input
        :type="type"
        class="formInputSelect__control"
        autocomplete="off"
        :placeholder="placeholder"
        :value="modelValue"
        @keyup="updateValue"
        @blur="stateBlur"
        @focus="stateFocus"
        ref="input"
        :readonly="readonly"
      >
    </div>
    <div class="formInputSelect__note" v-if="note">
      {{note}}
    </div>

    <div
      class="formInput__notice"
      v-if="hasValidationErrors"
    >
      <span        
        v-for="validation in validations" :key="validation.message"
        v-show="!validation.rule"
      >
        {{validation.message}}
      </span>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'FormInputPhone',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      require: true
    },
    countryCode: {
      type: String,
      require: true
    },
    countryCodes: {
      type: Array,
      require: true
    },
    validations: {
      type: Array,
      default() {
          return [];
      }
    },
    validationsEventName: {
      type: String,
      default: 'validations'
    },
    readonly: {
      type: Boolean,
    }
  },
  data() {
    return {
      codeListIsOpen: false,
      selectedCode: null,
      hasValidationErrors: false,
      phoneEntered: false,
      mask: null
    }
  },
  methods: {
    dropDownHandler() {
      if(!this.readonly) this.codeListIsOpen = !this.codeListIsOpen
    },
    stateBlur(event) {
      if(event.target.value !== '') {
        event.target.classList.add('formInputSelect__control--filled')
        this.$emit('filled', true)
      }
    },
    stateFocus(event) {
      if(!event.target.readOnly) event.target.classList.remove('formInputSelect__control--filled')
    },
    selectCountryCode(code) {
      this.selectedCode = code
      this.$emit('update:countryCode', code) 
    },
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value)
      this.$emit('inputValueUpdate')
      this.phoneEntered ? this.$emit('phoneEntered', true) : this.$emit('phoneEntered', false)
    },
    close() {
      this.codeListIsOpen = false
    },
    checkValidationErrors() {
      this.hasValidationErrors = false
      this.validations.forEach(validation => {
        if (!validation.rule) {
          this.hasValidationErrors = true;
        }
      })
    }
  },
  mounted() {
    
    YC.EventBus.on(this.validationsEventName, this.checkValidationErrors)

    this.mask = IMask(this.$refs.input, {
      mask: '000-000-00-00'
    })
    this.mask.on('accept', () => {
      this.phoneEntered = false
    })
    this.mask.on('complete', () => {
      this.phoneEntered = true
    });
  },
  watch: {
    selectedCode(val) {

      this.$emit('update:modelValue', '')

      switch(val) {
        case '+380': this.mask.updateOptions({ mask: '000-00-00-00' }); break
        case '+375': this.mask.updateOptions({ mask: '00-000-00-00' }); break

        case '+7': this.mask.updateOptions({ mask: '000-000-00-00' }); break
      }

    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.formInputSelect {
  &__wrap {
    display: flex;
  }

  &__select {
    background-color: $white;
    flex: 0 0 114px;
    box-shadow: 0px 5px 15px 0px rgba($black, 0.05);
    margin-right: 5px;
    border-radius: 15px;
    position: relative;
    z-index: 2;
    @include breakpoint(xs) {
      flex: 0 0 75px;
    }

    &--open &Current {
      svg {
        transform: rotate(180deg);
      }
    }

    &Current {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      position: relative;
      height: 60px;
      @include breakpoint(xs) {
        height: 40px;
      }
      svg {
        margin-right: 20px;
      }
      span {
        margin-right: 10px;
        @include breakpoint(xs) {
          margin-right: 2px;
          font-size: 15px;
        }
      }
    }

    &List {
      background-color: $white;
      padding: 22px 0;
      box-shadow: 0px 5px 15px 0px rgba($black, 0.05);
      border-radius: 15px;
      position: absolute;
      width: 100%;
      top: 65px;
      left: 0;
      @include breakpoint(xs) {
        top: 45px;
      }
      span {
        margin-right: 15px;
        width: 40px;
        text-align: center;
        @include breakpoint(xs) {
          width: 28px;
        }
      }
      &Item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: right;
    display: block;
    width: 100%;
    text-align: left;
    @include breakpoint(xl) {
      font-size: 14px;
    }
    @include breakpoint(md) {
      font-size: 15px;
    }
    @include breakpoint(sm) {
      font-size: 14px;
    }
  }

  &__control {
    background-color: $gray-100;
    height: 60px;
    border: none;
    box-shadow: $box-shadow-input inset;
    text-align: center;
    width: 100%;
    border-radius: 15px;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 15px;
    @include breakpoint(xs) {
      height: 40px;
    }
    &::placeholder {
      font-family: $font-primary;
      font-weight: 500;
      font-size: 15px;
      color: $gray-400;
    }
    &--filled {
      box-shadow: $box-shadow-input;
      background-color: $white;
    }
  }

  &__note {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 500;
    text-align: left;
    color: $gray-400;
    @include breakpoint(xs) {
      font-size: 10px;
    }
  }

  &__notice {
    font-size: 12px;
    color: $red;
    margin-top: 5px;
    &--captcha {
      margin-top: 15px;
      margin-bottom: -15px;
    }
  }

}

</style>