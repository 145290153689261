<template>
  <BasePopup>
    <div class="adminSellersDetailPopup">
      <div class="adminSellersDetailPopupTable">
        <div class="adminSellersDetailPopupTableHeader">
          <div class="adminSellersDetailPopupTableHeader__title">
            Отчет по продавцу: ID 56411674
          </div>
          <FilterDropdown
            :label="'Искать за период'"
          />
        </div>
        <div class="adminSellersDetailPopupTableContent">
          <!-- Table Header -->
          <div class="adminSellersDetailPopupTableContent__header">
            <div class="adminSellersDetailPopupTableContent__headerBottom">
              <div class="adminSellersDetailPopupTableContent__headerBottomCol">
                Категория
              </div>
              <div class="adminSellersDetailPopupTableContent__headerBottomCol">
                Артикул
              </div>
              <div
                class="adminSellersDetailPopupTableContent__headerBottom--child adminSellersDetailPopupTableContent__headerBottom--childFirst"
              >
                <div
                  class="adminSellersDetailPopupTableContent__headerBottom--childTitle"
                >
                  Бидер цен
                </div>
                <div
                  class="adminSellersDetailPopupTableContent__headerBottom--childContent"
                >
                  <div
                    class="adminSellersDetailPopupTableContent__headerBottom--childContentCol"
                  >
                    Включен
                  </div>
                  <div
                    class="adminSellersDetailPopupTableContent__headerBottom--childContentCol"
                  >
                    Выключен
                  </div>
                  <div
                    class="adminSellersDetailPopupTableContent__headerBottom--childContentCol"
                  >
                    Цена min
                  </div>
                  <div
                    class="adminSellersDetailPopupTableContent__headerBottom--childContentCol"
                  >
                    Цена max
                  </div>
                </div>
              </div>
              <div
                class="adminSellersDetailPopupTableContent__headerBottom--child"
              >
                <div
                  class="adminSellersDetailPopupTableContent__headerBottom--childTitle"
                >
                  Заказы
                </div>
                <div
                  class="adminSellersDetailPopupTableContent__headerBottom--childContent"
                >
                  <div
                    class="adminSellersDetailPopupTableContent__headerBottom--childContentCol"
                  >
                    Всего
                  </div>
                  <div
                    class="adminSellersDetailPopupTableContent__headerBottom--childContentCol"
                  >
                    Средняя цена
                  </div>
                </div>
              </div>
              <div
                class="adminSellersDetailPopupTableContent__headerBottom--child"
              >
                <div
                  class="adminSellersDetailPopupTableContent__headerBottom--childTitle"
                >
                  Заработано
                </div>
                <div
                  class="adminSellersDetailPopupTableContent__headerBottom--childContent"
                >
                  <div
                    class="adminSellersDetailPopupTableContent__headerBottom--childContentCol"
                  >
                    С 1 заказа
                  </div>
                  <div
                    class="adminSellersDetailPopupTableContent__headerBottom--childContentCol"
                  >
                    Итого
                  </div>
                </div>
              </div>
              <div class="adminSellersDetailPopupTableContent__headerBottomCol">
                Упущено
              </div>
              <div class="adminSellersDetailPopupTableContent__headerBottomCol">
                Продажи
              </div>
              <div class="adminSellersDetailPopupTableContent__headerBottomCol">
                % Выкупа
              </div>
            </div>
          </div>
          <!-- Table List -->
          <div class="adminSellersDetailPopupTableContent__list">
            <AdminSellersListByCategory
              v-for="category in categories"
              :category="category"
              :key="category.id"
            />
          </div>
        </div>
      </div>
      <div class="adminSellersDetailPopup__bar">
        <div class="adminSellersDetailPopup__barHeader">
          <div class="adminSellersDetailPopup__barHeaderTitle">
            О товарах
          </div>
          <div class="adminSellersDetailPopup__barHeaderLegend">
            <div class="adminSellersDetailPopup__barHeaderLegend--item">
              <span class="adminSellersDetailPopup__barHeaderLegend--itemBlue"></span>
              Всего товаров в категории
            </div>
            <div class="adminSellersDetailPopup__barHeaderLegend--item">
              <span class="adminSellersDetailPopup__barHeaderLegend--itemGreen"></span>
              В скольких бидер вкл.
            </div>
            <div class="adminSellersDetailPopup__barHeaderLegend--item">
              <span class="adminSellersDetailPopup__barHeaderLegend--itemRed"></span>
              В скольких бидер выкл.
            </div>
          </div>
        </div>
        <div class="adminSellersDetailPopup__barChart">
          <div class="adminSellersDetailPopup__barChart--content">
            <canvas
              width="1600px"
              height="250px"
              ref="bidderGraphContentByCategory__barChart"
              id="bidderGraphContentByCategory__barChart"
            ></canvas>
          </div>
        </div>
      </div>

      <div class="adminSellersDetailPopup__line">
        <div class="adminSellersDetailPopup__lineHeader">
          Вкл./Выкл. биддер
        </div>
        <div class="adminSellersDetailPopup__lineChart">
          <div class="adminSellersDetailPopup__lineChart--content">
            <canvas
              width="1600px"
              height="250px"
              ref="bidderGraphContentByCategory__lineChart"
              id="bidderGraphContentByCategory__lineChart"
            ></canvas>
          </div>
        </div>
      </div>
    </div>
  </BasePopup>
</template>

<script>
import Chart from "chart.js/auto";
import BasePopup from "@/components/Global/BasePopup.vue";
import FilterDropdown from "@/views/Admin/UIComponents/FilterDropdown";
import AdminSellersListByCategory from "@/views/Admin/AdminSellers/AdminSellersListByCategory.vue";
export default {
  name: 'AdminSellersDetailPopup',
  components: {
    BasePopup,
    AdminSellersListByCategory,
    FilterDropdown,
  },
  data() {
    return {
      categories: [
        {
          id: 1,
          title: "Крема",
          item: "15",
          turnOn: "9",
          turnOff: "6",
          min: "-",
          max: "-",
          totalItem: "150",
          average: "-",
          order: "1200 ₽",
          totalPrice: "1200 ₽",
          lost: "2500 ₽",
          sell: "100",
          pro: "75.35%",
          subCategories: [
            {
              id: 1,
              title: "Крем от бородавок",
              item: "15",
              turnOn: "9",
              turnOff: "6",
              min: "-",
              max: "-",
              totalItem: "150",
              average: "-",
              order: "1200 ₽",
              totalPrice: "1200 ₽",
              lost: "2500 ₽",
              sell: "100",
              pro: "75.35%",
              products: [
                {
                  title: "Кремный крем",
                  id: "3252542",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "1005025",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "053542",
                  state: false,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "5507404",
                  state: false,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "4560456",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
              ],
            },
            {
              id: 2,
              title: "Крем от бородавок",
              item: "15",
              turnOn: "9",
              turnOff: "6",
              min: "-",
              max: "-",
              totalItem: "150",
              average: "-",
              order: "1200 ₽",
              totalPrice: "1200 ₽",
              lost: "2500 ₽",
              sell: "100",
              pro: "75.35%",
              products: [
                {
                  title: "Кремный крем",
                  id: "3252542",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "1005025",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "053542",
                  state: false,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "5507404",
                  state: false,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "4560456",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: "Крема",
          item: "15",
          turnOn: "9",
          turnOff: "6",
          min: "-",
          max: "-",
          totalItem: "150",
          average: "-",
          order: "1200 ₽",
          totalPrice: "1200 ₽",
          lost: "2500 ₽",
          sell: "100",
          pro: "75.35%",
          subCategories: [
            {
              id: 1,
              title: "Крем от бородавок",
              item: "15",
              turnOn: "9",
              turnOff: "6",
              min: "-",
              max: "-",
              totalItem: "150",
              average: "-",
              order: "1200 ₽",
              totalPrice: "1200 ₽",
              lost: "2500 ₽",
              sell: "100",
              pro: "75.35%",
              products: [
                {
                  title: "Кремный крем",
                  id: "3252542",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "1005025",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "053542",
                  state: false,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "5507404",
                  state: false,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "4560456",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
              ],
            },
            {
              id: 2,
              title: "Крем от бородавок",
              item: "15",
              turnOn: "9",
              turnOff: "6",
              min: "-",
              max: "-",
              totalItem: "150",
              average: "-",
              order: "1200 ₽",
              totalPrice: "1200 ₽",
              lost: "2500 ₽",
              sell: "100",
              pro: "75.35%",
              products: [
                {
                  title: "Кремный крем",
                  id: "3252542",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "1005025",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "053542",
                  state: false,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "5507404",
                  state: false,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
                {
                  title: "Кремный крем",
                  id: "4560456",
                  state: true,
                  min: "50 ₽",
                  max: "500 ₽",
                  totalItem: "10",
                  average: "350 ₽",
                  order: "125 ₽",
                  totalPrice: "4200 ₽",
                  lost: "15000 ₽",
                  sell: "52",
                  pro: "135%",
                },
              ],
            },
          ],
        },
      ],
      barChart: [
        {
          labels: [
            "Крема",
            "Аксессуары",
            "Мази",
            "Чай",
            "Детские товары",
            "Автотовары",
            "Электроника",
            "Кофе",
            "Крема",
            "Аксессуары",
            "Мази",
            "Чай",
            "Детские товары",
            "Автотовары",
            "Электроника",
          ],
        },
      ],
      lineChart: [
        { x: "01", y: 15 },
        { x: "02", y: 9 },
        { x: "03", y: 13 },
        { x: "04", y: 11 },
        { x: "05", y: 14 },
        { x: "06", y: 5 },
        { x: "07", y: 9 },
        { x: "08", y: 3 },
        { x: "09", y: 1 },
        { x: "10", y: 7 },
        { x: "11", y: 9 },
        { x: "12", y: 8 },
        { x: "13", y: 13 },
        { x: "14", y: 4 },
        { x: "15", y: 2 },
        { x: "16", y: 5 },
        { x: "17", y: 12 },
        { x: "18", y: 4 },
        { x: "19", y: 2 },
        { x: "20", y: 6 },
        { x: "21", y: 12 },
        { x: "22", y: 3 },
        { x: "23", y: 15 },
        { x: "24", y: 4 },
        { x: "25", y: 2 },
        { x: "26", y: 6 },
        { x: "27", y: 12 },
        { x: "28", y: 15 },
        { x: "29", y: 9 },
        { x: "30", y: 5 },
      ],
    };
  },
  mounted() {
    const ctx = this.$refs.bidderGraphContentByCategory__lineChart;
    const ctxBar = this.$refs.bidderGraphContentByCategory__barChart;
    Chart.defaults.font.family = "Montserrat";
    Chart.defaults.font.weight = 500;
    Chart.defaults.font.size = 11;
    Chart.defaults.color = "#000";

    this.bidderBarChart = new Chart(ctxBar, {
      type: "bar",
      data: {
        labels: [
          "Крема",
          "Аксессуары",
          "Мази",
          "Чай",
          "Детские товары",
          "Автотовары",
          "Электроника",
          "Кофе",
          "Крема",
          "Аксессуары",
          "Мази",
          "Чай",
          "Детские товары",
          "Автотовары",
          "Электроника",
        ],
        datasets: [
          {
            label: "Всего товаров в категории",
            data: [15, 8, 5, 12, 9, 8, 5, 7, 9, 5, 12, 9, 8, 5, 7],
            borderRadius: 10,
            barThickness: 10,
            backgroundColor: "rgba(0, 142, 198, 1)",
          },
          {
            label: "В скольких бидер вкл.",
            data: [10, 3, 2, 11, 9, 8, 5, 3, 7, 12, 15, 5, 7, 9, 8],
            borderRadius: 10,
            barThickness: 10,
            backgroundColor: "rgba(0, 212, 136, 1)",
          },
          {
            label: "В скольких бидер выкл.",
            data: [5, 2, 1, 7, 10, 7, 9, 10, 11, 15, 12, 14, 8, 12, 9],
            borderRadius: 10,
            barThickness: 10,
            backgroundColor: "rgba(255, 89, 89, 1)",
          },
        ],
      },
      options: {
        responsive: false,
        plugins: {
          legend: {
            display: false,
            position: "top",
            align: "start",
          },
          title: {
            display: false,
            text: "Chart.js Bar Chart",
          },

        },
        scales: {
          y: {
            ticks: {
              min: 0,
              max: 15,
              stepSize: 5,
              suggestedMin: 0,
              suggestedMax: 15,
            },
            gridLines: { display: false },
          },
        },
      },
    });

    this.bidderLineChart = new Chart(ctx, {
      type: "line",
      data: {
        datasets: [
          {
            label: "sss",
            data: this.lineChart,
            backgroundColor: ["rgba(0, 142, 198, 1)"],
          },
        ],
      },
      options: {
        fill: true,
        responsive: false,
        plugins: {
          legend: {
            position: "top",
            display: false,
          },
          title: {
            display: false,
            align: "start",
            text: "Вкл./Выкл. биддер",
          },
        },
        scales: {
          y: {
            ticks: {
              min: 0,
              max: 15,
              stepSize: 5,
              suggestedMin: 0,
              suggestedMax: 15,
            },
            gridLines: { display: false },
          },
        },
      },
    });
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/_mixins.scss';
@import 'src/scss/base/project/_var.scss';
.adminSellersDetailPopup {
  width: 98vw;
  height: 98vh;
  margin-bottom: 30px;
  overflow: auto;

  &Table {
    &::before {
      content: "";
      display: block;
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: $white;
      box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.15);
      height: 150px;
      // position: absolute;
    }

    &Header {
      position: relative;
      margin-top: -150px;
      padding: 30px 20px 20px;
      display: flex;
      align-items: center;
      gap: 60px;
      &__title {
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        font-family: $font-primary;
      }

      @include breakpoint(xl) {
        font-size: 16px;
        line-height: 16px;
      }

      @include breakpoint(md) {
        font-size: 20px;
        line-height: 20px;
      }

      @include breakpoint(sm) {
        font-size: 14px;
        line-height: 14px;
      }
    }

    &Content {
      position: relative;
      overflow-x: auto;

      &__header {
        padding: 0 20px;
        position: relative;
        background-color: transparent;

        &Bottom {
          display: flex;
          gap: 5px;
          font-size: 14px;
          line-height: 14px;
          padding: 10px 10px 26px;
          cursor: pointer;

          @include mediaMaxWidth(1600) {
            gap: 0;
          }

          &Col {
            display: flex;
            justify-content: center;
            align-items: end;
            text-align: center;
            font-size: 15px;
            width: 6.77%;
            min-width: 100px;
            white-space: nowrap;
            padding: 10px 0;

            &:first-child {
              min-width: 280px;
              @include mediaMaxWidth(1440) {
                min-width: 240px;
              }
            }
          }

          &--child {
            width: 14%;
            min-width: 200px;
            display: flex;
            flex-flow: column;

            &Title {
              width: 100%;
              text-align: center;
              padding: 10px;
              border-radius: 10px;
              background-color: $gray-100;
            }

            &Content {
              display: flex;
              justify-content: space-between;
              gap: 9px;
              @include mediaMaxWidth(1500) {
                gap: 0;
              }
              &Col {
                display: flex;
                justify-content: center;
                align-items: end;
                width: 50%;
                text-align: center;
                font-size: 15px;
                min-width: 100px;
                white-space: nowrap;
                padding: 10px 0;
              }
            }

            &First {
              width: 28.2%;
              min-width: 400px;
            }
          }
        }
      }

      &__list {
        display: flex;
        flex-flow: column;
        gap: 5px;
        margin: 0 20px 10px;
      }
    }
  }
  &__bar {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &Header {
      display: flex;
      align-items: center;
      gap: 30px;
      padding: 0 20px;
      &Title {
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        font-family: $font-primary;
      }
      &Legend {
        display: flex;
        align-items: center;
        gap: 15px;
        &--item {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 14px;
          line-height: 14px;
          font-weight: 500;
          font-family: $font-primary;

          &Blue {
            width: 20px;
            height: 10px;
            border-radius: 10px;
            background-color: $dark-green-200;
          }

          &Green {
            width: 20px;
            height: 10px;
            border-radius: 10px;
            background-color: $green-100;
          }

          &Red {
            width: 20px;
            height: 10px;
            border-radius: 10px;
            background-color: $red;
          }
        }
      }
    }
  }
  &__line {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &Header {
      padding: 0 20px;
      font-size: 20px;
      line-height: 20px;
      font-weight: 600;
      font-family: $font-primary;
    }
  }
  &__barChart,
  &__lineChart {
    position: relative;
    padding: 0 20px;
    border-radius: 10px;

    &--content {
      padding: 30px 40px 50px 30px;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07);
      overflow-x: auto;
      @include breakpoint(md) {
        padding: 20px;
      }
    }
  }
}
</style>
