<template>
  <div class="adminPromocodesCreatePromocodeDiscountPopup">
    <AdminPromocodesBasePopup
      title="Создание промокода"
      generateButtonTitle="Сгенерировать промокод"
      :isLinkGenerated="isLinkGenerated"
      @generate="createPromocode"
      @close="closePopup"
    >
      <AdminPromocodesDropDown
        :placeholder="discountDropDownData.placeholder"
        :options="discountDropDownData.options"
        :isLinkGenerated="isLinkGenerated"
        classModifier="adminPromocodesCreatePromocodeDiscountPopup__dropdown"
        @select-option="(n) => selectOption(n, 'discount')"
        :validations="[
          {
            rule: $validations.isStringNotEmpty(selectedDiscount),
            message: 'Пожалуйста, выберите размер скидки'
          }
        ]"
      />

      <AdminPromocodesDropDown
        :placeholder="validityPeriodDropDownData.placeholder"
        :options="validityPeriodDropDownData.options"
        :isLinkGenerated="isLinkGenerated"
        :withDate="true"
        @select-option="(n) => selectOption(n, 'validityPeriod')"
        :validations="[
          {
            rule: $validations.isStringNotEmpty(selectedValidityPeriod),
            message: 'Пожалуйста, выберите срок действия промокода'
          }
        ]"
      />

      <AdminPromocodesCopyLink
        title="Промокод:"
        :isHidden="!isPromocodeCreated"
        :link="generatedPromocode"
        classModifier="adminPromocodesCreatePromocodeDiscountPopup__copyLink"
        typeOfLink="promocode"
      />
    </AdminPromocodesBasePopup>
  </div>
</template>

<script>
import AdminPromocodesBasePopup from '@/views/Admin/AdminPromocodes/AdminPromocodesPopups/AdminPromocodesBasePopup'
import AdminPromocodesDropDown from '@/views/Admin/AdminPromocodes/AdminPromocodesDropDown'
import AdminPromocodesCopyLink from '@/views/Admin/AdminPromocodes/AdminPromocodesCopyLink'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'AdminPromocodesCreatePromocodeDiscountPopup',
  components: {
    AdminPromocodesDropDown,
    AdminPromocodesCopyLink,
    AdminPromocodesBasePopup,
  },
  data() {
    return {
      isPromocodeCreated: false,
      selectedDiscount: null,
      selectedValidityPeriod: null,
      promocodeType: 'promo_percent',
      generatedPromocode: ''
    }
  },
  computed: {
    ...mapState({
      discountDropDownData: (state) => state.admin.promocodeDropDownData.discount,
      validityPeriodDropDownData: (state) => state.admin.promocodeDropDownData.validityPeriod,
      isLinkGenerated: (state) => state.admin.isPromocodeLinkGenerated,
      employeeId: (state) => state.auth.user.id
    }),
    ...mapGetters({
      promcodeExpiredAt: 'admin/promcodeValidityPeriodForPostRequset'
    }),
    popupFieldsIsValid() {
      return this.$validations.isStringNotEmpty(this.selectedDiscount) && this.$validations.isStringNotEmpty(this.selectedValidityPeriod)
    }
  },
  methods: {
    ...mapMutations({
      setIsPromocodeLinkGenerated: 'admin/setIsPromocodeLinkGenerated'
    }),
    ...mapActions({
      generatePromocode: 'admin/postCreatePromocode'
    }),
    createPromocode() {
      if(this.popupFieldsIsValid) {
        const payload = {
          user_id: this.employeeId,
          type: this.promocodeType,
          value: this.selectedDiscount,
          expired_at: this.promcodeExpiredAt
        }
        this.generatePromocode(payload)
          .then((data) => {
            this.generatedPromocode = data.code
            this.isPromocodeCreated = true
            this.setIsPromocodeLinkGenerated(true)
          })
      } else {
        return
      }
      
    },
    closePopup() {
      this.isPromocodeCreated = false
      this.isLinkCopied = false
    },
    selectOption(selectedOption, type) {
      if(type === 'discount') {
        this.selectedDiscount = selectedOption
      } else if (type === 'validityPeriod') {
        this.selectedValidityPeriod = selectedOption
      } else {
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.adminPromocodesCreatePromocodeDiscountPopup {
  &__copyLink {
    margin-top: 30px;
  }

  &__dropdown {
    margin-bottom: 30px;
  }
}
</style>
