<template>
  <div
    :class="`popup ${classModifier ? 'popup--' + classModifier : ''}`"
    ref=""
  >
    <div 
      :class="`popup__layout ${classModifier ? 'popup__layout--' + classModifier : ''}`"
      @click="handlerClosePopup"
    >
      <div
        :class="`popup__container ${classModifier ? 'popup__container--' + classModifier : ''}`"
        @click.stop
      >
        <BaseCloseButton
          @click="handlerClosePopup"
          v-if="!cannotBeClosed"
        />
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
export default {
  name: 'BasePopup',
  props: {
    classModifier: {
      type: String,
      default: ''
    },
    cannotBeClosed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
    }),
    ...mapMutations({
      cleanQueue: 'popup/cleanQueue',
    }),
    handlerClosePopup() {
      this.cannotBeClosed ? '' : this.closePopup()
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';
.popup {

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  overflow-y: auto;
  z-index: 9999;

  &--sidebarPopupWithInstruction {
    @include breakpoint(md) {
      margin-top: 266px;
      &OpenProfile {
        margin-top: 210px;
      }
    }
    @include breakpoint(sm) {
      margin-top: 177px;
      &OpenProfile {
        margin-top: 145px;
      }
    }
    @include breakpoint(xs) {
      margin-top: 0; 
      &OpenProfile {
        margin-top: 0;
      }
    }
  }
  
  &__layout {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    height: auto;
    &::before {
      content: '';
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($black, .5);
    }
    &--thirdStepInstructionPopup {
      @include mediaMaxHeight(940) {
        align-items: flex-start;
        padding-top: 240px;
      }
      @include breakpoint(xl) {
        @include mediaMaxHeight(940) {
          padding: 20px 0;
          align-items: center;
        }
        @include mediaMaxHeight(850) {
          align-items: flex-start;
          padding-top: 220px;
        }
      }
      @include breakpoint(md) {
        @include mediaMaxHeight(935) {
          padding-top: 220px;
          align-items: flex-start;
        }
      }
      @include breakpoint(sm) {
        align-items: flex-start;
        @include mediaMaxHeight(935) {
          padding-top: 20px;
        }
      }
    }
    &--sidebarPopupWithInstruction {
      @include breakpoint(md) {
        align-items: flex-start;
        &::before {
          display: none;
        }
      }
      @include breakpoint(sm) {
        &::before {
          display: block;
        }
      }
      &OpenProfile {
        @include breakpoint(md) {
          align-items: flex-start;
          &::before {
            display: none;
          }
        }
        
      }
    }
    &--sidebarPopupWithInstructionOpenProfile {
      @include breakpoint(sm) {
        &::before {
          display: block;
        }
      }
    }
  }

  &__container {
    box-shadow: 0px 5px 15px 0px rgba($black, 0.20);
    border-radius: 20px;
    background-color: $white;
    position: relative;
    max-width: 100%;

    &--account {
      padding: 50px 50px 30px 50px;
      max-width: 500px;
      width: 100%;
      @include breakpoint(sm) {
        max-width: 380px;
        padding: 30px 20px;
      }
      @include breakpoint(xs) {
        max-width: 310px;
        padding: 30px 15px;
      }
    }

    &--team-confirm {
      max-width: 649px;
      width: 100%;
      @include breakpoint(sm) {
        max-width: 380px;
      }
    }

    &--ticket-support-chat-popup {
      max-width: 1880px;
      width: 100%;
      height: 100%;

      @include breakpoint(xl) {
        max-width: 1360px;
      }

      @include breakpoint(md) {
        max-width: 720px;
      }

      @include breakpoint(sm) {
        max-width: 380px;
      }

      @include breakpoint(xs) {
        max-width: 310px;
      }
    }

    .uiButtonClose {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 9999;
    
      &--sidebar {
        top: 10px;
        right: 10px;
        background-color: transparent;
      }
    }
  }

}
</style>