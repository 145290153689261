export default {
  namespaced: true,

  state: () => ({
    balanceInfo: '',
    timeToFree: 0,
    currentTransactionTab: '',
    transactionsIn: [],
    transactionsOut: [],
    needTransaction: null
  }),

  mutations: {
    setBalanceInfo(state, payload) {
      state.balanceInfo = payload
    },
    setTimeToFree(state, payload) {
      state.timeToFree = payload
    },
    setTransactionsIn(state, payload) {
      state.transactionsIn = payload
    },
    setTransactionsOut(state, payload) {
      state.transactionsOut = payload
    },
    setCurrentTransactionTab(state, payload) {
      state.currentTransactionTab = payload
    },
    setNeedTransaction(state, payload) {
      state.needTransaction = payload
    }
  },

  getters: {
    transactionsInReverse(state) {
      const reversedList = state.transactionsIn.slice()
      return reversedList.reverse()
    },
    transactionsOutReverse(state) {
      const reversedList = state.transactionsOut.slice()
      return reversedList.reverse()
    },
  },

  actions: {
    getBalanceInfo({ commit }) {
      return new Promise((resolve) => {
        YC.userBalanceApi.getBalanceInfo()
          .then(({ data }) => {

            const userBalance = data.data.balance===-1 ?'Бесплатно 2 недели':data.data.balance+ ' ₽'
            const transactionsIn = data.data.transactionsIn
            const transactionsOut = data.data.transactionsOut
            const timeToFree = data.data.daysToFree

            commit("setBalanceInfo", userBalance)
            commit("setTransactionsIn", transactionsIn)
            commit("setTransactionsOut", transactionsOut)
            commit("setTimeToFree", timeToFree)

            resolve()
          })
      })
    },

    checkBalanceForZero({commit}) {
      return new Promise((resolve) => {
        YC.userBalanceApi.getCheckBalanceForZero()
          .then((response) => {
            commit('setNeedTransaction', response.data.data.needTransaction)
            resolve(response.data.data)
          })
      })
    },

    postCashInBalance({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        YC.userBalanceApi.postCashInBalance(payload)
          .then((response) => {
            // dispatch("getBalanceInfo");
            console.log("cashe in:",response);
            if(response?.data?.url)
            {
                document.location.replace(response?.data?.url)
            }
            resolve()
          })
          .catch(() => {
            reject()

          })
      })
    }
  }
}
