<template>
  <nav class="homeNavigation">

    <template v-for="item in navigations">

      <router-link
        :to="item.url"
        class="homeNavigation__link"
        :class="{'homeNavigation__link--inProgress': item.inProgress}"
        @click="globalPreloaderInit"
        :key="item.name"
        v-if="user.role === 'Admin' ? true : item.name === 'Репрайсер' || item.name === 'CRM' || item.name === 'Расширение'"
      >
        <div class="homeNavigation__linkInner">
          <picture
            class="homeNavigation__linkPicture"
          >
            <source type="image/webp" media="(max-width: 320px)"
              :srcset="`${item.imgPath}-xs-2x.webp 2x, ${item.imgPath}-xs.webp 1x`">
            <source type="image/webp" media="(max-width: 1200px)"
              :srcset="`${item.imgPath}-md-2x.webp 2x, ${item.imgPath}-md.webp 1x`">
            <source type="image/webp" media="(max-width: 1440px)"
              :srcset="`${item.imgPath}-xl-2x.webp 2x, ${item.imgPath}-xl.webp 1x`">
            <source type="image/webp" :srcset="`${item.imgPath}-2x.webp 2x, ${item.imgPath}.webp 1x`">
            <img :src="`${item.imgPath}.webp`" alt="" class="homeNavigation__linkImage">
          </picture>
          <span>{{item.name}}</span>
        </div>
      </router-link>

    </template>

  </nav>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  data() {
    return {
      navigations: [
        {name: 'Репрайсер', url: '/price_bidder', imgPath: '/images/home/price', inProgress: false},
        {name: 'Рекламный бидер', url: '', imgPath: '/images/home/advert', inProgress: true},
        {name: 'Бидер креативов', url: '', imgPath: '/images/home/creative', inProgress: true},
        {name: 'CRM', url: '/crm/dashboard', imgPath: '/images/home/crm', inProgress: false},
        {name: 'SEO парсер', url: '', imgPath: '/images/home/seo', inProgress: true},
        {name: 'Расширение', url: '', imgPath: '/images/home/extension', inProgress: true},
      ]
    }
  },
  methods: {
    ...mapActions({
      showGlobalPreloader: 'showGlobalPreloader'
    }),
    globalPreloaderInit() {
      this.showGlobalPreloader()
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  }
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.homeNavigation {
  &__link {
    flex: 0 0 100%;
    max-width: 270px;
    position: relative;
    &--inProgress &Picture {
      cursor: not-allowed;
      &::before {
        position: relative;
        content: 'Coming Soon';
        position: absolute;
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 700;
        padding: 10px 15px;
        border-radius: 10px;
        background-color: $white;
        white-space: nowrap;
        text-transform: uppercase;
        z-index: 1;
        @include breakpoint(md) {
          font-size: 13px;
          line-height: 13px;
          top: 55%;
          left: 18px;
          transform: translateY(-50%);
        }
        @include breakpoint(sm) {
          left: 8px;
          padding: 5px 10px;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
    &:not(.homeNavigation__link--inProgress):hover &Picture {
      box-shadow: 0px 5px 10px 0px rgba($black, 0.07);
      background-color: $white;
      @include breakpoint(md) {
        box-shadow: none;
        background-color: transparent;
      }
    }
    &:not(.homeNavigation__link--inProgress):hover &Image {
      filter: none;
    }
    @include breakpoint(xl) {
      max-width: 220px;
    }
    @include breakpoint(md) {
      display: flex;
      margin-bottom: 30px;
      flex: 0 0 90px;
      justify-content: flex-end;
      align-items: center;
      padding: 0 40px 0 8px;
      border-radius: 20px;
      background-color: $gray-100;
      position: relative;
      max-width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include breakpoint(xs) {
      margin-bottom: 20px;
      flex: 0 0 90px;
    }
    &Inner {
      padding: 0 10px;
    }
    &Picture {
      min-height: 310px;
      background-color: $gray-100;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      margin-bottom: 30px;
      transition: background-color $transition-duration ease, box-shadow $transition-duration ease;
      @include breakpoint(xl) {
        min-height: 248px;
      }
      @include breakpoint(md) {
        background-color: transparent;
        min-height: auto;
        margin-bottom: 0;
        margin-top: -46px;
        position: absolute;
        left: 8px;
      }
      @include breakpoint(xs) {
        left: 0;
        margin-top: 0;
        bottom: 0;
      }
    }
    &:first-child &Image {
      left: 4px;
    }
    &:nth-child(2) &Image {
      left: 16px;
    }
    &:last-child &Image {
      left: -10px;
      @include breakpoint(xs) {
        left: 0;
      }
    }
    &Image {
      position: relative;
      transition: filter $transition-duration ease;
      filter: grayscale(1);
      @include breakpoint(md) {
        filter: none;
      }
    }
    span {
      font-weight: 300;
      @include breakpoint(xl) {
        font-size: 14px;
      }
      @include breakpoint(md) {
        font-size: 16px;
        max-width: 175px;
        width: 100%;
      }
      @include mediaMaxWidth(390) {
        max-width: 97px;
      }
      @include breakpoint(xs) {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}

</style>
