export default {
  namespaced: true,

  state: () => ({
    storeList: [],
    currentStore: ''
  }),

  mutations: {
    setStoreList(state, payload) {
      state.storeList = payload
    },
    setCurrentStore(state, payload) {
      state.currentStore = payload
    },
    setSwitchStore(state, id) {
      state.storeList.map(store => {
        if(store.id === id) store.active = true
        else store.active = false
      })
    },
    setResetStoreInfo(state, id) {
      state.storeList.map(store => {
        if(store.id === id) {
          store.current_account = '',
          store.supplierFullName ='',
          store.address = '',
          store.inn = '',
          store.ogrnip = '',
          store.bik = '',
          store.kpp = ''
        }
      })
    }
  },

  getters: {
    
  },

  actions: {
    storeCreate(store, payload) {
      return new Promise((resolve, reject) => {
        YC.userStoreApi.postStoreCreate(payload)
          .then(response => {
            resolve(response)

          })
          .catch(response => {
            reject(response)
          })
      })
    },

    getCurrentStore({commit}) {
      return new Promise((resolve => {
        YC.userStoreApi.getCurrentStore()
          .then(store => {
            commit('setCurrentStore', store.data.data.store)
            resolve()
          })
      }))
    },

    switchStore({commit}, payload) {
      commit('setSwitchStore', payload)
      return new Promise((resolve, reject) => {
        YC.userStoreApi.postSwitchStore(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    getStoreList({commit}) {
      return new Promise((resolve => {
        YC.userStoreApi.getStoreList()
          .then(store => {
            commit('setStoreList', store.data.data)
            resolve()
          })
      }))
    },

    updateStoreInfo(store, payload) {
      const {id, data} = payload
      return new Promise((resolve, reject) => {
        YC.userStoreApi.postUpdateStoreInfo(id, data)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    deleteStore(store, storeId) {
      return new Promise((resolve, reject) => {
        YC.userStoreApi.postDeleteStore(storeId)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    updateToken(store, key) {
      return new Promise((resolve, reject) => {
        YC.userStoreApi.postUpdateToken(key)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    }

  }
}
