<template>
  <div class="adminSellersListBySubCategory">
    <AdminSellersListItemBySubCategory
      :subCategory="subCategory"
      @productState="handleDataFromChild"
    />
    <AdminSellersListByProduct
      v-if="productIsOpen"
      :products="subCategory.products"
    />
  </div>
</template>

<script>
import AdminSellersListByProduct from "./AdminSellersListByProduct.vue";
import AdminSellersListItemBySubCategory from "./AdminSellersListItemBySubCategory.vue";

export default {
  props: {
    subCategory: {
      type: Object,
      required: true,
    },
  },
  components: {
    AdminSellersListItemBySubCategory,
    AdminSellersListByProduct,
  },
  data() {
    return {
      productIsOpen: false,
    };
  },
  methods: {
    handleDataFromChild(data) {
      this.productIsOpen = data;
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';
.adminSellersListBySubCategory {
  display: flex;
  flex-flow: column;
  gap: 5px;
}
</style>
