<template>

    <div class="fload"  v-if="isLoading">

    </div>

    <Content v-else
        :tableCellsParams="tableCellsParams"
             :tableDataFieldName="fieldNode"
             :tablesData="getTables"
             :key_field="'id'"

        />

</template>

<script>
    import Content from "./BaseContent/Content";
    import {mapActions, mapMutations,mapGetters} from 'vuex'
    import BasePreloader from "../../Global/BasePreloader";

    export default {
        components: {BasePreloader, Content},
        name: "Product",
        data() {
            return {
                tableCellsParams: [
                    { width: 300, type: "name", title:"Название товара", field:"product_name", withCheckbox:true, isSticky:true  },
                    { width: 300, type: "name", title:"Страна", field:"country", },
                    { width: 350, type: "name", title:"Артикул", field:"art", },
                    { width: 150, type: "status", title:"Единица измерения", field:"unit" },
                    { width: 150, type: "name", title:"Вес", field:"weight" },
                    { width: 150, type: "name", title:"Объем", field:"volume" },

                   // { width: 200 , type: "status",title:"Статус", field:"status",statustype:1},
                    { width: 45,type: "add-new"   },


                ],
                tablesData:[],
                fieldNode:'items',
                desk_id:9,
            };
        },
        computed: {

            ...mapGetters({
                isLoading : 'crm/isLoading',
                getDesdkdata: 'crm/getDeskData',
            }),
            getTables()
            {
                return this.tablesData;
            }
        },
        methods: {

            ...mapActions({
                fetchApiDeskData: 'crm/fetchDesk',

            }),
            async fetchDeskData() {

                try {
                    console.log('Try fetchDeskData :',this.desk_id);
                    await this.fetchApiDeskData(this.desk_id).then(r=>
                    {
                        //Список возможных статусов
                        if(this.getDesdkdata.statuses) {

                            //console.log('Список возможных статусов :', this.getDesdkdata.statuses);
                            this.$store.commit('crm/setStatusValues', this.getDesdkdata.statuses);
                            //this.tablesData = this.getDesdkdata.statuses;
                        }

                        //Список доп полей
                        if(this.getDesdkdata.extra && this.getDesdkdata.extra.extracols) {

                            //console.log('Список доп колонок :', this.getDesdkdata.extra);
                            this.$store.commit('crm/setExtraCols', this.getDesdkdata.extra);
                            //this.tablesData = this.getDesdkdata.extracols;

                            this.getDesdkdata.extra.extracols.forEach((addcols, rowIndex) => {
                                const newColl = {width: 200, type: "name", title:addcols.name , field:"extra_"+addcols.id, is_extra:true};
                                this.tableCellsParams.splice(this.tableCellsParams.length - 1, 0, newColl);
                            });
                        }

                        if(this.getDesdkdata.desk_groups) {
                            //console.log('!  Данные успешно загружены:', this.getDesdkdata);
                            this.tablesData = this.getDesdkdata.desk_groups;
                        }
                        else {
                            console.error('Ошибка в формате данных таблицы:', this.getDesdkdata);
                        }
                    });
                } catch (error) {
                    console.error('Error fetching Desk data:', error);
                }
                finally {
                    this.$store.commit('crm/setLoading', false);
                }
            },
            init()
            {

            }
        },
        created() {

            this.$store.commit('crm/setLoading', true);
            this.$store.commit('crm/setDeskId', this.desk_id);
            //this.$store.commit('crm/setTableParams', {noaddrow:true});

        },
        mounted() {
            this.init();


            this.fetchDeskData();
        },
        watch:{
            getDesdkdata:
            {
                handler(deskData, oldVal) {
                    this.tablesData=deskData?.desk_groups ?? []
                },
                deep: true,

            }
        }

    }
</script>

<style scoped>
    .fload{
        width: 100vw;
    }
    .load-container{
        margin: 5% 40%;
        position: relative;
    }
</style>
