<template>
  <BasePopup>

    <div class="settingsChangeName">
      <div class="settingsChangeName__titlePopup titlePopup titlePopup--large">Новое имя</div>

        <FormInput   
          class="settingsChangeName__formInput"     
          :placeholder="'Новое имя'"
          v-model="newName"
          :validations="[
            {
              rule: $validations.isStringNotEmpty(newName),
              message: 'Пожалуйста, ведите новое имя'
            }
          ]"
        />

      <BaseButton
        class="settingsChangeName__button button--primary"
        @click="handlerChangeName"
        :preloader="isLoading"
      >
        Изменить
      </BaseButton>

    </div>

  </BasePopup>
</template>

<script>
import FormInput from '@/components/Form/FormInput'
import {mapActions} from 'vuex'

export default {
  name: 'SettingsChangeNamePopup',
  components: {
    FormInput
  },
  data() {
    return {
      isLoading: false,
      newName: null
    }
  },
  methods: {
    ...mapActions({
      changeName: 'settings/changeName',
      getUser: 'auth/getUser',
      openPopup: 'popup/openPopup',
      closePopup: 'popup/closePopup'
    }),
    handlerChangeName() {
      YC.EventBus.emit('validations')
      if(this.newNameIsValid) {
        this.isLoading = true
        this.changeName({name: this.newName})
        .then(() => {
          this.getUser()
          this.closePopup()
          this.openPopup(
            {
              component: 'SettingsAlertPopup', 
              props: {
                text: 'Имя успешно изменено'
              }
            }
          )
        })
        .catch(() => {

        })
        .finally(() => {
          this.isLoading = false
        })
      }
    },
  },  
  computed: {
    newNameIsValid() {
      return this.$validations.isStringNotEmpty(this.newName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.settingsChangeName {

  width: 500px;
  max-width: 100%;
  padding: 50px;
  @include breakpoint(xl) {
    padding: 30px 50px;
  }
  @include breakpoint(md) {
    padding: 50px;
  }
  @include breakpoint(sm) {
    padding: 40px 20px;
    width: 380px;
  }
  @include breakpoint(xs) {
    padding: 30px 15px;
    width: 310px;
  }
  
  &__titlePopup, &__formInput {
    margin-bottom: 20px;
    @include breakpoint(xl) {
      margin-bottom: 15px;
    }
    @include breakpoint(md) {
      margin-bottom: 20px;
    }
  }

  &__button {
    height: 50px;
    max-width: 160px;
    margin: 0 auto;
    &[data-password-confirmed="true"] {
      max-width: 253px;
      @include breakpoint(xl) {
        max-width: 232px;
      }
      @include breakpoint(md) {
        max-width: 253px;
      }
    }
    @include breakpoint(xl) {
      height: 40px;
      max-width: 152px;
    }
    @include breakpoint(md) {
      height: 50px;
      max-width: 160px;
    }
    @include breakpoint(xs) {
      height: 40px;
    }
  }

}

</style>