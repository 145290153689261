import baseApi from '@/api/baseApi'

export default class adminApi extends baseApi {

  /*
  * Тикеты
  */
  getTicketList(params) {
    let queryString = "";
    if (params["order_by"]["field"]) {
      queryString += "?sort=" + params["order_by"]["field"];

      if (params["order_by"]["type"]) {
        queryString += "&type=" + params["order_by"]["type"];
      }
    }
    return this.get("tickets/list" + queryString);
  }

  getTicket(id) {
    return this.get("tickets/" + id);
  }

  postTicketMessage(ticketId, payload) {
    return this.post("tickets/message/" + ticketId, payload);
  }
  

  /*
  *  Список товаров и отфильтрованный список товаров
  */
  getGoodsList() {
   return this.get("reports/product/list?limit=50&skip=1")
  }

  postFiltredGoodsList(params) {
    const body = {
      limit: 50,
      ...params
    };

    return this.post("reports/product/filter", body);
  }

  
  /*
  *  Промокоды - получение списка,
  *  создание и аннулирование промокода/реф.ссылки.
  *  Проверка наличия почты клиента youcamp.
  */
  getPromocodeList() {
    return this.get('promo-code/get-all')
  }

  postCreatePromocode(payload) {
    return this.post('promo-code/create-promo', payload)
  }

  postCreateReferralLink(payload) {
    return this.post('promo-code/create-referral', payload)
  }

  postDeactivatePromocode(id) {
    return this.post('promo-code/deactivate', { id })
  }

  postCheckEmployeeEmail(email) {
    return this.post('promo-code/check-user/', { contact: email })
  }

  postSiteSettings(payload) {
    return this.post('settings/update', payload)
  }
  getSiteSettings() {
    return this.get('settings/get')
  }

}