<template>

    <template  v-if="type===1">


        <button @click="delCol" class="camp-btn-ic camp-btn-ic__close" type="button" aria-label="Удалить">
                                          <span class="camp-btn-ic__icon">
                                            <!-- close.svg -->
                                            <Svg :img="'close'"/>
                                          </span>
        </button>

    </template>




</template>

<script>

    import {mapActions, mapMutations,mapGetters} from 'vuex'

    import Svg from "./Svg";
    export default {
        name: "CellMenu",
        components: {Svg},
        props:{
            type:0,
            field:null,
        },

        methods:{

            delCol(){
                if(confirm("Удалить колонку ?"))
                {
                    console.log("Delete extracol",this.field);
                    this.handleDel(this.field);
                }
            },
            handleDel(field){

                    this.delCollumn({
                        obj: {
                            action: 'delcollumn',
                            field: field,
                            del:true,
                        }
                    });
            },


            ...mapActions({
                delCollumn: 'crm/delCollumn',
            }),
        }
    }
</script>

<style scoped>

    .dell-cell{
        width: 20px;
        margin: 8px auto;
    }

</style>
