<template>
  <div class="searchMobile">
    <div class="searchMobile__wrapper">
      <input type="text" class="searchMobile__control" placeholder="Название/артикул товара">
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSearchMobile'
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.searchMobile {

  &__control {
    height: 40px;
    border-radius: 20px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    color: #000;
    font-family: $font-primary;
    &::placeholder {
      color: $gray-300;
    }
    @include breakpoint(xs) {
      &::placeholder {
        font-size: 14px;
      }
    }
  }

}

</style>