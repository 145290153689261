<template>
  <header
    class="header"
    :class="`${this.$route.path.includes('crm') ? 'header--crm' : `header--${this.$route.name}`}`"
  >
    <div class="header__container">
      <BaseButtonBack
        @click="$router.go(-1)"
        v-if="$store.state.isMobile && !$route.meta.hideProfile"
      >
        Назад
      </BaseButtonBack>
      
      <component
        :class="`header__${$route.meta.header}`"
        :is="$route.meta.header"
      />

      <Profile
        v-if="$store.state.isMobile && !$route.meta.hideProfile"
        class="header__profile"
        :class="`header__profile--${this.$route.name}`"
      />

      <HeaderInfo     
        :class="`header__headerInfo header__headerInfo--${this.$route.name}`"  
        v-if="!$store.state.isMobile && $route.name !== 'PBSingleProduct'"
      />
      
    </div>
  </header>
</template>

<script>
import Profile from '@/components/Profile'
import HeaderInfo from '@/components/Header/HeaderInfo'

import PBBasicProductsHeader from '@/views/PriceBidder/PBBasicProductsHeader.vue'
import PBSingleProductHeader from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductHeader'

import SettingsHeader from '@/views/Account/Settings/SettingsHeader'
import TicketsHeader from '@/views/Account/Tickets/TicketsHeader'



export default {
  components: {
    Profile,
    HeaderInfo,
    PBBasicProductsHeader,
    PBSingleProductHeader,
    SettingsHeader,
    TicketsHeader
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.header {
  min-height: 80px;
  z-index: 3;
  background-color: $white;
  padding: 25px 20px 25px 20px;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    box-shadow: 0px -7.5px 15px -7.5px rgba(0, 0, 0, 0.15) inset;
    z-index: -1;
    @include breakpoint(md) {
      display: none;
    }
  }
  @include breakpoint(xl) {
    min-height: 60px;
    padding: 17px 20px 17px 20px;
  }

  @include breakpoint(md) {
    padding: 30px 34px;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  }
  @include breakpoint(sm) {
    padding: 20px 17px;
  }
  @include breakpoint(xs) {
    padding: 10px 15px 15px 15px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include breakpoint(md) {
      max-width: 700px;
      margin: 0 auto;
    }
  }

  &--crm {
    &::before {
      display: none;
    }
  }

  &--PBBasicProducts, &--PBSingleProduct {
    &::before {
      display: none;
    }
    box-shadow: 0px 7.5px 15px -7.5px rgba(0, 0, 0, 0.15);
  }

  &--PBBasicProducts {
    padding: 40px 0 0 0;
    @include breakpoint(xl) {
      padding: 30px 0 0 0;
    }
    @include breakpoint(md) {
      padding: 30px 15px 25px;
    }
    @include breakpoint(sm) {
      padding: 25px 15px 25px;
    }
    @include breakpoint(xs) {
      padding: 10px 15px 15px;
    }
  }

  &__profile {
    margin-left: auto;
  }

  &__headerInfo--PBBasicProducts {
    position: absolute;
    top: 30px;
    right: 20px;
    z-index: 3;
    @include breakpoint(xl) {
      top: 20px;
    }
  }

  &__profile--PBBasicProducts, &__profile--PBSingleProduct {
    position: absolute;
    top: 25px;
    right: 40px;
    @include breakpoint(xl) {
      top: 15px;
      right: 20px;
    }
  }

  &__PBBasicProductsHeader, &__PBSingleProductHeader {
    width: 100%;
  }

  &__ticketsHeader {
    width: 100%;
  }

  &__settingsHeader {
    width: 100%;
  }

}

</style>