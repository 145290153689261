<template>
  <BasePopup
    :classModifier="classModifierPopup"
  >
    <div
      class="sidebarPopup"
      :class="getModifyClass"
    >
      <Sidebar   
        :class="`sidebarPopup__sidebar sidebarPopup__sidebar--${this.$route.name}`"     
      />
    </div>
  </BasePopup>
</template>

<script>
import Sidebar from '@/components/Sidebar/Sidebar'
import { mapActions, mapState } from 'vuex'


export default {
  name: 'SidebarMobile',
  components: {
    Sidebar
  },
  props: {
    instruction: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      classModifierPopup: null
    }
  },
  computed: {
    ...mapState({
      internalStepsInstructionOnMobile: state => state.popup.internalStepsInstructionOnMobile
    }),
    getModifyClass() {
      return `sidebarPopup--${this.$route.name}`
    }
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup'
    })
  },
  mounted() {

    if(this.$store.state.isMobile && !this.$store.state.auth.user.is_instructed.integration && this.instruction) this.classModifierPopup = 'sidebarPopupWithInstruction'

    window.addEventListener('resize', () => {
      if(!this.$store.state.isMobile) this.closePopup()
    })
  },
  watch: {
    internalStepsInstructionOnMobile(val) {
      if(val === 2) this.classModifierPopup = 'sidebarPopupWithInstructionOpenProfile' 
      else this.classModifierPopup = 'sidebarPopupWithInstruction'
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.sidebarPopup {
  color: $white;
  position: relative;
  width: 430px;
  max-width: 100%;
  background: radial-gradient(278.96% 86.84% at 50% 22.96%, #0153CE 0%, #006FD6 64.62%, #00D1D1 100%);
  border-radius: 20px;
  @include breakpoint(sm) {
    width: 380px;
  }

  @include mediaMaxWidth(375) {
    width: 300px;
  }

  &__sidebar {
    @include breakpoint(md) {
      width: 100%;
      box-shadow: none;
      height: auto;
      background-image: none;
      border-radius: 0;
    }
    &--PBSingleProduct {
      
    }
  }

  &--PBSingleProduct {
    
  }

  &--crm {
    background-image: none;
  }

  &__profile {
    max-width: 100%;
    background-color: $white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    color: $black;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    @include breakpoint(xs) {
      padding: 15px 0;
    }
  }
}
</style>