import baseApi from '@/api/baseApi'

export default class userStoreApi extends baseApi {

  /*
  * User store settings
  */
 
  postStoreCreate(payload) {
    return this.post("my-store/create", payload);
  }

  getCurrentStore() {
    return this.get('my-store')
  }

  getStoreList() {
    return this.get('my-store/list')
  }

  postUpdateStoreInfo(storeId, payload) {
    return this.post("my-store/update/" + storeId, payload);
  }

  postDeleteStore(storeId) {
    return this.post("my-store/delete/" + storeId);
  }

  postUpdateToken(key) {
    return this.post("my-store/set-token", key);
  }

  postSwitchStore(storeId) {
    return this.post("profile/switch/" + storeId);
  }

}