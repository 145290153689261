<template>
    <!-- edit -->
    <div class="camp-dropdown-statuses__edit camp-dropdown__level camp__is-hidden">
        <div class="camp-dropdown-statuses__list camp-dropdown__body">

            <div  v-for="(status, index) in getstatuses" :key="index"
                  class="camp-dropdown-statuses__list--item">
                <button class="camp-btn-ic camp-btn-ic__draggable" type="button" aria-label="Перетащить">
					<span class="camp-btn-ic__icon">
<!--						<Svg :img="'draggable'"/>-->
					</span>
                </button>

                <div class="camp-status-editor">

                    <ColorPickers
                        :key="getstatuseslenght+index"
                        :defalut_color="status.color"
                        :keyid="this.field"
                        :value="status.id"
                        :typePicker="1"
                        @colorChanged="handleColorChange"
                    />

                    <input type="text" class="camp-status-editor__input"
                        :value="status.status_text"
                        placeholder="Новый статус"
                        :dataid="status.id"
                        @change="setStatusText"
                        @keyup.enter="handleEnterKey"
                    >

                </div>

                <button @click="deleteStatus(status.id,index)" class="camp-btn-ic camp-btn-ic__close camp-tooltip" type="button" aria-label="Удалить метку" data-tippy-content="Удалить метку">
					<span class="camp-btn-ic__icon">

                        <Svg :img="'close'"/>
					</span>
                </button>
            </div>


            <div class="camp-dropdown-statuses__list--item camp-dropdown-statuses__list--item_btn"
                 v-if="getAddStatus"
            >
                <button
                    @click="addnew"

                    class="camp-btn-wide camp-btn-stroke"
                    type="button"
                    aria-label="Новая метка">
					<span class="camp-btn-ic camp-btn-ic__add">
						<span class="camp-btn-ic__icon">

                            <Svg :img="'close'"/>
						</span>
					</span>
                    <span>Новая отметка</span>
                </button>
            </div>

        </div>
        <div class="camp-dropdown__footer">
            <button @click="applyStatusEdit"
                    class="camp-btn-transparent camp-btn-wide camp__js-dropdown-btn"
                    data-action="accept" type="button">Применить</button>
        </div>
    </div>
    <!-- //edit -->
</template>

<script>
    import ColorPickers from "./ColorPickers";
    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import Svg from "../Svg";

    export default {
        name: "StatusEditor",
        components: {Svg, ColorPickers},
        data()
        {
            return{
                liststatus:   this.statuses,
                newstatustext:String,
                isAdd:false,

            }
        },
        props: {
            statuses: {
                type: Array,
                required: false
            },
            group_id:0,
            item_id:Number,
            field:null,
            value:null,
            statustype:1,
            // view_type:0, // Тип представления статусов.
        },
        methods: {
            /*
            * Добавляем  статус
            */
            addnew(){
                this.isAdd=true
                let statusType = this.statusType ? this.statusType : 100 + parseInt(this.field.replace('extra_', ''))
                    //console.log("addStatus", this.statuses);
                    const newstat = {
                        id: 0,
                        status_text: "",
                        color: "#FF3D57",
                        type: statusType,
                    };
                    this.liststatus.push(newstat);

            },
            //Удалить статус
            deleteStatus(statusid,index)
            {
                this.setEditedField(this.field)

                console.log("delstat",statusid,index);

                this.liststatus.splice(index, 1);
                // Обновлеяем на сервере
                this.editStatus({
                    obj:{
                        delid:statusid,
                        editfield: this.field,
                    },
                });

            },
            /*
            * Сохраняем изменения по статусам
            */
            applyStatusEdit(){
                this.setEditedField(this.field)

                console.log("applyStatusEdit", this.liststatus);

                this.editStatus({
                    obj: {
                        statuses: this.liststatus,
                        editfield: this.field,
                    },
                })

                this.isAdd=false
                this.$emit('onEditStatuses', this.liststatus);


            },
            setStatusText(event)
            {
                const currentValue = event.target.value;
                const currentId = parseInt(event.target.getAttribute('dataid'));
                console.log("setStatusText", currentId,currentValue);

                this.liststatus.forEach((stat, index) => {
                    //console.log("stat", stat,stat.id);
                    if(stat.id===currentId)
                    {
                        console.log("found", currentId,stat.status_text,currentValue);
                        this.liststatus[index].status_text=currentValue;

                    }
                });
                console.log("new-statuses", this.liststatus);

            },
            handleColorChange(c){
                console.log("handleColorChange", c);
                //console.log("statuses", this.statuses);

                this.statuses.forEach((stat, index) => {
                    if(stat.id===c.value)
                    {
                        this.statuses[index].color=c.color;
                    }
                });
                //console.log("new-statuses", this.statuses);
            },
            handleEnterKey(){
                console.log("on enter click");
                this.applyStatusEdit()
            },
            ...mapActions({
                editStatus: 'crm/editStatus',
                setStatusGroupValues: 'crm/setStatusGroupValues',
            }),
            ...mapMutations({
                setEditedField: 'crm/setEditedField'
            })
        },

        computed: {

            getstatuses(){
                return this.liststatus;
            },
            getstatuseslenght(){
                return  this.liststatus.length;
            },
            getAddStatus(){
                return !this.isAdd;
            },
            ...mapGetters({
                getUiAction: 'crm/getUiAction',
                getStatusValues: 'crm/getStatusValues',
                getStatusEditor: 'crm/getStatusEditor',
            }),
        },
        watch: {
            getUiAction(uiact) {
                if(uiact && uiact.act) {

                    if(uiact.act==='update_statuses') {

                        const allstatuses=this.getStatusValues
                        console.log("allstatuses",allstatuses);
                        this.liststatus = allstatuses[this.field]

                    }
                }

            }
        },
    }
</script>

<style scoped>

</style>
