<template>
  <BasePopup>

    <div class="settingsChangePhone">
      <div class="settingsChangePhone__titlePopup titlePopup titlePopup--large">Новый номер</div>

      <template v-if="!phoneSent">
        <FormInputPhone
          class="settingsChangePhone__formInput"    
          :placeholder="'999-000-00-00'"  
          :countryCodes="countryCodes"
          v-model="newPhone"
          :countryCode="countryCode"
          @update:countryCode="setCountryCode"
          @inputValueUpdate="phoneExists = false"
          :validations="[
            {
              rule: $validations.isPhoneValid(newPhone),
              message: 'Введите Телефон'
            },
            {
              rule: !$validations.isPhoneValid(newPhone) || !this.phoneExists,
              message: 'Такой телефон уже существует'
            }
          ]"
        />
      </template>

      <template v-else>
        <BaseFieldFilled 
          :data="concatPhoneValue"      
          class="settingsChangePhone__filledField"   
        />

        <FormInput
          class="settingsChangePhone__formInput"  
          :label="`SMS-код отправлен на номер ${concatPhoneValue}`"
          :placeholder="'Введите код'"
          :phoneConfirm="true"
          :countdownStart="countdownStart"
          v-model="smsCode"
          @sendCode="resendCode"
          @wrongSmsCodeUpd="wrongSmsCode = false"
          :validations="[
            {
              rule: $validations.isStringNotEmpty(smsCode),
              message: 'Введите SMS-код'
            },
            {
              rule: !$validations.isStringNotEmpty(smsCode) || !this.wrongSmsCode,
              message: 'Неверный код'
            }
          ]"
        />
      </template>

      <BaseButton
        class="settingsChangePhone__button button--primary"
        @click="handlerActionPhone"
        :preloader="isLoading"
        :data-phone-sent="phoneSent"
      >
        {{buttonText}}
      </BaseButton>

    </div>

  </BasePopup>
</template>

<script>
import FormInputPhone from '@/components/Form/FormInputPhone'
import FormInput from '@/components/Form/FormInput'
import {mapMutations, mapState, mapActions} from 'vuex'

export default {
  name: 'SettingsChangePhonePopup',
  components: {
    FormInputPhone,
    FormInput
  },
  data() {
    return {
      isLoading: false,
      phoneSent: false,
      phoneExists: false,
      newPhone: '',
      smsCode: '',
      wrongSmsCode: false,
      buttonText: 'Далее', 
      countdownStart: false
    }
  },
  methods: {
    ...mapMutations({
      setCountryCode: 'setCountryCode'
    }),
    ...mapActions({
      changePhone: 'settings/changePhone',
      confirmPhone: 'settings/confirmPhone',
      getUser: 'auth/getUser',
      openPopup: 'popup/openPopup',
      closePopup: 'popup/closePopup'
    }),
    handlerActionPhone() {
      YC.EventBus.emit('validationPhone')
      this.phoneSent ? this.handlerConfirmPhone() : this.handlerChangePhone()
    },
    handlerChangePhone() {
      if(this.newPhoneIsValid) {
        this.isLoading = true
        this.changePhone({phone: this.formattedPhone})
        .then(() => {
          this.phoneSent = true
          this.buttonText = 'Изменить'
        })
        .catch(() => {
          this.phoneExists = true
          setTimeout(() => {
            YC.EventBus.emit('validationPhone')
          })
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    },
    handlerConfirmPhone() {
      if(this.newPhoneIsValid && this.phoneSent) {
        this.isLoading = true
        this.confirmPhone({phone: this.formattedPhone, code: parseInt(this.smsCode)})
        .then(() => {
          this.getUser()
          this.closePopup()
          this.openPopup(
            {
              component: 'SettingsAlertPopup', 
              props: {
                text: 'Номер телефона успешно изменен'
              }
            }
          )
        })
        .catch(() => {
          this.wrongSmsCode = true
          setTimeout(() => {
            YC.EventBus.emit('validationConfirmPhone')
          })
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    },
    resendCode() {
      if(this.newPhoneIsValid) {
        this.countdownStart = true
        this.changePhone({phone: this.formattedPhone})
      }
    },
  },
  computed: {
    ...mapState({
      countryCodes: state => state.countryCodes,
      countryCode: state => state.countryCode
    }),
    newPhoneIsValid() {
      return this.$validations.isStringNotEmpty(this.newPhone) && this.$validations.isPhoneValid(this.newPhone)
    },
    concatPhoneValue() {
      return this.countryCode + ' ' + this.newPhone
    },
    formattedPhone() {
      return this.concatPhoneValue.replace(/[\D]+/g, '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.settingsChangePhone {

  width: 500px;
  max-width: 100%;
  padding: 50px;
  @include breakpoint(xl) {
    padding: 30px 50px;
  }
  @include breakpoint(md) {
    padding: 50px;
  }
  @include breakpoint(sm) {
    padding: 40px 20px;
    width: 380px;
  }
  @include breakpoint(xs) {
    padding: 30px 15px;
    width: 310px;
  }
  
  &__titlePopup, &__formInput, &__filledField {
    margin-bottom: 20px;
  }

  &__button {
    height: 50px;
    max-width: 160px;
    margin: 0 auto;
    &[data-password-confirmed="true"] {
      max-width: 192px;
      @include breakpoint(xl) {
        max-width: 180px;
      }
      @include breakpoint(md) {
        max-width: 192px;
      }
    }
    @include breakpoint(xl) {
      height: 40px;
      max-width: 152px;
    }
    @include breakpoint(md) {
      height: 50px;
      max-width: 160px;
    }
    @include breakpoint(xs) {
      height: 40px;
    }
  }

}

</style>