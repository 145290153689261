<template>
  <div class="primary">
    <div class="primary__layout">

      <YouCampHomeHeader
        v-if="!headerShow"
        class="primary__homeHeader"
      />

      <main class="primary__main">
        <div class="primary__container container">
          <BaseAuth
            class="primary__auth"
            v-if="$route.meta.isAuth"
          >
          </BaseAuth>

          <PaymentStatus
            class="primary__paymentStatus"
            v-else-if="$route.meta.payment"
          >
          </PaymentStatus>

          <PageNotFound
            class="primary__pageNotFound"
            v-else-if="$route.meta.pageNotFound"
          >

          </PageNotFound>
          
          <Home
            v-else
            class="primary__general"
          />
 
        </div>
      </main>
      
    </div>
    
    <component
      v-for = "item in modalQueue"
      :key="item"
      :is = "item.component"
      v-bind = "item.props"
    />

  </div>
</template>

<script>
import YouCampHomeHeader from '@/views/YouCampHomeHeader'
import BaseAuth from '@/views/Auth/BaseAuth'
import Home from '@/views/YouCampHome'
import PageNotFound from '@/views/Error/404'
import PaymentStatus from '@/views/Payment/PaymentStatus'

import {mapState, mapMutations} from 'vuex'

export default {
  components: {
    BaseAuth,
    YouCampHomeHeader,
    Home,
    PageNotFound,
    PaymentStatus
  },
  methods: {
    ...mapMutations({
      cleanQueue: 'popup/cleanQueue'
    }),
  },
  computed: {
    ...mapState({
      modalQueue: state => state.popup.modalQueue
    }),
    headerShow() {
      return this.$route.meta.isAuth && this.$store.state.isMobile || this.$route.meta.pageNotFound || this.$route.meta.payment
    }
  },
  watch: {
    $route() {
      this.cleanQueue()
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.primary {

  padding: 30px 0;
  @include breakpoint(xs) {
    padding: 15px 0;
  }

  &__layout {
    min-height: calc(100vh - 60px);
    width: 100%;
    &--popup {
      position: relative;
      z-index: -1;
    }
    @include breakpoint(xs) {
      min-height: calc(100vh - 30px);
    }
  }

  &__main, &__container {
    min-height: inherit;
  }

  &__auth, &__general, &__pageNotFound, &__paymentStatus {
    min-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__paymentStatus {
    @include breakpoint(md) {
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 120px;
    }    
    @include breakpoint(xs) {
      padding-top: 85px;
    }
  }

  &__general {
    @include breakpoint(md) {
      min-height: calc(100vh - 260px);
      padding-top: 130px;
    }
    @include breakpoint(sm) {
      min-height: calc(100vh - 200px);
      padding-top: 140px;
    }
    @include breakpoint(xs) {
      padding-top: 90px;
      min-height: calc(100vh - 105px);
    }
  }
}

</style>