<template>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonLoader'
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.loader {
  display: flex;
  span {
    width: 10px;
    height: 10px;
    background-color: $white;
    border-radius: 100%;
    margin: 0 3px;
    animation: loader .6s linear infinite alternate;
    animation-delay: 5s;
    &:first-child {
      margin-left: 0;
      animation-delay: -0.2s;
    }
    &:nth-child(2) {
      animation-delay: -0.4s;
    }
    &:last-child {
      margin-right: 0;
      animation-delay: -0.6s;
    }
  }

  @keyframes loader {
    from { transform: scale(.7) }
    to { transform: scale(1.1) }
  }
}

</style>