import baseApi from '@/api/baseApi'

export default class settingsApi extends baseApi {

  /*
  * user settings
  */

  postChangePassword(payload) {
    return this.post("profile/change-password", payload);
  }
  postCreatePassword(payload) {
    return this.post("profile/confirm-password", payload);
  }

  postChangeEmail(payload) {
    return this.post("profile/change-email", payload);
  }
  postConfirmEmail(payload) {
    return this.post("profile/confirm-email", payload);
  }

  postChangePhone(payload) {
    return this.post("profile/change-phone", payload);
  }
  postConfirmPhone(payload) {
    return this.post("profile/confirm-phone", payload);
  }

  postChangeName(payload) {
    return this.post("profile/change-name", payload);
  }

  postSetInstructed(payload) {
    return this.post("profile/set-instructed", payload);
  }

  getToggleMenuFavorites(payload) {
    return this.get(`account/toggle-menu-favorites/${payload}`)
  }

}