<template>
  <BasePopup>

    <div class="loginPasswordRecoveryPopup">
      
      <div class="loginPasswordRecoveryPopup__titlePopup titlePopup titlePopup--medium">{{titleText}}</div>

      <div
        class="loginPasswordRecoveryPopup__buttons"
        v-if="selectionButtons"
      >
        <BaseButton       
          class="loginPasswordRecoveryPopup__button button button--primary"  
          @click="changeMethod('Email')"
        >
          По Email
        </BaseButton>
        <BaseButton     
          class="loginPasswordRecoveryPopup__button button button--primary"      
          @click="changeMethod('Phone')"
        >
          По Sms
        </BaseButton>
      </div>

      <div
        class="loginPasswordRecoveryPopup__form"
        v-else
      >
        <div class="loginPasswordRecoveryPopup__fieldset">
          
          <template v-if="firstStep">

            <FormInput
              class="loginPasswordRecoveryPopup__formInput"  
              :placeholder="'Введите почту'"
              :type="'email'"
              v-model="form.email"
              v-if="changeMethodValue === 'Email'"
              @inputValueUpdate="emailExists = false"
              :validationsEventName="'validationsPasswordRecovery'"
              :validations="[
                {
                  rule: $validations.isStringNotEmpty(form.email),
                  message: 'Введите E-mail'
                },
                {
                  rule: !$validations.isStringNotEmpty(form.email) || $validations.isEmailValid(form.email),
                  message: 'Некорректный e-mail'
                },
                {
                rule: !$validations.isEmailValid(form.email) || !this.emailExists,
                message: 'Такой e-mail не зарегистрирован'
              }
              ]"
            />

            <FormInputPhone
              v-else
              class="loginPasswordRecoveryPopup__formInput"    
              :placeholder="'999-000-00-00'"  
              :countryCodes="countryCodes"
              :countryCode="countryCode"
              v-model="form.phone"
              @update:countryCode="setCountryCode"
              @inputValueUpdate="phoneExists = false"
              @phoneEntered="setPhoneValid"
              :validationsEventName="'validationsPasswordRecovery'"
              :validations="[
                {
                  rule: this.phoneIsValid,
                  message: 'Введите Телефон'
                },
                {
                  rule: !this.phoneIsValid || !this.phoneExists,
                  message: 'Такой телефон не зарегистрирован'
                }
              ]"
            />

          </template>

          <template v-else-if="secondStep">
            <div class="loginPasswordRecoveryPopup__text">Мы только что отправили вам {{ changeMethodValue === 'Email' ? 'на почту письмо' : 'SMS' }} c проверочным кодом</div>

            <FormInput
              class="loginPasswordRecoveryPopup__formInput"  
              :placeholder="`Введите код ${changeMethodValue === 'Email' ? 'с Email' : 'из SMS'}`"
              v-model="form.smsCode"
              @wrongSmsCodeUpd="wrongSmsCode = false"
              :validationsEventName="'validationsPasswordRecovery'"
              :validations="[
                {
                  rule: $validations.isStringNotEmpty(form.smsCode),
                  message: 'Введите код'
                },
                {
                  rule: !$validations.isStringNotEmpty(form.smsCode) || !this.wrongSmsCode,
                  message: 'Неверный код'
                }
              ]"
            />

          </template>

          <template v-else>
            <FormInput   
              class="loginPasswordRecoveryPopup__formInput loginPasswordRecoveryPopup__formInput--password"     
              :type="'password'"
              :placeholder="'Введите новый пароль'"
              :labelClassModificator="'setting'"
              v-model="newPassword"
              :validationsEventName="'validationsPasswordRecovery'"
              :validations="[
                {
                  rule: $validations.isStringNotEmpty(newPassword),
                  message: 'Пожалуйста, ведите новый пароль'
                },
                {
                  rule: !$validations.isStringNotEmpty(newPassword) || $validations.isStringLengthBiggerThan(newPassword, 6),
                  message: 'Минимум 6 знаков'
                },
                {
                  rule: !$validations.isStringNotEmpty(newPassword) || !$validations.isStringLengthBiggerThan(newPassword, 6) || this.passwordСomparison,
                  message: 'Пароли не совпадают'
                },
              ]"
            />
            <FormInput   
              class="loginPasswordRecoveryPopup__formInput"    
              :type="'password'"
              :placeholder="'Подтвердите пароль'"
              :labelClassModificator="'setting'"
              v-model="confirmNewPassword"
              @inputValueUpdate="passwordsNotMatch = false"
              :validationsEventName="'validationsPasswordRecovery'"
              :validations="[
                {
                  rule: $validations.isStringNotEmpty(confirmNewPassword),
                  message: 'Пожалуйста, подтвердите новый парль'
                },
                {
                  rule: !$validations.isStringNotEmpty(confirmNewPassword) || $validations.isStringLengthBiggerThan(confirmNewPassword, 6),
                  message: 'Минимум 6 знаков'
                },
                {
                  rule: !$validations.isStringNotEmpty(confirmNewPassword) || !$validations.isStringLengthBiggerThan(confirmNewPassword, 6) || this.passwordСomparison,
                  message: 'Пароли не совпадают'
                },
              ]"
            />
          </template>
        </div>

        <BaseButton     
          class="loginPasswordRecoveryPopup__button loginPasswordRecoveryPopup__button--form button button--primary"
          @click="passwordRecoveryHandler"
          :preloader="isLoading"
        >
          {{buttonText}}
        </BaseButton>
      </div>

    </div>

  </BasePopup>
</template>

<script>
import FormInput from '@/components/Form/FormInput'
import FormInputPhone from '@/components/Form/FormInputPhone'
import {mapMutations, mapState, mapActions} from 'vuex'

export default {
  name: 'LoginPasswordRecoveryPopup',
  components: {
    FormInput,
    FormInputPhone
  },
  data() {
    return {
      selectionButtons: true,
      firstStep: false,
      secondStep: false,
      thirdStep: false,
      changeMethodValue: '',
      buttonText: 'Восстановить доступ',
      titleText: 'Восстановить пароль',
      form: {
        email: '',
        phone: '',
        smsCode: ''
      },
      newPassword: '',
      confirmNewPassword: '',
      phoneIsValid: false,
      isLoading: false,
      formattedPhone: '',
      wrongSmsCode: false,
      phoneExists: false,
      emailExists: false
    }
  },
  computed: {
    ...mapState({
      countryCodes: state => state.countryCodes,
      countryCode: state => state.countryCode,
    }),
    emeilIsValid() {
      return this.$validations.isEmailValid(this.form.email)
    },
    codeIsValid() {
      return this.$validations.isStringNotEmpty(this.form.smsCode)
    },
    passwordСomparison() {
      return this.newPassword === this.confirmNewPassword
    }
  },
  methods: {
    ...mapMutations({
      setCountryCode: 'setCountryCode',
    }),
    ...mapActions({
      restorePasswordRequest: 'auth/restorePasswordRequest',
      restorePasswordCheckCode: 'auth/restorePasswordCheckCode',
      restorePassword: 'auth/restorePassword',
      login: 'auth/login',
      closePopup: 'popup/closePopup'
    }),
    setPhoneValid(val) {
      this.phoneIsValid = val
    },
    changeMethod(val) {
      this.selectionButtons = false
      this.firstStep = true
      this.changeMethodValue = val
    },
    loginAfterPasswordRecovery() {
      this.$store.commit('auth/setLoginAndPassword', {
        login: this.form.email ? this.form.email : String(this.formattedPhone),
        password: this.newPassword
      })

      this.login()
        .then(() => {
          this.$router.push('/')
        })
        .catch(() => {
          console.log('Login failed')
        })
    },
    passwordRecoveryHandler() {

      YC.EventBus.emit('validationsPasswordRecovery')

      if(this.firstStep && this.phoneIsValid || this.firstStep && this.emeilIsValid) {
        this.isLoading = true

        const concatPhoneValue = this.countryCode + ' ' + this.form.phone
        this.formattedPhone = parseInt(concatPhoneValue.replace(/[\D]+/g, ''))

        this.restorePasswordRequest(this.changeMethodValue === 'Phone' ? {phone: this.formattedPhone} : {email: this.form.email})
        .then(() => {
          this.firstStep = false
          this.secondStep = true
          this.titleText = 'Код для восстановления'
          this.buttonText = 'Отправить'
        })
        .catch((response) => {
          if(!response || response.response.status !== 500) {
            this.changeMethodValue === 'Phone' ? this.phoneExists = true : this.emailExists = true
            setTimeout(() => {
              YC.EventBus.emit('validationsPasswordRecovery')
            })
          }
          else {
            alert(`Error: ${response.response.data.error}`)
          }
        })
        .finally(() => {
          this.isLoading = false
        })

      } else if (this.secondStep && this.codeIsValid) {
        this.isLoading = true 
        this.restorePasswordCheckCode(this.changeMethodValue === 'Phone' ? {phone: this.formattedPhone, code: this.form.smsCode} : {email: this.form.email, code: this.form.smsCode})
        .then(() => {
          this.secondStep = false
          this.thirdStep = true
          this.titleText = 'Новый пароль'
          this.buttonText = 'Войти'
        })
        .catch((response) => {
          if(!response || response.response.status !== 500) {
            this.wrongSmsCode = true
            setTimeout(() => {
              YC.EventBus.emit('validationsPasswordRecovery')
            })
          }
          else {
            alert(`Error: ${response.response.data.error}`)
          }
        })
        .finally(() => {
          this.isLoading = false
        })

      } else if(this.thirdStep && this.codeIsValid && this.passwordСomparison) {
        this.isLoading = true
        this.restorePassword({phone: this.formattedPhone, email: this.form.email, code: this.form.smsCode, password: this.newPassword})
        .then(() => {
          this.closePopup()
          this.loginAfterPasswordRecovery()
        })
        .catch((response) => {
          if(!response || response.response.status !== 500) {
            setTimeout(() => {
              YC.EventBus.emit('validationsPasswordRecovery')
            })
          }
          else {
            alert(`Error: ${response.response.data.error}`)
          }
        })

      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.loginPasswordRecoveryPopup {
  width: 600px;
  max-width: 100%;
  padding: 50px 50px 30px;

  @include breakpoint(md) {
    width: 430px;
  }
  @include breakpoint(sm) {
    width: 380px;
  }
  @include mediaMaxWidth(395) {
    width: 300px;
    padding: 30px;
  }

  &__titlePopup {
    margin-bottom: 20px;
    @include mediaMaxWidth(395) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__formInput--password {
    margin-bottom: 10px;
  }

  &__text {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__button {
    max-width: 100px;
    height: 34px;
    border-radius: 10px;
    &:first-child {
      margin-right: 20px;
    }
    @include breakpoint(md) {
      max-width: 155px;
    }
    @include breakpoint(sm) {
      max-width: 130px;
    }
    &--form {
      padding: 0 20px;
      max-width: fit-content;
      margin: 20px auto 0;
      @include breakpoint(md) {
      max-width: 100%;
    }
    }
  }
}

</style>