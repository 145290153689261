<template>
  <div
    class="productPrice"
    :class="classModificator"
  >
    <div class="productPrice__discount">Цена со скидкой: {{priceDiscount}} ₽</div>
    <div class="productPrice__withoutDiscount">Цена без скидки: {{priceWithoutDiscount}} ₽</div>
  </div>
</template>

<script>
export default {
  props: {
    priceDiscount: {
      type: [String, Number],
      default: ''
    },
    priceWithoutDiscount: {
      type: [String, Number],
      default: ''
    },
    classModificator: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.productPrice {
  display: flex;
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  @include breakpoint(xl) {
    font-size: 13px;
    line-height: 13px;
  }
  @include breakpoint(md) {
    font-size: 15px;
    line-height: 15px;
  }
  @include mediaMaxWidth(395) {
    flex-direction: column;
  }
  &--sidebar {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    flex-direction: column;
    @include breakpoint(md) {
      flex-direction: row;
    }
    @include mediaMaxWidth(395) {
      flex-direction: column;
    }
  }
  &--sidebar &__discount {
    margin-right: 0;
    margin-bottom: 10px;
    @include breakpoint(md) {
      margin-right: 10px;
      margin-bottom: 0;
    }
    @include mediaMaxWidth(395) {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
  &__discount {
    margin-right: 20px;
    @include mediaMaxWidth(395) {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
}

</style>