<template>
  <div
    class="settingField"
    :class=" classModificator ? `settingField--${classModificator}` : ''"
  >
    <div
      v-if="label"
      class="settingField__label"
      :class="{'settingField__label--edit': edit}"
    >
      {{label}}
    </div>
    <div
      class="settingField__controlWrap"
    >
      <input
        class="settingField__control"
        :class="{'settingField__control--edit': edit || buttonEditing}"
        :readonly="!edit && !buttonEditing"
        :placeholder="placeholder"
        @focus="stateFocus"
        @blur="stateBlur"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      >
      <div class="settingField__icon" v-if="editIcon"></div>
    </div>

    <div
      class="settingField__notice"
      v-if="hasValidationErrors"
    >
      <span        
        v-for="validation in validations" :key="validation.message"
        v-show="!validation.rule"
      >
        {{validation.message}}
      </span>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BaseSettingField',
  props: {
    classModificator: {
      type: String,
      default: ''
    },
    label: {
      type: String
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    editIcon: {
      type: Boolean,
      default: false
    },
    focusEditing: {
      type: Boolean
    },
    buttonEditing: {
      type: Boolean,
      defautl: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    validations: {
      type: Array,
      default() {
          return [];
      }
    },
    validationsEventName: {
      type: String,
      default: 'validations'
    }
  },
  data() {
    return {
      edit: false,
      hasValidationErrors: false,
    }
  },
  methods: {
    stateFocus() {
      this.focusEditing ? this.edit = true : ''
      this.$emit('editing', true)
    },
    stateBlur() {
      this.focusEditing ? this.edit = false : ''
      if(!this.modelValue) this.$emit('editing', false)
      else this.$emit('editing', true)
    },
    checkValidationErrors() {
      this.hasValidationErrors = false
      this.validations.forEach(validation => {
        if (!validation.rule) {
          this.hasValidationErrors = true;
        }
      })
    }
  },
  mounted() {
    YC.EventBus.on(`${this.validationsEventName}`, this.checkValidationErrors)
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.settingField {
  @include breakpoint(md) {
    display: flex;
    align-items: center;
  }
  @include breakpoint(sm) {
    display: block;
  }
  @include breakpoint(xs) {
    font-size: 14px;
    line-height: 14px;
  }
  &--storeInfo &__label {
    flex: 0 0 100%;
    max-width: 200px;
    font-size: 12px;
    line-height: 12px;
    color: $gray-450;
  }
  &--storeInfo &__controlWrap {
    flex: 1;
  }
  &__label {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 5px;
    transition: color $transition-duration ease;
    &--edit {
      color: $gray-400;
    }
    @include breakpoint(xl) {
      font-size: 13px;
      line-height: 13px;
    }
    @include breakpoint(md) {
      font-size: 14px;
      line-height: 14px;
      flex: 0 0 100px;
    }
  }
  &__controlWrap {
    cursor: pointer;
    position: relative;
    @include breakpoint(md) {
      flex: 1;
    }
  }
  &__control {
    cursor: pointer;
    width: 100%;
    background-color: $gray-90;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 15px;
    min-height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px 36px 10px 26px;
    text-align: center;
    border: 1px solid transparent;
    transition: all $transition-duration ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus::placeholder {
      color: transparent;
    }
    &--edit {
      background-color: $white;
      box-shadow: 0 2px 10px 0 rgba($black, .05);
      cursor: text;
    }
    @include breakpoint(xl) {
      font-size: 13px;
    }
    @include breakpoint(md) {
      background-color: $white;
      font-size: 14px;
    }
  }
  &--storeApi &__control, &--storeInfo &__control {
    background-color: $gray-90;
    &--edit {
      background-color: $white;
    }
  }
  &__icon {
    pointer-events: none;
    content: '';
    @include verticalCenter();
    width: 16px;
    height: 16px;
    right: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.41071 6.3158H4.42102L1.07361 9.66317C0.765198 9.97158 0.570496 10.5179 0.411499 11.02C0.243103 11.5463 0.210449 12.1316 0.210449 12.5674V15.7895H3.43256C3.86835 15.7895 4.59888 15.6842 5.08624 15.4842L5.22333 15.4285C5.65906 15.2518 6.05841 15.0899 6.33679 14.8105L9.68414 11.579V11.5922L11.7894 9.48689V9.47369L15.5378 5.72527C15.8463 5.41685 15.9999 5.01264 15.9999 4.60842C15.9999 4.20422 15.8463 3.80106 15.5378 3.49264L12.5073 0.462107C12.3608 0.31528 12.1867 0.198863 11.995 0.119563C11.8033 0.0402553 11.5979 -0.000378809 11.3904 2.66116e-06C10.9852 2.66116e-06 10.582 0.153689 10.2736 0.462107L6.52625 4.21053H6.51599L4.41071 6.3158ZM4.94733 13.339L2.66101 11.0526L9.28522 4.42842L11.5715 6.71474L4.94733 13.339ZM2.25153 11.9295L4.09991 13.7484L2.31573 13.6842L2.25153 11.9295ZM12.3157 5.97053L10.0294 3.68421L11.3904 2.32316L13.6757 4.60948L12.3157 5.97053Z' fill='%23B3B3B3'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }

  &__notice {
    font-size: 12px;
    color: $red;
    margin-top: 5px;
  }
}

</style>