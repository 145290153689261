<template>
  <BasePopup>

    <div class="settingsChangeEmeil">
      <div class="settingsChangeEmeil__titlePopup titlePopup titlePopup--large">Новый e-mail</div>

      <template v-if="!emailSent">
        <FormInput   
          class="settingsChangeEmeil__formInput"     
          :placeholder="'Новый e-mail'"
          v-model="newEmail"
          :type="'email'"
          @inputValueUpdate="this.isEmailFailed = false"
          :validations="[
            {
              rule: $validations.isStringNotEmpty(newEmail),
              message: 'Пожалуйста, введите e-mail'
            },
            {
              rule: !$validations.isStringNotEmpty(newEmail) || $validations.isEmailValid(newEmail),
              message: 'Некорректный e-mail'
            },
            {
              rule: !$validations.isStringNotEmpty(newEmail) || !$validations.isEmailValid(newEmail) || !this.isEmailFailed,
              message: 'Этот email занят'
            },
          ]"
        />
      </template>

      <template v-else>

        <BaseFieldFilled 
          :data="this.newEmail"      
          class="settingsChangeEmeil__filledField"   
        />

        <FormInput   
          class="settingsChangeEmeil__formInput"     
          :placeholder="'Введите код'"
          v-model="emailCode"
          :label="'На Ваш e-mail адрес отправлен код для авторизации'"
          :labelClassModificator="'settingsChangeEmeil'"
          :validations="[
            {
              rule: $validations.isStringNotEmpty(emailCode),
              message: 'Пожалуйста, введите код'
            },
            {
              rule: !$validations.isStringNotEmpty(emailCode) || !this.isEmailCodeFailed,
              message: 'Неверный код'
            },
          ]"
        />
      </template>

      <BaseButton
        class="settingsChangeEmeil__button button--primary"
        @click="handlerActionEmail"
        :preloader="isLoading"
        :data-email-sent="emailSent"
      >
        {{buttonText}}
      </BaseButton>

    </div>

  </BasePopup>
</template>

<script>
import FormInput from '@/components/Form/FormInput'
import {mapActions} from 'vuex'

export default {
  name: 'SettingsChangeEmailPopup',
  components: {
    FormInput
  },
  data() { 
    return {
      isLoading: false,
      newEmail: '',
      emailSent: false,
      emailCode: null,
      isEmailFailed: false,
      isEmailCodeFailed: false,
      buttonText: 'Далее'
    }
  },
  methods: {
    ...mapActions({
      getUser: 'auth/getUser',
      changeEmail: 'settings/changeEmail',
      confirmEmail: 'settings/confirmEmail',
      openPopup: 'popup/openPopup',
      closePopup: 'popup/closePopup'
    }),
    handlerActionEmail() {
      YC.EventBus.emit('validations')
      this.emailSent ? this.handlerConfirmEmail() : this.handlerChangeEmail()
    },
    handlerChangeEmail() {
      if(this.newEmailIsValid) {
        this.isLoading = true
        this.changeEmail({email: this.newEmail})
        .then(() => {
          this.buttonText = 'Изменить'
          this.emailSent = true
        })
        .catch(() => {
          this.isEmailFailed = true
          setTimeout(() => {
            YC.EventBus.emit('validations')
          })
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    },
    handlerConfirmEmail() {
      if(this.newEmailIsValid && this.codeEmailIsValid) {
        this.isLoading = true
        this.confirmEmail({email: this.newEmail, code: parseInt(this.emailCode)})
        .then(() => {
          this.getUser()
          this.closePopup()
          this.openPopup(
            {
              component: 'SettingsAlertPopup', 
              props: {
                text: 'E-mail почта успешно изменена'
              }
            }
          )
        })
        .catch(() => {
          this.isEmailCodeFailed = true
          setTimeout(() => {
            YC.EventBus.emit('validations')
          })
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    }
  },
  computed: {
    newEmailIsValid() {
      return this.$validations.isStringNotEmpty(this.newEmail) && this.$validations.isEmailValid(this.newEmail)
    },
    codeEmailIsValid() {
      return this.$validations.isStringNotEmpty(this.emailCode)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.settingsChangeEmeil {
  width: 500px;
  max-width: 100%;
  padding: 50px;
  @include breakpoint(xl) {
    padding: 30px 50px;
  }
  @include breakpoint(md) {
    padding: 50px;
  }
  @include breakpoint(sm) {
    padding: 40px 20px;
    width: 380px;
  }
  @include breakpoint(xs) {
    padding: 30px 15px;
    width: 310px;
  }
  
  &__titlePopup, &__formInput {
    margin-bottom: 20px;
  }

  &__button {
    height: 50px;
    max-width: 160px;
    margin: 0 auto;
    &[data-password-confirmed="true"] {
      max-width: 192px;
      @include breakpoint(xl) {
        max-width: 180px;
      }
      @include breakpoint(md) {
        max-width: 192px;
      }
    }
    @include breakpoint(xl) {
      height: 40px;
      max-width: 152px;
    }
    @include breakpoint(md) {
      height: 50px;
      max-width: 160px;
    }
    @include breakpoint(xs) {
      height: 40px;
    }
  }

  &__filledField {
    margin-bottom: 20px;
  }

}

</style>