<template>
  <div
    class="formInput"
  >
    <label
      class="formInput__label"
      :class="labelClassModificator ? `formInput__label--${labelClassModificator}` : ''"
      v-if="label"
    >
      {{label}}
    </label>
    <div class="formInput__wrap">
      <input
        :type="type"
        :class="['formInput__control', `${classControlModified ? `formInput__control--${classControlModified}` : ''}`]"
        autocomplete="off"
        :placeholder="placeholder"
        :value="modelValue"
        @input="updateValue"
        @blur="stateBlur"
        @focus="stateFocus"
        @keyup="keyup"
      >
    </div>
    <div
      class="formInput__note"
      v-if="note"
    >
      <span>{{note}}</span>
    </div>
    <div
      v-else-if="phoneConfirm"
      class="formInput__getCode"
    >
      <div
        @click="this.$emit('sendCode')"
        class="formInput__getCodeLink"
        v-if="!countdown"
      >
        Получить код повторно
      </div>
      <div
        v-else
        class="formInput__getCodeCountdown"
      >
        Получить код через: <span ref="countdown">2:59</span>
      </div>
    </div>

    <div
      class="formInput__notice"
      v-if="hasValidationErrors"
    >
      <span        
        v-for="validation in validations" :key="validation.message"
        v-show="!validation.rule"
      >
        {{validation.message}}
      </span>
    </div>

  </div>
</template>

<script>
import {initializeTime} from '@/components/Countdown'

export default {
  name: 'FormInput',
  data() {
    return {
      hasValidationErrors: false,
      countdown: false
    }
  },
  props: {
    classControlModified: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    labelClassModificator: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: ''
    },
    phoneConfirm: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number],
      require: true
    },
    validations: {
      type: Array,
      default() {
          return [];
      }
    },
    validationsEventName: {
      type: String,
      default: 'validations'
    },
    countdownStart: {
      type: Boolean
    }
  },
  methods: {
    stateBlur(event) {
      if(event.target.value !== '') {
        event.target.classList.add('formInput__control--filled')
        this.$emit('filled', true) 
      }
    },
    stateFocus(event) {
      event.target.classList.remove('formInput__control--filled')
    },
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value)
    },
    keyup() {
      // this.checkValidationErrors()
      if(this.phoneConfirm) {
        this.$emit('wrongSmsCodeUpd', false)
      } else {
        this.$emit('inputValueUpdate')
      }
    },
    checkValidationErrors() {
      this.hasValidationErrors = false
      this.validations.forEach(validation => {
        if (!validation.rule) {
          this.hasValidationErrors = true;
        }
      })
    },
    initCountdown(data) {

      const endTime = new Date(Date.parse(new Date()) + data * 1000)
      localStorage.setItem('countdownTime', endTime)
      initializeTime(this.$refs.countdown, endTime)

    }
  },
  mounted() {
    
    if(this.phoneConfirm) YC.EventBus.on('validationConfirmPhone', this.checkValidationErrors)
    else YC.EventBus.on(this.validationsEventName, this.checkValidationErrors)
  
    //if phoneConfirm input
    //Check if the countdown has passed. If not, initialise the countdown with the remaining time.
    if(localStorage.hasOwnProperty('countdownTime') && this.phoneConfirm) {
      if(Date.parse(localStorage.getItem('countdownTime')) < Date.parse(new Date())) return
      else {
        const remainingTime = Math.abs(Date.parse(new Date()) - Date.parse(localStorage.getItem('countdownTime'))) / 1000
        this.countdown = true
        setTimeout(() => {
          this.initCountdown(remainingTime)
        })
      }
    }
  },
  watch: {
    countdownStart(val) {
      if(val) {
        this.countdown = true
        setTimeout(() => {
          this.initCountdown(179)
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var';
@import 'src/scss/utils/mixins';

.formInput {

  &__wrap {
    display: flex;
    &--sm-mod {
      @include breakpoint(sm) {
        flex-direction: column;
      }
    }
  }

  &__label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: right;
    display: block;
    width: 100%;
    text-align: left;
    @include breakpoint(xl) {
      font-size: 14px;
    }
    @include breakpoint(md) {
      font-size: 15px;
    }
    @include breakpoint(sm) {
      font-size: 14px;
    }
    &--setting {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 20px;
      @include breakpoint(xl) {
        font-size: 13px;
        line-height: 13px;
        margin-bottom: 15px;
      }
    }
    &--settingsChangeEmeil {
      font-size: 13px;
      line-height: 11px;
      margin-bottom: 15px;
    }
  }

  &__control {
    background-color: $gray-100;
    height: 60px;
    border: none;
    box-shadow: $box-shadow-input inset;
    text-align: center;
    width: 100%;
    border-radius: 15px;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 15px;

    &--h-50 {
      height: 50px;
    }
    
    &--filled {
      box-shadow: $box-shadow-input;
      background-color: $white;
    }

    @include breakpoint(sm) {
      height: 50px;
    }
    @include breakpoint(xs) {
      height: 40px;
    }

    &::placeholder {
      font-family: $font-primary;
      font-weight: 500;
      font-size: 15px;
      color: $gray-400;
    }
  }

  &__note {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 500;
    text-align: left;
    color: $gray-400;
    @include breakpoint(xs) {
      font-size: 10px;
    }
  }

  &__getCode {
    margin-top: 10px;
    font-size: 15px;
    color: $dark-green-400;
    @include breakpoint(xs) {
      font-size: 14px;
    }
    &Link {
      cursor: pointer;
    }
    &Countdown {

    }
  }

  &__notice {
    font-size: 12px;
    color: $red;
    margin-top: 5px;
  }

  &--center {
    text-align: center;
  }

}

</style>