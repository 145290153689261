<template>
  <div class="adminLogDetailListItem" v-bind:class="{'adminLogDetailListItem__checked' : check_state}">
    <div class="adminLogDetailListItem__bottom">
      <div class="adminLogDetailListItem__bottomCol">
        <input type="checkbox" class="adminLogDetailListItem__bottomCol--checkbox" :name="log.id" @click="handleCheck" />
      </div>
      <div class="adminLogDetailListItem__bottomCol">
        <Tooltip
          :text="log.created_at"
          v-if="!check_state"
        >
          <span>{{ timeDiff }}</span>
        </Tooltip>
        <div v-else>{{ timeDiff }}</div>
      </div>
      <div class="adminLogDetailListItem__bottomCol">
        {{ log.perforemer_user_name }}
      </div>
      <div class="adminLogDetailListItem__bottomCol">
        {{ log.message }}
      </div>
      <div class="adminLogDetailListItem__bottomCol">
        <div class="adminLogDetailListItem__bottomCol--userId">
          ID {{ log.user_id }}
        </div>
      </div>
      <div class="adminLogDetailListItem__bottomCol" v-bind:class="{'adminLogDetailListItem__bottomColCenter' : !log.page}">
        {{ log.page ? log.page : '-' }}
      </div>
      <div class="adminLogDetailListItem__bottomCol" v-bind:class="{'adminLogDetailListItem__bottomColCenter' : !log.type}">
        {{ log.type ? log.type : '-' }}
      </div>
      <div class="adminLogDetailListItem__bottomCol">
        <div class="adminLogDetailListItem__bottomCol--state">
          <div class="adminLogDetailListItem__bottomCol--stateChecked" v-if="check_state">

          </div>
          <div v-else-if="log.type === 'Изменение роли' && log.before_state === 'Admin'">
            Аккаунт
          </div>
          <div v-else-if="log.type === 'Изменение роли' && log.before_state === 'Manager'">
            Администратор
          </div>
          <div v-else-if="log.type === 'Изменение роли' && log.before_state === 'Client'">
            Клиент
          </div>
          <div v-else-if="log.type === 'Статус' && log.before_state === '0'">
            Пингануть
          </div>
          <div v-else-if="log.type === 'Статус' && log.before_state === '1'">
            Активен
          </div>
          <div v-else-if="log.type === 'Статус' && log.before_state === '2'">
            Неактивен
          </div>
          <div v-else-if="log.type === 'Изменение Часовой пояс'">
            {{ '(UTC' + (log.before_state === '0' ? '+00' :  log.before_state < 0 ? log.before_state : '+' + log.before_state) + ')' }}
          </div>
          <div v-else>
            {{ log.before_state ? log.before_state : '-' }}
          </div>
        </div>
      </div>
      <div class="adminLogDetailListItem__bottomCol" v-bind:class="{'adminLogDetailListItem__bottomColChecked' : check_state}">
        <div class="adminLogDetailListItem__bottomCol--state">
          <div class="adminLogDetailListItem__bottomCol--stateChecked" v-if="check_state">
            Текст
          </div>
          <div v-else-if="log.type === 'Изменение роли' && log.after_state === 'Admin'">
            Аккаунт
          </div>
          <div v-else-if="log.type === 'Изменение роли' && log.after_state === 'Manager'">
            Администратор
          </div>
          <div v-else-if="log.type === 'Изменение роли' && log.after_state === 'Client'">
            Клиент
          </div>
          <div v-else-if="log.type === 'Статус' && log.after_state === '0'">
            Неактивен
          </div>
          <div v-else-if="log.type === 'Статус' && log.after_state === '1'">
            Пингануть
          </div>
          <div v-else-if="log.type === 'Статус' && log.after_state === '2'">
            Активен
          </div>
          <div v-else-if="log.type === 'Изменение Часовой пояс'">
            {{ '(UTC' + (log.after_state === '0' ? '+00' :  log.after_state < 0 ? log.after_state : '+' + log.after_state) + ')' }}
          </div>
          <div v-else>
            {{ log.after_state ? log.after_state : '-' }}
          </div>
        </div>
      </div>
      <div class="adminLogDetailListItem__bottomCol">
        <div v-if="!check_state" class="adminLogDetailListItem__bottomColCancel" @click="handleDeletePopup">
          <div class="adminLogDetailListItem__bottomColCancel--text">
            Отменить действие
          </div>
          <div class="adminLogDetailListItem__bottomColCancel--svgGray">
            <SvgUse
              :width="12"
              :height="12"
              id="iconCloseInActive"
            />
          </div>
          <div class="adminLogDetailListItem__bottomColCancel--svgBlack">
            <SvgUse
              :width="12"
              :height="12"
              id="iconCloseActive"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Tooltip from '@/views/Admin/UIComponents/Tooltip.vue';
export default {
  components: {
    Tooltip,
  },
  props: {
    log: {
      type: Object,
    }
  },
  data() {
    return {
      check_state: false,
      timeDiff: '',
    }
  },
  computed: {
    ...mapState({
        checkedLog: (state) => state.adminLog.checkedLog,
        logParams: (state) => state.adminLog.logParams,
    }),
  },
  methods: {
    ...mapActions({
      openPopup: "popup/openPopup",
    }),
    ...mapMutations({
      setCheckedLog: 'adminLog/setCheckedLog',
    }),
    handleCheck(e) {
      this.check_state = e.target.checked;
      let newCheckedLog = this.checkedLog;
      const addIndex = newCheckedLog.length;
      const popIndex = newCheckedLog.findIndex(item => item.id === this.log.id);
      if (e.target.checked)
        newCheckedLog.splice(addIndex, 0, this.log);
      else
        if (popIndex !== -1) {
          newCheckedLog.splice(popIndex, 1);
        }
      this.setCheckedLog(newCheckedLog);
    },
    handleDeletePopup() {
      var data = [];
      data.push(this.log);
      this.openPopup({
        component: 'AdminLogDetailDeletePopup',
        props: {
          deleteLogs: data,
          mode: 'single'
        },
      })
    },
  },
  mounted() {
    const date = new Date()
    const created = new Date(this.log.created_at)
    var diffMsTime = date.getTime() - created.getTime()
    const hDiff = Math.floor(diffMsTime / 1000 / 3600)
    const minDiff = Math.floor((diffMsTime - hDiff * 1000 * 3600) / 1000 / 60)
    const secDiff = Math.floor((diffMsTime - hDiff * 1000 * 3600 - minDiff * 60 * 1000) / 1000)
    const dayDiff = Math.floor(hDiff / 24);
    this.timeDiff = (dayDiff ? dayDiff + 'д ' : '') + (hDiff ? hDiff + 'ч ' : '') + (minDiff ? minDiff + 'м ' : '') + (secDiff ? secDiff + 'с' : '');
  }
 }
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";
.adminLogDetailListItem {
  position: relative;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  font-family: $font-primary;
  background-color: $white;
  box-shadow: 0 5px 10px 0 rgba($black, 0.07);

  @include breakpoint(xl) {
    font-size: 14px;
    line-height: 14px;
    width: 1750px;
  }

  @include mediaMaxWidth(1430) {
    font-size: 15px;
    line-height: 15px;
  }

  @include breakpoint(sm) {
    font-size: 14px;
    line-height: 14px;
  }

  &__checked {
    background-color: $gray-100;
  }

  &__bottom {
    display: flex;
    align-items: center;
    &Col {
      height: 35px;
      overflow: hidden;
      text-align: start;
      text-overflow: ellipsis;
      flex: 1 1 211px;
      white-space: nowrap;
      padding: 10px 0 0 10px;
      border-right: 2px solid $gray-50;

      &:first-child {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: none;
      }
      &:nth-child(2) {
        flex-basis: 100px;
        border: none;
      }
      &:nth-child(3) {
        flex-basis: 150px;
      }
      &:nth-child(4) {
        flex-basis: 300px;
        border: none;
      }
      &:nth-child(5) {
        padding: 0 5px;
      }
      &:nth-child(8) {
        padding: 0;
      }
      &:nth-child(9) {
        padding: 0;
      }
      &:last-child {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }

      &Center {
        text-align: center;
      }

      &Checked {
        border: none;
      }

      &--checkbox {
        width: 20px;
        height: 20px;
        appearance: none;
        border: 2px solid #ACACAC;
        border-radius: 5px;

        @include breakpoint(xl) {
          width: 18px;
          height: 18px;
        }

        @include mediaMaxWidth(1400) {
          width: 20px;
          height: 20px;
        }

        &:hover {
          border-color: $black;
        }

        &:checked {
          box-shadow: -1px 2px 3px 0 rgba($black, 0.25) inset, 0 4px 4px 0 rgba($white, 0.05) inset;
          border: none;
          background: url('@/assets/img/icons/svg/customCheck.svg') center center no-repeat #0097EC;
        }
      }

      &--userId {
        background-color: $gray-100;
        border-radius: 10px;
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0 20px;
      }

      &--state {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: 35px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        font-family: $font-primary;
        &Checked {
          color: $black;
          font-size: 15px;
          line-height: 15px;
        }
        &Before {
          padding: 5px 20px;
          background-color: $green-100;
          color: $white;
          border-radius: 10px;
        }
        &Last {
          padding: 5px 20px;
          background-color: $red;
          color: $white;
          border-radius: 10px;
        }
      }
      &Cancel {
          position: absolute;
          right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          box-shadow: 0 2px 5px 0 rgba($black, 0.1);
          border-radius: 24px;
          background-color: $white;
          cursor: pointer;
          &:hover {
            width: 200px;
            height: 35px;
            border-radius: 20px;
            justify-content: start;
            padding: 0 6px 0 15px;
            // padding: 0px, 6px, 0px, 15px;
          }
          &:hover &--text {
            display: block;
          }
          &:hover &--svgGray {
            display: none;
          }
          &:hover &--svgBlack {
            display: block;
          }
          &--text {
            display: none;
          }
          &--svgGray {
            display: flex;
            align-items: center;
          }
          &--svgBlack {
            display: none;
            padding-left: 5px;
          }
        }
    }
  }
}
</style>
