import TicketSupportChatPopup from '@/components/TicketSupportChatPopup.vue'
import AdminUsersDeletePopUp from '@/views/Admin/AdminUsersDeletePopUp.vue'
import AdminSellersDetailPopup from '@/views/Admin/AdminSellersDetailPopup.vue'
import AdminLogDetailPopup from '@/views/Admin/AdminLogDetailPopup.vue'
import AdminLogDeletePopup from '@/views/Admin/AdminLogDeletePopup.vue'
import AdminPromocodesCreatePromocodeDiscountPopup from '@/views/Admin/AdminPromocodes/AdminPromocodesPopups/AdminPromocodesCreatePromocodeDiscountPopup.vue'
import AdminPromocodesCreateReferralLinkPopup from '@/views/Admin/AdminPromocodes/AdminPromocodesPopups/AdminPromocodesCreateReferralLinkPopup.vue'
import AdminPromocodesPopupsCreatePromocodeBalancePopup from '@/views/Admin/AdminPromocodes/AdminPromocodesPopups/AdminPromocodesPopupsCreatePromocodeBalancePopup.vue'
import AdminPromocodesRemovePopup from '@/views/Admin/AdminPromocodes/AdminPromocodesPopups/AdminPromocodesRemovePopup.vue'
import AdminLogDetailDeletePopup from '@/views/Admin/AdminLogDetailDeletePopup.vue'
import AdminSearchEmailPopup from '@/views/Admin/AdminSearchEmailPopup.vue'
import AdminSearchPhonePopup from '@/views/Admin/AdminSearchPhonePopup.vue'
import AdminTicketSupportChatPopup from '@/views/Admin/AdminTickets/AdminTicketSupportChatPopup.vue'
import RoleChangeConfirmPopup from '@/views/Admin/RoleChangeConfirmPopup.vue'

export default [
  TicketSupportChatPopup,
  AdminUsersDeletePopUp,
  AdminSellersDetailPopup,
  AdminLogDetailPopup,
  AdminLogDeletePopup,
  AdminPromocodesCreatePromocodeDiscountPopup,
  AdminPromocodesCreateReferralLinkPopup,
  AdminPromocodesPopupsCreatePromocodeBalancePopup,
  AdminPromocodesRemovePopup,
  AdminLogDetailDeletePopup,
  AdminSearchEmailPopup,
  AdminSearchPhonePopup,
  RoleChangeConfirmPopup,
  AdminTicketSupportChatPopup
]