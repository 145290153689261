<template>

    <div v-if="isSticky" :class="getCellClass()" :style="getCellStyle()">

        <div class="camp-table-left-indicator" style="background-color: #CACACA;"></div>
        <div class="camp-table-stub"></div>

        <div class="camp-table-cell-inner">
            <div class="camp-table-title__wrapper" style="color: #CACACA;">

                <div class="camp-table-title__inner">

                    <button type="button" class="aaa camp-table-title__ic camp__is-active camp__js-open-table-btn"

                    >

                        <Svg :img="'arrow'"/>

                    </button>

                    <!-- camp__is-active -->
                    <div class="camp-table-title">

                        <div class="camp-table-cell-title">
                            <div class="camp-table-cell-title__display camp-tooltip" data-tippy-content="Нажмите чтобы отредактировать">
                                <div class="camp-table-cell-title__display-inner">
                                    <h2 class="camp-table-cell-title__display-text">Создать группу</h2>
                                    <div class="camp-table-cell-title__edit" style="display: none">
                                      <ColorPickers/>

                                        <input type="text"
                                                :placeholder="'Введите название'"
                                               v-model="inputValue"
                                               @change="handleAddGrop"
                                        >
                                    </div>

                                </div>
                            </div>
                        </div>

                        <span class="camp-table-title__notify">0 элементов</span>
                    </div>
                </div>
            </div>
        </div>

        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>

    </div>
    <div v-else-if="type==='name'" :class="getCellClass()" :style="{ width: `${width}px` }">
        <p v-if="title" class="camp-table-label">{{title}}</p>
        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>

    </div>
     <div v-else-if="type==='noedit'" :class="getCellClass()" :style="{ width: `${width}px` }">
        <p v-if="title" class="camp-table-label">{{title}}</p>
        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>

    </div>
    <div v-else-if="type==='add-new'" :class="getCellClass()" :style="getCellStyle()">
        <div class="camp-table-cell camp-table-cell__add-new" style="width: 66px;">
            <div class="camp-table-cell-inner">

            </div>
        </div>

    </div>
    <div v-else-if="type==='price'"  :class="getCellClass()" :style="getCellStyle()">
        <CheckboxCell v-if="withCheckbox"/>

        <NumTotal
            :field="field"
            :title="title"
            :group_id="group_id"
            :value="value"
        />
    </div>
    <div v-else-if="type==='date'"  :class="getCellClass()" :style="getCellStyle()">
        <CheckboxCell v-if="withCheckbox"/>

        <DateTotal
            :field="field" :title="title"
            :value="value"
        />
    </div>
    <div v-else-if="type==='link'" :class="getCellClass()" :style="getCellStyle()">

        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>

    </div>
    <div v-else-if="type==='status'" :class="getCellClass()" :style="getCellStyle()">

        <Status
            :view_type="tableType"
            :field="field"
            :title="title"
            :value="value"
            />


        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>

    </div>
    <div v-else class="camp-table-cell camp-table-cell__link" :style="{ width: `${width}px` }">
        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>

    </div>


</template>

<script>
    import {mapActions} from 'vuex'
    import CheckboxCell from "./CheckboxCell";
    import Status from "./Inputs/Status";


    import Svg from "./Svg";
    import ColorPickers from "./Inputs/ColorPickers";
    import NumTotal from "./Totals/NumTotal";
    import DateTotal from "./Totals/DateTotal";
    import AddColumn from "./Inputs/AddColumn";
    export default {
        name: "TableCellCollapsed",
        components: {AddColumn, DateTotal, NumTotal, ColorPickers, Svg, Status, CheckboxCell},
        /*
        * tableType -тип таблицы
        *   0-Обычный вид рабочей таблицы;
        *   1 - Таблица создания Групы. ;
        *   2 - Таблица контента свернутая - результирующие данные
         */
        props: {
            width: {
                type: Number,
                default: 20
            },
            value:{
                type: String,
                default: ''
            },
            field:null,
            group_id:0,
            title:'',
            withCheckbox:Boolean,
            isSticky:Boolean,
            type:String,
            tableType:0,
        },
        data() {
            return {
                inputValue: '',
                doc:null,
                numrows:0,
            }
        },

        methods: {
            ...mapActions({
                editGroup: 'crm/editGroup',
            }),
            handleAddGrop() {
                console.log('add group',this.inputValue);
                this.postGroupData(this.inputValue);
            },
            postGroupData(groupname) {
                this.editGroup({
                    deskid:1,
                    obj: {
                        name:groupname,
                        action:'create_group',
                    }
                }).then(() => {

                })
                    .catch(() => {

                    })
                    .finally(() => {

                    })
            },
            getCellClass() {

                let tablename=this.type;
                if(tablename==='name'){tablename='link';}

                return {
                    'camp-table-cell': true,
                    'camp-table-cell-sticky camp-table-cell-collapsed': this.isSticky,

                    [`camp-table-cell__${tablename}`]: true,

                };
            },
            getCellStyle() {
                return {
                    width: `${this.width}px`,
                    // Другие стили, если необходимо
                };
            },
            tableTitleEdit() {
                const doc=this.doc;
                // Edit table title
                const tableTitles = doc.querySelectorAll('.camp-table-title');

                if (tableTitles) {
                    tableTitles.forEach(function (tableTitle) {
                        tableTitle.addEventListener('click', function(e) {
                            let target = e.target;
                            let editor = tableTitle.querySelector('.camp-table-cell-title__edit');
                            if (target.classList.contains('camp-table-cell-title__display-text')) {
                                target.style.display = 'none';
                                editor.style.display = 'block';
                                tableTitle.classList.add('camp__is-active');
                            }
                        });
                    });
                }

            },
            dropdowns(){
                const doc=this.doc;

                function closeDropdowns(dropdownsElements) {
                    dropdownsElements.forEach(function(dropdown) {
                        dropdown.parentNode.classList.remove('camp__is-opened');
                        dropdown.parentNode.classList.remove('camp__is-active');
                    });
                }
                // Dropdowns
                const dropdowns = doc.querySelectorAll('.camp__js-dropdown .camp-table-cell__status-picker_display');
                const dropdownsStatuses = doc.querySelectorAll('.camp-dropdown .camp-status');


                if (dropdowns) {
                    dropdowns.forEach(function(dropdown) {
                        dropdown.addEventListener('click', function(e) {
                            if (this.classList.contains('camp__is-opened') && this.classList.contains('camp__is-active')) {
                                closeDropdowns(dropdowns);
                                this.parentNode.classList.remove('camp__is-opened');
                                this.parentNode.classList.remove('camp__is-active');
                            } else {
                                closeDropdowns(dropdowns);
                                this.parentNode.classList.add('camp__is-opened');
                                this.parentNode.classList.add('camp__is-active');
                            }
                        });
                    });
                }
                if (dropdownsStatuses) {
                    // console.log(dropdownsStatuses);
                    dropdownsStatuses.forEach(function(dropdownStatus) {
                        dropdownStatus.addEventListener('click', function() {
                            const currentDropdown = this.closest('.camp__js-dropdown');
                            const currentStatusText = this.innerHTML;
                            const currentStatusColor = this.style.backgroundColor;

                            currentDropdown.querySelector('.camp-table-cell__status-picker_display').style.backgroundColor = currentStatusColor;
                            currentDropdown.querySelector('.camp-table-cell__status-picker_text').innerHTML = currentStatusText;
                            currentDropdown.classList.remove('camp__is-opened');
                            currentDropdown.classList.remove('camp__is-active');


                        });
                    });

                }
                const dropdownsStatusesLevels = doc.querySelectorAll('.camp-dropdown-statuses');
                if (dropdownsStatusesLevels) {
                    dropdownsStatusesLevels.forEach(function(dropdownsStatusesLevel) {
                        dropdownsStatusesLevel.addEventListener('click', function(e) {
                            let target = e.target;
                            if (!target.classList.contains('camp__js-dropdown-btn')) return;

                            let action = target.getAttribute('data-action');
                            let level = target.closest('.camp-dropdown__level');

                            if (action === 'edit') {
                                level.classList.add('camp__is-hidden');
                                level.nextElementSibling.classList.remove('camp__is-hidden');
                            } else {
                                target.closest('.camp__js-dropdown').classList.remove('camp__is-opened');
                                target.closest('.camp__js-dropdown').classList.remove('camp__is-active');
                                setTimeout(function() {
                                    level.classList.add('camp__is-hidden');
                                    level.previousElementSibling.classList.remove('camp__is-hidden');
                                }, 200);

                            }
                        });
                    });
                }
            },
            collapseTable(){
                const doc=this.doc;
                // Collapse table
                const openTableBtns = doc.querySelectorAll('.camp__js-open-table-btn');
                const closeTableBtns = doc.querySelectorAll('.camp__js-close-table-btn');

                if (openTableBtns) {
                    openTableBtns.forEach(function(openTableBtn) {
                        openTableBtn.addEventListener('click', function(e) {
                            e.preventDefault();

                            const currentCollapsedTable = this.closest('.camp-table-collapsed');
                            const currentFullTable = currentCollapsedTable.nextElementSibling;

                            currentCollapsedTable.style.display = 'none';
                            currentFullTable.style.display = 'block';

                        });
                    });

                    closeTableBtns.forEach(function(closeTableBtn) {
                        closeTableBtn.addEventListener('click', function(e) {
                            e.preventDefault();

                            const currentFullTable = this.closest('.camp-table-full');
                            const currentCollapsedTable = currentFullTable.previousElementSibling;

                            currentFullTable.style.display = 'none';
                            currentCollapsedTable.style.display = 'block';

                        });
                    });
                }

                // Toggle favorite btn active class
                let btnsFavorites = doc.querySelectorAll('.camp__js-favorite-btn');

                if (btnsFavorites) {
                    btnsFavorites.forEach(function(btnFavorite) {
                        btnFavorite.addEventListener('click', function(e) {
                            e.preventDefault();
                            this.classList.toggle('camp__is-active');
                        });
                    });
                }
            },
            init(){
                this.doc = document;
                //this.collapseTable();
                //this.dropdowns();
                //this.tableTitleEdit();
            }
        },
        mounted() {

            this.init();
        }
    }
</script>

<style scoped>

</style>
