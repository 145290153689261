<template>
    <div class="camp-table-cell-inner">
        <p v-if="title" class="camp-table-label">{{title}}</p>
        <div class="camp-table-cell-title camp-table-cell-title__price"
            >
            <p> {{value}} <span>Сумма</span></p>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapMutations,mapGetters} from 'vuex'
    export default {
        name: "NumTotal",
        props: {
            group_id:0,
            item_id:Number,
            field:null,
            value:null,
            title:'',
        },
        computed:{

            ...mapGetters({
                isLoading : 'crm/isLoading',
                getDesdkdata: 'crm/getDeskData',
                getItemsByGroupId: 'crm/getItemsByGroupId',
                getTotalByField: 'crm/getTotalByField',
            }),
            calsSumm()
            {

                return  this.getTotalByField(this.group_id,this.field);
                const groupData=this.getItemsByGroupId(this.group_id);


                console.log("numtotalItems:",this.group_id, groupData);

                const field='price';
                groupData.forEach((itm, index) => {
                    console.log("itm",itm, itm[field]);
                });
                // if(deskData && deskData.desk_groups) {
                //     //console.log("numtotal", this.group_id,deskData.items);
                //     console.log("numtotal", deskData.desk_groups);
                // }
                return 0;
            },


        },
    }
</script>

<style scoped>

</style>
