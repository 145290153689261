<template>
        <div
            :class="isshow"
        >
            <button
                @click="toggleDropdown"
                class="camp-table-cell__add-new_btn camp-tooltip"
                type="button" aria-label="Добавить колонку"
                data-tippy-content="Добавить колонку"></button>

            <div class="camp-dropdown camp-dropdown-add-col">
                <div class="camp-dropdown__body">
                    <div class="camp-dropdown-heading">
                        <p>Добавить колонку</p>
                    </div>
                    <div class="camp-form-group">
                        <input v-model="text"
                            type="text"
                            class="camp-form-input-view"
                            placeholder="Введите название">
                    </div>
                    <!-- //group -->
                    <div class="camp-form-group">
                        <p>Тип колонки:</p>

                        <div class="camp-table-cell-types">
                            <label class="camp-table-cell-type">
                                <input type="radio" name="cell-type" value="4"
                                       v-model="typeselected"
                                >
                                <span class="camp-table-cell-type__inner">
                                    <Svg :img="'stateIcon'"></Svg>
                                    Статус
                                </span>
                            </label>

                            <label class="camp-table-cell-type">
                                <input type="radio" name="cell-type" value="3"
                                       v-model="typeselected"
                                >
                                <span class="camp-table-cell-type__inner">
                                    <Svg :img="'numberIcon'"></Svg>
                                    Числа
                                </span>
                            </label>

                            <label class="camp-table-cell-type">
                                <input type="radio" name="cell-type" value="2"
                                       v-model="typeselected"
                                >
                                <span class="camp-table-cell-type__inner">
                                    <Svg :img="'calendarIcon'"></Svg>
                                    Дата
                                </span>
                            </label>

                            <label class="camp-table-cell-type">
                                <input type="radio" name="cell-type" value="1"
                                       v-model="typeselected">
                                <span class="camp-table-cell-type__inner">
                                    <Svg :img="'textIcon'"></Svg>
                                    Текст
                                </span>
                            </label>
                        </div>
                    </div>
                    <button @click="handleAdd" class="camp-btn-stroke camp-btn-stroke__accent camp-btn-wide" type="button">Применить</button>
                </div>
            </div>
        </div>



    <template v-show="false">
    <div class=" camp__js-dropdown">

    <div class="camp-dropdown camp-dropdown-statuses" :style="modalStyle" >

        <div  class="camp-dropdown__level">
            <div class=" camp-dropdown__body">

                <template v-show="false">
                    <div  class="camp-dropdown-statuses__list--item">
                        <button class="camp-status statbtn0" type="button" :style="classSelect(1)" @click="selectButton(1)">Текст</button>
                    </div>
                    <div class="camp-dropdown-statuses__list--item">
                        <button class="camp-status statbtn1" type="button" :style="classSelect(2)" @click="selectButton(2)">Список</button>
                    </div>
                </template>

                <div class="camp-dropdown-statuses__list--item">
                    <div class="camp-table-cell__text-editor_content">
                    <p>
                        <input v-model="text" type="text" placeholder="Название колонки" style="text-align: center;"/>
                    </p>
                    </div>

                </div>


            </div>


        </div>

        <button @click="handleAdd" class="camp-btn-transparent camp-btn-wide " :style="classSelect(1)"  type="button">Применить</button>
    </div>

        <div  class="camp-table-cell__status-picker_display camp-status" :style="'display:none !important;'">
            <p class="camp-table-cell__status-picker_text"></p>
        </div>

    </div>
    </template>
</template>

<script>

    import {mapActions, mapMutations,mapGetters} from 'vuex'
    import Svg from "@/components/Crm/UI/Svg.vue";
    export default {
        name: "AddColumn",
        components: { Svg },
        data(){
            return {
                coltype:null,
                modalStyle:'',
                show:false,
                //classSelect:'background-color: rgb(73,255,107);',
                //classNonselect:'background-color: rgb(221,224,253);',
                text:'',
                typeselected: 1,
            }
        },
        props:{
            group_id:0,
        },
        computed:{
            isshow(){
                return this.show ? 'camp-table-cell-inner camp-dropdown__wrapper camp__js-dropdown camp__is-active':'camp-table-cell-inner camp-dropdown__wrapper camp__js-dropdown';
            }
        },
        methods:{


            classSelect(type) {
                return type === this.typeselected ? 'background-color: rgb(73,255,107);' : 'background-color: rgb(221,224,253);';
            },
            selectButton(type) {
                this.typeselected = type;
            },

            handleClick() {

                this.modalStyle='display:block !important; transform: translateX(-160px);';
                return 0;
            },

            handleAdd(){

                console.log(this.text,this.typeselected);

                this.modalStyle='display:none !important';
                this.show=false
                if (this.text) {
                    this.addCollumn({
                        obj: {
                            group_id: this.group_id,
                            action: 'addcollumn',
                            name: this.text,
                            type: this.typeselected,
                        }
                    });
                }

            },
            toggleDropdown(){
                console.log("toggle",this.show);
                this.show=!this.show
            },
            ...mapActions({
                addCollumn: 'crm/addCollumn',
            }),
        },
        mounted()
        {

           // this.nextElementSibling.classList.toggle('camp__is-active');
           // this.classList.toggle('camp__is-active');
        }
    }

</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.camp-table-cell__add-new .camp-form-group>p {
    text-align: left !important;
    display: block !important;
    font: 15px Montserrat-SemiBold,Arial,sans-serif;
    color: #6f6f6f;
    margin-bottom: 20px
}

.camp-table-cell-types {
    text-align: left;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 15px
}

.camp-table-cell-type {
    width: 50%;

    &__inner {
        white-space: nowrap;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        height: 40px;
        border-radius: 10px;
        border: 1px solid transparent;
        padding: 5px 10px;
        cursor: pointer;

        svg {
            display: block;
            -webkit-box-flex: 0;
            -webkit-flex: none;
            -ms-flex: none;
            flex: none;
            width: 25px;
            fill: none;
            height: 25px;
            margin-right: 10px;
            stroke: #a0a0a0;
            stroke-width: 1.8px;
            stroke-linecap: round;
            stroke-linejoin: round;
            -webkit-transition: stroke .17s ease-in-out;
            -o-transition: stroke .17s ease-in-out;
            transition: stroke .17s ease-in-out
        }
    }

    input {
        display: none !important;
        &:checked + .camp-table-cell-type__inner {
            background-color: #fff;
            border-color: #cacaca;
            -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
            box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
            
            svg {
                stroke: #000
            }
        }
    }
}

</style>
