<template>
  <div class="paymentStatus">

    <picture class="paymentStatus__picture">
      <source type="image/webp"
        :srcset="`/images/payment/${this.$route.name}-2x.webp 2x, /images/payment/${this.$route.name}.webp 1x`">
        <img :src="`/images/payment/${this.$route.name}.webp`" :alt="this.$route.meta.title" class="paymentStatus__image">
    </picture>

    <PaymentStatusContent   
      class="paymentStatus__paymentStatusContent"   
      :status=" this.$route.name === 'paymentSuccess' ? paymentSuccess : paymentFailure"
    />

  </div>
</template>

<script>
import PaymentStatusContent from '@/views/Payment/PaymentStatusContent'

export default {
  components: {
    PaymentStatusContent
  },
  data() {
    return {
      paymentSuccess: {
        title: 'Всё получилось!',
        description: 'Ваша оплата прошла успешно. Ожидайте письмо на почту (проверьте папку “Спам”)',
        type: 'Success'
      },
      paymentFailure: {
        title: 'Оплата не прошла :(',
        description: 'Ваша оплата не прошла. Попробуйте повторить платеж или напишите нам - мы поможем!',
        type: 'Failure'
      }
    }
  },
  mounted() {
    console.log(this.$route.name)
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.paymentStatus {

  max-width: 970px;
  margin: 0 auto;
  position: relative;

  @include breakpoint(xl) {
    max-width: 808px;
  }
  @include breakpoint(md) {
    max-width: 480px;
  }

  &__paymentStatusContent {
    margin-left: 60px;
    flex: 1;
    @include breakpoint(md) {
      flex: none;
      margin-left: 0;
      margin-top: 40px;
    }
  }

  &__picture {
    flex: 0 0 534px;
    padding: 0 62px;
    border-radius: 20px;
    background: radial-gradient(71.07% 99.99% at 71.07% 99.99%, rgba(252, 169, 44, 0.40) 0%, rgba(217, 217, 217, 0.00) 100%), $gray-100;
    @include breakpoint(xl) {
      flex: 0 0 410px;
      padding: 0 31px;
    }
    @include breakpoint(md) {
      flex: 0 0 100%;
      width: 100%;
      text-align: center;
      margin-top: 40px;
    }
    @include breakpoint(xs) {
      margin-top: 20px;
    }
  }

  &__image {
    margin-top: -20px;
    @include breakpoint(xl) {
      margin-top: -30px;
    }
  }

}

</style>