<template>
  <div
    class="storeEdit"
    v-if="show"
  >
    
    <SettingsStoreApi      
      class="storeEdit__storeApi"
      :store="store"
    />

    <SettingsStoreInfo  
      class="storeEdit__storeInfo"    
      :store="store"
    />

  </div>
</template>

<script>
import SettingsStoreApi from '@/views/Account/Settings/SettingsStore/SettingsStoreApi'
import SettingsStoreInfo from '@/views/Account/Settings/SettingsStore/SettingsStoreInfo'

export default {
  components: {
    SettingsStoreApi,
    SettingsStoreInfo
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    store: {
      type: Object,
      require: true
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.storeEdit {

  padding: 20px 20px;

  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    width: calc(100% - 40px);
    height: 2px;
    background-color: $gray-110;
  }

  &__storeApi {
    margin-bottom: 20px;
    @include breakpoint(sm) {
      margin-bottom: 30px;
    }
  }

}

</style>