<template>
  <BasePopup>
    <div class="PBSingleProductBidderRefreshProductListPopup">

      <div
        class="PBSingleProductBidderRefreshProductListPopup__products"
        v-if="productsAdded.length"
      >
        <div class="PBSingleProductBidderRefreshProductListPopup__productsTitlePrimary titlePrimary titlePrimary--small">Новые товары добавлены в список</div>

        <div class="PBSingleProductBidderRefreshProductListPopup__productsList">

          <div
            class="PBSingleProductBidderRefreshProductListPopup__productsCategory"
            v-for="category in productsAdded" :key="category.id"
          >
            <div class="PBSingleProductBidderRefreshProductListPopup__productsCategoryTitle">{{category.title}}</div>
            <div class="PBSingleProductBidderRefreshProductListPopup__productsCategoryList">
              <div
                class="PBSingleProductBidderRefreshProductListPopup__productsCategoryListItem"
                v-for="item in category.list"
              >
                {{item.name}}
              </div>
            </div>
          </div>

        </div>

      </div>

      <div
        class="PBSingleProductBidderRefreshProductListPopup__productsEmpty"
        v-else
      >
        <div class="PBSingleProductBidderRefreshProductListPopup__titlePrimary titlePrimary titlePrimary--small">Новых товаров не обнаружено</div>
      </div>

    </div>
  </BasePopup>
</template>

<script>

export default {
  name: 'PBSingleProductBidderRefreshProductListPopup',
  data() {
    return {
      productsAdded: [
        {id: 1, title: 'Крема', list: [{name: 'Крем для увлажнения'}, {name: 'Крем от псориаза'}, {name: 'Крем от солнца'}]},
        {id: 2, title: 'Масла', list: [{name: 'Масло оливковое'}, {name: 'Масло автомобильное'}, {name: 'Масло конопляное'}]},
        {id: 3, title: 'Кофе', list: [{name: 'Кофе арабский'}, {name: 'Кофе африканский'}, {name: 'Кофе сургутский'}]},
        {id: 3, title: 'Кофе', list: [{name: 'Кофе арабский'}, {name: 'Кофе африканский'}, {name: 'Кофе сургутский'}]},
        {id: 3, title: 'Кофе', list: [{name: 'Кофе арабский'}, {name: 'Кофе африканский'}, {name: 'Кофе сургутский'}]},
      ]
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.PBSingleProductBidderRefreshProductListPopup {

  min-width: 473px;
  width: 100%;
  max-width: 990px;
  @include breakpoint(md) {
    max-width: 530px;
  }
  @include breakpoint(sm) {
    max-width: 322px;
    min-width: auto;
  }
  @include breakpoint(xs) {
    max-width: 280px;
  }

  &__products {
    padding: 50px;
    @include breakpoint(xs) {
      padding-left: 20px;
      padding-right: 20px;
    }
    &TitlePrimary {
      margin-bottom: 30px;
      text-align: center;
    }
    &List {
      display: flex;
      flex-flow: row wrap;
      gap: 30px;
    }
    &Category {
      font-size: 15px;
      line-height: 15px;
      width: 200px;
      @include breakpoint(xl) {
        font-size: 13px;
        line-height: 13px;
      }
      @include breakpoint(md) {
        font-size: 15px;
        line-height: 15px;
      }
      @include breakpoint(xs) {
        font-size: 14px;
        line-height: 14px;
      }
      &Title {
        font-weight: 700;
        margin-bottom: 10px;
      }
      &List {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    &Empty {
      padding: 70px 135px;
      text-align: center;
      @include breakpoint(md) {
        padding: 150px 90px;
      }
      @include breakpoint(sm) {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }

}

</style>