<template>
  <div class="adminSellersListItemBySubCategory">
    <div
      class="adminSellersListItemBySubCategory__cell adminSellersListItemBySubCategory__cell--clientID"
    >
      <div
        class="adminSellersListItemByCategory__cell--title"
        @click="handleDataToParent"
      >
        {{ subCategory.title }}
        <SvgUse
          :width="10"
          :height="10"
          id="arrowUpActive"
          v-if="productIsOpen"
        />
        <SvgUse :width="10" :height="10" id="arrowDownActive" v-else />
      </div>
    </div>
    <div class="adminSellersListItemBySubCategory__cell">
      {{ subCategory.item }}
    </div>
    <div
      class="adminSellersListItemBySubCategory__cell adminSellersContentItem__cell--turnOn"
    >
      {{ subCategory.turnOn }}
    </div>
    <div
      class="adminSellersListItemBySubCategory__cell adminSellersContentItem__cell--turnOff"
    >
      {{ subCategory.turnOff }}
    </div>
    <div class="adminSellersListItemBySubCategory__cell">
      {{ subCategory.min }}
    </div>
    <div class="adminSellersListItemBySubCategory__cell">
      {{ subCategory.max }}
    </div>
    <div class="adminSellersListItemBySubCategory__cell">
      {{ subCategory.totalItem }}
    </div>
    <div class="adminSellersListItemBySubCategory__cell">
      {{ subCategory.average }}
    </div>
    <div class="adminSellersListItemBySubCategory__cell">
      {{ subCategory.order }}
    </div>
    <div class="adminSellersListItemBySubCategory__cell">
      {{ subCategory.totalPrice }}
    </div>
    <div class="adminSellersListItemBySubCategory__cell">
      {{ subCategory.lost }}
    </div>
    <div class="adminSellersListItemBySubCategory__cell">
      {{ subCategory.sell }}
    </div>
    <div class="adminSellersListItemBySubCategory__cell">
      {{ subCategory.pro }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subCategory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      productIsOpen: false,
    };
  },
  methods: {
    handleDataToParent() {
      this.productIsOpen = !this.productIsOpen;
      this.$emit("productState", this.productIsOpen);
    },
  },
};
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";


.adminSellersListItemBySubCategory {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  margin-left: 20px;
  padding: 5px;
  box-shadow: 0 5px 10px rgba($black, 0.07);
  background-color: $white;

  @include mediaMaxWidth(1440) {
    width: 1420px;
  }

  &__cell {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    width: 6.77%;
    min-width: 100px;
    border-right: 2px solid $gray-50;
    padding: 5px 20px;
    text-align: center;
    white-space: nowrap;

    &--title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &--clientID {
      border: none;
      height: 35px;
      display: flex;
      justify-content: start;
      align-items: center;
      min-width: 260px;
      padding: 5px 15px;
      font-size: 15px;
      line-height: 15px;
      font-weight: 500;
      @include mediaMaxWidth(1490) {
        min-width: 220px;
      }
    }
    &--turnOn {
      color: $green-100;
    }
    &--turnOff {
      color: $red;
    }
  }
  &__cell:last-child {
    border: none;
  }
}
</style>
