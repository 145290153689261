<template>
  <div class="paymentStatusContent">
      <img src="/images/logo.svg" alt="YouCamp" class="paymentStatusContent__logo">

      <div class="paymentStatusContent__title">{{ status.title }}</div>

      <p class="paymentStatusContent__text">

        {{ status.description }}

      </p>

      <div
        class="paymentStatusContent__id"
        v-if="status.type === 'Success'"
      >
        Номер платежа: <strong>#1005623</strong>
      </div>

      <div class="paymentStatusContent__actions">

        <template v-if="status.type === 'Success'">
          <BaseButton
            class="paymentStatusContent__button button button--primary"
            @click="$router.push('/account/balance')"
          >
            Назад
          </BaseButton>
        </template>

        <template v-else>
          <BaseButton class="paymentStatusContent__button button button--primary">Повторить</BaseButton>
          <BaseButton
            class="paymentStatusContent__button paymentStatusContent__button--tickets button  button--transparent button--green"
            @click="$router.push('/account/tickets')"
          >
            Написать нам
          </BaseButton>
        </template>

      </div>

    </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.paymentStatusContent {
  
  &__logo {
    width: 60px;
    margin-bottom: 30px;
    @include breakpoint(md) {
      position: absolute;
      top: 70px;
      left: 0;
      width: auto;
    }
    @include breakpoint(xs) {
      width: 50px;
      top: 64px;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 10px;
    @include breakpoint(xl) {
      font-size: 24px;
      line-height: 24px;
    }
    @include breakpoint(md) {
      font-size: 32px;
      line-height: 32px;
    }
    @include breakpoint(xs) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__text, &__id {
    font-weight: 400;
    @include breakpoint(xl) {
      font-size: 14px;
      line-height: 14px;
    }
    @include breakpoint(md) {
      font-size: 16px;
      line-height: 16px;
    }
    @include breakpoint(xs) {
      font-size: 14px;
      line-height: 14px;
    }
  }

  &__id {
    margin-top: 30px;
    strong {
      font-weight: 600;
      margin-left: 10px;
      display: inline;
    }
    @include breakpoint(md) {
      margin-top: 20px;
    }
    
  }

  &__actions {
    margin-top: 30px;
    @include breakpoint(md) {
      margin-top: 40px;
    }
    @include breakpoint(xs) {
      margin-top: 20px;
    }
  }

  &__button {
    height: 44px;
    border-radius: 5px;
    padding: 0 30px;
    display: inline-block;
    width: auto;
    &--tickets {
      margin-left: 10px;
      @include breakpoint(sm) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
    @include breakpoint(xl) {
      height: 33px;
    }
    @include breakpoint(md) {
      height: 44px;
    }
    @include breakpoint(sm) {
      width: 100%;
    }
    @include breakpoint(xs) {
      height: 33px;
    }
  }

}

</style>