window.YC = {

  isAuthRoute() {
    return window.location.pathname.indexOf('/auth/') !== -1
  },

  isMobile() {
    return window.innerWidth < 1200
  }

}

import '@/scss/index.scss'

import tippy from 'tippy.js'
YC.tippy = tippy
YC.tippy.setDefaultProps({
  animation: 'fade',
  trigger: 'mouseenter',
  arrow: false,
  maxWidth: 377,
  placement: 'right',
  offset: [0, -15],
})
YC.tippyMount = () => {
  YC.tippy('.tooltip')
}

import axios from 'axios'
import mitt from 'mitt'
import IMask from "imask"
import router from '@/router/router'
import store from '@/store'
import App from '@/App'
import popupComponents from '@/components/Popup'
import components from '@/components/Global'
import vClickOutside from '@/directives/v-click-outside'

window.axios = axios
//api setup
import { apiInitialisation } from '@/api/'
apiInitialisation(YC)

YC.EventBus = mitt()

YC.router = router
YC.store = store

YC.validations = {

  isStringNotEmpty: (string) => {
    if (string === null) return false;
    return String(string).trim().length >= 1;
  },
  isPhoneValid: (phone) => {
    let cleanPhone = phone.replace(/\D+/g, '');
    let isPhoneValid = /^\+?[1-9][0-9]{9,9}$/.test(cleanPhone);
    if (!isPhoneValid) {
        return false;
    }
    return true;
  },
  isEmailValid: (email) => {
    let isEmailValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    if (!isEmailValid) {
        return false;
    }
    return true;
  },
  isStringLengthBiggerThan: (string, min) => {
    if (string === null) return false;
    return string.length > parseInt(min);
  },

}

import { createApp } from 'vue'
YC.app = createApp(App)
  .use(YC.router)
  .use(YC.store)

// Popup components reg
popupComponents.forEach(component => {
  if(Object.prototype.toString.call(component) === '[object Array]') {
    component.forEach(component => {
      YC.app.component(component.name, component)
    })
  } else YC.app.component(component.name, component)
})
//basic component installation
components.forEach(component => {
  YC.app.component(component.name, component)
})

YC.app.directive('click-outside', vClickOutside)

YC.app.directive('focus', {
    mounted(el) {
        el.focus();
    }
})

YC.app.config.globalProperties.$validations = YC.validations

YC.app.mount('#app')
