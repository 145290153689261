<template>
    <div class="camp-table-cell-title__edit">
        <input
            type="text"
            v-model="inputValue"
            @change="handleInput"
            class="camp-tooltip camp-input-text"
            :data-tippy-content="inputValue"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "EditColumn",
    data() {
        return {
            inputValue: this.value
        }
    },
    props: {
        value: {
            type: String,
        },
        field:null,
    },
    methods: {
        ...mapActions({
            editColumn: 'crm/editColumn',
        }),
        handleInput(e) {
            this.editColumn({
                obj: {
                    id: Number(this.field.replace('extra_', '')),
                    action: 'editColumn',
                    name: e.target.value,
                    field: this.field,
                    edit: true,
                }
            });
        }
    },
    computed: {
        ...mapGetters({
            getUiAction: 'crm/getUiAction',
        }),
    },
    watch: {
        getUiAction(uiact) {

            if(uiact && uiact.action && uiact.action === 'editColumn') {
                this.inputValue = this.value
            }
        }
    }
}
</script>

<style>
</style>