<template>
  <div class="sortingFilterDropDown" @click.stop v-click-outside="close">
    <span>{{label}}</span>
    <div class="sortingFilterDropDown__buttons">
      <button
        class="sortingFilterDropDown__button"
        @click="dropDownIsOpen = !dropDownIsOpen"
      >
        <SvgUse    
          :width="20"     
          :height="20" 
          id="arrowDownInActive"
          v-if="!dropDownIsOpen"
        />
        <SvgUse    
          :width="20"     
          :height="20" 
          id="arrowDownActive"
          v-else
        />
      </button>
    </div>
    <div
      class="sortingFilterDropDown__opt"
      v-if="dropDownIsOpen"
      ref="opt"
    >
      <div
        :class="['sortingFilterDropDown__optItem', 
        {'sortingFilterDropDown__optItem--active': option.value === defaultLimit}]"
        v-for="option in dropDownOptions" :key="option.label"
        @click="selectedOption(option.value)"
        
      >
        {{option.label}}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaseFilterDropDown',
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Number,
      require: true
    }
  },
  data() {
    return {
      dropDownOptions: [
        {label: '10', value: 10},
        {label: '20', value: 20},
        {label: '50', value: 50},
        {label: 'Все', value: ''},
      ],
      dropDownIsOpen: false,
      defaultLimit: 10
    }
  },
  methods: {
    selectedOption(option) {
      this.defaultLimit = option
      this.dropDownIsOpen = false
      this.$emit('update:modelValue', option)
    },
    close() {
      this.dropDownIsOpen = false
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.sortingFilterDropDown {
  position: relative;
  font-size: 15px;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    flex: 1;
    @include mediaMaxWidth(375) {
      margin-right: 0;
    }
  }

  @include breakpoint(xl) {
    font-size: 13px;
    line-height: 13px;
  }
  @include breakpoint(md) {
    font-size: 15px;
    line-height: 15px;
  }
  @include breakpoint(sm) {
    &:first-child {
      flex: 0 0 103px;
    }
  }
  @include mediaMaxWidth(375) {
    flex-direction: column;
    &:first-child {
      flex: 0 0 134px;
    }
  }

  &__buttons {
    @include mediaMaxWidth(375) {
      margin-top: 10px;
    }
  }

  &__button {
    display: flex;
  }

  &__opt {
    position: absolute;
    top: 30px;
    left: 48%;
    transform: translateX(-50%);
    padding: 21px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $gray-300;
    display: flex;
    z-index: 9999;
    background-color: $white;
    @include breakpoint(xl) {
      top: 25px;
    }
    @include breakpoint(md) {
      top: 20;
    }
    @include breakpoint(sm) {
      flex-direction: column;
      top: 30px;
      left: auto;
      right: -26px;
      transform: none;
    }
    @include mediaMaxWidth(375) {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      top: 50px;
    }
    &Item {
      cursor: pointer;
      font-size: 15px;
      line-height: 15px;
      margin: 0 10px;
      @include breakpoint(sm) {
        margin: 10px 0;
        text-align: center;
      }
      &:first-child {
        margin-left: 0;
        @include breakpoint(sm) {
          margin-top: 0;
          margin-right: 0;
        }
      }
      &:last-child {
        margin-right: 0;
        @include breakpoint(sm) {
          margin-bottom: 0;
        }
      }
      &--active {
        color: $black;
      }
    }
  }

}
</style>