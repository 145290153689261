<template>
  <div
    class="favoriteIcon"
    :class="{'favoriteIcon--active' : isFavorite}"
  >
  </div>
</template>

<script>

export default {
  name: 'BaseFavoriteIcon',
  props: {
    isFavorite: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";

.favoriteIcon {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='26' viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_i_4824_1101)'%3E%3Cpath d='M11.0734 4.89057C11.5224 3.50861 13.4776 3.50861 13.9266 4.89058L14.9697 8.10081C15.1705 8.71885 15.7464 9.13729 16.3962 9.13729H19.7717C21.2248 9.13729 21.8289 10.9967 20.6534 11.8508L17.9226 13.8348C17.3968 14.2168 17.1769 14.8939 17.3777 15.5119L18.4207 18.7221C18.8698 20.1041 17.288 21.2533 16.1125 20.3992L13.3817 18.4152C12.8559 18.0332 12.1441 18.0332 11.6183 18.4152L8.88753 20.3992C7.71196 21.2533 6.13024 20.1041 6.57927 18.7221L7.62234 15.5119C7.82315 14.8939 7.60316 14.2168 7.07743 13.8348L4.34664 11.8508C3.17107 10.9967 3.77523 9.13729 5.22832 9.13729H8.60376C9.2536 9.13729 9.82953 8.71885 10.0303 8.10081L11.0734 4.89057Z' fill='%23DEDEDE'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_i_4824_1101' x='3.72534' y='3.85413' width='17.5493' height='17.8383' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='0.5'/%3E%3CfeComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='shape' result='effect1_innerShadow_4824_1101'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &--active {
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='26' viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_i_4824_1101)'%3E%3Cpath d='M11.0734 4.89057C11.5224 3.50861 13.4776 3.50861 13.9266 4.89058L14.9697 8.10081C15.1705 8.71885 15.7464 9.13729 16.3962 9.13729H19.7717C21.2248 9.13729 21.8289 10.9967 20.6534 11.8508L17.9226 13.8348C17.3968 14.2168 17.1769 14.8939 17.3777 15.5119L18.4207 18.7221C18.8698 20.1041 17.288 21.2533 16.1125 20.3992L13.3817 18.4152C12.8559 18.0332 12.1441 18.0332 11.6183 18.4152L8.88753 20.3992C7.71196 21.2533 6.13024 20.1041 6.57927 18.7221L7.62234 15.5119C7.82315 14.8939 7.60316 14.2168 7.07743 13.8348L4.34664 11.8508C3.17107 10.9967 3.77523 9.13729 5.22832 9.13729H8.60376C9.2536 9.13729 9.82953 8.71885 10.0303 8.10081L11.0734 4.89057Z' fill='%23FFD362'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_i_4824_1101' x='3.72534' y='3.85413' width='17.5493' height='17.8383' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='0.5'/%3E%3CfeComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='shape' result='effect1_innerShadow_4824_1101'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}

</style>