<template>
  <BasePopup :classModifier="'tickets-chat'">
    <BaseButton />
    <div
      class="ticketThanksPopup ticketThanksPopup_type_lowGrade"
      v-if="rating.value < 4"
    >
      <div class="ticketThanksPopup__titlePopup titlePopup">Спасибо за ваш отзыв!</div>
    </div>
    <div
      class="ticketThanksPopup ticketThanksPopup_type_highGrade"
      v-else
    >
      <picture class="ticketThanksPopup__ratingItemPicture">
        <source
          type="image/webp"
          :srcset="`${rating.iconSrc}-2x.webp 2x, ${rating.iconSrc}.webp 1x`"
        >
        <img
          :src="rating.iconSrc"
          :alt="rating.name"
        >
      </picture>
      <div class="ticketThanksPopup__titlePopup titlePopup">Спасибо за ваш отзыв!</div>
    </div>
  </BasePopup>
</template>

<script>

export default {
  name: 'TicketsThanksPopup',
  props: {
    rating: {
      type: String,
      required: true
    }
  }

}

</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.ticketThanksPopup {
  width: 100%;

  &_type_lowGrade {
    height: 160px;
    max-width: 600px;
    padding: 70px 180px;

    @include breakpoint(xl) {
      padding: 71px 192px;
    }

    @include breakpoint(md) {
      padding: 70px 180px;
    }

    @include breakpoint(sm) {
      max-width: 380px;
      padding: 70px;
    }

    @include breakpoint(xs) {
      max-width: 310px;
      padding: 70px 35px;
    }
  }

  &_type_highGrade {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 380px;
    height: 250px;
    padding: 55px 70px;

    @include breakpoint(xl) {
      padding: 56px 82px;
    }

    @include breakpoint(md) {
      padding: 55px 70px;
    }

    @include breakpoint(xs) {
      max-width: 310px;
      padding: 55px 35px;
    }
  }

  &__ratingItemPicture {
    margin-bottom: 20px;
  }

  &__titlePopup {
    font-family: $font-primary;
    font-weight: 500;
    margin: 0;

    @include breakpoint(xl) {
      font-size: 18px;
      line-height: 18px;
    }

    @include breakpoint(md) {
      font-size: 20px;
      line-height: 20px;
    }
  }
}
</style>