export default {

  namespaced: true,

  state: () => ({
    token: false,
    login: '',
    password: '',
    user: false
  }),

  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setLoginAndPassword(state, {login, password}) {
      state.login = login
      state.password = password
    },
    setUser(state, user) {
      state.user = user;
    }
  },

  getters: {
    hasToken(state) {
      return state.token !== false
    },
    getToken(state, getters) {
      if(getters.hasToken) return state.token
      if(localStorage.getItem('token')) return localStorage.getItem('token')
      else return false
    }
  },

  actions: {

    login({commit, state}) {
      return new Promise((resolve, reject) => {
        YC.authApi.postLogin(state.login, state.password)
          .then(response => {
            commit('setToken', response.data.authorization.token)
            localStorage.setItem('token', response.data.authorization.token)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    registration(store, payload) {
      return new Promise((resolve, reject) => {
        YC.authApi.postRegistration(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    logout({commit}) {
      commit('setToken', false);
      localStorage.removeItem('token')
      document.location.reload()
    },

    sendCode(store, payload) { 
      return new Promise((resolve, reject) => {
        YC.authApi.postSendCode(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    confirmCode(store, payload) { 
      return new Promise((resolve, reject) => {
        YC.authApi.postConfirmCode(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    getUser({commit}) {
      return new Promise((resolve => {
        YC.authApi.getUser()
          .then(user => {
            commit('setUser', user.data.user)
            resolve()
          })
      }))
    },

    restorePasswordRequest(store, payload) { 
      return new Promise((resolve, reject) => {
        YC.authApi.postRestorePasswordRequest(payload)
          .then(response => {
            resolve()
          })
          .catch(response => {
            reject(response)
          })
      })
    },

    restorePasswordCheckCode(store, payload) { 
      return new Promise((resolve, reject) => {
        YC.authApi.postRestorePasswordCheckCode(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    restorePassword(store, payload) { 
      return new Promise((resolve, reject) => {
        YC.authApi.postRestorePassword(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

  }

}