<template>

    <div class="camp-table-footer">

        <div class="camp-table-row camp-row camp-row-nowrap">
            <div class="camp-table-cell camp-table-cell__name camp-table-cell-sticky camp-table-cell-rounded" style="width: 350px;">
                <div class="camp-table-stub"></div>

            </div>
            <!-- //tcell -->

            <div class="camp-table-cell camp-table-cell__status" style="width: 210px;">
                <div class="camp-table-cell-inner">
                    <div class="camp-statuses-total">
                        <span class="camp-tooltip" data-tippy-content="Новый 15%" style="width: 15%; background-color: #3db1ff"></span>
                        <span class="camp-tooltip" data-tippy-content="В работе 35%" style="width: 30%; background-color: #5c5c5c"></span>
                        <span class="camp-tooltip" data-tippy-content="Подтвержден 65%" style="width: 65%; background-color: #23ea00"></span>
                    </div>
                </div>
            </div>
            <!-- //tcell -->

            <div class="camp-table-cell camp-table-cell__link" style="width: 330px;"></div>
            <!-- //tcell -->

            <div class="camp-table-cell camp-table-cell__price" style="width: 140px;">
                <div class="camp-table-cell-inner">
                    <div class="camp-table-cell-title camp-table-cell-title__price">
                        <p>22 255 650 <span>Сумма</span></p>
                    </div>
                </div>
            </div>
            <!-- //tcell -->

            <div class="camp-table-cell camp-table-cell__date" style="width: 210px;">
                <div class="camp-table-cell-inner">
                    <div class="camp-status camp-status-date" style="background-color: #0073EA;">
                        <span>17-31 августа</span>
                    </div>
                </div>
            </div>
            <!-- //tcell -->

            <div class="camp-table-cell camp-table-cell__status" style="width: 260px;">
                <div class="camp-table-cell-inner">
                    <div class="camp-statuses-total">
                        <span class="camp-tooltip" data-tippy-content="Новый 15%" style="width: 15%; background-color: #3db1ff"></span>
                        <span class="camp-tooltip" data-tippy-content="В работе 35%" style="width: 30%; background-color: #5c5c5c"></span>
                        <span class="camp-tooltip" data-tippy-content="Подтвержден 65%" style="width: 65%; background-color: #23ea00"></span>
                    </div>
                </div>
            </div>
            <!-- //tcell -->

            <div class="camp-table-cell camp-table-cell__add-new" style="width: 66px;"></div>
            <!-- //tcell -->
        </div>
        <!-- //trow -->



    </div>
    <!-- //tfooter -->


</template>

<script>
    export default {
        name: "TableFooter"
    }
</script>

<style scoped>

</style>
