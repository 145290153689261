<template>

    <div class="camp-table-row camp-table-row__add camp-row camp-row-nowrap">
        <div class="camp-table-cell camp-table-cell__name camp-table-cell-sticky" style="width: 520px; left: 90px">

            <div class="camp-table-left-indicator" style="background-color: #0097EC;"></div>

            <div class="camp-table-stub"></div>


            <div class="camp-table-cell-checkbox">
                <label class="camp-form-checkbox__label">
                    <input type="checkbox" name="">
                    <span v-show="false" class="camp-form-checkbox" aria-label="Отметить"><span class="camp-form-checkbox__ic"></span></span>
                </label>
            </div>

            <div class="camp-table-cell-inner">

                <p @click="addRow">+ Добавить элемент</p>

            </div>
        </div>
        <!-- //tcell -->
        <template  v-for="n in 15" :key="n">
        <div class="camp-table-cell" style="width: 120px;"></div>
        <div class="camp-table-cell" style="width: 120px;"></div>
        </template>

    </div>
    <!-- //trow -->

</template>

<script>
import {mapState} from "vuex";

export default {
    name: "SubgroupAddRow",
    props:{
        groupIndex:Number,
    },
    computed: {
        ...mapState({
            subGroups: state => state.crm.subgroups,
        }),
    },
    methods:{
        addRow(){
            if (!this.subGroups[this.groupIndex].items) {
                this.subGroups[this.groupIndex] = {
                    "name": "",
                    "show": false,
                    "items": [
                        {"key": "", "data": []}
                    ]
                };
            }
            else {
                this.subGroups[this.groupIndex].items.push({"key": "", "data": []})
            }
            this.$store.commit('crm/setSubgroups', this.subGroups);

        },
    },

}
</script>

<style scoped lang="scss">

</style>
