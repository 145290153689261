import baseApi from '@/api/baseApi'

export default class userTicketsApi extends baseApi {
  
  /*
  * Тикеты - клиент
  */

  getUserTicketList() {
    return this.get("tickets/my-tickets")
  }

  getUserTicketInfo(id) {
    return this.get('tickets/' + id)
  }

  postNewTicket(payload) {
    return this.post('tickets/ticket/new', payload)
  }

  postNewMessage(payload) {
    const { id, message, status } = payload
    return this.post('tickets/message/' + id, { message, status })
  }

  postTicketRating(id, payload) {
    return this.post(`tickets/${id}/rate`, payload)
  }

}