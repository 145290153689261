<template>
  <BasePopup
  >
  <div class="PBBasicProductsMultipleEditingAlertPopup">
    <p class="PBBasicProductsMultipleEditingAlertPopup__text">Данные успешно применены</p>
  </div>
  </BasePopup>
</template>


<script>

export default {
  name: 'PBBasicProductsMultipleEditingAlertPopup',
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins';
@import 'src/scss/base/project/var';

.PBBasicProductsMultipleEditingAlertPopup {

  max-width: 100%;
  width: 500px;
  padding: 40px 0;

  &__text {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }

}
</style>