<template>


        <TableAdd v-if="showGroup"
            :tableCellsParams="tableCellsParams"
        />

        <Table v-for="(tableData, index) in this.tablesData"
               :key="`table_${tableData.id}_${getEditGroupIndex(tableData.id)}`"
               :tableCellsParams="getTableCellsParams(tableData.id)"
               :key_field="key_field"
               :tableData="tableData[tableDataFieldName]"
               :groupName="tableData.name"
               :groupID="tableData.id"
               :itemIndex="index"
               :color="getCurrentColor(tableData.id)"
               :expanded="tableData.expanded"
        />

</template>

<script>

    import Table from "../../UI/Table";
    import TableAdd from "../../UI/TableAdd";
    import {mapActions, mapGetters, mapState} from 'vuex'

    import { getExtraType, getGroupById } from '@/components/Crm/helpers/dataHelpers';

    export default {
        name: "Content",
        components: {TableAdd, Table},
        data() {
            return {
                editedGroup:0,
                editedGroupId:Array,
                is_addgroup:false,
            };
        },
        props: {
            tableCellsParams: {
                campMainLevel:null,
                required: true,
            },
            customCellParams:[],
            tableDataFieldName: {
                type: String,
                default: 'ad_items' // По умолчанию используется поле 'ad_items'
            },
            key_field:String,
            tableNode:null,
            tablesData:[],
        },
        methods: {
            ...mapActions({
                editGroup: 'crm/editGroup',
            }),
            // Проверяем не была ли изменена эта группа(таблица)
            isEdited(checkGroupId) {
                const min=1000;
                const max=9999;
                if(this.editedGroup===checkGroupId)
                {
                    //Возвращаем новый случайный ключ для перегенерации таблицы передав новый :key в нее
                    return Math.random() * (max - min) + min;
                }
                else {
                    return 0;
                }
            },
            /*
            * Изменение данных строки по
            */
            editRow(uiact) {

                console.log("edti tablesData",this.tablesData);
                this.tablesData.forEach((tableData, index) => {
                    tableData[this.tableDataFieldName].forEach((row, rowIndex) => {
                        if (row.id === uiact.item_id && row.group_id === uiact.group_id) {
                            console.log("editRowItem", row,uiact);
                            row[uiact.key_field]=uiact.val;


                            this.deskData.data.desk_groups=this.tablesData
                            this.$store.commit('crm/setDeskData', this.deskData );
                        }
                    });
                });

            },
            addTable(uiact){


                if(uiact && uiact?.r?.data?.group) {
                    console.log("AddTable=",uiact?.r?.data?.group);
                    let newGroupdata=[];
                    const newgroup={
                        ...uiact?.r?.data?.group,
                        expanded:true,
                        items:[],
                    };
                    if (!this.tablesData || this.tablesData.length === 0) {

                        this.tablesData.push(newgroup);
                        newGroupdata={
                            data:{
                                desk_groups:[newgroup],
                            }
                        }
                    } else {
                        this.tablesData.unshift(newgroup);

                        if(this.deskData?.data?.desk_groups) {

                            //console.log("updateGroupData", newGroupdata)

                            this.deskData.data.desk_groups.unshift(newgroup);
                            newGroupdata =this.deskData
                        }
                    }

                    this.$store.commit('crm/setDeskData', newGroupdata );


                }

            },
            delTable(uiact){


                if(uiact && uiact?.group_id) {

                    this.tablesData.forEach((tableData, index) => {
                        if(tableData.id===uiact?.group_id)
                        {
                            console.log("delTable=",uiact?.group_id);
                            this.tablesData.splice(index, 1);
                        }
                    });
                    this.deskData.data.desk_groups=this.tablesData
                    this.$store.commit('crm/setDeskData', this.deskData );
                }

            },
            addColl(uiact){

                if(uiact && uiact.name && uiact?.response?.data?.extra?.id) {
                    const newid=uiact?.response?.data?.extra?.id
                    let type = uiact?.response?.data?.extra?.type
                    const newColl = {width: 200, type: getExtraType(type), title:uiact.name, field:"extra_"+newid,is_extra:true};
                    this.tableCellsParams.splice(this.tableCellsParams.length - 1, 0, newColl);
                }

            },
            delColl(uiact){

                if(uiact && uiact.field) {
                    //const newColl = {width: 200, type: "name", title:uiact.name};
                    this.tableCellsParams.forEach((tableCell, index) => {

                        console.log("tcelldel", tableCell);
                        if(tableCell.field===uiact.field)
                        {
                            //console.log("tcell", );

                            this.tableCellsParams.splice(index, 1);
                        }
                    });
                    //this.tableCellsParams.splice(this.tableCellsParams.length - 1, 0, newColl);
                }

            },
            editColl(uiact){
                if(uiact && uiact.field) {
                    this.tableCellsParams.forEach((tableCell, index) => {
                        if(tableCell.field===uiact.field)
                        {
                            console.log("this====>", uiact.field)
                            tableCell.title = uiact.name;
                        }
                    });
                }
            },
            addRow(uiact) {

                const targetGroup=getGroupById(this.tablesData, uiact.group_id);
                //targetGroup[this.tableDataFieldName];
                //console.log("add Row",  uiact);
                if(uiact?.api_data?.data?.item) {
                    let fieldVal = uiact.api_data.data.item;
                    console.log("addRow",uiact,  fieldVal);
                    targetGroup[this.tableDataFieldName].push(fieldVal);

                    this.deskData.data.desk_groups=this.tablesData
                    this.$store.commit('crm/setDeskData', this.deskData );
                }


            },
            /*
            * Удаление строки
            */
            deleteRow(uiact)  {
                this.tablesData.forEach((tableData, index) => {
                    tableData[this.tableDataFieldName].forEach((row, rowIndex) => {
                        if (row.id === uiact.item_id && row.group_id === uiact.group_id) { // Проверяем нужный ID
                            console.log("deleteItem", row);
                            // Удалить элемент из массива tableData[this.tableDataFieldName]
                            this.tablesData[index][this.tableDataFieldName].splice(rowIndex, 1);


                            this.deskData.data.desk_groups=this.tablesData
                            this.$store.commit('crm/setDeskData', this.deskData );
                        }
                    });
                });
               // console.log("after Del tablesData",this.tablesData);
            },
            deleteMultiple(item_list){
                item_list.forEach((item_id) => {
                    this.tablesData.forEach((tableData, index) => {
                        tableData[this.tableDataFieldName].forEach((row, rowIndex) => {
                            if (row.id === item_id) {
                                console.log("deleteItem", row);
                                // Удалить элемент из массива tableData[this.tableDataFieldName]
                                this.tablesData[index][this.tableDataFieldName].splice(rowIndex, 1);
                            }
                        });
                    });
                });
            },
            renameGroup(uiact) {
                const targetGroup=getGroupById(this.tablesData,uiact.group_id);
                if(targetGroup)
                {
                    targetGroup.name=uiact.name;
                }
            },
            getCurrentColor(group_id){
                const targetGroup=getGroupById(this.tablesData,group_id);
                if(targetGroup) {
                    return targetGroup.color;

                }
                else {
                    return '#5698F7';
                }
            },
            setGroupColor(uiact) {
                const targetGroup=getGroupById(this.tablesData,uiact.group_id);
                if(targetGroup)
                {
                    targetGroup.color=uiact.color;
                    //console.log("ser Group color",targetGroup.color);
                    this.editedGroup=uiact.group_id;

                    this.editGroup({
                        obj: {
                            group_id: uiact.group_id,
                            color: uiact.color,
                            action: 'edit_group',
                        }
                    });
                    // if(this.tablesData ) {
                    //     this.tablesData.forEach((tableData, index) => {
                    //         console.log("cTd color",tableData);
                    //     });
                    // }

                }
            },
            fillExtra()
            {
                const extra=this.getExtraCols;
                if(this.tablesData && extra) {
                    this.tablesData.forEach((tableData, index) => {
                        tableData[this.tableDataFieldName].forEach((row, rowIndex) => {
                            //console.log("tdata", row);
                            let ecols = [];
                            if (extra && extra.extracols) {

                                //Проходим по массиву колонок
                                extra.extracols.forEach((cols, index) => {
                                    let val='';
                                    //if(extra.extravals && extra.extravals[row.group_id] && extra.extravals[row.group_id][cols.id]  && extra.extravals[row.group_id][cols.id][row.id]   )

                                    if(extra.extravals && extra.extravals[row.group_id]  && extra.extravals[row.group_id][cols.id] && extra.extravals[row.group_id][cols.id][row.id] )
                                    {
                                        val=extra.extravals[row.group_id][cols.id][row.id];
                                    }
                                    row["extra_" + cols.id] = val;


                                });


                            }


                        });
                    });

                }
            },
            dynamicTablesData(){
                //console.log("dynamicTablesData",this.tablesData);

                this.fillExtra();

                return this.tablesData;
            },
            getTableCellsParams(id) {
                console.log("Get Id",id)
                const newTabCellParams=this.tableCellsParams;
                if (this.customCellParams && this.customCellParams[id]) {
                     // Копируем tableCellsParams, заменяя элементы, если они есть в customCellParams
                     const customParamRow=this.customCellParams[id]

                     return newTabCellParams.map((cell, index) => {
                        // Проверяем, есть ли элемент с тем же индексом в customCellParams
                        if (customParamRow[index] !== undefined && customParamRow[index] !== null) {
                         // Возвращаем элемент из customCellParams, если он определен и не undefined
                            cell.title= customParamRow[index]+""+id;
                        }
                        // В противном случае возвращаем оригинальный элемент из tableCellsParams
                        return cell;
                     });
                }

                return newTabCellParams;
            },
            getIsExpanded(index){
              return !!this.deskData?.data?.desk_groups[index]?.expanded;
            },
        },
        computed:{
            ...mapState({
                deskData: state => state.crm.deskData
            }),
            ...mapGetters({
                getMinLayoutWidth: 'crm/getMinLayoutWidth',
                getUiAction: 'crm/getUiAction',
                getExtraCols: 'crm/getExtraCols',
                getTableParams: 'crm/getTableParams',

            }),
            showGroup(){

                console.log("this.is_addgroup",this.is_addgroup);
                return this.is_addgroup;
            },
            editGroupIndex() {
                // Вычисляем индекс для использования в ключе таблицы
                return this.editedGroup !== null ? this.editedGroup : 'default';
            },

            getEditGroupIndex() {
                return (tableId) => {
                    // Вычисляем индекс для использования в ключе таблицы
                    return this.editedGroup === tableId ? this.editedGroup : 'default';
                };
            },
            getGroupname()
            {
                return 'Группа';
            },

            getTablesData(){
                console.log("Content Tables:",this.tablesData,this.key_field);
                return this.tablesData;
            },

            /*
            * Необходимо обновить данные с сервера
             */
             needGetApiData() {
                 this.$emit('need-update', eventData);
             }
        },
        watch: {
            getUiAction(uiact) {

                if(uiact && uiact.action)
                {
                    if(uiact.action==='delete' &&  uiact.group_id && uiact.item_id) {
                        console.log("ui DELETE act", uiact.group_id, uiact.item_id);
                        this.deleteRow(uiact);
                    }
                    else if(uiact.action==='multidelete'  && uiact.item_list ) {
                        console.log("delete items", uiact.item_list);
                        this.deleteMultiple(uiact.item_list);
                    }
                    else if(uiact.action==='edit_group' &&  uiact.group_id && uiact.name) {
                        console.log("Rename Group act", uiact.group_id, uiact.name);
                        this.renameGroup(uiact);
                    }
                    // Этот action - отображает форму добавления новой группы
                    else if(uiact.action==='add_group') {
                        console.log("Add Group action");
                        this.is_addgroup=true;
                    }
                    else if(uiact.action==='change_color') {
                        console.log("change_color",uiact);
                        this.setGroupColor(uiact);
                    }
                    else if(uiact.action==='create_group') {
                        console.log("Create Group action",uiact);
                        this.addTable(uiact);

                    }
                    else if(uiact.action==='delete_group') {
                        console.log("Create Group action",uiact);
                        this.delTable(uiact);

                    }
                    else if(uiact.action==='edit') {
                        console.log("Edit Item action",uiact);
                        this.editRow(uiact);
                    }
                    else if(uiact.action==='add') {
                        console.log("Add Item action");
                        //this.needGetApiData();
                        this.addRow(uiact);
                    }
                    else if(uiact.action==='addcollumn') {
                        console.log("Add Column action");
                        this.addColl(uiact);
                    }
                    else if(uiact.action==='delcollumn') {
                        console.log("del Column action");
                        this.delColl(uiact);
                    }
                    else if(uiact.action === 'editColumn') {
                        this.editColl(uiact);
                    }
                    else{
                        console.log("Other action",uiact);
                        //editRow(uiact)
                    }
                }
            },
        },
        mounted() {
           // console.log("Content Tables:",this.tablesData,this.key_field);
            this.fillExtra();
        }
    }
</script>

<style scoped>
    .body {
        color: #1a1a1a !important;
        font: 16px Montserrat-Medium, Arial, sans-serif !important;
    }
</style>
