<template>
  <div class="youcamp">
    <div class="youcamp__layout">
      <router-view />

      <component
        v-for = "item in modalQueue"
        :key="item"
        :is = "item.component"
        v-bind = "item.props"
      />

    </div>
  </div>
</template>

<script>

import { mapState , mapGetters } from 'vuex'



export default {
  components: {
    
  },
  computed: {
    ...mapGetters({
      checkModalQueue: 'popup/checkModalQueue'
    }),
    ...mapState({
      modalQueue: state => state.popup.modalQueue,
    }),
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.youcamp {

  &__layout {
    width: 100%;
    &--popup {
      position: relative;
      z-index: -1;
    }
  }

  &__admin {

  }

}

</style>
