<template>
  <BaseInstructionPopup>
    <div class="finalStepInstructionPopup">
      <div class="finalStepInstructionPopup__titlePopup titlePopup">
        Поздравляем! 🥳
      </div>
      <p class="finalStepInstructionPopup__text">Вы только что интегрировали кабинет CAMP со своим кабинетом на маркетплейсе! </p>
      <p class="finalStepInstructionPopup__text">Если у вас два и более магазинов - <span>можете добавлять их по аналогичному методу</span></p>
      
      <BaseButton        
        class="finalStepInstructionPopup__button button button--primary"
        @click="finalStepHandler"
      >
        Ура!
      </BaseButton>
    </div>
  </BaseInstructionPopup>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'FinalStepInstructionPopup',
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
      setInstructed: 'settings/setInstructed',
      getUser: 'auth/getUser',
    }),
    finalStepHandler() {
      this.closePopup()
      this.setInstructed({integration: true})
      .then(() => {
        this.getUser()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.finalStepInstructionPopup {
  position: relative;
  padding: 20px;
  width: 500px;
  max-width: 100%;
  @include breakpoint(xl) {
    width: 400px;
  }
  @include breakpoint(md) {
    width: 500px;
  }
  @include breakpoint(sm) {
    width: 360px;
  }
  @include breakpoint(xs) {
    width: 300px;
  }
  &__titlePopup {
    text-align: left;
    margin-bottom: 20px;
    @include breakpoint(md) {
      margin-bottom: 10px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    @include breakpoint(xs) {
      font-size: 13px;
      line-height: 13px;
    }
    &:last-of-type {
      margin-bottom: 20px;
      @include breakpoint(md) {
        margin-bottom: 10px;
      }
    }
    span {
      font-weight: 600;
      color: $dark-green-400;
    }
  }

  &__button {
    max-width: 71px;
    height: 34px;
    border-radius: 10px;
  }
}

</style>