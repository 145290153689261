<template>

    <div class="camp-table-subgroup camp__is-active"
         v-if="isVisible"
    >
        <div class="camp-table-subgroup-inner camp-row camp-row-nowrap">

            <div class="camp-table-subgroup-line">
                <div class="camp-table-stub"></div>
                <div class="camp-table-subgroup-connector"></div>
            </div>

            <!-- Full table -->
            <div class="camp-table__wrapper-inner camp-table-full camp-table-static camp-table-seo" style="display: block;">

                <!-- Table -->
                <div class="camp-table">

                    <div class="camp-table-inner"

                    >

                        <SubgroupHeaderRow/>

                        <div class="camp-table-body">

                            <SubgroupRow
                                v-for="(rowitem, index) in getSubgroupItems(groupIndex)"
                                :key="rowitem.id || index"
                                :row_id="groupIndex"
                                :item_id="index"
                                :rowData="rowitem"
                            />

                            <SubgroupAddRow
                                :groupIndex="groupIndex"
                            />

                        </div>
                        <!-- //tbody -->

                    </div>
                </div>
                <!-- //table -->

            </div>
            <!-- //Full table -->
        </div>
    </div>

</template>
<script>
import SubgroupHeaderRow from "@/components/Crm/Desks/Custom/SubgroupHeaderRow.vue";
import SubgroupAddRow from "@/components/Crm/Desks/Custom/SubgroupAddRow.vue";
import SubgroupRow from "@/components/Crm/Desks/Custom/SubgroupRow.vue";
import {mapState, mapActions, mapGetters} from "vuex";
import Table from "@/components/Crm/UI/Table.vue";

export default {
    name: "Subgroup",
    components: {Table, SubgroupRow, SubgroupAddRow, SubgroupHeaderRow},
    data(){
      return{
        tempSubgroup:[],
      }
    },
    props:{
        groupIndex:Number,
    },
    computed:{
        ...mapState({
            subGroup:state => state.crm.subgroups,
        }),
        ...mapGetters({
            getSubgroupsData :'crm/getSubgroupsData',
        }),
        isVisible() {
            return this.subGroup[this.groupIndex]?.show ?? false
        }

    },
    methods: {
        ...mapActions({
            editGroup: 'crm/editGroup',
        }),
        //Элементы подгруппы
        getSubgroupItems(index)
        {

            return this.tempSubgroup[index]?.items ?? null;
        }
    },
    watch:{

        getSubgroupsData: {
            handler(newVal, oldVal) {
                //this.tablesData=deskData?.desk_groups ?? []
                //console.log("subGroupNew", newVal);
                this.tempSubgroup=newVal
            },
            deep: true,

        }
    }
}
</script>

<style scoped lang="scss">

</style>
