const getTimeRemaining = (endtime) => {
  let total = Date.parse(endtime) - Date.parse(new Date())
  let sec = Math.floor((total / 1000) % 60)
  let min = Math.floor((total / 1000 / 60) % 60)
  return {
    total: total,
    sec: sec,
    min: min
  }
}

const initializeTime = (element, endtime) => {

  const updateTime = () => {
    let time = getTimeRemaining(endtime)
    
    if (time.total <= 0) {
      clearInterval(timeinterval)
      return true
    }
    
    element.innerHTML = time.min + ':' + ('0' + time.sec).slice(-2)
  }

  updateTime()
  let timeinterval = setInterval(updateTime, 1000)

}

export {initializeTime}