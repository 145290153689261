export default {
  namespaced: true,

  state: () => ({
    notifications: []
  }),

  mutations: {
    setNotifications(state, payload) {
      state.notifications = payload
    },
    removeNotification(state, id) {
      state.notifications = state.notifications.filter(notification => notification.id !== id)
    },
    removeAllNotification(state) {
      state.notifications = []
    }
  },

  actions: {
    markAsRead({commit, store}, payload) {
      commit('removeNotification', payload)
      return new Promise((resolve, reject) => {
        YC.userNotificationApi.postMarkAsRead(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    markAsAllRead({commit, store}) {
      commit('removeAllNotification')
      return new Promise((resolve, reject) => {
        YC.userNotificationApi.postMarkAllAsRead()
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    notificationList({commit}) {
      return new Promise((resolve => {
        YC.userNotificationApi.getNotificationList()
          .then(response => {
            commit('setNotifications', response.data.messages)
            resolve()
          })
      }))
    },

  }
}
