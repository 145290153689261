import baseApi from '@/api/baseApi'

export default class priceBidderApi extends baseApi {

  getUpdatePricesFromApi() {
    return this.get('wbbidder/update/prices-from-api')
  }

  getProductList(queryParams) {
    let setQueryParam = {}

    for(let param in queryParams) {
      if(queryParams[param]) {
        setQueryParam[param] = queryParams[param]
      }
    }

    return this.get('wbbidder', setQueryParam)
  }

  searchProducts(payload) {
    return this.post(`wbbidder/search`, payload)
  }

  addFavorite(id) {
      const payload = {
            product_id: id,
      }
    return this.post(`wbbidder/product/add-favorite/`, payload)
  }

  removeFavorite(id) {
      const payload = {
          product_id: id,
      }
    return this.post(`wbbidder/product/remove-favorite/`, payload)
  }

  cloneBidderSettings(payload) {
    return this.post(`wbbidder/clone-bidder-settings`, payload)
  }

  searchAllLeaders(id) {
      const payload = {
          store_id: id,
      }
    return this.post(`wbbidder/get-all-leaders/`, payload)
  }

  getSingleProduct(product_id) {

    return this.get(`wbbidder/${product_id}`)
  }

  runBidderForProduct(payload) {
    return this.post("wbbidder/start-bidder", payload);
  }

  stopBidderForProduct(payload) {
    return this.post("wbbidder/stop-bidder", payload);
  }

  updateBidderForProduct(payload) {
    return this.post("wbbidder/update-bidder", payload);
  }


  runSearchLeader(product_id,days) {
    return this.get("wbbidder/leaders-v2/"+product_id+"/"+days);
  }


  getProductDiagram(product_id) {
    return this.get(`wbbidder/diagram/${product_id}`);
  }
  getProductDiagram2(product_id) {
    return this.get(`wbbidder/diagram2/${product_id}`);
  }

}