<template>
  <BaseInstructionPopup
    :classModifier="setClassModified"
  >
    <div
      class="DashboardInstructionPopup"
    >

      <div class="DashboardInstructionPopup__content">

        <div class="DashboardInstructionPopup__firstStep" v-if="step === 'first'">
          <div class="DashboardInstructionPopup__titlePopup titlePopup">Добро пожаловать на Главную страницу CAMP! 😌</div>
          <p class="DashboardInstructionPopup__text">В данном блоке вы можете увидеть свой отчет по заказам и по продажам <span class="DashboardInstructionPopup__textBlue">в режиме реального времени</span>, а также применить фильтр, чтобы увидеть отчеты за период.</p>
        </div>

        <div class="DashboardInstructionPopup__secondStep" v-else-if="step === 'second'">
          <p class="DashboardInstructionPopup__text">График продаж и заказов наглядно покажет динамику вашего роста за нужный период 📈</p>
        </div>

        <div class="DashboardInstructionPopup__thirdStep" v-else-if="step === 'third'">
          <p class="DashboardInstructionPopup__text">Разделы "Топовые товары" и "Просадка" покажут, какие товары заказывают ваши клиенты больше всего, а также, на какие товары упал спрос</p>
        </div>

      </div>

      <footer class="DashboardInstructionPopup__footer">
        <div
          class="DashboardInstructionPopup__dontShow"
          @click="dontShow"
        >
          Не показывать больше
        </div>
        <BaseButton
          class="DashboardInstructionPopup__button button button--primary"
          @click="nextStep"
        >
          Далее
        </BaseButton>
      </footer>

    </div>
  </BaseInstructionPopup>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'DashboardInstructionPopup',
  data() {  
    return {
      step: 'first'
    }
  },
  methods: {

    ...mapActions({
      closePopup: 'popup/closePopup',
      setInstructed: 'settings/setInstructed',
      getUser: 'auth/getUser'
    }),

    nextStep() {
      if(this.step === 'first') {
        this.step = 'second'
        if(window.innerWidth < 1366) document.querySelector('html').scrollTo(0, 600)
        else document.querySelector('html').scrollTo(0, 300)
      } else if(this.step === 'second') {
        this.step = 'third'
        if(window.innerWidth < 1366) document.querySelector('html').scrollTo(0, 1000)
        else document.querySelector('html').scrollTo(0, 700)
      } else if(this.step === 'third') {
        this.closePopup()
        this.setInstructed({dashboard: true})
        .then(() => {
          this.getUser()
        })
        document.querySelector('html').scrollTo(0, 0)
      }
    },

    dontShow() {
      this.closePopup()
      this.setInstructed({dashboard: true})
      .then(() => {
        this.getUser()
      })
    }
  },
  computed: {
    setClassModified() {
      switch(this.step) {
        case 'first' : return 'DashboardInstructionFirstStep';
        case 'second' : return 'DashboardInstructionSecondStep';
        case 'third' : return 'DashboardInstructionThirdStep';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var';
@import 'src/scss/utils/mixins';

.DashboardInstructionPopup {

  padding: 20px;

  width: 500px;
  max-width: 100%;  

  &__titlePopup {
    text-align: left;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    height: 34px;
    width: 87px;
    border-radius: 10px;
  }
  &__dontShow {
    font-size: 12px;
    line-height: 12px;
    color: $gray-450;
    cursor: pointer;
  }

  &__content {
    margin-bottom: 20px;
  }

  &__titlePopup {
    text-align: left;
    margin-bottom: 20px;
  }
  
  &__text {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &Blue {
      color: $dark-green-400;
      font-weight: 600;
    }
  }

}

</style>
