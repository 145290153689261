<template>
    <div class="mainMenu">
        <ul class="mainMenu__list">
            <li
              class="mainMenu__listItem"
            >
              <router-link
                class="mainMenu__listItemInner"
                :to="{path: '/crm/dashboard'}"
                :class="{'mainMenu__listItemInner--mod': sidebarRollUp}"
                title="Главная"
              >
                  <div class="mainMenu__listItemIcon">
                    <SvgUse
                      :width="25"
                      :height="25"
                      :id="'homeIconMenu'"
                    />
                  </div>
                  <div
                    v-show="!sidebarRollUp"
                    class="mainMenu__listItemLink"
                  >
                      Главная
                  </div>
              </router-link>
            </li>

            <li
              v-for="item of navigation.dropdown" :key="item.name"
              class="mainMenu__listItem"
              :class="{'mainMenu__listItem--open': item.menu.isDropDownRoot}"
              @click="listItemDropdownHandler(item)"
            >
              <div
                class="mainMenu__listItemInner"
                :class="{'mainMenu__listItemInner--mod': sidebarRollUp}"
              >
                <div class="mainMenu__listItemIcon">
                  <SvgUse
                    :width="25"
                    :height="25"
                    :id="item.menu.icon"
                  />
                </div>
                <div
                  class="mainMenu__listItemLink"
                  v-show="!sidebarRollUp"
                >
                    {{item.menu.root}}
                </div>
              </div>
                <ul
                  class="mainMenu__subList"
                  v-if="item.menu.isDropDownRoot"
                >
                  <template v-if="user.role !== 'Admin'">
                    <li
                        v-for="link in item.menu.links"
                        :key="link.id"
                        class="mainMenu__subListItem"
                    >
                        <router-link
                            :to="link.urlPath"
                            class="mainMenu__subListLink"
                            @click.stop
                        >
                            {{link.name}}
                            <div
                                @click.prevent.stop="toggleFavoriteHandler({id: link.id, role: user.role})"
                                class="mainMenu__subListLinkFavorite"
                            >
                                <SvgUse
                                    :width="15"
                                    :height="15"
                                    :id="link.isFavorite ? 'favoriteActiveMenu' : 'favoriteInActiveMenu'"
                                />
                            </div>
                        </router-link>
                    </li>
                  </template>

                  <template v-else>
                    <li
                        class="mainMenu__subListItem"
                        :class="{'mainMenu__subListItem--open': childItem.isDropDownChild}"
                        @click.stop="childItem.isDropDownChild = !childItem.isDropDownChild"
                        v-for="childItem of item.menu.childs" :key="childItem.name"
                    >
                      <span>
                        {{childItem.name}}
                      </span>
                        <ul
                            class="mainMenu__listSubList"
                            v-if="childItem.isDropDownChild"
                        >
                            <router-link
                                :to="childLink.urlPath"
                                v-for="childLink of childItem.links" :key="childLink.name"
                                class="mainMenu__subListLink"
                                @click.stop
                            >
                                {{childLink.name}}
                                <div
                                    @click.prevent.stop="toggleFavoriteHandler({id: childLink.id, role: user.role})"
                                    class="mainMenu__subListLinkFavorite"
                                >
                                    <SvgUse
                                        :width="15"
                                        :height="15"
                                        id="favoriteActiveMenu"
                                        v-if="childLink.isFavorite"
                                    />
                                    <SvgUse
                                        :width="15"
                                        :height="15"
                                        id="favoriteInActiveMenu"
                                        v-else
                                    />
                                </div>
                            </router-link>
                        </ul>
                    </li>
                  </template>
                </ul>
            </li>

            <template v-for="item of navigation.common">
              <li
                class="mainMenu__listItem"
                :key="item.name"
                v-if="user.role === 'Admin' ? true : item.name === 'Репрайсер' || item.name === 'Расширение'"
              >
                <router-link
                    class="mainMenu__listItemInner"
                    :class="{'mainMenu__listItemInner--mod': sidebarRollUp}"
                    :to="item.urlPath"
                    :title="item.name"
                >
                    <div class="mainMenu__listItemIcon">
                        <SvgUse
                            :width="25"
                            :height="25"
                            :id="item.icon"
                        />
                    </div>
                    <div
                        v-show="!sidebarRollUp"
                        class="mainMenu__listItemLink"
                    >
                        {{item.name}}
                    </div>
                </router-link>
              </li>
            </template>
        </ul>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  computed: {
    ...mapState({
      user: state => state.auth.user,
      navigation: state => state.sidebarMenu.navigation,
      favorite: state => state.sidebarMenu.favorite,
      sidebarRollUp: state => state.sidebarRollUp
    })
  },
  methods: {
    ...mapActions({
      getMainMenuWithFavorites: 'sidebarMenu/getMainMenuWithFavorites',
      toggleMenuFavorites: 'sidebarMenu/toggleMenuFavorites',
      getFavoriteMenu: 'sidebarMenu/getFavoriteMenu',
      sidebarRollUpAction: 'sidebarRollUpAction'
    }),
    toggleFavoriteHandler(data) {
      this.toggleMenuFavorites(data)
      this.getFavoriteMenu(data.role)
    },
    listItemDropdownHandler(item) {
      if(this.sidebarRollUp) {
        this.sidebarRollUpAction(false)
        item.menu.isDropDownRoot = true
      }
      else {
        item.menu.isDropDownRoot = !item.menu.isDropDownRoot
      }
    },
    listItemHandler() {
      if(this.sidebarRollUp) this.sidebarRollUpAction(false)
    }
  },
  watch: {
    sidebarRollUp(newValue) {
      if(newValue) this.navigation.dropdown[0].menu.isDropDownRoot = false
    },
    user(val) {
      this.getMainMenuWithFavorites({role: this.user.role, favorites: this.user.menu_favorites})
    }
  }

}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.mainMenu {
  font-family: $font-primary;
  &__list {
    &Item {
      margin-bottom: 15px;
      min-height: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      &--open &Link {
        padding-left: 20px;
      }
      &--open &Icon {
        transform: scale(1.16);
      }
      &Inner {
        display: flex;
        align-items: center;
        max-width: max-content;
        height: 100%;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        &--mod {
          justify-content: center;
          max-width: 100%;
        }
        span {
          padding-left: 40px;
          display: block;
          position: relative;
        }
      }
      &Inner:hover &Icon {
        transform: scale(1.16);
      }
      &Inner:hover &Link, &Inner:hover span {
        padding-left: 20px;
      }
      &Icon {
        width: 25px;
        height: 25px;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform $transition-duration ease;
      }
      &Link {
        padding-left: 15px;
        z-index: 1;
        transition: padding $transition-duration ease;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        height: 25px;
      }
    }
  }
  &__subList {
    padding-left: 60px;
    margin-top: 10px;
    &Item {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      position: relative;
      padding-bottom: 10px;
      cursor: pointer;
      &:last-child {
        padding-bottom: 0;
      }
      &::before {
        content: '';
        width: 3px;
        height: 3px;
        position: absolute;
        top: 7px;
        left: -20px;
        background-color: $white;
      }
    }
    &Link {
      font-size: 14px;
      line-height: 14px;
      font-weight: inherit;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        margin-bottom: 0;
      }
      &Favorite {
        flex: 0 0 100%;
        max-width: 15px;
        margin-left: 5px;
      }
    }
  }
  &__listSubList {
    margin-left: 5px;
    padding: 15px 0 10px;
  }
}

</style>
