import baseApi from '@/api/baseApi'

export default class userBalanceApi extends baseApi {

  /*
  * Баланс - просмотр и пополнение
  */
 
  getBalanceInfo() {
    return this.get('balance')
  }

  getCheckBalanceForZero() {
    return this.get('balance/check-balance-for-zero')
  }

  postCashInBalance(payload) {
    return this.post('balance/cash-in', payload)
  }

}