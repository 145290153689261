<template>
  <div class="ticketSupportChatPopupWraper">
    <BasePopup classModifier="ticket-support-chat-popup">
    <div class="ticketSupportChatPopup">
      <div class="ticketSupportChatPopup__header">
        <div class="ticketSupportChatPopup__profile">
          <div class="ticketSupportChatPopup__profileAvatar">{{ firstWord }}</div>
          <div class="ticketSupportChatPopup__profileInfo">
            <div class="ticketSupportChatPopup__profileInfoEmail">{{ currentUser.email }}</div>
            <div class="ticketSupportChatPopup__profileInfoId">ID {{ currentUser.id }}</div>
          </div>
        </div>
        <div class="ticketSupportChatPopup__aboutTicket">
          <h2 class="ticketSupportChatPopup__aboutTicketTitle">
            {{ selectedTicket.title }}
          </h2>
          <p class="ticketSupportChatPopup__aboutTicketId">
            ID тикета: {{ selectedTicket.id }}
          </p>
        </div>
        <div :class="[
          'ticketSupportChatPopup__ticketStatus',
          `${selectedTicket.status !== 'closed' ? 'ticketSupportChatPopup__ticketStatus_hidden' : ''}`
        ]">
          Тикет закрыт
        </div>
      </div>
      <div class="ticketSupportChatPopup__main">
        <div
          class="ticketSupportChatPopup__mainItem"
          v-for="message in messages"
          :key="message.date"
        >
          <div class="ticketSupportChatPopup__chatDate">
            {{ transformDate(message.date, 'fullDate') }}
          </div>
          <div
            class="ticketSupportChatPopup__messages"
            v-for="chat in message.chat"
            ref="messages"
            :key="chat.id"
          >
            <div
              class="ticketSupportChatPopup__message"
              :class="[
                chat.user_id === currentUser.id
                  ? 'ticketSupportChatPopup__message_from_admin'
                  : 'ticketSupportChatPopup__message_from_client',
              ]"
            >
              <p class="ticketSupportChatPopup__messageText">
                {{ chat.description }}
              </p>
              <p class="ticketSupportChatPopup__messageTime">
                <span>{{ transformDate(chat.created_at, 'time') }}</span>
                <SvgUse
                  v-if="chat.user_id === currentUser.id"
                  width="11"
                  height="7"
                  id="CheckMark"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="ticketSupportChatPopup__controll">
        <textarea
          v-model="chatMessage"
          placeholder="Введите сообщение"
          type="text"
          class="ticketSupportChatPopup__controllInput"
        ></textarea>
        <BaseButton
          v-if="isMobile"
          :disabled="!chatMessage"
          @click="sendMessage"
          classModificator="ticketSupportChatPopup__controllButton button button--primary"
        >
          <SvgUse
            width="25"
            heigth="25"
            id="arrowUpWhite"
          />
        </BaseButton>
        <BaseButton
          v-else
          :disabled="!chatMessage"
          @click="sendMessage"
          classModificator="ticketSupportChatPopup__controllButton button button--primary"
        >Отправить</BaseButton>
      </div>
    </div>
  </BasePopup>
  </div>
</template>

<script>
import BaseButton from "./Global/BaseButton.vue";
import BasePopup from "./Global/BasePopup.vue";
import SvgUse from "./Global/SvgUse.vue";

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: 'TicketSupportChatPopup',
  data() {
    return {
      chatMessage: "",
    };
  },
  components: {
    BaseButton,
    BasePopup,
    SvgUse
  },
  computed: {
    ...mapState({
      selectedTicket: (state) => state.admin.selectedTicket,
      currentUser: (state) => state.auth.user,
      isMobile: (state) => state.isMobile
    }),
    ...mapGetters({
      messages: 'admin/messages'
    }),
    firstWord() {
      if (this.currentUser) return this.currentUser.email[0];
    },
  },
  methods: {
    ...mapActions({
      sendMessageToChat: "admin/sendMessageToChat",
    }),
    ...mapMutations({
      setSelectedTicket: 'admin/setSelectedTicket'
    }),
    sendMessage() {
      this.sendMessageToChat({
        id: this.selectedTicket.id,
        message: this.chatMessage,
      });

      this.chatMessage = ""
    },
    transformDate(date, format) {
      const currentDate = new Date(date).toLocaleString().replaceAll(".", "-")

      if (format === 'fullDate') {
        return currentDate.slice(6, 10) + '-' + currentDate.slice(3, 5) + '-' + currentDate.slice(0, 2)
      } else if (format === 'time') {
        return currentDate.slice(12, 17)
      }
    },
    scrollTolastMessage() {
      setTimeout(() => {
        const ms = this.$refs.messages
        if (ms && ms.length) {
          const latsMessage = ms[ms.length - 1]
          latsMessage.scrollIntoView()
        } else {
          return
        }
      }, 1000)
    }
  },
  watch: {
    selectedTicket: {
      handler(newValue) {
        this.scrollTolastMessage()
      },
      deep: true
    }
  },
  mounted() {
    this.scrollTolastMessage()
  },
  beforeUnmount() {
    this.setSelectedTicket('')
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/utils/_mixins.scss";
@import "src/scss/base/project/_var.scss";

.ticketSupportChatPopupWraper .popup__layout {
  height: 100%;
}

.ticketSupportChatPopup {
  display: grid;
  grid-template-rows: 80px 1fr max-content;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  height: 100%;

  @include breakpoint(sm) {
    grid-template-rows: 115px auto max-content;
  }

  &__header {
    padding: 20px 50px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);

    @include breakpoint(xl) {
      padding: 25px 50px;
    }

    @include breakpoint(md) {
      padding: 20px 30px;
    }

    @include breakpoint(sm) {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }

  &__profile {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-basis: 216px;

    @include breakpoint(xl) {
      flex-basis: 148px;
    }

    @include breakpoint(md) {
      flex-basis: 175px;
    }

    @include breakpoint(sm) {
      flex-basis: 160px;
      gap: 5px;
      order: 2;
    }

    &Avatar {
      width: 40px;
      height: 40px;
      background-image: $default-icon-bg;
      border-radius: 100%;
      overflow: hidden;
      text-align: center;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 600;

      @include breakpoint(xl) {
        width: 30px;
        height: 30px;
        font-size: 20px;
      }

      @include breakpoint(md) {
        width: 40px;
        height: 40px;
        font-size: 24px;
      }

      @include breakpoint(sm) {
        width: 30px;
        height: 30px;
        font-size: 20px;
      }
    }

    &InfoEmail {
      font-size: 20px;
      color: $black;
      margin-bottom: 5px;

      @include breakpoint(xl) {
        font-size: 13px;
      }

      @include breakpoint(md) {
        font-size: 15px;
      }

      @include breakpoint(xs) {
        font-size: 14px;
      }
    }

    &InfoId {
      font-size: 12px;
      color: $gray-400;
    }
  }

  &__aboutTicket {
    flex-grow: 1;
    max-width: 450px;

    @include breakpoint(md) {
      max-width: 170px;
    }

    @include breakpoint(sm) {
      order: 3;
    }

    &Title {
      font-family: $font-primary;
      margin-bottom: 10px;
      text-align: center;
      font-size: 20px;
      color: $black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include breakpoint(xl) {
        font-size: 13px;
        margin-bottom: 5px;
      }

      @include breakpoint(md) {
        font-size: 15px;
      }
    }

    &Id {
      font-size: $font-primary;
      text-align: center;
      font-size: 12px;
      color: $gray-400;
    }
  }

  &__ticketStatus {
    font-size: 12px;
    color: $gray-400;

    &_hidden {
      visibility: hidden;
    }

    @include breakpoint(sm) {
      order: 1;

      &_hidden {
        display: none;
      }
    }
  }

  &__main {
    padding: 20px 30px 0;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    max-height: 750px;

    @include breakpoint(xl) {
      max-height: 630px;
    }

    @include breakpoint(md) {
      max-height: 809px;
    }

    @include breakpoint(sm) {
      max-height: 609px;
    }

    @include breakpoint(xs) {
      max-height: 358px;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    @include breakpoint(md) {
      padding-left: 11px;
      padding-right: 11px;

      max-height: 809px;
    }

    @include breakpoint(sm) {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      padding: 20px 10px 0;

      max-height: 609px;
    }

    @include breakpoint(xs) {
      // grid-template-rows: 115px minmax(auto, 358px) max-content;
      max-height: 358px;

    }
  }

  &__chatDate {
    text-align: center;
    font-size: 12px;
    color: $gray-400;
    margin-bottom: 20px;
  }

  &__messages {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 15px;

    @include breakpoint(sm) {
      row-gap: 5px;
    }
  }

  &__message {
    padding: 20px 30px 5px;
    max-width: 800px;
    border-radius: 20px;
    width: max-content;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .07);

    @include breakpoint(xl) {
      font-size: 13px;
      max-width: 740px;
    }

    @include breakpoint(md) {
      font-size: 15px;
      max-width: 597px;
    }

    @include breakpoint(sm) {
      max-width: 300px;
    }

    @include breakpoint(xs) {
      max-width: 260px;
      padding: 10px 18px 5px;
    }

    &_from_client {
      color: $dark-green-500;
    }

    &_from_admin {
      color: $black;
      justify-self: right;
    }

    &Text {
      font-family: $font-primary;
      margin-bottom: 5px;
    }

    &Time {
      font-size: 12px;
      color: $gray-400;
      text-align: right;
    }
  }

  &__controll {
    display: flex;
    padding: 20px 50px 30px;
    align-items: center;
    align-self: flex-end;
    column-gap: 10px;

    @include breakpoint(sm) {
      padding: 30px 10px 6px;
    }

    &Input {
      background: $white;
      border: 1px solid $gray-400;
      text-align: left;
      padding: 15px 30px;
      width: 100%;
      border-radius: 20px;
      height: 50px;
      resize: none;
      font-family: $font-primary;
      font-size: 15px;
      line-height: 17px;
      color: $black;
      overflow-y: scroll;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;

      /* Firefox */
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      &::placeholder {
        font-family: $font-primary;
        font-size: 15px;
        color: $gray-400;

        @include breakpoint(xs) {
          font-size: 14px;
        }
      }
    }

    &Button {
      flex: 0 0 100%;
      max-width: 270px;
      align-self: flex-end;
      height: 50px;

      @include breakpoint(md) {
        flex-basis: 60px;

        span {
          display: flex;
        }
      }
    }
  }
}
</style>
