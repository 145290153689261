<template>
  <BasePopup
    :classModifier="'account'"    
  >

    <div class="balancePromocodePopup">

      <div class="balancePromocodePopup__titlePopup titlePopup">Промокод</div>
      <FormInput 
        type="number"
        class="balancePromocodePopup__formInput"
        :placeholder="'Введите промокод'"
        
      />

      <BaseButton
        class="balancePromocodePopup__button button--primary"
        :preloader="isLoading"
      >
        Применить
      </BaseButton>
    </div>

  </BasePopup>
</template>

<script>
import FormInput from '@/components/Form/FormInput'

export default {
  name: 'BalancePromocodePopup',
  components: {
    FormInput
  },
  data() {
    return {
      isLoading: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.balancePromocodePopup {

  &__formInput {
    margin-bottom: 30px;
    @include breakpoint(sm) {
      margin-bottom: 20px;
    }
  }

  &__button {
    height: 50px;
    max-width: 175px;
    margin: 0 auto;
  }

}

</style>