<template>
  <div class="sorting">
    <span>{{label}}</span>
    <div class="sorting__buttons">
      <button
        class="sorting__button"
        @click="updateAsc"
        title="По возрастанию"
      >
        <SvgUse    
          :width="20"     
          :height="20" 
          id="arrowDownActive"
          v-if="asc"
        />
        <SvgUse    
          :width="20"     
          :height="20" 
          id="arrowDownInActive"
          v-else
        />
        
      </button>
      <button
        class="sorting__button"
        @click="updateDesc"
        title="По убыванию"
      >
        <SvgUse    
          :width="20"     
          :height="20" 
          id="arrowUpActive"
          v-if="desc"
        />
        <SvgUse    
          :width="20"     
          :height="20" 
          id="arrowUpInActive"
          v-else
        />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaseSorting',
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Object
    }
  },
  data() {
    return {
      asc: false,
      desc: false
    }
  },
  methods: {
    updateAsc() {
      this.asc = !this.asc
      this.desc = false
      this.$emit('update:model-value', {
        asc: this.asc,
        desc: this.desc
      })
    },
    updateDesc() {
      this.desc = !this.desc
      this.asc = false
      this.$emit('update:model-value', {
        asc: this.asc,
        desc: this.desc
      })
    }
  },
  watch: {
    
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.sorting {

  font-size: 15px;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    @include mediaMaxWidth(375) {
      margin-right: 0;
    }
  }

  @include breakpoint(xl) {
    font-size: 13px;
    line-height: 13px;
  }
  @include breakpoint(md) {
    font-size: 15px;
    line-height: 15px;
  }
  @include breakpoint(sm) {
    &:first-child {
      flex: 0 0 103px;
    }
  }
  @include mediaMaxWidth(375) {
    flex-direction: column;
    &:first-child {
      flex: 0 0 134px;
    }
  }

  &__buttons {
    display: flex;
    @include mediaMaxWidth(375) {
      margin-top: 10px;
    }
  }

  &__button {
    display: flex;
    &:first-child {
      margin-right: 5px;
    }
    &--mr-5 {
      margin-right: 5px;
    }
  }

}

</style>