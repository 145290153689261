<template>
  <div class="userStore">
    <div
      class="userStore__title"
      @click="userStoreIsOpen = !userStoreIsOpen"
    >
      Мои магазины
    </div>

    <div
      class="userStore__list"
      v-if="userStoreIsOpen"
    >
      <UserStoreItem     
        v-for="store in storeList" :key="store.id" :store="store"
      />

    </div>

  </div>
</template>

<script>
import UserStoreItem from '@/components/UserStore/UserStoreItem'

export default {
  components: {
    UserStoreItem
  },
  props: {
    storeList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      userStoreIsOpen: false,
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.userStore {
  font-size: 15px;
  line-height: 12px;
  @include breakpoint(xl) {
    font-size: 13px;
    line-height: 10px;
  }
  @include breakpoint(md) {
    font-size: 14px;
    line-height: 11px;
  }
  &__title {
    margin-bottom: 10px;
    cursor: pointer;
  }
  &__list {
    background-color: $gray-100;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &Item {
      display: flex;
      align-items: center; 
      justify-content: space-between;
      &Name {
        flex: 0 0 170px;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &__switcher {
        flex: 1;
      }
    }
  }
}

</style>