<template>
  <div
    class="thirdStepInstructionPopup"
    v-if="popupShow"
  >
    <BaseCloseButton
      @click="popupShow = false"
    />
    <p class="thirdStepInstructionPopup__text">
      Выбрав маркетплейс, создайте новый токен в личном кабинете продавца на стороне маркетплейса
      и вписав его нажмите на кнопку <span>"Добавить компанию"</span> 💰
    </p>

    <p class="thirdStepInstructionPopup__text">
      <span>P.S.</span> Находясь в кабинете самого маркетплейса предоставьте сервису все API методы для управления вашим магазином для наиболее эффективной работы
      А именно: Контент, Маркетплейс, Статистика, Аналитика, Продвижение, Рекомендации, Вопросы и отзывы, Цены и скидки, Чат с покупателем
    </p>
    
    <div
      class="thirdStepInstructionPopup__dontShow"
      @click="dontShow"
    >
      Не показывать больше
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data() {
    return {
      popupShow: true
    }
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
      setInstructed: 'settings/setInstructed',
      getUser: 'auth/getUser'
    }),
    dontShow() {
      this.popupShow = false
      this.setInstructed({integration: true})
      .then(() => {
        this.getUser()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.thirdStepInstructionPopup {
  width: 986px;
  max-width: 100%;
  box-shadow: 0px 5px 15px 0px rgba($black, 0.20);
  border-radius: 20px;
  background-color: $white;

  padding: 20px 35px 20px;

  @include breakpoint(xl) {
    width: 703px;
  }

  &__text {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    @include breakpoint(xs) {
      font-size: 13px;
      line-height: 13px;
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
    span {
      font-weight: 600;
      color: $dark-green-400;
    }
  }
  &__dontShow {
    font-size: 12px;
    line-height: 12px;
    color: $gray-450;
    cursor: pointer;
  }
}

</style>