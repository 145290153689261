<template>
  <BasePopup>

    <div class="settingsChangePassword">
      <div class="settingsChangePassword__titlePopup titlePopup titlePopup--large">Пароль</div>

        <template v-if="!passwordConfirmed">
          <FormInput   
            class="settingsChangePassword__formInput"     
            :placeholder="'Старый пароль'"
            :label="'Подтвердите, что это ваш аккаунт'"
            :labelClassModificator="'setting'"
            v-model="currentPassword"
            :type="'password'"
            :validations="[
              {
                rule: $validations.isStringNotEmpty(currentPassword),
                message: 'Пожалуйста, ведите старый пароль'
              },
              {
                rule: !$validations.isStringNotEmpty(currentPassword) || !this.isPasswordFailed,
                message: 'Неверный пароль'
              }
            ]"
          />
        </template>

        <template v-else>
          <FormInput   
            class="settingsChangePassword__formInput"     
            :placeholder="'Введите пароль'"
            :label="'Новый пароль'"
            :labelClassModificator="'setting'"
            v-model="newPassword"
            :validations="[
              {
                rule: $validations.isStringNotEmpty(newPassword),
                message: 'Пожалуйста, ведите новый парль'
              },
              {
                rule: !$validations.isStringNotEmpty(newPassword) || $validations.isStringLengthBiggerThan(newPassword, 6),
                message: 'Минимум 6 знаков'
              },
              {
                rule: !$validations.isStringNotEmpty(newPassword) || !$validations.isStringLengthBiggerThan(newPassword, 6) || this.passwordСomparison,
                message: 'Пароли не совпадают'
              },
            ]"
          />
          <FormInput   
            class="settingsChangePassword__formInput"     
            :placeholder="'Подтвердите пароль'"
            :label="'Подтвердите новый пароль'"
            :labelClassModificator="'setting'"
            v-model="confirmNewPassword"
            @inputValueUpdate="passwordsNotMatch = false"
            :validations="[
              {
                rule: $validations.isStringNotEmpty(confirmNewPassword),
                message: 'Пожалуйста, подтвердите новый парль'
              },
              {
                rule: !$validations.isStringNotEmpty(confirmNewPassword) || $validations.isStringLengthBiggerThan(confirmNewPassword, 6),
                message: 'Минимум 6 знаков'
              },
              {
                rule: !$validations.isStringNotEmpty(confirmNewPassword) || !$validations.isStringLengthBiggerThan(confirmNewPassword, 6) || this.passwordСomparison,
                message: 'Пароли не совпадают'
              },
            ]"
          />
        </template>

      <BaseButton
        class="settingsChangePassword__button button--primary"
        @click="handlerActionPassword"
        :preloader="isLoading"
        :data-password-confirmed="passwordConfirmed"
      >
        {{buttonText}}
      </BaseButton>

    </div>

  </BasePopup>
</template>

<script>
import FormInput from '@/components/Form/FormInput'
import {mapActions} from 'vuex'

export default {
  name: 'SettingsChangePasswordPopup',
  components: {
    FormInput
  },
  data() {
    return {
      isLoading: false,
      isPasswordFailed: false,
      passwordConfirmed: false,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      buttonText: 'Далее'
    }
  },
  methods: {
    ...mapActions({
      getUser: 'auth/getUser',
      changePassword: 'settings/changePassword',
      createPassword: 'settings/createPassword',
      openPopup: 'popup/openPopup',
      closePopup: 'popup/closePopup'
    }),
    handlerActionPassword() {
      YC.EventBus.emit('validations')
      this.passwordConfirmed ? this.handlerCreatePassword() : this.handlerChangePassword()
    },
    handlerChangePassword() {
      if(this.currentPasswordIsValid) {
        this.isLoading = true
        this.changePassword({password: this.currentPassword})
        .then(response => {
          this.passwordConfirmed = true
          this.buttonText = 'Сменить пароль'
        })
        .catch(() => {
          this.isPasswordFailed = true
          setTimeout(() => {
            YC.EventBus.emit('validations')
          })
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    },
    handlerCreatePassword() {
      if(this.createPasswordIsValid && this.passwordСomparison) {
        this.isLoading = true
        this.createPassword({password: this.newPassword, password2: this.confirmNewPassword})
        .then(response => {
          this.closePopup()
          this.openPopup(
            {
              component: 'SettingsAlertPopup', 
              props: {
                text: 'Пароль успешно изменен'
              }
            }
          )
        })
        .catch(() => {
          
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    }
  },
  computed: {
    currentPasswordIsValid() {
      return this.$validations.isStringNotEmpty(this.currentPassword)
    },
    createPasswordIsValid() {
      return this.$validations.isStringLengthBiggerThan(this.newPassword, 6)
             && this.$validations.isStringLengthBiggerThan(this.confirmNewPassword, 6)
    },
    passwordСomparison() {
      return this.newPassword === this.confirmNewPassword
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.settingsChangePassword {

  width: 500px;
  max-width: 100%;
  padding: 50px;
  @include breakpoint(xl) {
    padding: 30px 50px;
  }
  @include breakpoint(md) {
    padding: 50px;
  }
  @include breakpoint(sm) {
    padding: 40px 20px;
    width: 380px;
  }
  @include breakpoint(xs) {
    padding: 30px 15px;
    width: 310px;
  }
  
  &__titlePopup, &__formInput {
    margin-bottom: 20px;
    @include breakpoint(xl) {
      margin-bottom: 15px;
    }
    @include breakpoint(md) {
      margin-bottom: 20px;
    }
  }

  &__button {
    height: 50px;
    max-width: 160px;
    margin: 0 auto;
    &[data-password-confirmed="true"] {
      max-width: 253px;
      @include breakpoint(xl) {
        max-width: 232px;
      }
      @include breakpoint(md) {
        max-width: 253px;
      }
    }
    @include breakpoint(xl) {
      height: 40px;
      max-width: 152px;
    }
    @include breakpoint(md) {
      height: 50px;
      max-width: 160px;
    }
    @include breakpoint(xs) {
      height: 40px;
    }
  }

}

</style>