<template>
  <div class="adminPromocodesCreateReferralLinkPopup">
    <AdminPromocodesBasePopup
      title="Создание реферальной ссылки:"
      generateButtonTitle="Сгенерировать"
      :isLinkGenerated="isLinkGenerated"
      classModifier="adminPromocodesCreateReferralLinkPopup__container"
      @generate="generatePromocode"
      @close="closePopup"
    >
      <FormInput
        v-if="!isReferralLinkGenerated"
        type="email"
        placeholder="Введите почту партнера:"
        v-model.trim="partnerEmail"
        
        :validations="[
          {
            rule: $validations.isStringNotEmpty(partnerEmail),
            message: 'Пожалуйста, заполните форму'
          },
          {
            rule: !$validations.isStringNotEmpty(partnerEmail) || $validations.isEmailValid(partnerEmail),
            message: 'Необходимо указать почту'
          },
          {
            rule: promocodeEmployeeInfo.isEmployeeEmailExist,
            message: 'Почта не найдена'
          }
        ]"
      />

      <div
        class="adminPromocodesCreateReferralLinkPopup__emailPartner"
        v-if="isReferralLinkGenerated"
      >
        {{ partnerEmail }}
      </div>

      <!-- <AdminPromocodesDropDown
        :placeholder="validityPeriodDropDownData.placeholder"
        :options="validityPeriodDropDownData.options"
        :isLinkGenerated="isLinkGenerated"
        :withDate="true"
        @select-option="selectOption"
        :validations="[
          {
            rule: $validations.isStringNotEmpty(selectedValidityPeriod),
            message: 'Пожалуйста, выберите срок действия реферальной ссылки'
          }
        ]"
      /> -->

      <AdminPromocodesCopyLink
        title="Реферальная ссылка:"
        :isHidden="!isReferralLinkGenerated"
        :link="`${this.referralLinkMainURL}${this.generatedReferralPromocode}`"
        classModifier="adminPromocodesCreateReferralLinkPopup__copyLink"
        typeOfLink="referral"
      />
    </AdminPromocodesBasePopup>
  </div>
</template>

<script>
import FormInput from '@/components/Form/FormInput'
import AdminPromocodesDropDown from '@/views/Admin/AdminPromocodes/AdminPromocodesDropDown'
import AdminPromocodesCopyLink from '@/views/Admin/AdminPromocodes/AdminPromocodesCopyLink'
import AdminPromocodesBasePopup from '@/views/Admin/AdminPromocodes/AdminPromocodesPopups/AdminPromocodesBasePopup'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'AdminPromocodesCreateReferralLinkPopup',
  data() {
    return {
      isReferralLinkGenerated: false,
      partnerEmail: '',
      selectedValidityPeriod: null,
      promocodeType: 'ref_percent',
      defaultReferralPerrcent: 10
    };
  },
  components: {
    FormInput,
    AdminPromocodesDropDown,
    AdminPromocodesCopyLink,
    AdminPromocodesBasePopup,
  },
  computed: {
    ...mapState({
      validityPeriodDropDownData: (state) =>
        state.admin.promocodeDropDownData.validityPeriod,
        isLinkGenerated: (state) => state.admin.isPromocodeLinkGenerated,
        promocodeEmployeeInfo: (state) => state.admin.promocodeEmployeeInfo,
        referralLinkMainURL: (state) => state.admin.promocodeListMainURL,
        generatedReferralPromocode: (state) => state.admin.generatedReferralPromocode
    }),
    ...mapGetters({
      promcodeExpiredAt: 'admin/promcodeValidityPeriodForPostRequset'
    }),
    popupFieldsIsValid() {
      return this.$validations.isStringNotEmpty(this.partnerEmail) && this.$validations.isEmailValid(this.partnerEmail)
    }
  },
  methods: {
    ...mapMutations({
      setIsPromocodeLinkGenerated: 'admin/setIsPromocodeLinkGenerated',
      resetPromocodeEmployeeInfo: 'admin/setPromocodeEmployeeInfo',
      resetGeneratedReferralPromocode: 'admin/setGeneratedReferralPromocode'
    }),
    ...mapActions({
      createReferralLink: 'admin/postCreateReferralLink',
      getPromocodeList: 'admin/getPromocodeList'
    }),
    generatePromocode() {
      if(this.popupFieldsIsValid) {
        const payload = {
          type: this.promocodeType,
          value: this.defaultReferralPerrcent,
          // expired_at: this.promcodeExpiredAt,
          userEmail: this.partnerEmail
        }

        this.createReferralLink(payload)
          .then(() => {
            this.isReferralLinkGenerated = true
            this.setIsPromocodeLinkGenerated(true)
            this.resetPromocodeEmployeeInfo({ id: null, isEmployeeEmailExist: false })
          })
        .catch(() => {
          YC.EventBus.emit('validations')
        })
      } else {
        return
      }
    },
    closePopup() {
      this.isReferralLinkGenerated = false
      this.partnerEmail = ''
    },
    selectOption(selectedOption) {
      this.selectedValidityPeriod = selectedOption
    }
  },
  beforeMount() {
    this.resetGeneratedReferralPromocode('')
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.adminPromocodesCreateReferralLinkPopup {
  & .formInput {
    margin-bottom: 30px;

    & span {
      display: block;
    }
  }

  &__container {
    width: 524px;

    @include breakpoint(xl) {
      width: 500px;
    }

    @include breakpoint(md) {
      width: 524px;
    }

    @include breakpoint(sm) {
      width: 380px;
    }

    @include mediaMaxWidth(390) {
      width: 310px;
    }
  }

  &__emailPartner {
    padding: 22px 0;
    border-radius: 15px;
    height: 60px;
    box-shadow: $box-shadow-input;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    margin-bottom: 30px;
  }

  &__copyLink {
    margin-top: 30px;
  }
}
</style>
