<template>
    <!-- Colorpickers -->
    <div class="camp-colorpicker camp__js-colorpicker" >
        <div class="camp-colorpicker__display" :style="{ backgroundColor: displayColor }" @click.self="handleDropdownClick"></div>

        <div class="camp-colorpicker__dropdown camp-dropdown"  ref="dropdown" @click.self="handleDropdownClick"
             :style="{
                display: dropdownVisible ? 'block !important' : 'none',
                transform: typePicker>0 ? 'translateX(-230px)' : 'none'
                }"
        >
            <button class="camp-btn-ic camp-btn-ic__close" type="button" aria-label="Закрыть" @click="toggleDropdown">
                <span class="camp-btn-ic__icon">
                    <Svg :img="'close'"/>
                </span>
            </button>
            <ul class="camp-dropdown__body camp-row camp-align-horisontal-start">
                <li class="camp-colorpicker-item" v-for="(color, index) in colorList" :key="index"
                    :style="{ backgroundColor: color }" @click="selectColor(color)">
                </li>
            </ul>
        </div>
    </div>
    <!-- //Colorpickers -->

</template>

<script>
    import Svg from "../Svg";
    export default {
        name: "ColorPickers",
        components: {Svg},
        data() {
            return {
                dropdownVisible: false,
                displayColor: this.defalut_color ? this.defalut_color: "#FF3D57",
                colorList : ["red","green","blue", "#0073EA", "#00D488", "#52ff6a", "#9B51E0", "#FDAB3D", "#FF3D57",
                            "#77ea3b", "#22181a", "#f75dcc", "#62e051", "#FDAB3D", "#FF3D57",
                            "#17b3ea", "#49d4b1", "#eef7bd", "#bae041", "#FDAB3D", "#FF3D57",
                             "#fff76c", "#52ff6a"],

                pickerStyle: 'transform: translateX(-230px);',
            }
        },
        props: {
            defalut_color:'',
            keyid:null,
            typePicker:{
                type: Number,
                default: 0
            },
            value:null,
        },
        computed:{
            getCurrentColor(){
                return this.displayColor;
            },
        },
        methods:{

            toggleDropdown() {
               // console.log(" Toggle");
                this.dropdownVisible = !this.dropdownVisible;
                if(this.dropdownVisible===false) {
                    this.$store.commit('crm/setUiaction', {action: 'close_dropdowns'});
                }
            },
            selectColor(color) {
                if(this.keyid) {
                    //console.log("Select color", color, this.keyid, this.value);
                    this.$store.commit('crm/setUiaction', {action:'close_dropdowns'});
                    this.$emit('colorChanged', {color:color, keyid: this.keyid, value: this.value});

                }
                this.displayColor = color;
                this.dropdownVisible = false; // Закрываем выпадающее меню после выбора цвета
            },
            handleDropdownClick(event) {
               // console.log("Click handleDropdownClick");
                this.$store.commit('crm/setUiaction', {action:'open_colorpicker',group_id:this.keyid});
                this.toggleDropdown();
                const target = event.target;
                if (target.classList.contains('camp-colorpicker-item')) {
                    const color = target.style.backgroundColor;

                    this.selectColor(color);
                } else if (target.classList.contains('camp-btn-ic__close')) {
                    this.toggleDropdown();
                }
            }
        },
        mounted() {
            this.displayColor= this.defalut_color ?? "#FF3D57"
        }
    }
</script>

<style scoped>

</style>
