export default {
  namespaced: true,

  state: () => ({
    isLoading: true,
    ticketList: [],
    userList: [],
    promocodeList: [],
    selectedTab: 'Пользователи',
    promocodeDropDownData: {
      discount: {
        placeholder: 'Выберете размер скидки',
        options: [
          {
            text: '5%',
            value: '5'
          },
          {
            text: '10%',
            value: '10'
          },
          {
            text: '15%',
            value: '15'
          },
          {
            text: '20%',
            value: '20'
          },
          {
            text: '30%',
            value: '30'
          },
          {
            text: '40%',
            value: '40'
          },
          {
            text: '60%',
            value: '60'
          },
          {
            text: '70%',
            value: '70'
          },
          {
            text: '80%',
            value: '80'
          },
          {
            text: '90%',
            value: '90'
          },
          {
            text: '100%',
            value: '100'
          }
        ]
      },
      validityPeriod: {
        placeholder: 'Выберите срок действия',
        options: [
          {
            text: '1 неделя',
            value: 'week'
          },
          {
            text: '1 месяц',
            value: 'month'
          },
          {
            text: '3 месяца',
            value: 'month3'
          },
          {
            text: '1 год',
            value: 'year'
          },
          {
            text: 'Бессрочно',
            value: 'null'
          }
        ],
      },
      currency: {
        placeholder: 'Выберите валюту',
        options: [
          {
            text: 'RUB',
            value: 'rub',
            flag: 'flagRus'
          },
          // {
          //   text: 'BYB',
          //   value: 'byb',
          //   flag: 'flagByb'
          // },
          // {
          //   text: 'KZT',
          //   value: 'kzt',
          //   flag: 'flagKzt'
          // },
          // {
          //   text: 'KGS',
          //   value: 'kgs',
          //   flag: 'flagKgs'
          // },
          // {
          //   text: 'AMD',
          //   value: 'amd',
          //   flag: 'flagAmd'
          // }
        ],
      },
      amount: {
        placeholder: 'Выберите сумму пополнения',
        options: [
          {
            text: '1000',
            value: 1000,
          },
          {
            text: '2000',
            value: 2000,
          },
          {
            text: '3000',
            value: 3000,
          },
          {
            text: '4000',
            value: 4000,
          },
          {
            text: '5000',
            value: 5000,
          },
          {
            text: '6000',
            value: 6000,
          },
          {
            text: '7000',
            value: 7000,
          },
          {
            text: '8000',
            value: 8000,
          },
          {
            text: '9000',
            value: 9000,
          },
          {
            text: '10 000',
            value: 10000,
          },
          {
            text: '50 000',
            value: 50000,
          },
          {
            text: '100 000',
            value: 100000,
          }
        ],
      },
    },
    promocodeListMainURL: 'https://youcamp.pro/?promo=',
    promocodeValidityPeriod: 'null',
    promocodeEmployeeInfo: {
      id: null,
      isEmployeeEmailExist: true
    },
    promocodeListParams: {
      offset: 1,
      limitCount: 20,
      showedLimit: 20,
      isLimitExceed: false,
      order_by: {
        field: "",
        type: "",
      }
    },
    isPromocodeLinkCopied: false,
    isPromocodeLinkGenerated: false,
    generatedReferralPromocode: '',
    selectedUser: [],
    selectedTicket: "",
    columnTitles: {
      users: [
        {
          sortKey: "name",
          label: "Логин",
          svgState: false,
        },
        {
          sortKey: "phone",
          label: "Телефон",
          svgState: false,
        },
        {
          sortKey: "email",
          label: "Почта",
          svgState: false,
        },
        {
          sortKey: "created_at",
          label: "Создание",
          svgState: false,
        },
        {
          sortKey: "last_login",
          label: "Последний вход",
          svgState: false,
        },
        {
          sortKey: "manager",
          label: "Менеджер",
          svgState: false,
        },
        {
          sortKey: "confirmed",
          label: "Статус",
          svgState: false,
        },
        {
          sortKey: "utm",
          label: "UTM",
          svgState: false,
        },
        {
          sortKey: "role",
          label: "Роль",
          svgState: false,
        },
        {
          sortKey: "legal",
          label: "Юр. лицо",
          svgState: false,
        },
      ],
      tickets: [
        {
          sortKey: "id",
          label: "№",
        },
        {
          sortKey: "client",
          label: "Логин",
        },
        {
          sortKey: "create",
          label: "Время обращения",
        },
        {
          sortKey: "title",
          label: "Заголовок",
        },
        {
          sortKey: "description",
          label: "Тема письма",
        },
        {
          sortKey: "message",
          label: "Последнее сообщение",
        },
        {
          sortKey: "department",
          label: "Отдел",
        },
        {
          sortKey: "manager",
          label: "Менеджер",
        },
        {
          sortKey: "status",
          label: "Статус",
        },
      ],
      goods: [
        {
          sortKey: "category",
          label: "Категория",
          tip: `
            Берется из кабинета Wildberries:
            > Товары > Карточки товара
          `
        },
        {
          sortKey: "sku",
          label: "Артикулов",
          tip: `
            Показывает сколько всего есть артикулов
            по данной Категории
          `
        },
        {
          sortKey: "goods_count",
          label: "Кол-во товара",
          tip: `
            Показывает сколько всего есть товара
            по данной Категории
          `
        },
        {
          sortKey: "bidder-on",
          label: "Включен",
          tip: `
            Берется общая информация по всем Категориям товаров
            с включенным биддером и суммируется в колонке
          `
        },
        {
          sortKey: "bidder-off",
          label: "Выключен  ",
          tip: `
            Показывает по какому кол-ву артикулов в Категории
            биддер выключен. Формула: Артикулов - Биддер включен
          `
        },
        {
          sortKey: "orders",
          label: "Всего",
          tip: `
            Кол-во заказов по Категории
            по товарам у которых ВКЛЮЧЕН биддер
          `
        },
        {
          sortKey: "averageOrders",
          label: "Среднее",
          tip: `
            Кол-во заказов по Категории
            по товарам у которых ВКЛЮЧЕН биддер
          `
        },
        {
          sortKey: "sales",
          label: "Продажи",
          tip: `Формула: Заказы всего / Биддер цен - Включен`
        },
        {
          sortKey: "salesPercent",
          label: "% Выкупа",
          tip: `
            Показывает % выкупа от заказанных с включенным биддерром.
            Формула: Продажи / Заказы всего *100%
          `
        },
      ],
      promocodes: [
        {
          sortKey: "created_at",
          label: "Дата"
        },
        {
          sortKey: "expire_date",
          label: "Срок действия"
        },
        {
          sortKey: "email",
          label: "Почта"
        },
        {
          sortKey: "name",
          label: "Имя сотрудника"
        },
        {
          sortKey: "role",
          label: "Роль в проекте"
        },
        {
          sortKey: "action",
          label: "Действие"
        },
        {
          sortKey: "",
          label: "Сумма / Реферал"
        },
        {
          sortKey: "",
          label: "Почта реферала"
        },
        {
          sortKey: "",
          label: ""
        }
      ]
    },
    goodsList: '',
    params: {
      limit: 100,
      offset: 0,
      order_by: {
        field: "",
        type: "",
      },
    },
    userParams: {
      limit:100,
      sort: 'created_at',
      skip: 0,
      period: '',
      date_form: '',
      date_to: '',
      search: '',
      search_value: '',
    },
    defaultGoodsParams: {
      sort: '',
      today: '',
      yesterday: '',
      date_from: '',
      date_to: ''
    },
    goodsParams: '',
    settingsSite:{
      pageTitle:'',
      pageDescription:'',
      pageHead:'',
      pageKeyword:'',
    },
  }),

  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    setTickets(state, payload) {
      state.ticketList = payload;
    },
    setGoods(state, payload) {
      state.goodsList = payload
    },
    setParamOrderBy(state, payload) {
      if (payload.field && payload.type) {
        if (
          payload.field === state.params["order_by"].field &&
          payload.type === state.params["order_by"].type
        ) {
          state.params["order_by"] = {
            field: "",
            type: "",
          };
        } else {
          state.params["order_by"] = payload;
        }
      } else {
        if (payload.field === state.params["order_by"].field) {
          state.params["order_by"] = {
            field: "",
            type: "",
          };
        } else {
          state.params["order_by"] = payload;
        }
      }
    },
    setGoodsParams(state, payload) {
      state.goodsParams = payload
    },
    setSelectedTicket(state, payload) {
      state.selectedTicket = payload;
    },

    setSiteSettings(state, payload) {
      state.settingsSite = payload;
    },
    
    setUserList(state, payload) {
      state.userList = payload;
    },
    setSelectedUser(state, payload) {
      state.selectedUser = payload;
    },
    setSortUserList(state, payload) {
      state.userList = payload
    },
    setIsPromocodeLinkCopied(state, payload) {
      state.isPromocodeLinkCopied = payload
    },
    setIsPromocodeLinkGenerated(state, payload) {
      state.isPromocodeLinkGenerated = payload
    },
    setPromocodeList(state, payload) {
      state.promocodeList = payload
    },
    setPromocodeValidityPeriod(state, payload) {
      state.promocodeValidityPeriod = payload
    },
    setPromocodeEmployeeInfo(state, payload) {
      state.promocodeEmployeeInfo = { ...state.promocodeEmployeeInfo, ...payload }
    },
    setPromocodeListParams(state, payload) {
      state.promocodeListParams = { ...state.promocodeListParams, ...payload }
    },
    setGeneratedReferralPromocode(state, payload) {
      state.generatedReferralPromocode = payload
    },
    setSelectedTab(state, payload) {
      state.selectedTab = payload
    }
  },

  getters: {
    getSettings(state){
        return state.settingsSite;
    },
    isHaveNewAnswer(state) {
      return state.ticketList.filter((ticket) => ticket.status !== "closed")
        .length;
    },
    messages(state) {
      if (state.selectedTicket) {
        return Object.values(
          state.selectedTicket.messages.reduce((acc, curr) => {
            const date = curr.created_at.split(" ")[0];
            acc[date] ??= { date, chat: [] };
            acc[date].chat.push(curr);
            return acc;
          }, [])
        );
      } else {
        return "";
      }
    },

    goods(state) {
          return Object.entries(state.goodsList).map((el) =>
              ({
                  name: el[0],
                  items: el[1]['items'],
                  meta: el[1]['meta']
              })
          )
    },

    promcodeValidityPeriodForPostRequset(state) {
      if(state.promocodeValidityPeriod === 'null') {
        return ''
      }
      return JSON.stringify(state.promocodeValidityPeriod).slice(1, 11)
    },

    computedPromocodeList(state) {
      const promocodeListCopy = [ ...state.promocodeList ]
        const endIndex = state.promocodeListParams.showedLimit

        if(!state.promocodeListParams.order_by.field || state.promocodeListParams.order_by.field === 'created_at') {
          return promocodeListCopy.sort((a, b) => a.created_at > b.created_at ? 0 : 1).slice(0, endIndex)
        } else {
          if(state.promocodeListParams.order_by.field === 'role') {
            if(state.promocodeListParams.order_by.type === 'Admin') {
              return promocodeListCopy.sort((a, b) => a.creator.role === 'Admin' && b.creator.role !== 'Admin' ? -1 : a.creator.role !== 'Admin' && b.creator.role == 'Admin' ? 1 : 0).slice(0, endIndex)
            } else {
              return promocodeListCopy.sort((a, b) => a.creator.role === 'Admin' && b.creator.role !== 'Admin' ? 1 : a.creator.role !== 'Admin' && b.creator.role == 'Admin' ? -1 : 0).slice(0, endIndex)
            }
          } else if(state.promocodeListParams.order_by.field === 'expire_date') {
            if(state.promocodeListParams.order_by.type === 'Active') {
              return promocodeListCopy.sort((a, b) => {
                if((a.expire_date !== 'Expired' && a.is_active === 'Активен') && (b.expire_date === 'Expired' || b.is_active === 'Не активен')) return -1
                if((a.expire_date === 'Expired' || a.is_active === 'Не активен') && (b.expire_date !== 'Expired' && b.is_active === 'Активен')) return 1
                return 0
              }).slice(0, endIndex)
            } else {
              return promocodeListCopy.sort((a, b) => {
                if((a.expire_date !== 'Expired' && a.is_active === 'Активен') && (b.expire_date === 'Expired' || b.is_active === 'Не активен')) return 1
                if((a.expire_date === 'Expired' || a.is_active === 'Не активен') && (b.expire_date !== 'Expired' && b.is_active === 'Активен')) return -1
                return 0
              }).slice(0, endIndex)
            }
          } else if(state.promocodeListParams.order_by.field === 'email') {
            if(state.promocodeListParams.order_by.type === 'asc') {
              return promocodeListCopy.sort((a, b) => a.creator.email > b.creator.email ? 1 : 0).slice(0, endIndex)
            } else {
              return promocodeListCopy.sort((a, b) => a.creator.email > b.creator.email ? 0 : 1).slice(0, endIndex)
            }
          } else if(state.promocodeListParams.order_by.field === 'name') {
            if(state.promocodeListParams.order_by.type === 'asc') {
              return promocodeListCopy.sort((a, b) => a.creator.name > b.creator.name ? 1 : 0).slice(0, endIndex)
            } else {
              return promocodeListCopy.sort((a, b) => a.creator.name > b.creator.name ? 0 : 1).slice(0, endIndex)
            }
          }
        }
    }
  },

  actions: {
    getTicketList({ commit }, params) {
      commit("setIsLoading", true);
      return new Promise((resolve) => {
        YC.adminApi.getTicketList(params)
          .then(({ data }) => {
            commit("setTickets", data);
            resolve();
          })
          .finally(() => {
            commit("setIsLoading", false);
          });
      });
    },
    getTicket({ commit }, id) {
      return new Promise((resolve) => {
        YC.adminApi.getTicket(id)
          .then(({ data }) => {
            commit("setSelectedTicket", data.data.ticket);
            resolve();
          })
          .finally(() => {
            commit("setIsLoading", false);
          });
      });
    },
    sendMessageToChat({ commit, dispatch }, payload) {
      const { id, message } = payload;

      return new Promise((resolve) => {
        YC.adminApi.postTicketMessage(id, { message: message })
          .then(() => {
            dispatch("getTicket", id);
            resolve();
          })
          .finally(() => {
            commit("setIsLoading", false);
          });
      });
    },
    getUserList({ commit }, userParams) {
      commit("setIsLoading", true);
      return new Promise((resolve) => {
        YC.adminApi.getUserList(userParams)
          .then((usersList) => {
            commit("setUserList", usersList.data.data);
            resolve();
          })
          .finally(() => {
            commit("setIsLoading", false);
          })
      });
    },
    getSelectedUser({ commit }, id) {
      commit("setSelectedUser", []);
      return new Promise((resolve) => {
        YC.adminApi.getSelectedUser(id)
          .then((user) => {
            console.log(user);
            commit("setSelectedUser", user.data.stores);
            resolve();
          })
          .finally(() => {
          
          })
      });
    },
    postDeleteUser({ commit }, id) {
      //commit("setIsLoading", true);
      return new Promise((resolve) => {
        YC.adminApi.post('admin/users/delete/'+id,{user:id})
          .then((response) => {
            console.log(response);

            resolve();
          })
          .finally(() => {
              //commit("setIsLoading", false);
          })
      });
    },
    setSortUserList({ commit }, data) {
      const userList = data.userList;
      const sort_item = data.sort_item;
      const sort_direction = data.sort_direction;
      userList.sort(function(a, b) {
        if(sort_item === 'name') {
          if (sort_direction === false) {
            return b.name.localeCompare(a.name);
          } else {
            return a.name.localeCompare(b.name);
          }
        } else if (sort_item === 'phone') {
          if (sort_direction === false) {
            return b.phone - a.phone;
          } else {
            return a.phone - b.phone;
          }
        } else if(sort_item === 'email') {
          if (sort_direction === false) {
            return b.email.localeCompare(a.email);
          } else {
            return a.email.localeCompare(b.email);
          }
        } else if(sort_item === 'created_at') {
          if (sort_direction === false) {
            return b.created_at.localeCompare(a.created_at);
          } else {
            return a.created_at.localeCompare(b.created_at);
          }
        } else if(sort_item === 'last_login') {
          if (sort_direction === false) {
            return b.last_login.localeCompare(a.last_login);
          } else {
            return a.last_login.localeCompare(b.last_login);
          }
        } else if(sort_item === 'manager') {
          if (sort_direction === false) {
            return b.manager.localeCompare(a.manager);
          } else {
            return a.manager.localeCompare(b.manager);
          }
        } else if(sort_item === 'confirmed') {
          if (sort_direction === false) {
            return b.confirmed - a.confirmed;
          } else {
            return a.confirmed - b.confirmed;
          }
        } else if(sort_item === 'utm') {
          if (sort_direction === false) {
            return b.utm.localeCompare(a.utm);
          } else {
            return a.utm.localeCompare(b.utm);
          }
        } else if(sort_item === 'role') {
          if (sort_direction === false) {
            return b.role.localeCompare(a.role);
          } else {
            return a.role.localeCompare(b.role);
          }
        }
      });
      commit('setSortUserList', userList);
    },
    getGoodsList({ commit }) {
      commit("setIsLoading", true);
      return new Promise((resolve) => {
        YC.adminApi.getGoodsList()
          .then(({ data }) => {
            commit("setGoods", data.data);
            resolve();
          })
          .finally(() => {
            commit("setIsLoading", false);
          });
      })
    },
    postFiltredGoodsList({ commit }, params) {
      commit("setIsLoading", true);
      return new Promise((resolve) => {
        YC.adminApi.postFiltredGoodsList(params)
          .then(({ data }) => {
            commit('setGoods', data.data)
            resolve()
          })
          .finally(() => {
            commit("setIsLoading", false);
          });
      })
    },
    setSettingsData(store, data) {
          return new Promise((resolve) => {
              YC.adminApi.postSiteSettings(data)
                  .then(() => {

                      resolve();
                  })
                  .finally(() => {
                      store.commit("setIsLoading", false);
                  });
          });
    },
    getSettings({commit}) {
      return new Promise((resolve, reject) => {
          YC.adminApi.getSiteSettings()
              .then(response => {
                  //console.log("rSettings",response);
                  if(response?.data?.settings)
                  {
                      commit('setSiteSettings', response?.data?.settings);
                  }
                  commit('setIsLoading', false);
                  resolve()
              })
              .finally(() => {
                  commit('setIsLoading', false);
              })
      })
    },
    
    postLoginAsUser({ commit }, userid) {
      return new Promise((resolve) => {
          YC.adminApi.post('admin/users/login-as-user',{user_id:userid})
          .then((response) => {
              console.log(response);
              commit('auth/setToken', response.data.authorization.token,{ root: true })
              localStorage.setItem('token', response.data.authorization.token)
              resolve();
          })
          .finally(() => {
          
          })
      });
    },
    getPromocodeList({ commit, state}) {
      commit("setIsLoading", true)
      return new Promise((resolve) => {
        YC.adminApi.getPromocodeList()
          .then(({ data }) => {
            if(state.promocodeListParams.showedLimit < data.lenght) {
              commit('setPromocodeListParams', { isLimitExceed: true })
            } else {
              commit('setPromocodeListParams', { isLimitExceed: false })
            }
            commit("setPromocodeList", data)
            resolve()
          })
          .finally(() => {
            commit("setIsLoading", false)
          })
      })
    },
    postCreatePromocode({ dispatch, commit }, payload) {
      commit('setIsLoading', true)

      return new Promise((resolve) => {
        YC.adminApi.postCreatePromocode(payload)
          .then(({data}) => {
            dispatch('getPromocodeList')
            resolve(data)
          })
          .catch(() => {
            console.log('Ошибка создания промокода')
          })
          .finally(() => {
            commit('setIsLoading', true)
          })
      })
    },

    postCreateReferralLink({ dispatch, commit, state}, payload) {
      commit('setIsLoading', true)

      const { userEmail } = payload

      return new Promise((resolve, reject) => {
        YC.adminApi.postCheckEmployeeEmail(userEmail)
          .then(({ data }) => {
            commit('setPromocodeEmployeeInfo', { id: data.user_id, isEmployeeEmailExist: true })
            resolve()
          })
          .then(() => {
            if(state.promocodeEmployeeInfo.isEmployeeEmailExist) {
              const { type, value, expired_at} = payload
              return new Promise((resolve) => {
                YC.adminApi.postCreateReferralLink({ user_id: state.promocodeEmployeeInfo.id, type, value, expired_at})
                  .then(({data}) => {
                    commit('setGeneratedReferralPromocode', data.code)
                    dispatch('getPromocodeList')
                    resolve()
                  })
                  .catch(() => {
                    console.log('Ошибка создания реферальной ссылки')
                  })
              })
            }
          })
          .catch(() => {
            console.log('Такая почта сотрудника отсутствует')
            commit('setPromocodeEmployeeInfo', { id: null, isEmployeeEmailExist: false })
            reject()
          })
          .finally(() => {
            commit('setIsLoading', false)
          })
      })
    },

    deactivatePromocode({ dispatch, commit }, promocodeId) {
      commit('setIsLoading', true)

      return new Promise((resolve) => {
        YC.adminApi.postDeactivatePromocode(promocodeId)
          .then(() => {
            dispatch('getPromocodeList')
            resolve()
          })
          .catch(() => {
            console.log('Ошибка деактивирования промокода/реф.ссылки')
          })
          .finally(() => {
            commit('setIsLoading', true)
          })
      })
    },
    checkEmployeeEmail({ commit }, email) {
      commit('setIsLoading', true)

      return new Promise((resolve, reject) => {
        YC.adminApi.postCheckEmployeeEmail(email)
          .then(({ data }) => {
            commit('setPromocodeEmployeeInfo', { id: data.user_id, isEmployeeEmailExist: true })
            resolve()
          })
          .catch(() => {
            console.log('Такая почта сотрудника отсутствует')
            commit('setPromocodeEmployeeInfo', { id: null, isEmployeeEmailExist: false })
            reject()
          })
          .finally(() => {
            commit('setIsLoading', false)
          })
      })
    }
  }
};
