<template>
  <div 
    :class="`adminPromocodesCopyLink ${classModifier}`"
    v-if="!isHidden"
  >
    <div class="adminPromocodesCopyLink__title">
      {{ title }}
    </div>
    <div class="adminPromocodesCopyLink__link">
      <div 
        class="adminPromocodesCopyLink__linkText adminPromocodesCopyLink__linkText_type_referral"
        v-if="typeOfLink === 'referral'"
      >{{ link }}</div>

      <div 
        class="adminPromocodesCopyLink__linkText adminPromocodesCopyLink__linkText_type_promocode"
        v-if="typeOfLink === 'promocode'"
      >{{ link }}</div>

      <SvgUse 
        :width="14"
        :height="16"
        id="copyIconFill"
        @click="copyLink"
      />
    </div>
    <template v-if="typeOfLink === 'referral'">
      <div 
        class="adminPromocodesCopyLink__copyStatusText"
        v-if="isLinkCopied"
      >Реферальная ссылка скопирована!</div>
    </template>

    <template v-if="typeOfLink === 'promocode'">
      <div 
        class="adminPromocodesCopyLink__copyStatusText"
        v-if="isLinkCopied"
      >Промокод скопирован!</div>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: [String, Number],
      default: ''
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    classModifier: {
      type: String,
      default: ''
    },
    typeOfLink: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      isLinkCopied: (state) => state.admin.isPromocodeLinkCopied
    })
  },
  methods: {
    ...mapMutations({
      copyPromocodeLink: 'admin/setIsPromocodeLinkCopied'
    }),
    copyLink() {
      if(!this.isLinkCopied) {
        this.copyPromocodeLink(true)
        navigator.clipboard.writeText(this.link)
        .then(() => {
          this.copyPromocodeLink(true)
        })
        .catch(() => {
          console.log('Произошла ошибка при копировании ссылки')
        })
      } else {
        return
      }
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.adminPromocodesCopyLink {

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 20px;

    @include breakpoint(xl) {
      font-size: 13px;
      line-height: 13px;
    }

    @include breakpoint(md) {
      font-size: 14px;
      line-height: 14px;
    }

    @include breakpoint(sm) {
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 15px;
    }
  }

  &__link {
    height: 50px;
    padding: 15px 10px;
    display: grid;
    grid-template-columns: 1fr 20px;
    border-radius: 15px;
    box-shadow: $box-shadow-input;
    margin-bottom: 5px;

    @include breakpoint(sm) {
      padding: 15px 22px;
    }

    & svg {
      cursor: pointer;
      opacity: 0.5;
      transition: opacity $transition-duration ease;
    }

    & svg:hover {
      opacity: 1;
    }

    &Text {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      color: #0097ec;

      &_type {
        &_referral {
          text-decoration: underline;
          text-align: center;
        }

        &_promocode {
          text-align: left;
        }
      }
      

      @include breakpoint(xl) {
        font-size: 14px;
        line-height: 14px;
      }

      @include breakpoint(md) {
        font-size: 15px;
        line-height: 15px;
      }

      @include breakpoint(sm) {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  &__copyStatusText {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $gray-400;
  }
}
</style>
