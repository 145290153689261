<template>
  <BasePopup>
    <div class="PBSingleProductBidderStartConfirmPopup">
      <div class="PBSingleProductBidderStartConfirmPopup__titlePrimary titlePrimary titlePrimary--small">
        Вы действительно хотите включить Репрайсер?
      </div>

      <div class="PBSingleProductBidderStartConfirmPopup__controls">
        <button class="PBSingleProductBidderStartConfirmPopup__button button button--transparent button--green"
          @click="setBidderStatus"
        >
          Да
        </button>
        <button
          class="PBSingleProductBidderStartConfirmPopup__button button button--transparent button--green"
          @click="closePopup"
        >
          Нет
        </button>
      </div>

      <picture class="PBSingleProductBidderStartConfirmPopup__picture">
        <source type="image/webp"
          srcset="/images/popup/activateBidderPopup-image-2x.webp 2x, /images/popup/activateBidderPopup-image.webp 1x">
        <img src="/images/popup/activateBidderPopup-image.webp" alt="" class="popupRunSearch__secondStepImage">
      </picture>
    </div>
  </BasePopup>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'PBSingleProductBidderStartConfirmPopup',
  methods: {
    ...mapActions({
      openPopup: 'popup/openPopup',
      closePopup: 'popup/closePopup',
      runBidderForProduct: 'priceBidderSingleProduct/runBidder',
      updateBidderData: 'priceBidderSingleProduct/updateBidderData'
    }),
    ...mapMutations({
      setSingleProductBidder: 'priceBidderSingleProduct/setSingleProductBidder',
      setSingleProductBidderAutoPrice: 'priceBidderSingleProduct/setSingleProductBidderAutoPrice',
      setSingleProductBidderHasHistory: 'priceBidderSingleProduct/setSingleProductBidderHasHistory'
    }),
    setBidderStatus(){

      if(this.singleProduct?.bidder_min_price > 0 && this.singleProduct?.bidder_max_price > 0 && this.singleProduct?.keywords.length > 0) {

        if(!this.singleProduct.bidder_has_history) {
          this.closePopup()
          this.openPopup(
            {
              component: 'PBSingleProductFirstRunPopup',
              props: {
                time: 3600,
              }
            }
          )
          this.setSingleProductBidderHasHistory(true)
        } else {
          this.closePopup()
        }

        this.setSingleProductBidder(1)
        this.setSingleProductBidderAutoPrice(1)
        this.updateBidderData()
        this.runBidderForProduct()

      } else {
        this.closePopup()
        this.openPopup(
          {
            component: 'SettingsAlertPopup',
            props: {
              text: 'Для запуска репрайсера необходимо указать ключевые слова, а также минимальную и максимальную цену товара'
            }
          }
        )
      }
    }
  },
  computed: {
    ...mapState({
      singleProduct: state => state.priceBidderSingleProduct.singleProduct
    })
  }
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.PBSingleProductBidderStartConfirmPopup {

  text-align: center;
  padding: 75px 0 30px;
  width: 740px;
  max-width: 100%;

  @include breakpoint(xl) {
    width: 690px;
    padding-top: 82px;
  }
  @include breakpoint(md) {
    width: 340px;
    padding: 210px 35px 40px;
  }
  @include breakpoint(sm) {
    width: 322px;
    padding: 210px 25px 30px;
  }
  @include breakpoint(xs) {
    width: 280px;
    padding: 200px 20px 20px;
  }

  &__picture {
    position: absolute;
    @include verticalCenter();
    left: -43px;
    @include breakpoint(md) {
      top: -42px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__titlePrimary {
    margin-bottom: 25px;
  }

  &__controls {
    display: flex;
    justify-content: center;
  }

  &__button {
    flex: 0 0 120px;
    height: 30px;
    &:first-child {
      margin-right: 20px;
    }
    @include breakpoint(md) {
      flex: 0 0 120px;
      height: 40px;
    }
    @include breakpoint(xs) {
      flex: 0 0 110px;
    }
  }

}

</style>
