<template>
  <div class="sortDropDown" @click.stop v-click-outside="close">
    {{ label }}
    <button
      class="sortDropDown__button"
      @click="dropDownIsOpen = !dropDownIsOpen"
    >
      <SvgUse
        :width="20"
        :height="20"
        id="arrowDownInActive"
        v-if="!dropDownIsOpen"
      />
      <SvgUse :width="20" :height="20" id="arrowDownActive" v-else />
    </button>
    <div class="sortDropDownContent" v-if="dropDownIsOpen">
      <!-- <div class="sortDropDownContent__item">
        <button
          class="sortDropDownContent__itemButton"
          @click="emailContentIsOpen = !emailContentIsOpen"
        >
          По E-mail
        </button>
        <div
          class="sortDropDownContent__itemActionContent"
          v-if="emailContentIsOpen"
        >
          <input
            type="email"
            class="sortDropDownContent__itemActionContent--customInput"
            placeholder="Введите e-mail"
          />
          <button class="sortDropDownContent__itemActionContent--popupButton">
            Ввести несколько
          </button>
        </div>
      </div>
      <div class="sortDropDownContent__item">
        <button
          class="sortDropDownContent__itemButton"
          @click="teleContentIsOpen = !teleContentIsOpen"
        >
          По телефону
        </button>
        <div
          class="sortDropDownContent__itemActionContent"
          v-if="teleContentIsOpen"
        >
          <input
            type="text"
            class="sortDropDownContent__itemActionContent--customInput"
            placeholder="Введите номер"
          />
          <button class="sortDropDownContent__itemActionContent--popupButton">
            Ввести несколько
          </button>
        </div>
      </div>
      <div class="sortDropDownContent__item">
        <button
          class="sortDropDownContent__itemButton"
          @click="sortByCreatedDateIsOpen = !sortByCreatedDateIsOpen"
        >
          По дате создания
        </button>
        <div
          class="sortDropDownContent__itemActionContent"
          v-if="sortByCreatedDateIsOpen"
        >
          <VueDatePicker
            v-model="createdDate"
            inline
            range
            locale="ru"
            :enable-time-picker="false"
            auto-apply
            disable-year-select
          />
        </div>
      </div> -->
      <div class="sortDropDownContent__item">
        <button
          class="sortDropDownContent__itemButton"
          @click="sortByLastLoginedDateIsOpen = !sortByLastLoginedDateIsOpen"
        >
          По дате последнего входа
        </button>
        <div
          class="sortDropDownContent__itemActionContent"
          v-if="sortByLastLoginedDateIsOpen"
        >
          <VueDatePicker
            v-model="lastLoginDate"
            inline
            range
            locale="ru"
            :enable-time-picker="false"
            auto-apply
            disable-year-select
          />
        </div>
      </div>
      <div class="sortDropDownContent__item">
        <button
          class="sortDropDownContent__itemButton"
          @click="roleContentIsOpen = !roleContentIsOpen"
        >
          По ролям
        </button>
        <div
          class="sortDropDownContent__itemActionContent"
          v-if="roleContentIsOpen"
        >
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--roleBtn"
            v-if="!clientBtnIsOpen"
            @click="clientBtnIsOpen = !clientBtnIsOpen"
          >
            Клиент
          </button>
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--selectedRoleBtn"
            v-else-if="clientBtnIsOpen"
            @click="clientBtnIsOpen = !clientBtnIsOpen"
          >
            Клиент
          </button>
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--roleBtn"
            v-if="!accountBtnIsOpen"
            @click="accountBtnIsOpen = !accountBtnIsOpen"
          >
            Аккаунт
          </button>
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--selectedRoleBtn"
            v-else-if="accountBtnIsOpen"
            @click="accountBtnIsOpen = !accountBtnIsOpen"
          >
            Аккаунт
          </button>
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--roleBtn"
            v-if="!adminBtnIsOpen"
            @click="adminBtnIsOpen = !adminBtnIsOpen"
          >
            Администратор
          </button>
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--selectedRoleBtn"
            v-else-if="adminBtnIsOpen"
            @click="adminBtnIsOpen = !adminBtnIsOpen"
          >
            Администратор
          </button>
        </div>
      </div>
      <div class="sortDropDownContent__item">
        <button
          class="sortDropDownContent__itemButton"
          @click="stateContentIsOpen = !stateContentIsOpen"
        >
          По статусу
        </button>
        <div
          class="sortDropDownContent__itemActionContent"
          v-if="stateContentIsOpen"
        >
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--stateBtn"
            v-if="!activeBtnIsOpen"
            @click="activeBtnIsOpen = !activeBtnIsOpen"
          >
            Активно
          </button>
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--primary"
            v-else-if="activeBtnIsOpen"
            @click="activeBtnIsOpen = !activeBtnIsOpen"
          >
            Активно
          </button>
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--stateBtn"
            v-if="!inactiveBtnIsOpen"
            @click="inactiveBtnIsOpen = !inactiveBtnIsOpen"
          >
            Неактивно
          </button>
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--block"
            v-else-if="inactiveBtnIsOpen"
            @click="inactiveBtnIsOpen = !inactiveBtnIsOpen"
          >
            Неактивно
          </button>
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--stateBtn"
            v-if="!pendingBtnIsOpen"
            @click="pendingBtnIsOpen = !pendingBtnIsOpen"
          >
            Пингануть
          </button>
          <button
            class="sortDropDownContent__itemActionContent--btn sortDropDownContent__itemActionContent--warning"
            v-else-if="pendingBtnIsOpen"
            @click="pendingBtnIsOpen = !pendingBtnIsOpen"
          >
            Пингануть
          </button>
        </div>
      </div>
      <!-- <div class="sortDropDownContent__item">
        <button
          class="sortDropDownContent__itemButton"
          @click="managerContentIsOpen = !managerContentIsOpen"
        >
          По аккаунт менеджеру
        </button>
        <div
          class="sortDropDownContent__itemActionContent"
          v-if="managerContentIsOpen"
        >
          <input
            type="text"
            class="sortDropDownContent__itemActionContent--customInput"
            placeholder="Аккаунт менеджер"
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "SortDropdown",
  components: { VueDatePicker },
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dropDownIsOpen: false,
      sortByLastLoginedDateIsOpen: false,
      roleContentIsOpen: false,
      stateContentIsOpen: false,
      clientBtnIsOpen: false,
      accountBtnIsOpen: false,
      adminBtnIsOpen: false,
      activeBtnIsOpen: false,
      inactiveBtnIsOpen: false,
      pendingBtnIsOpen: false,
      lastLoginDate: null,
    };
  },
  methods: {
    close() {
      this.dropDownIsOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";

.sortDropDown {
  position: relative;
  font-size: 15px;
  line-height: 12px;
  display: flex;
  align-items: center;
  gap: 10px;

  @include breakpoint(xl) {
    font-size: 14px;
    line-height: 11.2px;
  }

  @include breakpoint(md) {
    font-size: 15px;
    line-height: 12px;
  }

  @include breakpoint(sm) {
    text-align: center;
    text-overflow: hidden;
    font-size: 12px;
    line-height: 9.6px;
  }

  @include mediaMaxWidth(710) {
    flex-flow: column;
  }

  &Content {
    position: absolute;
    top: 25px;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $gray-300;
    display: flex;
    flex-flow: column;
    gap: 20px;
    z-index: 9999;
    background-color: $white;

    &__item {
      display: flex;
      flex-flow: column;
      gap: 5px;

      &Button {
        font-size: 15px;
        line-height: 15px;
        text-align: start;
        white-space: nowrap;
      }

      &ActionContent {
        display: flex;
        flex-flow: column;
        gap: 5px;
        transition: ease-in-out 4s;
        transition-duration: 4s;

        // &--customInput {
        //   min-width: 240px;
        //   border-radius: 10px;
        //   border: none;
        //   padding: 5px 20px;
        //   text-align: center;
        //   background-color: #f6f6f6;
        //   color: #b3b3b3;
        //   outline: none;
        //   font-size: 15px;
        //   line-height: 18.29px;
        //   font-weight: 500;
        //   font-family: Montserrat;
        //   &:focus {
        //     background-color: white;
        //     color: #000000;
        //     box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.05);
        //   }
        // }

        // &--popupButton {
        //   font-size: 12px;
        //   line-height: 12px;
        //   font-weight: 500;
        //   font-family: Montserrat;
        //   padding: 0;
        // }

        &--btn {
          width: fit-content;
          padding: 5px 20px;
          border-radius: 10px;
          font-size: 15px;
          line-height: 18.29px;
          text-align: center;
          font-weight: 500;
        }

        &--roleBtn {
          background-color: #f6f6f6;
          color: #b3b3b3;
        }

        &--selectedRoleBtn {
          background-color: white;
          color: black;
          box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.05);
        }

        &--stateBtn {
          background-color: #f6f6f6;
          color: #b3b3b3;
        }

        &--primary {
          background-color: #00d488;
          color: white;
        }

        &--block {
          background-color: #ff5959;
          color: white;
        }

        &--warning {
          background-color: #ff9900;
          color: white;
        }
      }
    }
  }
}
</style>
