<template>
  <div class="underDevelopment">
    <div class="underDevelopment__layout">

      <Header      
        class="underDevelopment__header"  
        v-if="$store.state.isMobile"
      />

      <ComingSoon
        class="underDevelopment__comingSoon"
      />

    </div>

    <component
      v-for = "item in modalQueue"
      :key="item"
      :is = "item.component"
      v-bind = "item.props"
    />

  </div>
</template>

<script>
import ComingSoon from '@/components/ComingSoon/ComingSoon'
import Header from '@/components/Header/Header'
import SidebarMobile from '@/components/Sidebar/SidebarMobile'
import {mapState} from 'vuex'

export default {
  components: {
    ComingSoon,
    Header,
    SidebarMobile
  },
  computed: {
    ...mapState({
      modalQueue: state => state.popup.modalQueue,
    })
  }  
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.underDevelopment {

  &__layout {
    min-height: 100vh;
    display: flex;
    justify-content: center;
	  align-items: center;
    @include breakpoint(md) {
      display: block;
      background-color: $gray-100;
    }
  }

  &__header {
    position: sticky;
    top: 0;
  }

}

</style>