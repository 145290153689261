<template>
  <div class="comingSoon">

    <BaseButton
      class="comingSoon__button"    
			v-if="!$store.state.isMobile" 
			@click="$router.go(-1)"
    >
      <SvgUse 
        :width="20"
        :height="20"
        id="arrowLeft" 
      />
      Назад
    </BaseButton>

    <div class="comingSoon__text">
      <h1 class="comingSoon__title">{{$route.meta.title}}</h1>
      <h2 class="comingSoon__subtitle">Coming soon</h2>
      <img :src="`/images/underDev/${$route.meta.imageName}.png`" width="294" height="328" alt="" class="comingSoon__img">
    </div>

  </div>
</template>

<script>

</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.comingSoon {
	margin: 0 auto;
	width: fit-content;
	position: relative;
	padding: 200px 20px;
	@include breakpoint(md) {
		margin-top: -100px;
		min-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	@include breakpoint(sm) {
		margin-top: -80px;
	}
	@include breakpoint(xs) {
		margin-top: -65px;
	}

  &__button {
    position: absolute;
    left: 20px;
    top: 20px;
    border-radius: 0;
    width: max-content;
    svg {
      margin-right: 5px;
    }
  }

  &__text {
    font-family: $font-secondary;
		@include breakpoint(md) {
			max-width: 580px;
		}
  }

	&__img {
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		width: auto;
		height: auto;
		max-width: 290px;
		max-height: 330px;
		filter: drop-shadow(29px 38px 50px rgba(0, 0, 0, 0.1));
		@media only screen and (max-width: 1440px) {
			max-width: 300px;
			max-height: 300px;
		}

		@media only screen and (max-width: 1200px) {
			transform: none;
			position: relative;
			top: auto;
			left: auto;
			margin: -45px auto 0 auto;
			max-width: 280px;
			max-height: 280px;
			filter: none;
		}

		@media only screen and (max-width: 575px) {
			max-width: 190px;
			max-height: 190px;
			margin-top: -20px;
			
		}

		@media only screen and (max-width: 413px) {
			max-width: 140px;
			max-height: 140px;
		}

		&--rotated {
			transform: translate(-50%, -50%) rotate(-15deg);
			@media only screen and (max-width: 1440px) {
				max-width: 260px;
			}

			@media only screen and (max-width: 1200px) {
				transform: rotate(-15deg);
			}

			@media only screen and (max-width: 575px) {
				max-height: 220px;
				max-width: 220px;
			}

			@media only screen and (max-width: 413px) {
				max-height: 170px;
				max-width: 170px;
			}
		}
	}

	&__title,
	&__subtitle {
		background: linear-gradient(358.08deg, $green-200 5.89%, $dark-green-300 80.04%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	&__subtitle {
		white-space: nowrap;
    font-size: 128px;
    font-weight: 900;
		text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
		line-height: 1;
		text-transform: uppercase;

		@media only screen and (max-width: 1440px) {
			font-size: 96px;
		}

		@media only screen and (max-width: 1200px) {
			white-space: normal;
			text-align: center;
		}

		@media only screen and (max-width: 575px) {
			font-size: 64px;
		}

		@media only screen and (max-width: 413px) {
			font-size: 48px;
			line-height: 0.8;
		}
	}

	&__title {
    font-size: 24px;
    font-weight: 600;
		line-height: 1;
		@media only screen and (max-width: 1440px) {
			font-size: 20px;
		}

		@media only screen and (max-width: 1200px) {
			margin-bottom: 50px;
			font-size: 24px;
		}

		@media only screen and (max-width: 575px) {
			margin-bottom: 30px;
		}

		@media only screen and (max-width: 413px) {
			font-size: 16px;
			margin-bottom: 20px;
		}
	}
}

</style>