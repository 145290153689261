<template>

    <div class="camp-table-row camp-row camp-row-nowrap">
        <div class="camp-table-cell camp-table-cell__name camp-table-cell-sticky" style="width: 520px;  left: 90px">

            <div class="camp-table-left-indicator" style="background-color: #0097EC;"></div>

            <div class="camp-table-stub"></div>



            <div class="camp-table-cell-checkbox">
                <label class="camp-form-checkbox__label">
                    <input type="checkbox" name="">
                    <span v-show="false" class="camp-form-checkbox" aria-label="Отметить"><span class="camp-form-checkbox__ic"></span></span>
                </label>

                <button type="button" aria-label="Удалить строку" class="camp-btn-delete camp-table-subgroup-delete"
                        @click="deleteRow"
                >
                <span class="camp-btn-delete__ic camp-tooltip" data-tippy-content="Удалить строку">
                    <!-- trash.svg -->
                    <svg viewBox="0 0 19 21" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.70801 5.23788H15.708M7.45801 2.59082H11.958M8.20801 14.0614V8.76729M11.208 14.0614V8.76729M12.333 17.5908H7.08301C6.25458 17.5908 5.58301 16.8007 5.58301 15.8261L5.24056 6.15697C5.2228 5.65568 5.56344 5.23788 5.98991 5.23788H13.4261C13.8526 5.23788 14.1932 5.65568 14.1755 6.15697L13.833 15.8261C13.833 16.8007 13.1614 17.5908 12.333 17.5908Z"></path>
                    </svg>

                </span>
                </button>
            </div>

            <div class="camp-table-cell-inner">

                <div class="camp-table-cell-title camp-tooltip" data-tippy-content="Полный текст">
                    <div class="camp-table-cell-title__edit camp__is-visible">

                        <TextInput
                            :group_id="row_id"
                            :item_id="item_id"
                            :subgroupInput="true"
                            :value="rowData?.key ?? ''"
                        />
                    </div>


                </div>
            </div>
        </div>
        <!-- //tcell -->

        <template  v-for="n in 15" :key="n">

        <div class="camp-table-cell" style="width: 120px;">
            <div class="camp-table-cell-inner">
                <div class="camp-table-cell-title">
                    <p>{{getItemData(n,0)}}</p>
                </div>
            </div>
        </div>
        <!-- //tcell -->

        <div class="camp-table-cell" style="width: 120px;">
            <div class="camp-table-cell-inner">
                <div class="camp-table-cell-title">
                    <p>{{getItemData(n,1)}}</p>
                </div>
            </div>
        </div>
        <!-- //tcell -->

        </template>


    </div>
    <!-- //row -->

</template>

<script>
import TextInput from "@/components/Crm/UI/Inputs/TextInput.vue";
import {mapActions, mapState} from "vuex";

export default {
    name: "SubgroupRow",
    components: {TextInput},
    props:{
        rowData: null,
        row_id:null,
        item_id:null,
    },
    computed: {
        ...mapState({
            subGroup: state => state.crm.subgroups,
        }),
    },
    methods:{
        ...mapActions({
           delSubgroupItem:'crm/delSubgroupItem',
        }),

        getItemData(index,key){

            if( !this.rowData?.data?.[index]?.[key]){
                return 0;
            }
            return this.rowData.data?.[index]?.[key] ?? 0;
        },
        deleteRow(){
            if(confirm("Удалить элемент ?"+ this.item_id)) {
                // this.$store.commit('crm/delSubgroupKey', {
                //     item_id:this.item_id,
                //     subgroup_id:this.row_id,
                // });
                this.delSubgroupItem({
                    item_id:this.item_id,
                    subgroup_id:this.row_id,
                })
            }
        }

    }
}
</script>

<style scoped lang="scss">
    .camp-btn-delete.camp-table-subgroup-delete  {
        right:0;
    }
</style>
