<template>

  <div :class="['switcher', classModificator]">
    <span
      v-if="name"
    >
      {{name}}
    </span>
    <div
      :class="['switcher__button', {'switcher__button--gray' : transparent}, {'switcher__button--checked' : active}]"
    >
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSwitcher',
  props: {
    classModificator: {
      type: String,
      default: ''
    },
    name: {
      type: String, 
      default: ''
    },
    transparent: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: false
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins';
@import 'src/scss/base/project/var';

.switcher {

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    flex: 1;
    padding-right: 30px;
    font-size: 15px;
    line-height: 15px;
    @include breakpoint(xl) {
      font-size: 13px;
      line-height: 13px;
    }
    @include breakpoint(md) {
      font-size: 15px;
      line-height: 15px;
    }
  }

  &--productSetting {
    span {
      font-size: 14px;
      line-height: 14px;
      padding-right: 10px;
    }
  }

  &--globalSetting {
    span {
      @include breakpoint(md) {
        font-size: 14px;
        line-height: 11px;
      }
    }
  }

  &__button {
    width: 60px;
    height: 30px;
    border-radius: 31px;
    box-shadow: 0px 2px 4px 0px rgba($black, 0.15) inset;
    background-color: $dark-green-400;
    position: relative;
    cursor: pointer;
    transition: background-color $transition-duration ease;
    &::before {
      content: '';
      position: absolute;
      width: 26px;
      height: 26px;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
      border-radius: 100%;
      background-color: $white;
      transition: left $transition-duration ease;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
      @include breakpoint(xl) {
        width: 21px;
        height: 21px;
      }
      @include breakpoint(md) {
        width: 26px;
        height: 26px;
      }
    }
    @include breakpoint(xl) {
      width: 50px;
      height: 25px;
    }
    @include breakpoint(md) {
      width: 60px;
      height: 30px;
    }
    &--gray {
      background-color: transparent;
    }
    &--checked {
      background-color: $green-100;
      &::before {
        left: calc(100% - 28px);
        @include breakpoint(xl) {
          left: calc(100% - 23px);
        }
        @include breakpoint(md) {
          left: calc(100% - 28px);
        }
      }
    }
  }
  &--productSetting &__button {
    background-color: $blue;
  }

  &--userStore &__button {
    background-color: rgba($black, .1);
  }

  &--productSetting &__button, &--userStore &__button {
    width: 40px;
    height: 20px;
    &::before {
      width: 16px;
      height: 16px;
    }
    &--checked {
      background-color: $green-100;
      &::before {
        left: calc(100% - 19px);
      }
    }
  }

}
</style>