<template>

    <div class="camp-table-footer">
        <div class="camp-table-row camp-row camp-row-nowrap">

            <TableCellFooter
                v-for="(cellParams, index) in tableCellsParams"
                :key="index"
                :name="cellParams.name"
                :width="cellParams.width"
                :type="cellParams.type"
                :field="cellParams.field"
                :title="cellParams.title"
                :group_id="group_id"
                :value="getCellFieldValue(cellParams)"
                :withCheckbox="cellParams.withCheckbox"
                :isSticky="cellParams.isSticky"
                :color="color"
            />

        </div>

    </div>


</template>

<script>
    import TableCellFooter from "./TableCellFooter";

    import {calcResult, findObjectByField,calculateSumBySelector} from '@/components/Crm/helpers/dataHelpers';


    export default {
        name: "TableRowFooter",
        components: {TableCellFooter},
        props: {
             tableCellsParams: {
                defalut:null,
                required: true,
            },
            tableData:null,
            group_id:0,
            desk_data:null,
            color:String,
        },
        data(){
            return{
                curTableData:this.tableData,
                totalResults:null,
            }
        },
        methods:{
        /*
        * Вычисляем итоговое поле
         */
            getCellFieldValue(f)  {

                if(f.nototal)
                {
                    return '';
                }
                const field=f.field;

                const objbyfield = findObjectByField(this.tableCellsParams, field);
                const tableData=this.tableData;


                if(objbyfield.formula)
                {

                    const r=calculateSumBySelector(".f_"+field+"_"+this.group_id)
                    console.log(".f_"+field+"_"+this.group_id, r);
                    return r;// ".f_"+field+"_";
                }
                else {

                    return calcResult(tableData, objbyfield, field);
                }

                //return this.tableData? this.tableData.length:0;
            },
            checkIsVisibleCell(cellParam){
                if(cellParam?.calc && cellParam?.calc==='nototal')
                {
                    return false;
                }
                else if(cellParam?.noShow){
                    return false;
                }
                else {
                    return true;
                }
            }


        },
        computed:{
            getTableData() {
                return this.tableData;
            },

        },
        watch: {
            curTableData(newValue, oldValue) {
                //this.editedGroup=newValue;
                console.log("watchFooterData:",newValue);

            },
        },
        mounted() {



            //var field='price';

            //const targetGroup=getGroupById( this.tableData,this.group_id);
            // console.log("Footer groupData=",this.group_id, targetGroup,this.tableData);


            // let total=0;
            // if(this.desk_data ) {
            //     this.desk_data.forEach((itm, index) => {
            //
            //         console.log("itm",itm.item, itm.item[field]);
            //         if (itm && itm.item[field]) {
            //             total += parseInt(itm.item[field]);
            //         }
            //     });
            //     console.log("total Price=", total);
            // }
        }
    }
</script>

<style scoped>

</style>
