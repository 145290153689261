<template>
  <div class="fieldFilled">
    {{data}}
  </div>
</template>

<script>
export default {
  name: 'BaseFieldFilled',
  props: {
    data: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.fieldFilled {
  height: 60px;
  background-color: $white;
  border-radius: 15px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  @include breakpoint(xs) {
    height: 40px;
  }
}
</style>