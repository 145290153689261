export default {
  namespaced: true,

  state: () => ({
    isLoading: true,
    columnTitles: [
      {
        label: 'ID пользователя',
        sortKey: 'user_id',
        isSorted: true
      },
      {
        label: 'Дата пополнения',
        sortKey: 'deposit_date',
        isSorted: true
      },
      {
        label: 'Сумма пополнения',
        sortKey: 'deposit_amount',
        isSorted: true
      },
      {
        label: 'Общий баланс клиента',
        sortKey: 'current_balance',
        isSorted: true
      },
      {
        label: 'Статус',
        sortKey: 'status',
        isSorted: true
      },
      {
        label: 'Менеджер',
        sortKey: 'manager',
        isSorted: true
      }
    ],
    financeParams: {
      search_by_period: {
        start_period: null,
        end_period: null
      },
      filter_by: {
        status: null
      },
      search_by: {
        id: null,
        deposit_amount: null
      }
    },
    financeList: []
  }),
  
  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload
    },
    setFinanceList(state, payload) {
      state.financeList = payload
    },
    setSortFinanceList(state, payload) {
      state.financeList = payload
    },
    setFinanceParams(state, payload) {
      state.financeParams = payload
    }
  },

  getters: {},

  actions: {
    sortFinanceList({ commit }, data) {
      const financeList = data.financeList;
      const sort_item = data.sort_item;
      const sort_direction = data.sort_direction;

      financeList.forEach(list => {
        list.user_id =  !list.user_id ? '' : list.user_id;
        list.deposit_date = !list.deposit_date ? '' : list.deposit_date;
        list.deposit_amount = !list.deposit_amount ? '' : list.deposit_amount;
        list.current_balance = !list.current_balance ? '' : list.current_balance;
        list.status = !list.status ? '' : list.status;
        list.manager = !list.manager ? '' : list.manager;
      });

      financeList.sort(function(a, b) {
        if(sort_item === 'user_id') {
          if (sort_direction) {
            return b.user_id - a.user_id;
          } else {
            return a.user_id - b.user_id;
          }
        } else if (sort_item === 'deposit_date') {
          if (sort_direction) {
            return b.deposit_date.localeCompare(a.deposit_date);
          } else {
            return a.deposit_date.localeCompare(b.deposit_date);
          }
        } else if (sort_item === 'deposit_amount') {
          if (sort_direction) {
            return b.deposit_amount - a.deposit_amount;
          } else {
            return a.deposit_amount - b.deposit_amount;
          }
        } else if(sort_item === 'current_balance') {
          if (sort_direction) {
            return b.current_balance - a.current_balance;
          } else {
            return a.current_balance - b.current_balance;
          }
        } else if(sort_item === 'status') {
          if (sort_direction) {
            return b.status.localeCompare(a.status);
          } else {
            return a.status.localeCompare(b.status);
          }
        } else if(sort_item === 'manager') {
          if (sort_direction === true) {
            return b.manager.localeCompare(a.manager);
          } else {
            return a.manager.localeCompare(b.manager);
          }
        }
      });
      commit('setSortFinanceList', financeList);
    },
    getFinanceList({commit}, payload) {
      commit('setIsLoading', true)
      return new Promise((resolve, reject) => {
        YC.adminFinanceApi.getFinanceList(payload)
          .then(response => {
            commit('setFinanceList', response.data)
            commit('setIsLoading', false)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
  }
}
