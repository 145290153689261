<template>
  <BasePopup 
    :classModifier="'tickets-new'"
  >
    <div class="ticketNewPopup">
      <div class="titlePopup titlePopup--large">Новый тикет</div>
      <BaseMultiSelect       
        :label="'Категория тикета'"   
        class="ticketNewPopup__multiSelect"
        :options="options"
        v-model:modelValue="newTicketData.category"
      />
      <FormInput        
        :placeholder="'Тема тикета'"
        class="ticketNewPopup__formInput"
        v-model:modelValue="newTicketData.title"
      />
      <FormTextarea        
        :placeholder="'Введите сообщение'"
        class="ticketNewPopup__formTextarea"
        v-model:modelValue="newTicketData.description"
      />
      <BaseButton
        class="ticketNewPopup__button button--primary"
        @click="createUserTicket"
      >
        Отправить
      </BaseButton>
    </div>
  </BasePopup>
</template>

<script>
import FormInput from '@/components/Form/FormInput'
import FormTextarea from '@/components/Form/FormTextarea'
import { mapActions } from 'vuex'

export default {
  name: 'TicketsNewPopup',
  components: {
    FormInput,
    FormTextarea
  },
  data() {
    return {
      options: [
        {name: 'Финансовые вопросы', subname: '', value: 'Финансовые вопросы'},
        {name: 'Технические вопросы', subname: '', value: 'Технические вопросы'},
        {name: 'Кабинет CAMP', subname: '', value: 'Кабинет CAMP'},
        {name: 'Другое', subname: '', value: 'Другое'}
      ],
      newTicketData: {
        title: '',
        description: '',
        category: ''
      }
    }
  },
  methods: {
    ...mapActions({
      createNewTicket: 'userTickets/createNewTicket',
      closePopup: 'popup/closePopup'
    }),
    createUserTicket() {
      this.createNewTicket(this.newTicketData)
      this.closePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.ticketNewPopup {
  padding: 50px;
  width: 600px;
  max-width: 100%;
  @include breakpoint(sm) {
    width: 380px;
    padding: 40px 25px;
  }
  @include breakpoint(xs) {
    padding: 30px 15px;
    width: 310px;
  }

  &__multiSelect, &__formInput {
    margin-bottom: 15px;
  }

  &__formTextarea {
    margin-bottom: 20px;
  }

  &__button {
    height: 50px;
    max-width: 270px;
    margin: 0 auto;
    @include breakpoint(xs) {
      height: 40px;
      max-width: 200px;
    }
  }

}

</style>