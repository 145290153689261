<template>
  <BasePopup>

    <div class="settingsAlert">
      <div class="settingsAlert__text">{{text}}</div>
    </div>

  </BasePopup>
</template>

<script>
export default {
  name: 'SettingsAlertPopup',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.settingsAlert {
  width: 600px;
  max-width: 100%;
  padding: 70px 115px;
  @include breakpoint(xl) {
    padding: 60px 110px;
    width: 580px;
  }
  @include breakpoint(md) {
    padding: 70px 115px;
    width: 600px;
  }
  &__text {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    @include breakpoint(xl) {
      font-size: 18px;
      line-height: 18px;
    }
    @include breakpoint(md) {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

</style>