<template>
  <div class="inputEdit">
    <label
      :class="['inputEdit__label', labelClass]"
    >
      {{label}}
    </label>
    <input
      :type="type"
      :class="['inputEdit__control', controlClass]"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
  </div>
</template>

<script>
export default {
  name: 'BaseInputEdit',
  data() {
    return {

    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    controlClass: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins';
@import 'src/scss/base/project/var';

.inputEdit {

  &__label {
    font-size: 15px;
    line-height: 15px;
    display: block;
    @include breakpoint(xl) {
      font-size: 13px;
      line-height: 13px;
    }
    @include breakpoint(md) {
      font-size: 14px;
      line-height: 14px;
    }
    &--PBBasicProductEditPopup {
      padding-right: 30px;
      @include mediaMaxWidth(375) {
        padding-right: 10px;
      }
    }
    &--productSetting {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 12px;
    }
  }

  &__control {
    text-align: center;
    width: 100%;
    display: block;
    border: none;
    margin: 0;
    font-size: 15px;
    color: rgba($white, 0.30);
    font-weight: 500;
    font-family: $font-primary;
    border-radius: 20px;
    background: $blue;
    box-shadow: 0px 2px 4px 0px rgba($black, 0.20) inset;
    height: 30px;
    line-height: 30px;
    @include breakpoint(xl) {
      height: 25px;
      line-height: 25px;
      font-size: 14px;
      line-height: 14px;
    }
    @include breakpoint(md) {
      height: 30px;
      line-height: 30px;
    }
    &--productSetting {
      height: 34px;
      border-radius: 10px;
    }
    &:focus {
      color: $white;
    }
    &--popupSidebarProduct {
      @include breakpoint(md) {
        height: 30px;
        line-height: 30px;
      }
    }
    &--gray {
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05) inset;
      background-color: $gray-100;
      border-radius: 15px;
      color: $gray-400;
      &:focus {
        color: $black;
      }
    }
    &--PBBasicProductEditPopup {
      flex: 0 0 179px;
      @include breakpoint(xl) {
        flex: 0 0 157px;
      }
      @include breakpoint(md) {
        flex: 0 0 140px;
      }
    }
  }

}
</style>
