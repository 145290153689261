<template>
  <BasePopup>
    <div class="topUpAlertPopup">
      <div class="topUpAlertPopup__content">
        <div class="topUpAlertPopup__titlePopup titlePopup">
          Пополните баланс, чтобы запустить репрайсер в работу
        </div>
        <BaseButton
          class="topUpAlertPopup__button button button--primary"
          @click="$router.push('/account/balance')"
        >
          Пополнить баланс
        </BaseButton>
      </div>
      <picture class="topUpAlertPopup__picture">
        <source type="image/webp" media="(max-width: 576px)" srcset="/images/popup/topUpAlertPopup-image-sm-2x.webp 2x, /images/popup/topUpAlertPopup-image-sm.webp 1x">
        <img src="/images/popup/topUpAlertPopup-image-xl.webp" alt="" class="topUpAlertPopup__image">
      </picture>
    </div>
  </BasePopup>
</template>

<script>

export default {
  name: 'TopUpAlertPopup',
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.topUpAlertPopup {
  width: 550px;
  max-width: 100%;
  padding: 30px;
  position: relative;
  background-image: radial-gradient(71.07% 100% at 80.58% 100%, rgba(252, 169, 44, 0.24) 0%, rgba(217, 217, 217, 0) 100%);
  @include breakpoint(sm) {
    max-width: 380px;
    padding: 136px 20px 20px;
  }
  @include breakpoint(xs) {
    max-width: 310px;
    padding-top: 126px;
  }

  &__content {
    max-width: 220px;
    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
  
  &__titlePopup {
    font-weight: 500;
    text-align: left;
    font-family: $font-primary;
    margin-bottom: 20px;
  }

  &__picture {
    position: absolute;
    bottom: 0;
    right: 40px;
    @include breakpoint(sm) {
      bottom: 124px;
      right: 50%;
      transform: translateX(50%);
    }
    @include breakpoint(xs) {
      bottom: 134px;
    }
  }

  &__image {
    @include breakpoint(sm) {
      max-width: none;
    }
  }

  &__button {
    max-width: 178px;
    height: 34px;
    border-radius: 10px;
    @include breakpoint(sm) {
      max-width: 100%;
    }
  }

}

</style>