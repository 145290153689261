<template>
  <BasePopup>

    <div class="PBSingleProductLeaderSearchResultPopup">

      <div class="PBSingleProductLeaderSearchResultPopup__row">
        <div class="PBSingleProductLeaderSearchResultPopup__rowTitlePrimary titlePrimary titlePrimary--small">Лидер конкурентов
        </div>
        <div class="PBSingleProductLeaderSearchResultPopup__rowContent">
          <div class="PBSingleProductLeaderSearchResultPopup__rowItem">Артикул: <br>
              <a  target="_blank" :href="getLinkBySku(sku)">{{sku}}</a>
          </div>
          <div class="PBSingleProductLeaderSearchResultPopup__rowItem">Цена до скидки: <br> {{maxPrice}} ₽</div>
          <div class="PBSingleProductLeaderSearchResultPopup__rowItem">Цена после скидки: <br> {{minPrice}} ₽</div>
        </div>
      </div>

      <div class="PBSingleProductLeaderSearchResultPopup__row">
        <div class="PBSingleProductLeaderSearchResultPopup__rowTitlePrimary titlePrimary titlePrimary--small">Новая цена успешно
          установлена
        </div>
        <div class="PBSingleProductLeaderSearchResultPopup__rowContent">
          <div class="PBSingleProductLeaderSearchResultPopup__rowItem">Цена товара до скидки: <br> {{productMaxPrice}} ₽</div>
          <div class="PBSingleProductLeaderSearchResultPopup__rowItem">Скидка: <br> {{productDiscount}}%</div>
          <div class="PBSingleProductLeaderSearchResultPopup__rowItem">Цена после скидки: <br> {{productMinPrice}} ₽</div>
        </div>
      </div>

      <BaseButton
        @click="closePopup"
        class="PBSingleProductLeaderSearchResultPopup__button button button--transparent button--bs"
      >
        Отлично!
      </BaseButton>

    </div>

  </BasePopup>
</template>

<script>

import product from '../../../../components/Crm/Desks/Product.vue'
import {mapActions} from 'vuex'

export default {
  name: 'PBSingleProductLeaderSearchResultPopup',
    computed: {
        product () {
            return product
        }
    },
    methods:{
      ...mapActions({
        closePopup: 'popup/closePopup'
      }),
        getLinkBySku(sku)
        {
            return 'https://www.wildberries.ru/catalog/'+sku+'/detail.aspx';
        },
    },
    props: {
        sku: {
            type: [String,Number],
            required: true
        },
        minPrice: {
            type: [String,Number],
            required: true
        },
        maxPrice: {
            type: [String,Number],
            required: true
        },
        discount: {
            type: [String,Number],
            required: true
        },
        productMinPrice: {
            type: [String,Number],
            required: true
        },
        productMaxPrice: {
            type: [String,Number],
            required: true
        },
        productDiscount: {
            type: [String,Number],
            required: true
        },
        leaderFound:true,
    },

}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.PBSingleProductLeaderSearchResultPopup {

  max-width: 100%;
  width: 900px;
  border-radius: 20px;
  background-image: linear-gradient(297deg, #00D1C1 -28%, #00D488 74.86%);
  padding-bottom: 20px;
  @include breakpoint(xl) {
    width: 808px;
  }
  @include breakpoint(md) {
    width: 340px;
  }
  @include breakpoint(sm) {
    width: 322px;
  }
  @include breakpoint(sm) {
    width: 280px;
  }

  &__row {
    display: flex;
    align-items: center;
    &:first-of-type {
      background-color: $white;
      border-radius: 20px;
      box-shadow: 0px 5px 10px 0px rgba($black, 0.10);
      padding: 55px 0;
      @include breakpoint(xl) {
        padding: 47px 0;
      }
    }
    &:first-of-type &Content {
      padding-left: 74px;
      @include breakpoint(xl) {
        padding-left: 60px;
      }
      @include breakpoint(md) {
        padding: 0;
      }
    }
    &:first-of-type {
      position: relative;
      &::before {
        content: '';
        @include horizontalCenter();
        width: 54px;
        height: 54px;
        bottom: -27px;
        background-image: url("data:image/svg+xml,%3Csvg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='27' cy='27' r='26' fill='white' stroke='%2300D488' stroke-width='2'/%3E%3Cpath d='M28.5 13C28.5 12.1716 27.8284 11.5 27 11.5C26.1716 11.5 25.5 12.1716 25.5 13L28.5 13ZM25.9393 42.0607C26.5251 42.6464 27.4749 42.6464 28.0607 42.0607L37.6066 32.5147C38.1924 31.9289 38.1924 30.9792 37.6066 30.3934C37.0208 29.8076 36.0711 29.8076 35.4853 30.3934L27 38.8787L18.5147 30.3934C17.9289 29.8076 16.9792 29.8076 16.3934 30.3934C15.8076 30.9792 15.8076 31.9289 16.3934 32.5147L25.9393 42.0607ZM25.5 13L25.5 41L28.5 41L28.5 13L25.5 13Z' fill='%2300D488'/%3E%3C/svg%3E%0A");
      }
    }
    &:last-of-type {
      padding: 44px 0 46px;
      color: $white;
      @include breakpoint(xl) {
        padding: 38px 0;
      }
    }
    &:last-of-type &TitlePrimary {
      &::before {
        background-color: rgba($white, .3);
      }
    }
    @include breakpoint(md) {
      flex-direction: column;
      text-align: center;
    }
    &TitlePrimary {
      flex: 0 0 246px;
      padding: 0 30px 0 76px;
      position: relative;
      @include breakpoint(xl) {
        padding: 0 18px 0 59px;
        flex: 0 0 217px;
      }
      @include breakpoint(md) {
        flex: 0 0 100%;
        padding: 0;
        margin-bottom: 20px;
      }
      &::before {
        content: '';
        @include verticalCenter();
        width: 2px;
        height: 60px;
        background-color: $gray-60;
        right: 0;
        @include breakpoint(md) {
          display: none;
        }
      }
    }
    &Content {
      display: flex;
      font-size: 15px;
      padding-right: 74px;
      padding-left: 30px;
      flex: 1;
      justify-content: space-between;
      @include breakpoint(xl) {
        padding-left: 13px;
        font-size: 13px;
        padding-right: 47px;
      }
      @include breakpoint(md) {
        flex-direction: column;
        text-align: center;
        padding: 0;
        font-size: 15px;
      }
      @include breakpoint(xs) {
        font-size: 14px;
      }
    }
    &Item {
      text-align: center;
      @include breakpoint(md) {
        br {
          display: none;
        }
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__button {
    border-color: transparent;
    background-color: $white;
    width: 158px;
    height: 33px;
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
  }

}

</style>
