<template>
    <!-- Collapsed table -->
    <div class="camp-table__wrapper-inner camp-table-collapsed"
        >
        <div class="camp-table ">
            <div class="camp-table-inner">
                <div class="camp-table-body">

                    <div  class="camp-table-row camp-row camp-row-nowrap">

                        <div  class="camp-table-cell camp-table-cell__name camp-table-cell-sticky camp-table-cell-collapsed"
                            :style="{ width: `${tableCellsParams[0].width}px` }">
                            <div class="camp-table-left-indicator" :style="backgroundcolor"></div>

                            <div class="camp-table-stub"></div>

                            <button v-if="deletable" type="button" aria-label="Удалить группу" class="camp-btn-delete "
                                @click="deleteGroup"
                            >
                                    <span class="camp-btn-delete__ic camp-tooltip" data-tippy-content="Удалить группу">
                                        <Svg :img="'trash'"/>

	                                </span>
                            </button>

                            <div class="camp-table-cell-inner">
                                <div class="camp-table-title__wrapper" :style="stylecolor">
                                    <div class="camp-table-title__inner">

                                        <button type="button" class="camp-table-title__ic camp__is-active camp__js-open-table-btn"
                                            @click="toggleExpande"
                                        >
                                            <Svg :img="'arrow'"/>

                                        </button>
                                        <!-- camp__is-active -->
                                        <div class="camp-table-title">

                                            <div class="camp-table-cell-title">
                                                <div class="camp-table-cell-title__display camp-tooltip" data-tippy-content="Нажмите чтобы отредактировать">
                                                    <div class="camp-table-cell-title__display-inner">
                                                        <h2 class="camp-table-cell-title__display-text">{{groupName}}</h2>
                                                        <div class="camp-table-cell-title__edit" style="display: none">
                                                            <ColorPickers
                                                                :keyid="groupID"
                                                                :defalut_color="color"
                                                                @colorChanged="handleColorChange"
                                                            />

                                                            <input type="text"
                                                                   v-model="inputValue"
                                                                   @change="handleEditGrop"
                                                            >
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <span class="camp-table-title__notify">{{numrows}} элементов</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>
                        </div>
                        <!-- //cell -->

                        <template v-for="(cellParams, index) in tableCellsParams">
                            <template
                                v-if="index !== 0  && index !== tableCellsParams.length - 1"
                            >



                                <TableCellCollapsed

                                    :name="cellParams.name"
                                    :width="cellParams.width"
                                    :type="cellParams.type"
                                    :field="cellParams.field"
                                    :title="cellParams.title"
                                    :group_id="groupID"
                                    :value="getCellFieldValue(cellParams.field)"
                                    :table-type="2"
                                    :withCheckbox="cellParams.withCheckbox"
                                    :isSticky="cellParams.isSticky"
                                />

                            </template>

                            <template
                                v-if=" index === tableCellsParams.length - 1"
                            >

                                <div class="camp-table-cell camp-table-cell__add-new"
                                     :style="{ width: `${cellParams.width}px` }">

                                </div>

                            </template>


                        </template>


                        <!-- //tcell -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- //Collapsed table -->
</template>

<script>
    import {mapActions,mapState} from 'vuex'
    import TableCellCollapsed from "./TableCellCollapsed";
    import {calcResult, findObjectByField, setGroupData} from '@/components/Crm/helpers/dataHelpers';

    import Svg from "./Svg";
    import ColorPickers from "./Inputs/ColorPickers";
    import AddColumn from "./Inputs/AddColumn";

    export default {
        name: "TableCollapsed",
        components: {AddColumn, ColorPickers, Svg, TableCellCollapsed},
        data() {
            return {
                inputValue: this.groupName,
                currentcolor:this.color,
            };
        },
        props: {
             tableCellsParams: {
                defalut:null,
                required: true,
            },
            itemIndex:0,
            deletable:true,
            key_field: String,
            tableData: null,
            groupName: String,
            color: String,
            groupID: Number,
            index: Number,
            numrows: Number,
        },
        computed:{
            ...mapState({
                deskData: state => state.crm.deskData
            }),
            stylecolor(){
                    this.currentcolor=this.color;
                    return this.currentcolor ? 'color:'+ this.currentcolor +';' : 'color: #5698F7;'
            },
            backgroundcolor(){
                this.currentcolor=this.color;
                return this.currentcolor ? 'background-color:'+ this.currentcolor +';' : 'background-color: #5698F7;'
            },
        },
        methods: {
            ...mapActions({
                editGroup: 'crm/editGroup',
            }),
            /*
           * Вычисляем итоговое поле
            */
            getCellFieldValue(field)  {

                const objbyfield = findObjectByField(this.tableCellsParams, field);
                const tableData=this.tableData;


                return calcResult(tableData, objbyfield, field);


            },
            deleteGroup() {

                if(confirm('Удалить группу?')) {
                    console.log('delete group', this.groupID);


                    this.editGroup({
                        obj: {
                            group_id: this.groupID,
                            name: 'delgroup',
                            action: 'delete_group',
                        }
                    });
                }

            },
            handleEditGrop() {
                console.log('edit group',this.inputValue);


                this.postGroupData(this.inputValue);
                this.updateGroupData({name:this.inputValue})
            },
            postGroupData(groupname) {
                this.editGroup({
                    obj: {
                        group_id:this.groupID,
                        name:groupname,
                        action:'edit_group',
                    }
                }).then(() => {

                })
                .catch(() => {

                })
                .finally(() => {

                })
            },

            handleColorChange(c){

                console.log("Handle ColorChange", c.color);
                this.$store.commit('crm/setUiaction', {action:'change_color','color':c.color,'group_id':this.groupID});
                this.currentcolor=c.color;

                this.updateGroupData({color:this.currentcolor})
            },
            toggleExpande(){
                //console.log("groupData",this.deskData)
                //return;
                if(this.deskData?.data?.desk_groups) {
                    const groups=this.deskData?.data?.desk_groups
                    //const isExpanded=this.deskData?.data?.desk_groups[this.itemIndex]?.enabled ?? false;
                    const newGroupdata = setGroupData(groups, this.groupID, {expanded: true})
                    console.log("groupData", newGroupdata)

                    this.deskData.data.desk_groups=newGroupdata

                    this.$store.commit('crm/setDeskData', this.deskData );
                }

            },
            updateGroupData(data)
            {
                if(this.deskData?.data?.desk_groups) {

                    const groups=this.deskData?.data?.desk_groups

                    const newGroupdata = setGroupData(groups, this.groupID, data )


                    this.deskData.data.desk_groups=newGroupdata
                    console.log("updateGroupData", newGroupdata)

                    this.$store.commit('crm/setDeskData', this.deskData );
                }
            },

        },
        mounted() {

            this.inputValue= this.groupName
        },
        watch: {
            groupName(newValue) {
                this.inputValue = newValue;
            }
        }
    }
</script>

<style scoped>

</style>
