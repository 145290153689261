<template>
  <BaseInstructionPopup
    :classModifier="setClassModifiedPopup"
  >
    <div
      class="firstStepInstructionPopup"
      :class="setClassModifiedInstr"
    >
      <Profile
        class="firstStepInstructionPopup__profile"
        :classModificator="'instruction'"
        v-if="!$store.state.isMobile"
      />
      <div class="firstStepInstructionPopup__titlePopup titlePopup">
        {{$store.state.auth.user.username}},<br> добро пожаловать в CAMP! 😌
      </div>
      <p class="firstStepInstructionPopup__text"><span>3 простых шага</span> для работы:</p>
      <p class="firstStepInstructionPopup__text">Перед тем, как приступить к работе с сервисом - перейдите в <span>"Настройки пользователя"</span> и подключите свой магазин на маркетплейсе💰</p>
      
      <div
        class="firstStepInstructionPopup__dontShow"
        @click="dontShow"
      >
        Не показывать больше
      </div>
    </div>
  </BaseInstructionPopup>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import Profile from '@/components/Profile'

export default {
  name: 'FirstStepInstructionPopup',
  components: {
    Profile
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
      setInstructed: 'settings/setInstructed',
      getUser: 'auth/getUser',
      openPopup: 'popup/openPopup',
      addSetInternalStepsInstructionOnMobile: 'popup/addSetInternalStepsInstructionOnMobile'
    }),
    dontShow() {
      this.closePopup(0)
      this.setInstructed({integration: true})
      .then(() => {
        this.getUser()
      })
    }
  },
  computed: {
    ...mapState({
      internalStepsInstructionOnMobile: state => state.popup.internalStepsInstructionOnMobile,
    }),
    ...mapGetters({
      checkModalQueue: 'popup/checkModalQueue'
    }),
    setClassModifiedInstr() {
      if(this.checkModalQueue > 1) {
        switch(this.internalStepsInstructionOnMobile) {
          case 1: return 'firstStepInstructionPopup--openMobileSidebar'; break;
          case 2: return 'firstStepInstructionPopup--openProfile'; break;
        }
      }
    },
    setClassModifiedPopup() {
      switch(this.internalStepsInstructionOnMobile) {
        case 0: return 'firstStepInstructionPopup'
        case 1: return 'firstStepInstructionPopup openMobileSidebar'; break;
        case 2: return 'firstStepInstructionPopup openMobileSidebar openProfile'; break;
        }
    }
  },
  watch: {
    checkModalQueue(val) {
      if(val === 1) this.addSetInternalStepsInstructionOnMobile(0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';


.firstStepInstructionPopup {
  position: relative;
  padding: 20px;
  width: 500px;
  max-width: 100%;
  @include breakpoint(sm) {
    max-width: 360px;
    width: 100%;
  }
  @include breakpoint(xs) {
    max-width: 300px;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: -365px;
    left: -287px;
    width: 100px;
    height: 578px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='105' height='586' viewBox='0 0 105 586' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M44.5754 585.766C47.521 585.766 49.9088 583.378 49.9088 580.433C49.9088 577.487 47.521 575.099 44.5754 575.099C41.6299 575.099 39.2421 577.487 39.2421 580.433C39.2421 583.378 41.6299 585.766 44.5754 585.766ZM102.665 1.46477C102.569 0.920886 102.05 0.55791 101.506 0.65404L92.6434 2.22033C92.0995 2.31646 91.7366 2.83525 91.8327 3.37914C91.9288 3.92296 92.4476 4.28594 92.9915 4.18981L100.869 2.79753L102.262 10.6755C102.358 11.2193 102.877 11.5823 103.42 11.4862C103.964 11.3901 104.327 10.8713 104.231 10.3274L102.665 1.46477ZM45.5108 580.079C-15.4328 418.983 -1.18007 274.511 28.3122 170.301C43.0587 118.194 61.6144 76.1585 76.4807 47.1537C83.9137 32.6518 90.4236 21.4087 95.0729 13.7948C97.3975 9.98784 99.2568 7.08831 100.534 5.14238C101.172 4.16942 101.665 3.43492 101.997 2.94457C102.164 2.69939 102.29 2.51531 102.374 2.39299C102.416 2.33184 102.448 2.28606 102.469 2.25591C102.479 2.24083 102.487 2.2296 102.492 2.22228C102.495 2.21862 102.497 2.21605 102.498 2.21422C102.499 2.21263 102.499 2.21202 101.68 1.63878C100.861 1.06554 100.86 1.06688 100.858 1.0692C100.857 1.07128 100.854 1.07457 100.852 1.07872C100.846 1.08702 100.837 1.09917 100.826 1.11528C100.804 1.14745 100.771 1.19512 100.727 1.25829C100.64 1.38463 100.511 1.5728 100.342 1.82207C100.004 2.32061 99.5057 3.06353 98.8616 4.04515C97.5733 6.00835 95.7024 8.92626 93.366 12.7525C88.6932 20.4049 82.1586 31.6913 74.7009 46.2414C59.7861 75.3408 41.1767 117.5 26.3878 169.756C-3.1908 274.271 -17.4904 419.196 43.6401 580.786L45.5108 580.079Z' fill='white'/%3E%3C/svg%3E%0A");
    transform: rotate(-50deg);
    z-index: 6;
    @include breakpoint(xl) {
      top: -247px;
      left: -210px;
      width: 100px;
      height: 435px;
      background-image: url("data:image/svg+xml,%3Csvg width='105' height='443' viewBox='0 0 105 443' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.0023 442.575C33.9478 442.575 36.3356 440.187 36.3356 437.242C36.3356 434.296 33.9478 431.908 31.0023 431.908C28.0568 431.908 25.669 434.296 25.669 437.242C25.669 440.187 28.0568 442.575 31.0023 442.575ZM103.413 1.3591C103.344 0.811101 102.844 0.422551 102.296 0.491246L93.3663 1.61078C92.8183 1.67948 92.4298 2.17939 92.4985 2.72739C92.5672 3.2754 93.0671 3.66395 93.6151 3.59525L101.553 2.60013L102.548 10.538C102.617 11.086 103.117 11.4745 103.665 11.4058C104.213 11.3371 104.601 10.8372 104.533 10.2892L103.413 1.3591ZM31.9216 436.848C-15.3977 326.294 2.38988 217.637 32.0588 136.551C46.8915 96.0131 64.6862 62.3897 78.7723 38.9019C85.8149 27.1587 91.9292 17.9514 96.2807 11.6826C98.4564 8.54828 100.191 6.14875 101.381 4.53434C101.976 3.72715 102.435 3.11625 102.744 2.70795C102.898 2.50379 103.016 2.35032 103.094 2.24823C103.133 2.19721 103.163 2.15903 103.182 2.13382C103.192 2.12122 103.199 2.11185 103.204 2.10572C103.206 2.10267 103.208 2.10053 103.209 2.099C103.21 2.09766 103.21 2.09714 102.421 1.48349C101.631 0.869847 101.63 0.870946 101.629 0.872899C101.628 0.874608 101.625 0.877355 101.623 0.880834C101.617 0.887761 101.609 0.897923 101.599 0.911351C101.578 0.938176 101.548 0.978001 101.507 1.03067C101.426 1.13602 101.307 1.29288 101.149 1.50043C100.835 1.9155 100.371 2.53342 99.771 3.34781C98.5707 4.97663 96.8247 7.39152 94.6377 10.5422C90.2638 16.8433 84.125 26.088 77.0571 37.8732C62.9224 61.4421 45.0665 95.1806 30.1806 135.864C0.412344 217.221 -17.5094 326.443 30.083 437.635L31.9216 436.848Z' fill='white'/%3E%3C/svg%3E%0A");
    }
    @include breakpoint(md) {
      width: 68px;
      height: 57px;
      right: -86px;
      transform: rotate(10deg);
      left: auto;
      top: 55px;
      background-image: url("data:image/svg+xml,%3Csvg width='68' height='57' viewBox='0 0 68 57' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.91895 56.0987C8.86446 56.0987 11.2523 53.7109 11.2523 50.7654C11.2523 47.8199 8.86446 45.432 5.91895 45.432C2.97343 45.432 0.585612 47.8199 0.585612 50.7654C0.585612 53.7109 2.97343 56.0987 5.91895 56.0987ZM59.5678 0.976419C59.0895 0.700277 58.4779 0.864149 58.2017 1.34244L53.7017 9.13667C53.4256 9.61497 53.5895 10.2266 54.0678 10.5027C54.546 10.7788 55.1576 10.615 55.4338 10.1367L59.4338 3.20847L66.362 7.20847C66.8403 7.48461 67.4519 7.32074 67.728 6.84244C68.0042 6.36415 67.8403 5.75256 67.362 5.47642L59.5678 0.976419ZM6.17776 51.7313C37.2478 43.4061 50.8181 31.0521 56.5528 20.5723C59.4152 15.3414 60.2964 10.627 60.4555 7.20372C60.535 5.49353 60.4344 4.10684 60.3116 3.13936C60.2502 2.65551 60.1833 2.27609 60.1305 2.01298C60.104 1.88141 60.0811 1.77886 60.0642 1.70683C60.0557 1.67081 60.0487 1.64241 60.0435 1.62181C60.041 1.61152 60.0388 1.60317 60.0372 1.5968C60.0363 1.59361 60.0356 1.59092 60.035 1.58872C60.0347 1.58763 60.0344 1.58635 60.0343 1.5858C60.034 1.58465 60.0337 1.58362 59.0678 1.84244C58.1018 2.10126 58.1016 2.10048 58.1014 2.09982C58.1014 2.09977 58.1013 2.09923 58.1013 2.09912C58.1012 2.09889 58.1013 2.09915 58.1015 2.09989C58.1018 2.10137 58.1027 2.10478 58.1041 2.11011C58.1067 2.12074 58.1113 2.13901 58.1173 2.16472C58.1294 2.21615 58.1476 2.29732 58.1696 2.4068C58.2136 2.62578 58.2726 2.95773 58.3275 3.39104C58.4375 4.25787 58.5312 5.5286 58.4577 7.11084C58.3107 10.2725 57.4957 14.6829 54.7983 19.6122C49.4134 29.4528 36.4093 41.5602 5.66013 49.7995L6.17776 51.7313Z' fill='white'/%3E%3C/svg%3E%0A");
    }
    @include breakpoint(sm) {
      width: 81px;
      height: 282px;
      right: 91px;
      top: -300px;
      background-image: url("data:image/svg+xml,%3Csvg width='81' height='283' viewBox='0 0 81 283' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6546 282.225C13.6002 282.225 15.988 279.838 15.988 276.892C15.988 273.947 13.6002 271.559 10.6546 271.559C7.70913 271.559 5.32131 273.947 5.32131 276.892C5.32131 279.838 7.70913 282.225 10.6546 282.225ZM80.1507 1.20593C80.1142 0.654873 79.6379 0.237698 79.0868 0.274167L70.1065 0.868436C69.5554 0.904904 69.1382 1.38119 69.1747 1.93228C69.2112 2.48336 69.6875 2.90054 70.2385 2.86407L78.2211 2.33581L78.7493 10.3184C78.7858 10.8694 79.2621 11.2866 79.8132 11.2501C80.3643 11.2137 80.7814 10.7374 80.745 10.1863L80.1507 1.20593ZM11.6206 276.633C-8.55319 201.344 8.497 132.682 30.6284 82.7997C41.6926 57.862 54.0198 37.6347 63.5796 23.6438C68.3591 16.649 72.4457 11.2148 75.335 7.53204C76.7796 5.6907 77.9248 4.28735 78.7076 3.34582C79.0989 2.87506 79.3997 2.51977 79.6019 2.28296C79.703 2.16455 79.7795 2.07574 79.8304 2.01693C79.8558 1.98751 79.8748 1.96563 79.8873 1.95126C79.8936 1.94409 79.8982 1.93878 79.9012 1.93536C79.9027 1.93368 79.9036 1.93255 79.9044 1.9317C79.905 1.93103 79.9051 1.93084 79.1529 1.27197C78.4006 0.613095 78.4 0.613858 78.3989 0.615109C78.398 0.616147 78.3965 0.617856 78.3946 0.619961C78.391 0.624142 78.3857 0.630215 78.3787 0.63815C78.3649 0.65408 78.3445 0.677578 78.3177 0.708554C78.2641 0.770535 78.1848 0.862545 78.0809 0.984219C77.8731 1.22757 77.5667 1.5896 77.1696 2.06726C76.3754 3.02258 75.2183 4.44064 73.7615 6.29754C70.8479 10.0113 66.735 15.4809 61.9283 22.5155C52.3158 36.5835 39.9237 56.9172 28.8002 81.9886C6.55617 132.125 -10.6427 201.273 9.68872 277.151L11.6206 276.633Z' fill='white'/%3E%3C/svg%3E%0A");
    }
    @include breakpoint(xs) {
      width: 67px;
      height: 168px;
      right: 60px;
      top: -173px;
      background-image: url("data:image/svg+xml,%3Csvg width='67' height='168' viewBox='0 0 67 168' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.11083 167.142C9.05635 167.142 11.4442 164.754 11.4442 161.808C11.4442 158.863 9.05635 156.475 6.11083 156.475C3.16531 156.475 0.777496 158.863 0.777496 161.808C0.777496 164.754 3.16531 167.142 6.11083 167.142ZM66.629 2.62652C66.6768 2.07631 66.2695 1.59155 65.7193 1.54377L56.753 0.765211C56.2028 0.717436 55.7181 1.12474 55.6703 1.67496C55.6225 2.22517 56.0298 2.70993 56.58 2.7577L64.55 3.44977L63.858 11.4198C63.8102 11.97 64.2175 12.4548 64.7677 12.5025C65.3179 12.5503 65.8027 12.143 65.8505 11.5928L66.629 2.62652ZM7.10684 161.719C2.57553 111.17 17.3794 71.5602 33.3173 44.5811C41.2872 31.0897 49.5395 20.7597 55.7951 13.8064C58.9226 10.33 61.5501 7.69871 63.3923 5.93939C64.3134 5.05977 65.038 4.39822 65.5306 3.95811C65.7768 3.73806 65.9651 3.57339 66.0908 3.46451C66.1537 3.41006 66.2009 3.36957 66.232 3.34306C66.2475 3.32982 66.259 3.32008 66.2664 3.31384C66.2701 3.31071 66.2727 3.30847 66.2743 3.30711C66.2752 3.30642 66.2756 3.30606 66.276 3.30572C66.2762 3.30557 66.2761 3.30565 65.6328 2.54002C64.9895 1.7744 64.9889 1.77491 64.988 1.77565C64.9874 1.77618 64.9862 1.77713 64.985 1.77821C64.9824 1.78036 64.9788 1.7834 64.9742 1.78731C64.965 1.79513 64.9516 1.80649 64.9342 1.82133C64.8993 1.85106 64.8483 1.89482 64.7816 1.95257C64.6482 2.0681 64.4521 2.23962 64.198 2.46672C63.6897 2.92091 62.9487 3.59746 62.011 4.49301C60.1356 6.28407 57.4725 8.95154 54.3082 12.4687C47.9804 19.5024 39.6444 29.9386 31.5953 43.5638C15.4952 70.8176 0.538116 110.842 5.11482 161.898L7.10684 161.719Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  &--openMobileSidebar {
    @include breakpoint(md) {
      &::before {
        width: 95px;
        height: 246px;
        top: 75px;
        background-image: url("data:image/svg+xml,%3Csvg width='95' height='246' viewBox='0 0 95 246' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.59081 11.1966C8.53633 11.1966 10.9241 8.80879 10.9241 5.86327C10.9241 2.91774 8.53633 0.529938 5.59081 0.529938C2.64529 0.529938 0.257476 2.91774 0.257477 5.86327C0.257477 8.80879 2.64529 11.1966 5.59081 11.1966ZM14.3343 240.46C14.1065 240.963 14.3297 241.555 14.8328 241.783L23.0316 245.495C23.5347 245.723 24.1272 245.5 24.355 244.997C24.5828 244.494 24.3596 243.901 23.8565 243.673L16.5687 240.374L19.8684 233.086C20.0962 232.583 19.873 231.99 19.3699 231.762C18.8667 231.535 18.2742 231.758 18.0464 232.261L14.3343 240.46ZM5.25988 6.80692C57.9808 25.2954 88.7288 72.9182 92.1157 121.273C95.5001 169.593 71.5642 218.589 14.8927 239.936L15.5978 241.808C73.1804 220.118 97.5505 170.242 94.1108 121.134C90.6736 72.0601 59.4673 23.6973 5.92174 4.91962L5.25988 6.80692Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
    @include breakpoint(sm) {
      &::before {
        top: -207px;
        right: 74px;
        width: 39px;
        height: 189px;
        transform: rotate(-7deg);
        background-image: url("data:image/svg+xml,%3Csvg width='39' height='189' viewBox='0 0 39 189' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1629 179.666C8.32164 177.367 4.96522 176.996 2.66612 178.837C0.367019 180.678 -0.00413534 184.035 1.83712 186.334C3.67838 188.633 7.0348 189.004 9.3339 187.163C11.633 185.322 12.0042 181.965 10.1629 179.666ZM23.8857 0.105127C23.3915 -0.141341 22.791 0.0595175 22.5445 0.553756L18.5281 8.60784C18.2816 9.10208 18.4825 9.70255 18.9767 9.94901C19.471 10.1955 20.0714 9.99462 20.3179 9.50038L23.8881 2.34119L31.0472 5.91135C31.5415 6.15782 32.1419 5.95696 32.3884 5.46272C32.6349 4.96848 32.434 4.36802 31.9398 4.12155L23.8857 0.105127ZM6.71225 183.702C36.2687 153.712 40.5984 107.823 37.6441 69.6736C36.1644 50.5666 32.8519 33.3261 29.9105 20.8643C28.4395 14.6322 27.0606 9.59223 26.0487 6.10793C25.5427 4.3657 25.1284 3.01223 24.8402 2.09294C24.696 1.63329 24.5834 1.28217 24.5066 1.04526C24.4682 0.9268 24.4387 0.836894 24.4187 0.776248C24.4087 0.745925 24.4011 0.722916 24.3959 0.707312C24.3933 0.699509 24.3914 0.693558 24.39 0.689469C24.3893 0.687424 24.3888 0.685756 24.3884 0.684732C24.388 0.683531 24.3878 0.682797 23.4394 1.00003C22.4911 1.31725 22.4911 1.31746 22.4914 1.31812C22.4916 1.31897 22.492 1.3201 22.4926 1.32179C22.4937 1.32517 22.4955 1.3304 22.4978 1.33748C22.5025 1.35165 22.5096 1.37321 22.5192 1.40208C22.5382 1.4598 22.5667 1.54675 22.6041 1.6622C22.679 1.89311 22.7896 2.23805 22.9318 2.69134C23.2161 3.59792 23.6262 4.93785 24.1281 6.66573C25.1317 10.1216 26.5017 15.1286 27.964 21.3238C30.8891 33.7165 34.1804 50.8514 35.65 69.828C38.5942 107.846 34.2042 152.957 5.28777 182.298L6.71225 183.702Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
    @include breakpoint(xs) {
      &::before {
        width: 62px;
        height: 149px;
        top: -168px;
        right: 33px;
        transform: rotate(7deg);
        background-image: url("data:image/svg+xml,%3Csvg width='62' height='149' viewBox='0 0 62 149' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55.9449 148.725C58.8904 148.725 61.2783 146.338 61.2783 143.392C61.2783 140.447 58.8904 138.059 55.9449 138.059C52.9994 138.059 50.6116 140.447 50.6116 143.392C50.6116 146.338 52.9994 148.725 55.9449 148.725ZM1.21055 1.67897C0.664377 1.76089 0.288023 2.27007 0.369942 2.81624L1.70489 11.7167C1.78681 12.2628 2.29599 12.6392 2.84216 12.5573C3.38834 12.4754 3.76469 11.9662 3.68277 11.42L2.49615 3.50851L10.4077 2.3219C10.9538 2.23997 11.3302 1.7308 11.2483 1.18463C11.1663 0.638458 10.6572 0.2621 10.111 0.344024L1.21055 1.67897ZM56.9449 143.401C57.4091 93.3565 43.7058 57.9943 29.854 35.1269C22.9303 23.6968 15.9735 15.3948 10.7393 9.94405C8.1221 7.21861 5.93526 5.20569 4.39801 3.87065C3.62937 3.20311 3.02307 2.70499 2.6065 2.37196C2.39822 2.20546 2.23736 2.0802 2.12735 1.99568C2.07235 1.95341 2.03005 1.92134 2.0009 1.89937C1.98632 1.88838 1.97503 1.87993 1.96708 1.87399C1.9631 1.87103 1.95996 1.86868 1.95766 1.86697C1.9565 1.86612 1.95541 1.86531 1.95484 1.86488C1.95395 1.86424 1.95328 1.86374 1.35888 2.66791C0.764479 3.47208 0.764232 3.47189 0.764195 3.47186C0.764464 3.47206 0.764638 3.4722 0.765175 3.4726C0.766248 3.47339 0.768161 3.47481 0.770907 3.47687C0.776398 3.48096 0.785221 3.48756 0.797322 3.49669C0.821524 3.51492 0.858837 3.54321 0.908835 3.58162C1.00883 3.65846 1.15957 3.77577 1.35763 3.93411C1.75376 4.25081 2.33917 4.73157 3.0866 5.38068C4.58149 6.67894 6.7242 8.65041 9.29671 11.3293C14.4418 16.6872 21.3049 24.8738 28.1434 36.1631C41.8158 58.7344 55.4055 93.7343 54.945 143.383L56.9449 143.401Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &--openProfile {
    @include breakpoint(md) {
      &::before {
        width: 145px;
        height: 457px;
        right: -100px;
        top: 130px;
        transform: rotate(13deg);
        background-image: url("data:image/svg+xml,%3Csvg width='145' height='457' viewBox='0 0 145 457' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.11326 11.0316C9.05878 11.0316 11.4466 8.64377 11.4466 5.69824C11.4466 2.75272 9.05878 0.364899 6.11326 0.364899C3.16774 0.364899 0.779928 2.75272 0.779928 5.69824C0.779928 8.64377 3.16774 11.0316 6.11326 11.0316ZM71.9722 454.075C71.9161 454.625 72.3161 455.116 72.8655 455.172L81.8191 456.085C82.3685 456.141 82.8593 455.741 82.9154 455.191C82.9714 454.642 82.5715 454.151 82.022 454.095L74.0633 453.283L74.8751 445.325C74.9311 444.775 74.5312 444.284 73.9817 444.228C73.4323 444.172 72.9414 444.572 72.8854 445.122L71.9722 454.075ZM5.74076 6.62628C35.6359 18.6258 63.3287 45.5325 85.9108 81.0133C108.483 116.479 125.893 160.425 135.264 206.361C154.02 298.296 140.527 397.833 72.3353 453.402L73.5987 454.952C142.639 398.692 156.042 298.202 137.224 205.961C127.808 159.809 110.314 115.63 87.598 79.9394C64.892 44.264 36.9128 16.9832 6.48576 4.7702L5.74076 6.62628Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
    @include breakpoint(sm) {
      &::before {
        display: none;
      }
    }
  }

  &__titlePopup {
    text-align: left;
    margin-bottom: 20px;
    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 10px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    @include breakpoint(xs) {
      font-size: 13px;
      line-height: 13px;
    }
    &:last-of-type {
      margin-bottom: 20px;
      @include breakpoint(sm) {
        margin-bottom: 10px;
      }
    }
    span {
      font-weight: 600;
      color: $dark-green-400;
    }
    
  }
  &__dontShow {
    font-size: 12px;
    line-height: 12px;
    color: $gray-450;
    cursor: pointer;
  }
  &__profile, &__hamburger {
    position: absolute;
  }
  &__profile {
    top: -348px;
    left: -443px;
    @include breakpoint(xl) {
      top: -250px;
      left: -313px;
    }
  }
  &__hamburger {
    position: absolute;
    top: 0;
    right: 0;
  }
}

</style>