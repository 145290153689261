<template>
  <div class="adminSellersListItemByProduct">
    <div
      class="adminSellersListItemByProduct__cell adminSellersListItemByProduct__cell--clientID"
    >
      {{ product.title }}
    </div>
    <div class="adminSellersListItemByProduct__cell">
      {{ product.id }}
    </div>
    <div
      class="adminSellersListItemByProduct__cell adminSellersListItemByProduct__cell--turnOn"
    >
      <button
        v-if="product.state"
        class="adminSellersListItemByProduct__cell--btn adminSellersListItemByProduct__cell--btnOnActive"
      >
        Включен
      </button>
      <button
        v-else
        class="adminSellersListItemByProduct__cell--btn adminSellersListItemByProduct__cell--btnDisable"
      >
        Включен
      </button>
    </div>
    <div
      class="adminSellersListItemByProduct__cell adminSellersListItemByProduct__cell--turnOff"
    >
      <button
        v-if="!product.state"
        class="adminSellersListItemByProduct__cell--btn adminSellersListItemByProduct__cell--btnOffActive"
      >
        Выключен
      </button>
      <button
        v-else
        class="adminSellersListItemByProduct__cell--btn adminSellersListItemByProduct__cell--btnDisable"
      >
        Выключен
      </button>
    </div>
    <div class="adminSellersListItemByProduct__cell">
      {{ product.min }}
    </div>
    <div class="adminSellersListItemByProduct__cell">
      {{ product.max }}
    </div>
    <div class="adminSellersListItemByProduct__cell">
      {{ product.totalItem }}
    </div>
    <div class="adminSellersListItemByProduct__cell">
      {{ product.average }}
    </div>
    <div class="adminSellersListItemByProduct__cell">
      {{ product.order }}
    </div>
    <div class="adminSellersListItemByProduct__cell">
      {{ product.totalPrice }}
    </div>
    <div class="adminSellersListItemByProduct__cell">
      {{ product.lost }}
    </div>
    <div class="adminSellersListItemByProduct__cell">
      {{ product.sell }}
    </div>
    <div class="adminSellersListItemByProduct__cell">
      {{ product.pro }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";
.adminSellersListItemByProduct {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  padding: 5px;
  @include mediaMaxWidth(1440) {
    width: 1400px;
  }
  &__cell {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    width: 6.77%;
    min-width: 100px;
    border-right: 2px solid $gray-50;
    padding: 5px 20px;
    text-align: center;
    white-space: nowrap;
    @include mediaMaxWidth(1440) {
      padding: 5px 10px;
    }
    &--clientID {
      border: none;
      height: 35px;
      display: flex;
      justify-content: start;
      align-items: center;
      min-width: 240px;
      padding: 5px 15px;
      font-size: 15px;
      line-height: 15px;
      font-weight: 500;
      @include mediaMaxWidth(1490) {
        min-width: 200px;
      }
    }
    &--turnOn {
      display: flex;
      justify-content: center;
      padding: 5px;
      align-items: center;
    }
    &--turnOff {
      display: flex;
      justify-content: center;
      padding: 5px;
      align-items: center;
    }
    &--btn {
      padding: 5px;
      border-radius: 10px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      text-align: center;
      &OnActive {
        background-color: $green-100;
        color: white;
      }
      &OffActive {
        background-color: $red;
        color: white;
      }
      &Disable {
        background-color: $gray-100;
        color: $gray-300;
      }
    }
  }
  &__cell:last-child {
    border: none;
  }
}
</style>
