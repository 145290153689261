<template>
    <div class="camp-table__wrapper">

        <!-- Collapsed table -->
        <div class="camp-table__wrapper-inner camp-table-collapsed" style="display: block;">
            <div class="camp-table">

                <div class="camp-table-inner">

                    <div class="camp-table-body">

                        <div class="camp-table-row camp-row camp-row-nowrap">

                            <TableCellCollapsed
                                v-for="(cellParams, index) in tableCellsParams"
                                :key="index"
                                :table-type="1"
                                v-if="index !== 0"
                                :name="cellParams.name"
                                :width="cellParams.width"
                                :type="cellParams.type"
                                :value="inputValue"
                                :withCheckbox="cellParams.withCheckbox"
                                :isSticky="cellParams.isSticky"
                            />


                        </div>

                    </div>

                </div>

            </div>
        </div>
        <!-- //Collapsed table -->

        <!-- Full table -->
        <div class="camp-table__wrapper-inner camp-table-full" style="display: none;">

            <!-- table header -->
            <div class="camp-table-title__wrapper camp-table-title__wrapper-sticky" style="color: #CACACA;">
                <div class="camp-table-title__inner">

                    <button type="button" class="camp-table-title__ic camp__js-close-table-btn">
                        <!-- arrow.svg -->
                        <svg viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.72941 11.0675L7.9817 10.3198L7.23403 11.0675C7.64699 11.4804 8.31647 11.4804 8.72941 11.0675ZM7.9817 8.82433L3.08978 3.93251C2.67682 3.51957 2.00741 3.51957 1.59445 3.93251C1.18149 4.34545 1.18149 5.01499 1.59445 5.42793L7.23403 11.0675L7.9817 10.3198L8.72941 11.0675L14.369 5.42793C14.7819 5.01499 14.7819 4.34546 14.369 3.93251C13.9561 3.51957 13.2865 3.51957 12.8736 3.93251L7.9817 8.82433Z"></path>
                        </svg>

                    </button>

                    <!-- camp__is-active -->
                    <div class="camp-table-title">

                        <div class="camp-table-cell-title">
                            <div class="camp-table-cell-title__display camp-tooltip" data-tippy-content="Нажмите чтобы создать группу">
                                <div class="camp-table-cell-title__display-inner">
                                    <h2 class="camp-table-cell-title__display-text">{{inputValue}}</h2>
                                    <div class="camp-table-cell-title__edit" style="display: none">
                                        <div class="camp-colorpicker camp__js-colorpicker">
                                            <div class="camp-colorpicker__display" style="background-color:#FF3D57"></div>
                                            <div class="camp-colorpicker__dropdown camp-dropdown">
                                                <button class="camp-btn-ic camp-btn-ic__close" type="button" aria-label="Закрыть">
			<span class="camp-btn-ic__icon">
				<!-- close.svg -->

<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 72 72" xml:space="preserve">
	<path d="M55.828,16.171c1.562,1.562,1.562,4.095,0,5.657l-34,34C21.048,56.609,20.023,57,19,57s-2.048-0.391-2.828-1.171
	c-1.562-1.562-1.562-4.095,0-5.657l34-34C51.732,14.61,54.268,14.61,55.828,16.171z"></path>
	<path d="M55.828,55.829c-1.561,1.562-4.096,1.562-5.656,0l-34-34c-1.562-1.562-1.562-4.095,0-5.657C16.952,15.391,17.977,15,19,15
	s2.048,0.391,2.828,1.171l34,34C57.391,51.734,57.391,54.266,55.828,55.829z"></path>
</svg>
			</span>
                                                </button>

                                                <ul class="camp-dropdown__body camp-row camp-align-horisontal-start">
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#0073EA"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color: #00D488"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#5698F7"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#9B51E0"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#FDAB3D"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color: #FF3D57"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#0073EA"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color: #00D488"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#5698F7"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#9B51E0"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#FDAB3D"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color: #FF3D57"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#0073EA"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color: #00D488"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#5698F7"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#9B51E0"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color:#FDAB3D"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color: #FF3D57"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color: #FF3D57"></li>
                                                    <li class="camp-colorpicker-item" role="button" style="background-color: #FF3D57"></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <input type="text" :placeholder="inputValue"
                                               v-model="inputValue"
                                               @input="handleAddGrop"
                                        >
                                    </div>

                                </div>
                            </div>
                        </div>

                        <span class="camp-table-title__notify">Элементы отсутствуют</span>
                    </div>
                </div>
            </div>
            <!-- //table header -->

            <!-- Table -->
            <div class="camp-table camp__js-table">

                <div class="camp-table-inner">
                    <div class="camp-table-header">
                        <div class="camp-table-row camp-row camp-row-nowrap">

                            <TableRowHeader
                                :tableCellsParams="tableCellsParams"
                            />


                        </div>

                    </div>
                    <!-- //theader -->

                    <div class="camp-table-body">

                        <div class="camp-table-row camp-table-row__add camp-row camp-row-nowrap">

                                <div
                                    :class="['camp-table-cell', 'camp-table-cell__link', { 'camp-table-cell-sticky': index === 0 }]"
                                    :style="{ width: `${cellParams.width}px` }"
                                    v-for="(cellParams, index) in tableCellsParams"
                                    :key="index"
                                >
                                    <template v-if="index === 0">

                                        <div class="camp-table-left-indicator" style="background-color: #5698F7;"></div>

                                        <div class="camp-table-stub"></div>

                                        <div class="camp-table-cell-checkbox">
                                            <label class="camp-form-checkbox__label">
                                                <input type="checkbox" name="">
                                                <span class="camp-form-checkbox" aria-label="Отметить"><span class="camp-form-checkbox__ic"></span></span>
                                            </label>
                                        </div>

                                        <div class="camp-table-cell-inner">

                                            <p @click="toggleTemplate">+ Добавить элемент</p>

                                        </div>

                                    </template>

                                </div>

                        </div>
                        <!-- //trow -->



                    </div>
                    <!-- //tbody -->

                    <TableRowFooter
                        :tableCellsParams="tableCellsParams"
                        :group_id="0"
                        :desk_data="null"
                        :tableData="null"
                         />
                </div>
            </div>
            <!-- //table -->

        </div>
        <!-- //Full table -->
    </div>
</template>

<script>
    import {mapActions, mapMutations,mapGetters} from 'vuex'
    import TableCellCollapsed from "./TableCellCollapsed";
    import TableRowHeader from "./TableRowHeader";
    import TableRowFooter from "./TableRowFooter";

    export default {
        name: "TableAdd",
        components: {TableRowFooter, TableRowHeader, TableCellCollapsed},
        props: {
             tableCellsParams: {
                defalut:null,
                required: true,
            },
        },
        data() {
            return {
                inputValue: 'Создать новую группу',
                doc:null,
                numrows:0,
            }
        },
        methods:{
            ...mapActions({
                editGroup: 'crm/editGroup',
            }),
            handleAddGrop() {
                console.log('add group',this.inputValue);
                //this.postGroupData(this.inputValue);
            },
            postGroupData(groupname)          {
                this.editGroup({
                    obj: {
                        name:groupname,
                        action:'create_group',
                    }
                }).then(() => {

                })
                .catch(() => {

                })
                .finally(() => {

                })
            },

            tableTitleEdit() {
                const doc=this.doc;
                // Edit table title
                const tableTitles = doc.querySelectorAll('.camp-table-title');

                if (tableTitles) {
                    tableTitles.forEach(function (tableTitle) {
                        tableTitle.addEventListener('click', function(e) {
                            let target = e.target;
                            let editor = tableTitle.querySelector('.camp-table-cell-title__edit');
                            if (target.classList.contains('camp-table-cell-title__display-text')) {
                                target.style.display = 'none';
                                editor.style.display = 'block';
                                tableTitle.classList.add('camp__is-active');
                            }
                        });
                    });
                }

            },
            dropdowns(){
                const doc=this.doc;
                const closeDropdowns=this.closeDropdowns;
                // Dropdowns
                const dropdowns = doc.querySelectorAll('.camp__js-dropdown .camp-table-cell__status-picker_display');
                const dropdownsStatuses = doc.querySelectorAll('.camp-dropdown .camp-status');


                if (dropdowns) {
                    dropdowns.forEach(function(dropdown) {
                        dropdown.addEventListener('click', function(e) {
                            if (this.classList.contains('camp__is-opened') && this.classList.contains('camp__is-active')) {
                                closeDropdowns(dropdowns);
                                this.parentNode.classList.remove('camp__is-opened');
                                this.parentNode.classList.remove('camp__is-active');
                            } else {
                                closeDropdowns(dropdowns);
                                this.parentNode.classList.add('camp__is-opened');
                                this.parentNode.classList.add('camp__is-active');
                            }
                        });
                    });
                }

                if (dropdownsStatuses) {
                    // console.log(dropdownsStatuses);
                    dropdownsStatuses.forEach(function(dropdownStatus) {
                        dropdownStatus.addEventListener('click', function() {
                            const currentDropdown = this.closest('.camp__js-dropdown');
                            const currentStatusText = this.innerHTML;
                            const currentStatusColor = this.style.backgroundColor;

                            currentDropdown.querySelector('.camp-table-cell__status-picker_display').style.backgroundColor = currentStatusColor;
                            currentDropdown.querySelector('.camp-table-cell__status-picker_text').innerHTML = currentStatusText;
                            currentDropdown.classList.remove('camp__is-opened');
                            currentDropdown.classList.remove('camp__is-active');


                        });
                    });

                }


                const dropdownsStatusesLevels = doc.querySelectorAll('.camp-dropdown-statuses');

                if (dropdownsStatusesLevels) {
                    dropdownsStatusesLevels.forEach(function(dropdownsStatusesLevel) {
                        dropdownsStatusesLevel.addEventListener('click', function(e) {
                            let target = e.target;
                            if (!target.classList.contains('camp__js-dropdown-btn')) return;

                            let action = target.getAttribute('data-action');
                            let level = target.closest('.camp-dropdown__level');

                            if (action === 'edit') {
                                level.classList.add('camp__is-hidden');
                                level.nextElementSibling.classList.remove('camp__is-hidden');
                            } else {
                                target.closest('.camp__js-dropdown').classList.remove('camp__is-opened');
                                target.closest('.camp__js-dropdown').classList.remove('camp__is-active');
                                setTimeout(function() {
                                    level.classList.add('camp__is-hidden');
                                    level.previousElementSibling.classList.remove('camp__is-hidden');
                                }, 200);

                            }
                        });
                    });
                }
            },
            collapseTable(){
                const doc=this.doc;
                // Collapse table
                const openTableBtns = doc.querySelectorAll('.camp__js-open-table-btn');
                const closeTableBtns = doc.querySelectorAll('.camp__js-close-table-btn');

                if (openTableBtns) {
                    openTableBtns.forEach(function(openTableBtn) {
                        openTableBtn.addEventListener('click', function(e) {
                            e.preventDefault();

                            const currentCollapsedTable = this.closest('.camp-table-collapsed');
                            const currentFullTable = currentCollapsedTable.nextElementSibling;

                            currentCollapsedTable.style.display = 'none';
                            currentFullTable.style.display = 'block';

                        });
                    });

                    closeTableBtns.forEach(function(closeTableBtn) {
                        closeTableBtn.addEventListener('click', function(e) {
                            e.preventDefault();

                            const currentFullTable = this.closest('.camp-table-full');
                            const currentCollapsedTable = currentFullTable.previousElementSibling;

                            currentFullTable.style.display = 'none';
                            currentCollapsedTable.style.display = 'block';

                        });
                    });
                }

                // Toggle favorite btn active class
                let btnsFavorites = doc.querySelectorAll('.camp__js-favorite-btn');

                if (btnsFavorites) {
                    btnsFavorites.forEach(function(btnFavorite) {
                        btnFavorite.addEventListener('click', function(e) {
                            e.preventDefault();
                            this.classList.toggle('camp__is-active');
                        });
                    });
                }
            },
            init(){
                this.doc = document;
                this.collapseTable();
                //this.dropdowns();
                this.tableTitleEdit();
            }
        },
        mounted() {

            this.init();
        }
    }
</script>

<style scoped>

</style>
