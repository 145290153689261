<template>

  <PBSingleProductHeaderDesktop     
    v-if="!$store.state.isMobile"
  />

  <PBSingleProductHeaderMobile
    v-else
  />

</template>

<script>
import PBSingleProductHeaderMobile from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductHeaderMobile'
import PBSingleProductHeaderDesktop from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductHeaderDesktop'
export default {
  components: {
    PBSingleProductHeaderMobile,
    PBSingleProductHeaderDesktop
  }
}
</script>

<style>

</style>