<template>
  <div class="favorite">
    <ul class="favorite__list">

      <li
        v-for="item of favorite.dropdown" :key="item.name"
        class="favorite__listItem"
        :class="{'favorite__listItem--open': item.menu.isDropDownRoot}"
        @click="listItemHandler(item)"
      >
        <div
          class="favorite__listItemInner"
          @mouseenter="hoverIcon = true"
          @mouseleave="hoverIcon = false"
          :class="{'favorite__listItemInner--mod': sidebarRollUp}"
          title="Избранное"
        >
          <div class="favorite__listItemIcon">
            <SvgUse
              :width="25"
              :height="25"
              id="favoriteIconMenu"
              v-if="!hoverIcon && !item.menu.isDropDownRoot"
            />
            <SvgUse
              :width="25"
              :height="25"
              id="favoriteIconMenuHover"
              v-else
            />
          </div>
          <div
            class="favorite__listItemLink"
            v-show="!sidebarRollUp"
          >
            {{item.menu.root}}
          </div>
        </div>
        <template v-if="item.menu.links.length">
        <ul
          class="favorite__subList"
          v-if="item.menu.isDropDownRoot"
        >
          <li
            class="favorite__subListItem"
            v-for="childLink of item.menu.links" :key="childLink.name"
          >
            <router-link
              :to="childLink.urlPath"
              class="favorite__subListLink"
            >
              {{childLink.name}}
              <div 
                class="favorite__icon"
                @click.stop.prevent="toggleFavoriteHandler({id: childLink.id, role: user.role})"
              >
                <SvgUse
                  :width="15"
                  :height="15"
                  id="favoriteActiveMenu"
                  v-if="childLink.isFavorite"
                />
                <SvgUse
                  :width="15"
                  :height="15"
                  id="favoriteInActiveMenu"
                  v-else
                />
              </div>
            </router-link>
          </li>
        </ul>
        </template>
        <template v-else>
          <div
            class="favorite__empty"
            v-if="item.menu.isDropDownRoot"
            @click.stop
          >
            <div class="favorite__emptyTitle">У вас еще нет избранных досок</div>
            <div class="favorite__emptyText">Отмечайте доски звездочкой, чтобы легко находить их в дальнейшем.</div>
          </div>
        </template>
      </li>

    </ul>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  data() {
    return {
      hoverIcon: false
    }
  },
  methods: {
    ...mapActions({
      getFavoriteMenu: 'sidebarMenu/getFavoriteMenu',
      toggleMenuFavorites: 'sidebarMenu/toggleMenuFavorites',
      sidebarRollUpAction: 'sidebarRollUpAction'
    }),
    toggleFavoriteHandler(data) {
      this.toggleMenuFavorites(data)
      this.getFavoriteMenu(data.role)
    },
    listItemHandler(item) {
      if(this.sidebarRollUp) {
        this.sidebarRollUpAction(false)
        item.menu.isDropDownRoot = true
      } 
      else {
        item.menu.isDropDownRoot = !item.menu.isDropDownRoot
      }
    }
  },
  computed: {
    ...mapState({
      favorite: state => state.sidebarMenu.favorite,
      sidebarRollUp: state => state.sidebarRollUp,
      user: state => state.auth.user
    })
  },
  watch: {
    sidebarRollUp(newValue) {
      if(newValue) this.favorite.dropdown[0].menu.isDropDownRoot = false
    },
    user(val) {
      this.getFavoriteMenu(this.user.role)
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.favorite {
  font-family: $font-primary;
  &__list {
    &Item {
      margin-bottom: 15px;
      min-height: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      &--open &Link {
        padding-left: 20px;
      }
      &--open &Icon {
        transform: scale(1.16);
      }
      &Inner {
        display: flex;
        align-items: center;
        max-width: max-content;
        height: 100%;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        &--mod {
          justify-content: center;
          max-width: 100%;
        }
        span {
          padding-left: 40px;
          display: block;
          position: relative;
        }
      }
      &Inner:hover &Icon {
        transform: scale(1.16);
      }
      &Inner:hover &Link, &Inner:hover span {
        padding-left: 20px;
      }
      &Icon {
        z-index: 1;
        width: 25px;
        height: 25px;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform $transition-duration ease;
      }
      &Link {
        padding-left: 15px;
        transition: padding $transition-duration ease;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        height: 25px;
      }
    }
  }
  &__subList {
    padding-left: 50px;
    margin-top: 10px;
    &Item {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      position: relative;
      padding-bottom: 10px;
      cursor: pointer;
      &:last-child {
        padding-bottom: 0;
      }
    }
    &Link {
      font-size: 14px;
      line-height: 14px;
      font-weight: inherit;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        margin-bottom: 0;
      }
      &Favorite {
        flex: 0 0 100%;
        max-width: 15px;
        margin-left: 5px;
      }
    }
  }
  &__empty {
    margin-top: 10px;
    background-color: rgba($black, .1);
    padding: 20px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    cursor: default;
    &Title {
      font-weight: 700;
      margin-bottom: 10px;
    }
    &Text {
      font-size: 12px;
      line-height: 12px;
    }
  }
}

</style>