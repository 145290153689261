<template>
  <div class="filterDropDown" v-bind:class="{'filterDropDown__custom': customStyle === 'nowrap'}" @click.stop v-click-outside="close">
    {{ label }}
    <button
      class="filterDropDown__button"
      @click="dropDownIsOpen = !dropDownIsOpen"
    >
      <SvgUse
        :width="20"
        :height="20"
        id="arrowDownInActive"
        v-if="!dropDownIsOpen"
      />
      <SvgUse :width="20" :height="20" id="arrowDownActive" v-else />
    </button>
    <div class="filterDropDown__content" v-if="dropDownIsOpen">
      <button class="filterDropDown__content--button" @click="handleSearch('today')">Сегодня</button>
      <button class="filterDropDown__content--button" @click="handleSearch('yesterday')">Вчера</button>
      <button class="filterDropDown__content--button" @click="handleSearch('this_week')">Текущая неделя</button>
      <button class="filterDropDown__content--button" @click="handleSearch('last_week')">Прошлая неделя</button>
      <button class="filterDropDown__content--button" @click="handleSearch('this_month')">Текущий месяц</button>
      <button class="filterDropDown__content--button" @click="handleSearch('last_month')">Прошлый месяц</button>
      <button
        class="filterDropDown__content--button"
        @click="calendarisOpen = !calendarisOpen"
      >
        Свой период
      </button>
      <div v-if="calendarisOpen" class="filterDropDown__contentDatePicker">
        <VueDatePicker
          v-model="date"
          inline
          range
          locale="ru"
          :enable-time-picker="false"
          auto-apply
          disable-year-select
          no-today
          hide-offset-dates
          calendar-class-name="filterDropDown__contentDatePicker--calendar"
          calendar-cell-class-name="dp-custom-cell"
          :calendar="calendarFn"
          @update:model-value="vueDatePickerSelectDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { mapState, mapMutations } from "vuex";
export default {
  name: "FilterDropdown",
  components: { VueDatePicker },
  props: {
    label: {
      type: String,
      default: "",
    },
    customStyle: {
      type: String,
    },
    page: {
      type: String,
    }
  },
  data() {
    return {
      dropDownIsOpen: false,
      calendarisOpen: false,
      date: null,
      today: null,
      tomorrow: null,
    };
  },
  computed: {
    ...mapState({
      userParams: (state) => state.adminUser.userParams,
      logParams: (state) => state.adminLog.logParams,
      financeParams: (state) => state.adminFinance.financeParams,
    }),
  },
  methods: {
    ...mapMutations({
      setUserParams: 'adminUser/setUserParams',
      setLogParams: 'adminLog/setLogParams',
      setFinanceParams: 'adminFinance/setFinanceParams'
    }),

    vueDatePickerSelectDate(modelData) {
      const [startDate, endDate] = modelData
      if (this.page === 'adminUsers') {
        this.setUserParams({
          ...this.userParams,
          last_login: false,
          period: '',
          date_from: startDate,
          date_to: endDate
        })
      } else if (this.page === 'adminLogs') {
        this.setLogParams({
          ...this.logParams,
          last_login: true,
          period: '',
          date_from: startDate,
          date_to: endDate
        })
      } else if (this.page === 'adminFinance') {
        this.setFinanceParams({
          ...this.financeParams,
          search_by_period: {
            start_period: startDate.toISOString().slice(0, 10),
            end_period: endDate.toISOString().slice(0, 10)
          }
        })
      }
      this.close()
    },
    calendarFn(weeks) {
      weeks.map(week => {
        week['days'].map((day, numberOfDay) => {
          if (numberOfDay === 6 && day.text) {
            day.classData['weekend'] = true
          }
        })
      })

      return weeks
    },
    
    handleSearch(type) {
      if (this.page === "adminUsers") {
        this.setUserParams({
          ...this.userParams,
          last_login: false,
          period: type,
          date_from: '',
          date_to: ''
        })
      } else if(this.page === "adminLogs") {
        this.setLogParams({
          ...this.logParams,
          last_login: true,
          period: type,
          date_from: '',
          date_to: ''
        })
      } else if (this.page === "adminFinance") {
        if (type === 'today') {
          this.setFinanceParams({
            ...this.financeParams,
            search_by_period: {
              start_period: this.today.toISOString().slice(0, 10),
              end_period: this.tomorrow.toISOString().slice(0, 10)
            }
          })
        } else if (type === 'yesterday') {
          const yesterday = new Date()
          yesterday.setUTCHours(yesterday.getUTCHours() + 3)
          yesterday.setDate(yesterday.getDate() - 1);
          this.setFinanceParams({
            ...this.financeParams,
            search_by_period: {
              start_period: yesterday.toISOString().slice(0, 10),
              end_period: this.today.toISOString().slice(0, 10)
            }
          })
        } else if (type === 'this_week') {
          const thisWeekStart = this.startOfWeek(new Date())
          this.setFinanceParams({
            ...this.financeParams,
            search_by_period: {
              start_period: thisWeekStart.toISOString().slice(0, 10),
              end_period: this.tomorrow.toISOString().slice(0, 10)
            }
          })
        } else if (type === 'last_week') {
          const currentDate = new Date(); 
          const lastWeekDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); 
          const lastWeekStart = this.startOfWeek(lastWeekDate)
          lastWeekDate.setUTCHours(lastWeekDate.getUTCHours() + 3);
          this.setFinanceParams({
            ...this.financeParams,
            search_by_period: {
              start_period: lastWeekStart.toISOString().slice(0, 10),
              end_period: lastWeekDate.toISOString().slice(0, 10)
            }
          })
        } else if (type === 'this_month') {
          const currentDate = new Date();
          const thisMonthStart = this.startOfMonth(currentDate);
          this.setFinanceParams({
            ...this.financeParams,
            search_by_period: {
              start_period: thisMonthStart.toISOString().slice(0, 10),
              end_period: this.tomorrow.toISOString().slice(0, 10)
            }
          })
        } else if (type === 'last_month') {
          let currentDate = new Date();
          currentDate.setDate(1);
          let firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
          let lastDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          this.setFinanceParams({
            ...this.financeParams,
            search_by_period: {
              start_period: firstDayOfPreviousMonth.toISOString().slice(0, 10),
              end_period: lastDayOfPreviousMonth.toISOString().slice(0, 10)
            }
          })
        }
      }
      this.close()
    },
    handleClick() {
      this.dropDownIsOpen = !this.dropDownIsOpen;
    },
    close() {
      this.dropDownIsOpen = false;
    },
    startOfWeek(date) {
      var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      const tempDate = new Date(date.setDate(diff));
      tempDate.setUTCHours(tempDate.getUTCHours() + 3);
      return tempDate;
    },
    startOfMonth(date) {
      const start = new Date(date.getFullYear(), date.getMonth(), 1);
      start.setUTCHours(start.getUTCHours() + 3);
      return start;
    }

  },
  mounted() {
    this.today = new Date();
    this.today.setUTCHours(this.today.getUTCHours() + 3);
    this.tomorrow = new Date()
    this.tomorrow.setUTCHours(this.tomorrow.getUTCHours() + 3)
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
  },
  watch: {
    financeParams(newVal) {
      if (newVal.search_by_period.start_period === null && newVal.search_by_period.end_period === null) {
        this.date = null
      }
    }
  }
};
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";

.filterDropDown {
  position: relative;
  font-size: 15px;
  line-height: 12px;
  display: flex;
  align-items: center;
  gap: 10px;

  @include breakpoint(xl) {
    font-size: 14px;
    line-height: 11.2px;
  }

  @include breakpoint(md) {
    font-size: 15px;
    line-height: 12px;
  }

  @include breakpoint(sm) {
    text-align: center;
    font-size: 12px;
    line-height: 9.6px;
  }

  @include mediaMaxWidth(710) {
    flex-direction: column;
  }

  &__custom {
    flex-direction: row !important;
  }

  &__button {
    display: flex;
    align-items: center;
  }

  &__content {
    position: absolute;
    top: 25px;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $gray-300;
    display: flex;
    flex-flow: column;
    gap: 20px;
    z-index: 9999;
    background-color: $white;
    &--button {
      width: fit-content;
      font-size: 15px;
      line-height: 15px;
      text-align: start;
      white-space: nowrap;
    }
    &DatePicker {
      padding: 10px;
      border: 1px solid $white;
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
      &--calendar {
        .dp__calendar_header {
          display: none;
          &_separator {
            display: none;
          }
        }
      }
    }
  }

  .dp__calendar_header,
  .dp__calendar_header_separator,
  .dp__arrow_top {
    display: none;
  }

  .dp--menu-wrapper {
    position: relative;
    top: 10px !important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .dp__menu {
    border-radius: 10px;
    border: none;
  }

  .dp__main div:first-child {
    font-family: $font-primary;
  }

  .dp__month_year_select,
  .dp__cell_inner {
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 500;
  }

  .dp__range_start,
  .dp__range_end,
  .dp__date_hover {
    border-radius: 0;
  }

  .weekend {
    background-color: $gray-100;
    padding: 8px 11px;
    border-radius: 0;
  }

  .dp__range_start,
  .dp__range_between,
  .dp__range_end {
    background-color: $green-100;
    color: $white;
  }

  .dp__range_start.weekend,
  .dp__range_between.weekend,
  .dp__range_end.weekend {
    background-color: $green-300;
  }

  .dp-custom-cell {
    border: none;
  }

  .dp__calendar_row {
    margin: 0;
  }

  .dp--header-wrap {
    margin-bottom: 10px;
  }

  .dp__range_start.weekend,
  .dp__range_between.weekend,
  .dp__range_end.weekend {
    background-color: $green-300;
  }
}
</style>
