<template>
    <div v-if="type==='name'" :class="getCellClass()" :style="getCellStyle()">

            <div v-if="isSticky" class="camp-table-stub"></div>


    </div>
    <div v-else-if="type==='noedit'" :class="getCellClass()" :style="getCellStyle()">

        <div v-if="isSticky" class="camp-table-stub"></div>
<!--        <span v-if="isNumeric(value)">{{ value }}</span>-->
        <template v-if="isNumeric(value)">
            <NumTotal
                :group_id="group_id"
                :field="field"
                :value="value"
            />
        </template>

    </div>
    <div v-else-if="type==='add-new'" :class="getCellClass()" :style="getCellStyle()">
    </div>
    <div v-else-if="type==='price'"  :class="getCellClass()" :style="getCellStyle()">
        <div v-if="isSticky" class="camp-table-stub"></div>
        <NumTotal
            :group_id="group_id"
            :field="field"
            :value="value"
        />
    </div>
    <div v-else-if="type==='link'" :class="getCellClass()" :style="getCellStyle()">
        <div v-if="isSticky" class="camp-table-stub"></div>
    </div>

    <div v-else-if="type==='status'" :class="getCellClass()" :style="getCellStyle()">
        <div v-if="isSticky" class="camp-table-stub"></div>
        <Status :view_type="2" :field="field" :value="value" />
    </div>
    <div v-else-if="type==='date'" :class="getCellClass()" :style="getCellStyle()">
        <div v-if="isSticky" class="camp-table-stub"></div>
        <DateTotal
            :field="field"
            :value="value"
        />
    </div>
    <div v-else class="camp-table-cell camp-table-cell__name" :style="{ width: `${width}px` }">
        <div v-if="isSticky" class="camp-table-stub"></div>

    </div>

</template>

<script>
    import CheckboxCell from "./CheckboxCell";
    import NumTotal from "./Totals/NumTotal";
    import DateTotal from "./Totals/DateTotal";
    import Status from "./Inputs/Status";
    export default {
        name: "TableCellFooter",
        components: {Status, DateTotal, NumTotal, CheckboxCell},
        props: {
            width: {
                type: Number,
                default: 20 // Устанавливаем значение по умолчанию равным 0
            },
            value:{
                type: String,
                default: '' // Устанавливаем значение по умолчанию равным 0
            },
            title:'',
            field:null,
            group_id:0,
            withCheckbox:Boolean,
            isSticky:Boolean,
            type:String,
            color:String,
        },
        methods: {
            getCellClass() {
                if(this.width===0)
                {
                    return "display_none";
                }
                return {
                    'camp-table-cell': true,
                    'camp-table-cell-sticky camp-table-cell-rounded': this.isSticky,
                    [`camp-table-cell__${this.type}`]: true,
                };
            },

            isNumeric(val) {
                // Проверяем, является ли значение числом и не равно NaN
                return !isNaN(parseFloat(val)) && isFinite(val);
            },

            getCellStyle() {
                const style = {
                    width: `${this.width}px`,
                };

                if (this.isSticky) {
                    style.border = 'none';
                }

                return style;
            },
        },
    }
</script>

<style scoped>
.display_none{
    display: none;
}
</style>
