<template>
    <div class="camp-table-cell-inner camp-tooltip"
    >
        <VueDatePicker v-model="inputValue"

         placeholder="Выберите дату"
        :enable-time-picker="false"
        auto-apply
        locale="ru"
        :format="format"

       @click="handleFocus"
        @update:model-value="handleInput"
        >
        </VueDatePicker>

    </div>


</template>

<script>

    import {mapActions, mapMutations,mapGetters} from 'vuex'
    import Svg from "../Svg";

    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import { directive,Tippy } from 'vue-tippy'
    import 'tippy.js/dist/tippy.css'


    export default {
        name: "DateInput",
        components: {Svg, VueDatePicker,Tippy},
        data() {
            return {
                inputValue: this.value,
                isHovered: false,
            };
        },
        directives: {
            tippy: directive,
        },
        props: {
            item_id:Number,
            group_id:0,
            viewtype:{
                type: Number,
                defalut:0,
            },
            field:String,
            value: {
                type: String,

            }
        },
        methods:{

            handleFocus(){

                this.$store.commit('crm/setUiaction', {action:'open_colorpicker',group_id:this.keyid});
            },
            handleInput() {
                //console.log('Введен новый date:', this.inputValue, this.item_id);
                this.$store.commit('crm/setUiaction', {action:'close_dropdowns'});

                const date=this.inputValue;
                if(date) {
                    const day = ('0' + date.getDate()).slice(-2); // Добавляем ведущий ноль, если число меньше 10
                    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Добавляем ведущий ноль, если месяц меньше 10
                    const year = date.getFullYear();

                    const resultDate = `${year}-${month}-${day}`;

                    console.log('Дата:',  resultDate);
                    this.handleSetValue(this.item_id, resultDate);
                }
            },
            format  (date)  {


                    const day = ('0' + date.getDate()).slice(-2); // Добавляем ведущий ноль, если число меньше 10
                    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Добавляем ведущий ноль, если месяц меньше 10
                    const year = date.getFullYear();


                    // return `${year}-${month}-${day}`;
                    return `${day}.${month}.${year}`;

            },
            handleSetValue(itemid,value) {

                this.editItem({

                    obj: {
                        group_id:this.group_id,
                        item_id:itemid,
                        key_field:this.field,
                        action:itemid===0 ? 'add':'edit',
                        val: value,
                    }
                }).then((r) => {
                    console.log("response Input",r);
                })
                .catch(() => {

                })
                .finally(() => {

                })
            },
            ...mapActions({
                editItem: 'crm/editItem',
            }),
        },
    }
</script>

<style scoped>
    .tippy-box {
        background-color: #22181a;
        color: yellow;
        border: 1px solid yellow;

    }
</style>
