<template>
  <div class="adminSellerListByCategory">
    <AdminSellersListItemByCategory
      :category="category"
      @subCategoryState="handleDataFromChild"
    />
    <div class="adminSellerListByCategory__items" v-if="subCategoryIsOpen">
      <AdminSellersListBySubCategory
        v-for="subCategory in category.subCategories"
        :subCategory="subCategory"
      />
    </div>
  </div>
</template>

<script>
import AdminSellersListBySubCategory from "./AdminSellersListBySubCategory.vue";
import AdminSellersListItemByCategory from "./AdminSellersListItemByCategory.vue";

export default {
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  components: {
    AdminSellersListItemByCategory,
    AdminSellersListBySubCategory,
  },
  data() {
    return {
      subCategoryIsOpen: false,
    };
  },
  methods: {
    handleDataFromChild(data) {
      this.subCategoryIsOpen = data;
    },
  },
};
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";


.adminSellerListByCategory {
  display: flex;
  flex-flow: column;
  gap: 5px;
  &__items {
    display: flex;
    flex-flow: column;
    gap: 5px;
  }
}
</style>
