<template>
  <div class="adminPromocodesBasePopup">
    <BasePopup>
      <div :class="`adminPromocodesBasePopup__container ${classModifier}`">
        <div class="adminPromocodesBasePopup__title">
          {{ title }}
        </div>

        <div
          v-if="isLoading"
          class="adminPromocodesBasePopup__preloaderContainer">
          <BasePreloader
            class="adminPromocodesBasePopup__preloaderContainerElement"
          />
        </div>
        
        <div v-show="!isLoading">
          <slot></slot>
        </div>

        <template v-if="!isLoading">
          <BaseButton
            v-if="!isLinkGenerated"
            class="button button--primary"
            classModificator="adminPromocodesBasePopup__button"
            @click="generate"
          >{{ generateButtonTitle }}</BaseButton>

          <BaseButton
            class="button button--primary"
            classModificator="adminPromocodesBasePopup__button"
            @click="close"
            v-else
          >Готово</BaseButton>
        </template>

        
      </div>
    </BasePopup>
  </div>
</template>

<script>
import BaseButton from '@/components/Global/BaseButton.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  emits: ['generate', 'close'],
  props: {
    title: {
      type: String,
      required: true
    },
    generateButtonTitle: {
      type: String,
      required: true
    },
    classModifier: {
      type: String,
      default: ''
    },
    isLinkGenerated: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.admin.isLoading
    })
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup'
    }),
    ...mapMutations({
      clearBufferCopy: 'admin/setIsPromocodeLinkCopied',
      setIsPromocodeLinkGenerated: 'admin/setIsPromocodeLinkGenerated',
      resetPromocodeLink: 'admin/setIsPromocodeLinkCopied',
    }),
    generate() {
      YC.EventBus.emit('validations')
      this.$emit('generate')
    },
    close() {
      this.closePopup()
      this.setIsPromocodeLinkGenerated(false)
      this.clearBufferCopy(false)
      this.resetPromocodeLink(false)
      this.$emit('close')
    }
  },
  beforeUnmount() {
    this.setIsPromocodeLinkGenerated(false)
    this.resetPromocodeLink(false)
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.adminPromocodesBasePopup {
  position: relative;

  &__container {
    width: 500px;
    padding: 50px 50px 30px;

    @include breakpoint(sm) {
      width: 380px;
      padding: 50px 20px 30px;
    }

    @include mediaMaxWidth(390) {
      width: 310px;
    }
  }

  &__preloaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    &Element {
      position: relative;
    }
  }

  &__title {
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 30px;

    @include breakpoint(xl) {
      font-size: 18px;
      line-height: 18px;
    }

    @include breakpoint(md) {
      font-size: 20px;
      line-height: 20px;
    }

    @include breakpoint(sm) {
      font-size: 18px;
      line-height: 18px;
    }
  }

  &__button {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 15px;
    line-height: 12px;
    height: 50px;
    padding: 19px 40px;
    max-width: max-content;
    margin: 30px auto 0;

    @include breakpoint(xs) {
      font-size: 14px;
      line-height: 11.2px;
      padding: 19px 23px;
    }
  }
}
</style>