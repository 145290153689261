<template>
  <nav class="navigation">
    <MainMenu />

    <FavoriteMenu      
      class="navigation__favorite"
    />
  </nav>
</template>

<script>
import MainMenu from '@/components/MainMenu'
import FavoriteMenu from '@/components/FavoriteMenu'

export default {
  components: {
    MainMenu,
    FavoriteMenu
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.navigation {

  &__favorite {
    margin-top: 50px;
    @include breakpoint(md) {
      margin-top: 30px;
    }
  }

}

</style>