<template>
  <div :class="`adminPromocodesDropDown ${classModifier}`">
    <select
      v-model="selectedOption"
      class="adminPromocodesDropDown__controlsHidden"
    >
      <option :value="null">Select</option>
      <option v-for="option in options" :value="option">
        {{ option.text }}
      </option>
    </select>

    <div
      :class="`adminPromocodesDropDown__selectedOption 
      ${ isDisabled ? 'adminPromocodesDropDown__selectedOption_disabled' : '' }
      ${ isLinkGenerated ? 'adminPromocodesDropDown__selectedOption_generated' : '' }`"
      @click="switchDropDown"
    >
      <!-- С датой -->
      <div
        class="adminPromocodesDropDown__selectedOptionPlaceholder"
        v-if="withDate"
      >
        {{ selectedOption ? 
        `${ selectedOption.value !== 'null' ? selectedOption.text +' (' + transformDate(startDate) + ' - ' + transformDate(endDate) + ')' : selectedOption.text }` :
        placeholder
        }}
      </div>

      <!-- С флагом -->
      <div
        class="adminPromocodesDropDown__selectedOptionPlaceholder"
        v-else-if="withFlag"
      >
        <AdminPromocodesCurrency
          v-if="selectedOption"
          :flagID="selectedOption.flag"
          :flagSize="[20, 20]"
          :currencyText="selectedOption.text"
          :classModifier="`adminPromocodesDropDown__option ${ isLinkGenerated ? 'adminPromocodesDropDown__option_generated' : '' }`"
        />
        <div v-else>
          {{ placeholder }}
        </div>
      </div>

      <!-- По умолчанию -->
      <div class="adminPromocodesDropDown__selectedOptionPlaceholder" v-else>
        {{ selectedOption ? selectedOption.text : placeholder }}
      </div>

      <tempalte v-if="!isLinkGenerated">
        <SvgUse
          :width="20"
          :height="20"
          id="arrowDownActive"
          v-if="!isDropDownOpen"
        />

        <SvgUse :width="20" :height="20" id="arrowUpActive" v-else />
      </tempalte>
    </div>

    <template v-if="withFlag">
      <div class="adminPromocodesDropDown__options" v-show="isDropDownOpen">
        <AdminPromocodesCurrency
          v-for="option in options"
          @click="selectOption(option)"
          :flagID="option.flag"
          :flagSize="[20, 20]"
          :currencyText="option.text"
          classModifier="adminPromocodesDropDown__option"
        />
      </div>

      <div 
        v-if="hasValidationErrors && !isDisabled"
        class="adminPromocodesDropDown__notice"
      >
        <span
          v-for="validation in validations"
          :key="validation.message"
          v-show="!validation.rule"
        >
          {{ validation.message }}
        </span>
      </div>
    </template>

    <template v-else>
      <div class="adminPromocodesDropDown__options" v-show="isDropDownOpen">
        <div
          v-for="option in options"
          class="adminPromocodesDropDown__option"
          @click="selectOption(option)"
          v-if="!isLinkGenerated"
        >
          {{ option.text }}
        </div>
      </div>

      <div 
        v-if="hasValidationErrors && !isDisabled"
        class="adminPromocodesDropDown__notice"
      >
        <span
          v-for="validation in validations"
          :key="validation.message"
          v-show="!validation.rule"
        >
          {{ validation.message }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import AdminPromocodesCurrency from '@/views/Admin/AdminPromocodes/AdminPromocodesCurrency'
import { mapState, mapMutations } from 'vuex'

export default {
  emits: ['selectOption'],
  props: {
    placeholder: {
      type: String,
      require: true,
    },
    options: {
      type: Array,
      require: true,
    },
    withFlag: {
      type: Boolean,
      default: false,
    },
    withDate: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    classModifier: {
      type: String,
      default: '',
    },
    validations: {
      type: Array,
      default() {
        return []
      },
    },
    isLinkGenerated: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      isDropDownOpen: false,
      selectedOption: null,
      startDate: new Date(),
      hasValidationErrors: false,
    }
  },
  computed: {
    ...mapState({
      endDate: (state) => state.admin.promocodeValidityPeriod
    })
  },
  methods: {
    ...mapMutations({
      setEndDate: 'admin/setPromocodeValidityPeriod'
    }),
    selectOption(option) {
      if (this.withDate) {
        let endDateCopy = JSON.stringify(this.startDate)
        endDateCopy = new Date(JSON.parse(endDateCopy))

        if (option.value === 'week') {
          endDateCopy.setDate(endDateCopy.getDate() + 7)
        } else if (option.value === 'month') {
          endDateCopy.setMonth(endDateCopy.getMonth() + 1)
        } else if (option.value === 'month3') {
          endDateCopy.setMonth(endDateCopy.getMonth() + 3)
        } else if (option.value === 'year') {
          endDateCopy.setFullYear(endDateCopy.getFullYear() + 1)
        } else if (option.value === 'null') {
          endDateCopy = 'null'
        }

        this.setEndDate(endDateCopy)
      }

      this.selectedOption = option
      this.isDropDownOpen = false

      this.$emit('selectOption', option.value)
    },
    transformDate(date) {
      return date.toLocaleString().slice(0, 10)
    },
    switchDropDown() {
      if (this.isDisabled || this.isLinkGenerated) {
        return
      } else {
        if (this.isDropDownOpen) {
          this.isDropDownOpen = false
        } else {
          this.isDropDownOpen = true
        }
      }
    },
    checkValidationErrors() {
      this.hasValidationErrors = false
      this.validations.forEach((validation) => {
        if (!validation.rule) {
          this.hasValidationErrors = true
        }
      })
    },
  },
  components: {
    AdminPromocodesCurrency,
  },
  mounted() {
    YC.EventBus.on('validations', this.checkValidationErrors)
    if(this.withFlag) {
      this.selectedOption = this.options[0]
      this.$emit('selectOption', this.options[0].value)
    }
  },
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.adminPromocodesDropDown {
  background-color: $white;
  color: $black;

  &__controlsHidden {
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: hidden;
  }

  &__selectedOptionPlaceholder,
  &__option {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    @include breakpoint(xl) {
      font-size: 14px;
      line-height: 17px;
    }

    @include breakpoint(md) {
      font-size: 15px;
      line-height: 18px;
    }

    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__selectedOption {
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 20px;
    justify-items: center;
    align-content: center;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: $box-shadow-input;
    cursor: pointer;
    align-items: center;

    & svg {
      transform: translate(-20px, 5px);

      @include breakpoint(xs) {
        transform: translate(-5px, 2px);
      }
    }

    &_disabled,
    &_generated {
      cursor: auto;
    }

    &_generated {
      grid-template-columns: 1fr;
    }
  }

  &__options {
    border-radius: 10px;
    box-shadow: $box-shadow-input;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 20px;
  }

  &__option {
    cursor: pointer;
    padding: 10px;

    &_generated {
      cursor: auto;
    }
  }

  &__notice {
    margin-top: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $red;
  }
}
</style>
