<template>
  <div class="globalSetting">
    <!-- <div class="globalSetting__fieldSwitcher">

      <BaseSwitcher
        class="switcher--globalSetting"
        :name="'Включить биддер'"
        :transparent="true"
      />

      <BaseTooltip
        :gray="true"
        :content="'Глобальное состояние бидера: включен или выключен. Если бидер выключен, то ни на один товар цены не будут устанавливаться автоматически'"
      />

    </div> -->

    <div class="globalSetting__fieldButton">

      <span>Поиск лидера у всех товаров</span>

      <BaseButton
        class="globalSetting__button button--primary"
        @click="searchAllLeadersHandler"
      >
        Искать
      </BaseButton>
      <BaseTooltip
        :gray="true"
        :content="'Автоматическая установка цен для всех ваших товаров. Скрипт для каждого вашего товара выбирает лидера продаж конкурентов на основании статистики, по ключевым словам, и устанавливает его цену минус 10 рублей на ваш товар'"
      />

    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  methods: {
    ...mapActions({
      openPopup: 'popup/openPopup',
      searchAllLeaders: 'priceBidderSingleProduct/searchAllLeaders'
    }),
    searchAllLeadersHandler() {
      if(this.products.length) {
        this.openPopup({
          component: 'PBBasicProductLeaderSearchPopup'
        })    

        this.searchAllLeaders(this.currentStore.id)
      }
    }
  },
  computed: {
    ...mapState({
      currentStore: state => state.userStore.currentStore,
      products: state => state.priceBidderBasicProducts.products
    })
  },
  mounted() {
    setTimeout(YC.tippyMount, 1000)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.globalSetting {

  position: relative;
  font-size: 15px;

  &__field {
    &Switcher {
      position: relative;
    }
    &Button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      max-width: 352px;
      width: 100%;
      > span {
        margin-right: 20px;
        @include breakpoint(xl) {
          font-size: 13px;
          line-height: 13px;
        }
        @include breakpoint(md) {
          font-size: 14px;
          line-height: 11px;
          margin-right: 10px;
        }
      }
      @include breakpoint(xl) {
        max-width: 321px;
      }
      @include breakpoint(md) {
        max-width: 100%;
      }
    }
  }

  &__buttonWrap {
    position: relative;
  }

  &__switcher {
    font-weight: 500;
    @include breakpoint(xs) {
      margin-bottom: 10px;
    }
  }

  &__button {
    height: 25px;
    flex: 0 0 100px;
    font-weight: 300;
    font-size: 14px;
    @include breakpoint(md) {
      height: 30px;
    }
  }

}
</style>
