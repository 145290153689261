import baseApi from '@/api/baseApi'

export default class crmApi extends baseApi {

  baseCrmUrl;
  crmroutes;
  constructor() {
    super();
    this.baseCrmUrl='crm/';
    this.crmroutes = [
          'aditem',
          'blogger',
          'ads-wb',
          'money-wb',
          'orders',
          'cost',
          'product-info',
          'unit-economics',
          'product',
          'purchase-batches',
          'seo',
          'dashboard',
          'turnover',
          'sale',

      ];
  }

  getDeskData(desk_id,query) {
    if (desk_id >= 1 && desk_id <= this.crmroutes.length) {

            let addquery='';

            if(query)
            {
                addquery='?q='+query;
            }
            console.log('deskurl=', this.baseCrmUrl + this.crmroutes[desk_id - 1] + '/desk-data'+addquery);
            return this.get(this.baseCrmUrl + this.crmroutes[desk_id - 1] + '/desk-data'+addquery);

    } else {
        return "Desk is not set";
    }
  }
  postItemData(desk_id,payload){

      console.log("postItemData",desk_id);
      if (desk_id >= 1 && desk_id <= this.crmroutes.length) {
          //console.log("postItemData",desk_id,payload,this.baseCrmUrl+this.crmroutes[desk_id - 1]+'/edit-item');

          return this.post(this.baseCrmUrl+this.crmroutes[desk_id - 1]+'/edit-item', payload);
      } else {
          return "Desk is not set";
      }
  }

  postStatusesEdit(desk_id,payload){

      //console.log("postStatuses",desk_id);

      return this.post(this.baseCrmUrl+'edit-status', payload);

  }

  postGroupData(desk_id,payload){

      if (desk_id >= 1 && desk_id <= this.crmroutes.length) {

          return this.post(this.baseCrmUrl+'groups/edit', payload);
      } else {
          return {error:"Desk is not set"};
      }
  }

    postExtracolData(desk_id,payload){

        console.log("postExtraColData",desk_id,payload);
        if (desk_id >= 1 && desk_id <= this.crmroutes.length) {

            if(payload.del) {
                return this.post(this.baseCrmUrl + 'extracol/del', payload);
            }
            else if (payload.edit) {
                return this.post(this.baseCrmUrl + 'extracol/edit', payload);
            }
            else{
                return this.post(this.baseCrmUrl + 'extracol/add', payload);
            }
        } else {
            return {error:"Desk is not set"};
        }
    }
  
  getDashboardDiagram() {

    return this.get(`crm/dashboard/get-graph`);

  }
}

