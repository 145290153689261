<template>
  <BasePopup
    :cannotBeClosed="!done"
  >

    <div class="leaderSearchPopup">

      <div
        class="leaderSearchPopup__firstStep"
        v-if="!done"
      >
        <header class="leaderSearchPopup__firstStepHeader">
          <div class="leaderSearchPopup__firstStepTitlePrimary titlePrimary titlePrimary--small">Установка цены и скидки у всех товаров</div>
          <div class="leaderSearchPopup__firstStepSubtitle">Бидер определит лидера конкурентов по всем вашим товарам и поставит конкурентоспособную цену и скидку на ваш товар</div>
        </header>

        <div
          class="leaderSearchPopup__firstStepProgress"
          :class="{'leaderSearchPopup__firstStepProgress--half': half}"
        >
          <span>{{percent}}%</span>
          <div class="leaderSearchPopup__firstStepProgressBar" :style="`width: ${percent}%;`"></div>
        </div>
      </div>

      <div
        class="leaderSearchPopup__secondStep"
        v-else
      >
        <div class="leaderSearchPopup__secondStepTitlePrimary titlePrimary titlePrimary--small">Готово!</div>
        <picture class="leaderSearchPopup__secondStepPicture">
          <source type="image/webp" srcset="/images/leaderSearchPopup-image-2x.webp 2x, /images/leaderSearchPopup-image.webp 1x">
          <img src="/images/leaderSearchPopup-image.webp" alt="" class="leaderSearchPopup__secondStepImage">
        </picture>
      </div>

    </div>

  </BasePopup>
</template>

<script>

export default {
  name: 'PBBasicProductLeaderSearchPopup',
  data() {
    return {
      done: false,
      percent: 0,
      half: false
    }
  },
  mounted() {
    let process
    setTimeout(() => {
      process = setInterval(() => {
        if(this.percent >= 60) this.half = true
        this.percent += 1
      }, 30)
    }, 500)

    setTimeout(() => {
      clearInterval(process)
      this.done = true
    }, 3500)

  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.leaderSearchPopup {

  width: 600px;
  max-width: 100%;
  text-align: center;
  @include breakpoint(md) {
    width: 340px;
  }
  @include breakpoint(sm) {
    width: 320px;
  }
  @include breakpoint(xs) {
    width: 280px;
  }

  &__firstStep {
    border-radius: 20px;
    overflow: hidden;
    &Header {
      padding: 37px 60px 30px;
      @include breakpoint(md) {
        padding: 50px 30px;
      }
      @include breakpoint(sm) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include breakpoint(sm) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &TitlePrimary {
      margin-bottom: 10px;
      @include breakpoint(md) {
        margin-bottom: 20px;
      }
    }
    &Subtitle {
      font-size: 14px;
      line-height: 14px;
      @include breakpoint(xl) {
        font-size: 13px;
        line-height: 13px;
      }
      @include breakpoint(md) {
        font-size: 14px;
        line-height: 14px;
      }
    }
    &Progress {
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @include breakpoint(md) {
        height: 50px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 2px 4px 0 rgba($black,.15) inset;
        z-index: 1;
      }
      &--half {
        span {
          color: $white;
        }
      }
      span {
        position: relative;
        z-index: 1;
        @include breakpoint(xl) {
          font-size: 14px;
        }
        @include breakpoint(md) {
          font-size: 16px;
        }
      }
      &Bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-image: $progressbar-bg;
        transition: all $transition-duration ease;
      }
    }
  }

  &__secondStep {
    padding: 70px 0;
    position: relative;
    @include breakpoint(md) {
      padding: 50px 0 210px;
    }
    &Picture {
      position: absolute;
      bottom: 0;
      left: 20px;
      @include breakpoint(md) {
        left: 50%;
        transform: translate(-50%);
      }
    }
  }

}

</style>