<template>
    <template v-if="img==='close'">
        <!-- close.svg -->

        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 72 72" xml:space="preserve">
  <path d="M55.828,16.171c1.562,1.562,1.562,4.095,0,5.657l-34,34C21.048,56.609,20.023,57,19,57s-2.048-0.391-2.828-1.171
  c-1.562-1.562-1.562-4.095,0-5.657l34-34C51.732,14.61,54.268,14.61,55.828,16.171z"></path>
            <path d="M55.828,55.829c-1.561,1.562-4.096,1.562-5.656,0l-34-34c-1.562-1.562-1.562-4.095,0-5.657C16.952,15.391,17.977,15,19,15
  s2.048,0.391,2.828,1.171l34,34C57.391,51.734,57.391,54.266,55.828,55.829z"></path>
</svg>
    </template>
    <template v-else-if="img==='trash'">
        <!-- trash.svg -->
        <svg viewBox="0 0 19 21" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.70801 5.23788H15.708M7.45801 2.59082H11.958M8.20801 14.0614V8.76729M11.208 14.0614V8.76729M12.333 17.5908H7.08301C6.25458 17.5908 5.58301 16.8007 5.58301 15.8261L5.24056 6.15697C5.2228 5.65568 5.56344 5.23788 5.98991 5.23788H13.4261C13.8526 5.23788 14.1932 5.65568 14.1755 6.15697L13.833 15.8261C13.833 16.8007 13.1614 17.5908 12.333 17.5908Z"></path>
        </svg>
    </template>
    <template v-else-if="img==='menu'">

        <svg viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 2.83105H21"></path>
            <path d="M1 7.83105H21"></path>
            <path d="M1 12.8311H21"></path>
        </svg>
    </template>
    <template v-else-if="img==='arrow'">
        <!-- arrow.svg -->
        <svg viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.72941 11.0675L7.9817 10.3198L7.23403 11.0675C7.64699 11.4804 8.31647 11.4804 8.72941 11.0675ZM7.9817 8.82433L3.08978 3.93251C2.67682 3.51957 2.00741 3.51957 1.59445 3.93251C1.18149 4.34545 1.18149 5.01499 1.59445 5.42793L7.23403 11.0675L7.9817 10.3198L8.72941 11.0675L14.369 5.42793C14.7819 5.01499 14.7819 4.34546 14.369 3.93251C13.9561 3.51957 13.2865 3.51957 12.8736 3.93251L7.9817 8.82433Z"></path>
        </svg>
    </template>
    <template v-else-if="img==='sorting-up'">

        <svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.70711 1.17375C8.31658 0.783228 7.68342 0.783228 7.29289 1.17375L0.928932 7.53771C0.538408 7.92824 0.538408 8.5614 0.928932 8.95193C1.31946 9.34245 1.95262 9.34245 2.34315 8.95193L8 3.29507L13.6569 8.95193C14.0474 9.34245 14.6805 9.34245 15.0711 8.95193C15.4616 8.5614 15.4616 7.92824 15.0711 7.53771L8.70711 1.17375ZM9 13.8809L9 1.88086L7 1.88086L7 13.8809L9 13.8809Z"></path>
        </svg>
    </template>
    <template v-else-if="img==='ic-icons'">

        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 72 72" xml:space="preserve">
                        <path d="M55.828,16.171c1.562,1.562,1.562,4.095,0,5.657l-34,34C21.048,56.609,20.023,57,19,57s-2.048-0.391-2.828-1.171
                        c-1.562-1.562-1.562-4.095,0-5.657l34-34C51.732,14.61,54.268,14.61,55.828,16.171z"/>
            <path d="M55.828,55.829c-1.561,1.562-4.096,1.562-5.656,0l-34-34c-1.562-1.562-1.562-4.095,0-5.657C16.952,15.391,17.977,15,19,15
                        s2.048,0.391,2.828,1.171l34,34C57.391,51.734,57.391,54.266,55.828,55.829z"/>
        </svg>
    </template>
    <template v-else-if="img==='sorting-down'">
        <!-- sorting-down.svg -->
        <svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.29289 13.588C7.68342 13.9785 8.31658 13.9785 8.70711 13.588L15.0711 7.224C15.4616 6.83348 15.4616 6.20032 15.0711 5.80979C14.6805 5.41927 14.0474 5.41927 13.6569 5.80979L8 11.4666L2.34315 5.80979C1.95262 5.41927 1.31946 5.41927 0.928932 5.80979C0.538408 6.20032 0.538408 6.83348 0.928932 7.22401L7.29289 13.588ZM7 0.880859L7 12.8809L9 12.8809L9 0.880859L7 0.880859Z"></path>
        </svg>
    </template>
    <template v-else-if="img==='search'">
        <!-- search.svg -->
        <svg  viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4569 14.6803C11.8224 15.9506 9.7655 16.5499 7.70494 16.3561C5.64437 16.1624 3.73509 15.1901 2.36585 13.6373C0.996619 12.0846 0.270386 10.068 0.335027 7.99835C0.399669 5.92866 1.25032 3.96143 2.71379 2.49723C4.17725 1.03302 6.14348 0.18193 8.21212 0.117256C10.2808 0.0525817 12.2963 0.779184 13.8482 2.14912C15.4002 3.51905 16.372 5.4293 16.5656 7.49092C16.7593 9.55253 16.1603 11.6105 14.8907 13.2458L20.3311 18.6686L18.8871 20.1133L13.467 14.6803H13.4569ZM8.47408 14.3547C9.27533 14.3547 10.0687 14.1968 10.809 13.8901C11.5492 13.5833 12.2218 13.1336 12.7884 12.5668C13.355 11.9999 13.8044 11.327 14.111 10.5864C14.4176 9.84573 14.5754 9.05193 14.5754 8.25028C14.5754 7.44863 14.4176 6.65483 14.111 5.9142C13.8044 5.17357 13.355 4.50062 12.7884 3.93377C12.2218 3.36692 11.5492 2.91727 10.809 2.61049C10.0687 2.30371 9.27533 2.14582 8.47408 2.14582C6.8559 2.14582 5.304 2.78896 4.15977 3.93377C3.01555 5.07858 2.37273 6.63127 2.37273 8.25028C2.37273 9.86928 3.01555 11.422 4.15977 12.5668C5.304 13.7116 6.8559 14.3547 8.47408 14.3547Z"/>
        </svg>
    </template>
    <template v-else-if="img==='duplicate'">
        <!-- duplicate.svg -->
        <svg viewBox="0 0 17 18" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.95355 4.3162V6.65815C7.95355 7.08929 8.30306 7.4388 8.7342 7.4388H11.0761M12.6374 1.1936H7.17272C6.31044 1.1936 5.61142 1.89262 5.61142 2.75489V4.3162M12.6374 1.1936H13.0729C13.2928 1.1936 13.502 1.2862 13.6535 1.44554C13.8811 1.68501 14.2416 2.05371 14.5891 2.36458C14.8614 2.60822 15.2892 3.04546 15.5454 3.3112C15.6839 3.45491 15.76 3.64679 15.76 3.8464L15.76 4.3162M12.6374 1.1936V3.53555C12.6374 3.96669 12.987 4.3162 13.4181 4.3162H15.76M15.76 4.3162L15.7598 12.1227C15.7598 12.985 15.0608 13.684 14.1985 13.684H11.0759M9.90517 5.48718C9.55773 5.17631 9.1972 4.80761 8.9696 4.56814C8.81814 4.4088 8.60888 4.3162 8.38904 4.3162H2.48882C1.62654 4.3162 0.927526 5.01521 0.92752 5.87749L0.92746 15.2453C0.927454 16.1075 1.62647 16.8066 2.48875 16.8066L9.51463 16.8066C10.3769 16.8066 11.0759 16.1076 11.0759 15.2453L11.0761 6.969C11.0761 6.76939 11 6.57751 10.8615 6.4338C10.6053 6.16806 10.1775 5.73082 9.90517 5.48718Z" />
        </svg>
    </template>
    <template v-else-if="img==='draggable'">
        <!-- draggable.svg -->
        <svg  viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg">
            <path  d="M2.0625 0.764648C2.89093 0.764648 3.5625 1.20587 3.5625 1.75014V22.7792C3.5625 23.3234 2.89093 23.7646 2.0625 23.7646C1.23407 23.7646 0.5625 23.3234 0.5625 22.7792V1.75014C0.5625 1.20587 1.23407 0.764648 2.0625 0.764648ZM7.0625 0.764648C7.89093 0.764648 8.5625 1.20587 8.5625 1.75014V22.7792C8.5625 23.3234 7.89093 23.7646 7.0625 23.7646C6.23407 23.7646 5.5625 23.3234 5.5625 22.7792V1.75014C5.5625 1.20587 6.23407 0.764648 7.0625 0.764648ZM12.0625 0.764648C12.8909 0.764648 13.5625 1.20587 13.5625 1.75014V22.7792C13.5625 23.3234 12.8909 23.7646 12.0625 23.7646C11.2341 23.7646 10.5625 23.3234 10.5625 22.7792V1.75014C10.5625 1.20587 11.2341 0.764648 12.0625 0.764648Z" />
        </svg>
    </template>
    <template v-else-if="img==='arrow-up'">
        <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0382 2.40617C10.6476 2.01565 10.0145 2.01565 9.62395 2.40617L3.25999 8.77013C2.86946 9.16066 2.86946 9.79382 3.25999 10.1843C3.65051 10.5749 4.28368 10.5749 4.6742 10.1843L10.3311 4.52749L15.9879 10.1843C16.3784 10.5749 17.0116 10.5749 17.4021 10.1843C17.7926 9.79383 17.7926 9.16066 17.4021 8.77014L11.0382 2.40617ZM11.3311 18.1133L11.3311 3.11328L9.33106 3.11328L9.33105 18.1133L11.3311 18.1133Z"/>
        </svg>
    </template>
    <template v-else-if="img==='arrow-down'">
        <!-- arrow-down.svg -->
        <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.62395 17.8204C10.0145 18.2109 10.6476 18.2109 11.0382 17.8204L17.4021 11.4564C17.7926 11.0659 17.7926 10.4327 17.4021 10.0422C17.0116 9.65169 16.3784 9.65169 15.9879 10.0422L10.3311 15.6991L4.6742 10.0422C4.28368 9.65169 3.65051 9.65169 3.25999 10.0422C2.86946 10.4327 2.86946 11.0659 3.25999 11.4564L9.62395 17.8204ZM9.33105 2.11328L9.33105 17.1133L11.3311 17.1133L11.3311 2.11328L9.33105 2.11328Z" />
        </svg>
    </template>
    <template v-else-if="img==='person'">
        <!-- person.svg -->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" >
            <path  d="M7.20207 4.3654C8.03223 3.53522 9.15818 3.06885 10.3323 3.06885C11.5062 3.06885 12.6322 3.53522 13.4623 4.3654C14.2925 5.19556 14.759 6.32151 14.759 7.49555C14.759 8.66959 14.2925 9.79554 13.4623 10.6257C12.6322 11.4559 11.5062 11.9223 10.3323 11.9223C9.15818 11.9223 8.03223 11.4559 7.20207 10.6257C6.37191 9.79554 5.90552 8.66959 5.90552 7.49555C5.90552 6.32151 6.37191 5.19556 7.20207 4.3654ZM10.3323 4.95564C9.6586 4.95564 9.01256 5.22324 8.53623 5.69956C8.05991 6.17589 7.79231 6.82193 7.79231 7.49555C7.79231 8.16917 8.05991 8.81521 8.53623 9.29154C9.01256 9.76787 9.6586 10.0355 10.3323 10.0355C11.0059 10.0355 11.6519 9.76787 12.1283 9.29154C12.6045 8.81521 12.8722 8.16917 12.8722 7.49555C12.8722 6.82193 12.6045 6.17589 12.1283 5.69956C11.6519 5.22324 11.0059 4.95564 10.3323 4.95564Z" />
            <path  d="M10.3311 0.113281C7.67889 0.113281 5.13536 1.16685 3.25999 3.04222C1.38463 4.91758 0.331055 7.46112 0.331055 10.1133C0.331055 12.7655 1.38463 15.309 3.25999 17.1843C3.41583 17.3402 3.5763 17.4904 3.74105 17.6348C3.75282 17.6455 3.7649 17.656 3.77731 17.6664C5.58992 19.2393 7.91543 20.1133 10.3311 20.1133C12.9833 20.1133 15.5268 19.0597 17.4021 17.1843C19.2775 15.309 20.3311 12.7655 20.3311 10.1133C20.3311 7.46112 19.2775 4.91758 17.4021 3.04222C15.5268 1.16685 12.9833 0.113281 10.3311 0.113281ZM5.66655 16.7516C7.02339 17.705 8.65034 18.2265 10.3311 18.2265C12.0117 18.2265 13.6385 17.7051 14.9952 16.7518C14.5206 16.1709 13.94 15.6817 13.2818 15.3118C12.3806 14.8054 11.3644 14.5394 10.3308 14.5394C9.29719 14.5394 8.28097 14.8054 7.37988 15.3118C6.72172 15.6817 6.14117 16.1708 5.66655 16.7516ZM16.4034 15.4939C17.7145 14.0143 18.4443 12.1015 18.4443 10.1133C18.4443 7.96153 17.5894 5.89791 16.0679 4.37639C14.5464 2.85487 12.4828 2.00007 10.3311 2.00007C8.17929 2.00007 6.11567 2.85487 4.59415 4.37639C3.07263 5.89791 2.21785 7.96153 2.21785 10.1133C2.21785 12.1013 2.94748 14.014 4.25846 15.4937C4.87177 14.7589 5.61549 14.1391 6.45549 13.667C7.63884 13.002 8.97341 12.6527 10.3308 12.6527C11.6882 12.6527 13.0228 13.002 14.2062 13.667C15.0463 14.1391 15.7901 14.7591 16.4034 15.4939Z" />
        </svg>
    </template>
    <template v-else-if="img==='filter'">
        <!-- filter.svg -->
        <svg viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
            <path  d="M20.1524 1.43693C20.4648 2.10553 20.3618 2.8945 19.8882 3.4605L13.4425 11.1647V17.0731C13.4425 17.8642 12.9523 18.5726 12.212 18.8513L9.78941 19.7637C9.20575 19.9834 8.55139 19.9028 8.03852 19.5479C7.52566 19.1931 7.2196 18.6091 7.2196 17.9854V11.1647L0.773866 3.4605C0.300329 2.8945 0.197329 2.10553 0.509691 1.43693C0.822041 0.768339 1.49327 0.341064 2.23121 0.341064H18.4309C19.1688 0.341064 19.84 0.768339 20.1524 1.43693ZM18.4309 2.24121H2.23121L8.97619 10.3031C9.069 10.4141 9.11975 10.5533 9.11975 10.6971V17.9854L11.5423 17.0731V10.6971C11.5423 10.5533 11.593 10.4141 11.6859 10.3031L18.4309 2.24121Z" />
        </svg>
    </template>
    <template v-else-if="img==='close1'">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 72 72" xml:space="preserve">
	<path d="M55.828,16.171c1.562,1.562,1.562,4.095,0,5.657l-34,34C21.048,56.609,20.023,57,19,57s-2.048-0.391-2.828-1.171
	c-1.562-1.562-1.562-4.095,0-5.657l34-34C51.732,14.61,54.268,14.61,55.828,16.171z"></path>
            <path d="M55.828,55.829c-1.561,1.562-4.096,1.562-5.656,0l-34-34c-1.562-1.562-1.562-4.095,0-5.657C16.952,15.391,17.977,15,19,15
	s2.048,0.391,2.828,1.171l34,34C57.391,51.734,57.391,54.266,55.828,55.829z"></path>
</svg>
    </template>
    <template v-else-if="img==='tumbler'">
        <!-- tumbler.svg -->
        <svg viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.9349 1.7451C3.9349 2.58713 3.23694 3.26974 2.37596 3.26974C1.51498 3.26974 0.817017 2.58713 0.817017 1.7451C0.817017 0.903063 1.51498 0.220459 2.37596 0.220459C3.23694 0.220459 3.9349 0.903063 3.9349 1.7451Z" />
            <path  d="M0.876163 1.7451C0.876163 1.34725 1.19869 1.02472 1.59654 1.02472H14.445C14.8428 1.02472 15.1654 1.34725 15.1654 1.7451C15.1654 2.14295 14.8428 2.46547 14.445 2.46547H1.59654C1.19869 2.46547 0.876163 2.14295 0.876163 1.7451Z" />
            <path d="M9.58002 5.51475C9.58002 6.35679 8.88205 7.03939 8.02106 7.03939C7.16008 7.03939 6.46211 6.35679 6.46211 5.51475C6.46211 4.67272 7.16008 3.99011 8.02106 3.99011C8.88205 3.99011 9.58002 4.67272 9.58002 5.51475Z" />
            <path  d="M0.87611 5.51475C0.87611 5.1169 1.19863 4.79438 1.59648 4.79438H14.445C14.8429 4.79438 15.1654 5.1169 15.1654 5.51475C15.1654 5.9126 14.8429 6.23513 14.445 6.23513H1.59648C1.19863 6.23513 0.87611 5.9126 0.87611 5.51475Z" />
            <path d="M15.1831 9.2844C15.1831 10.1264 14.4851 10.809 13.6242 10.809C12.7632 10.809 12.0652 10.1264 12.0652 9.2844C12.0652 8.44237 12.7632 7.75976 13.6242 7.75976C14.4851 7.75976 15.1831 8.44237 15.1831 9.2844Z" />
            <path  d="M0.87611 9.2844C0.87611 8.88655 1.19863 8.56403 1.59648 8.56403H14.4449C14.8428 8.56403 15.1653 8.88655 15.1653 9.2844C15.1653 9.68225 14.8428 10.0048 14.4449 10.0048H1.59648C1.19863 10.0048 0.87611 9.68225 0.87611 9.2844Z"/>
        </svg>
    </template>
    <template v-else-if="img==='stateIcon'">
        <!-- stateIcon.svg -->
        <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7369 20.6209C22.7369 21.7715 21.817 22.7043 20.6821 22.7043L6.04173 22.7043C4.9069 22.7043 3.98694 21.7715 3.98694 20.6209L3.98694 17.4959C3.98694 16.3453 4.9069 15.4126 6.04173 15.4126L20.6821 15.4126C21.817 15.4126 22.7369 16.3453 22.7369 17.4959V20.6209Z"  />
            <path d="M22.7369 6.03759C22.7369 4.88699 21.817 3.95425 20.6821 3.95425L6.04173 3.95425C4.9069 3.95425 3.98694 4.88699 3.98694 6.03759L3.98694 9.16259C3.98694 10.3132 4.9069 11.2459 6.04173 11.2459H20.6821C21.817 11.2459 22.7369 10.3132 22.7369 9.16259V6.03759Z" />
        </svg>
    </template>
    <template v-else-if="img==='numberIcon'">
        <!-- numberIcon.svg -->
        <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.80888 14.7075H10.8619M10.8619 14.7075H12.7726M10.8619 14.7075V7.2118C10.8619 7.2118 9.42869 8.19715 8.51034 8.82852M20.2683 11.3292C20.2683 16.5242 16.0569 20.7356 10.8619 20.7356C5.66694 20.7356 1.45557 16.5242 1.45557 11.3292C1.45557 6.13425 5.66694 1.92288 10.8619 1.92288C16.0569 1.92288 20.2683 6.13425 20.2683 11.3292Z" />
        </svg>
    </template>
    <template v-else-if="img==='textIcon'">
        <!-- textIcon.svg -->
        <svg viewBox="0 0 22 21"xmlns="http://www.w3.org/2000/svg">
            <path d="M9.13611 14.3761H10.9816M10.9816 14.3761H12.9124M10.9816 14.3761V6.28235M10.9816 6.28235H7.97131C7.65202 6.28235 7.39319 6.54118 7.39319 6.86047V7.71066M10.9816 6.28235H13.7526C14.0719 6.28235 14.3307 6.54118 14.3307 6.86047V7.94871M3.92444 19.5792H17.7994C19.0766 19.5792 20.1119 18.5439 20.1119 17.2667V3.39172C20.1119 2.11457 19.0766 1.07922 17.7994 1.07922H3.92444C2.64728 1.07922 1.61194 2.11457 1.61194 3.39172V17.2667C1.61194 18.5439 2.64728 19.5792 3.92444 19.5792Z"  />
        </svg>
    </template>
    <template v-else-if="img==='calendarIcon'">
        <!-- calendarIcon.svg -->
        <svg  viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.93486 18.7669V18.6863M13.6224 18.7669V18.6863M13.6224 14.4006V14.3201M17.789 14.4006V14.3201M5.80986 10.1149H20.3932M7.69478 3.95422V5.56155M18.3099 3.95422V5.56136M18.3099 5.56136H7.89319C6.1673 5.56136 4.76819 7.00044 4.76819 8.77563V19.49C4.76819 21.2651 6.1673 22.7042 7.89319 22.7042H18.3099C20.0357 22.7042 21.4349 21.2651 21.4349 19.49L21.4349 8.77563C21.4349 7.00044 20.0357 5.56136 18.3099 5.56136Z" />
        </svg>
    </template>
    <template v-else-if="img==='inputFile'">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 72 72" xml:space="preserve">
            <path d="M55.828,16.171c1.562,1.562,1.562,4.095,0,5.657l-34,34C21.048,56.609,20.023,57,19,57s-2.048-0.391-2.828-1.171
            c-1.562-1.562-1.562-4.095,0-5.657l34-34C51.732,14.61,54.268,14.61,55.828,16.171z"/>
            <path d="M55.828,55.829c-1.561,1.562-4.096,1.562-5.656,0l-34-34c-1.562-1.562-1.562-4.095,0-5.657C16.952,15.391,17.977,15,19,15
            s2.048,0.391,2.828,1.171l34,34C57.391,51.734,57.391,54.266,55.828,55.829z"/>
        </svg>
    </template>
    <template v-else-if="img ==='pencil'">
        <svg  viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.53281 3.14703L9.92397 5.53819M1.55493 11.5161L4.45488 10.9318C4.60882 10.9008 4.75018 10.825 4.86119 10.7139L11.353 4.21852C11.6642 3.9071 11.664 3.40231 11.3525 3.09115L9.97733 1.71751C9.66594 1.40648 9.1614 1.40669 8.85028 1.71798L2.35781 8.21402C2.24701 8.32488 2.17135 8.46595 2.1403 8.61958L1.55493 11.5161Z" />
        </svg>
    </template>
    <template v-else-if="img ==='expand'">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.91009 5.14399H12.4601V9.69399H13.7601V3.84399H7.91009V5.14399ZM5.15938 7.89208H3.85937V13.7421H9.70938V12.4421H5.15938V7.89208Z" fill="#CACACA"/>
        </svg>
    </template>
</template>

<script>
    export default {
        name: "Svg",
        props:{
            img:'',
        }
    }
</script>

<style scoped>

</style>
