<template>
    <div class="camp-table-cell-inner camp-tooltip" data-tippy-content="Добавить описание"
         :style="getCellInnerStyle()"
         :class="getCustomClass()">

        <template v-if="viewtype===1">

                <div class="camp-table-cell__text-editor camp__is-visible">
                    <div class="camp-table-cell__text-editor_display">
                        <div class="camp-table-cell__text-editor_field">
                            <input
                                type="text"

                                v-model="inputValue"
                                v-focus
                                @blur="handleBlur"
                                @change="handleInput"
                                @keyup.enter="handleEnter"
                            >
                            <span v-show="false" class="camp-btn-ic camp-btn-ic__add">
                                <span class="camp-btn-ic__icon">
                                  <Svg :img="'close'"/>
                               </span>
                            </span>

                            <button  class="camp-btn-ic camp-btn-ic__close camp-tooltip" type="button" aria-label="Очистить поле" data-tippy-content="Очистить поле"
                                @click="clearField"
                            >
                                    <span class="camp-btn-ic__icon">
                                      <Svg :img="'close'"/>
                                  </span>
                            </button>
                        </div>
                    </div>
                </div>


        </template>
        <template v-else>

                <div class="camp-table-cell-title">
                    <div class="camp-table-cell-title__edit">
                        <input :type="getFieldType"
                                v-model="inputValue"
                                @change="handleInput"
                                @keyup.enter="handleEnter"
                                class="camp-tooltip camp-input-text"
                                :data-tippy-content="inputValue"

                         >
                        <button class="camp-btn-ic camp-btn-ic__close" type="button" aria-label="Очистить поле"
                            @click="clearField"
                        >
                        <span class="camp-btn-ic__icon">
                            <Svg :img="'close'"/>
                        </span>
                        </button>
                    </div>
                </div>


        </template>
    </div>


</template>

<script>

    import {mapActions, mapMutations,mapGetters} from 'vuex'
    import TeamAddMemberPopup from "../../../../views/Account/Team/TeamAddMemberPopup";
    import Svg from "../Svg";

    export default {
      name: "TextInput",
      components: {Svg, TeamAddMemberPopup},
      data() {
          return {
              inputValue:  this.formatValue(this.value),
              isHovered: false,

          };
      },
      props: {
          item_id:Number,
          subgroupInput:false,
          group_id:0,
          viewtype:{
          type: Number,
            defalut:0,
          },
          fieldType:{
            type: String,
            defalut:'name',
          },
          field:String,
            value: {
            type: String,
          }
      },
      methods:{
          handleBlur() {
              this.$store.commit('crm/setUiaction', {action:'cancel_add_item'});
          },
          handleEnter(event) {
              // Убираем фокус с поля ввода
              event.target.blur();
          },
          handleInput() {


            if(this.subgroupInput) {

                this.setSubgroup({
                    subgroup_id : this.group_id,
                    item_id:this.item_id,
                    value: this.inputValue,
                });

            }else{
                this.handleSetValue(this.item_id, this.inputValue);
            }

            if(this.viewtype===1)
            {
                this.inputValue='';
            }
          },
          clearField(){

            this.inputValue='';
            if(this.fieldType === 'price') {
              this.handleSetValue(this.item_id, 0);
            }
          },
          handleSetValue(itemid,value) {
            this.editItem({
              obj: {
                group_id:this.group_id,
                item_id:itemid,
                key_field:this.field,
                action:itemid===0 ? 'add':'edit',
                val: value,
              }
            }).then((r) => {
                console.log("response Input",r);
            })
            .catch(() => {

            })
            .finally(() => {

            })
          },

          formatInput() {
            this.inputValue = this.formatValue(this.inputValue);
          },


          formatValue(value) {
            if(this.fieldType !== 'price')
            {
              return value;
            }

            // Возвращаем пусто если 0
            if(value < 0.001) {
              return ;
            }
            if (Number.isInteger(parseFloat(value))) {
              // Если число целое, возвращаем без десятичных знаков
              return parseInt(value);
            } else {
              // Если число дробное, возвращаем с двумя знаками после запятой
              return parseFloat(value).toFixed(2);
            }
          },
          getCellInnerStyle(){
            const style = {};


            return style;
          },
          getCustomClass(){
            if (this.field==='sale_price') {
              return 'camp-table-sale-title';
            }
          },
          ...mapActions({
              editItem: 'crm/editItem',
              setSubgroup : 'crm/setSubgroupsData',
          }),
      },
      computed: {
        getFieldType()
        {
           return this.fieldType === 'price' ? 'number' : 'text'
        }
      },
      watch:{
        value(newVal, oldVal)
        {
          this.inputValue = this.formatValue(newVal);
        }
      },
      mounted(){
            //console.log("text mounted");
        }
    }
</script>

<style scoped lang="scss">

  .camp-table-sale-title{
    background-color: #00CB8E;
    margin: 4px;
    border-radius: 5px;
    &:hover{
      .camp-tooltip.camp-input-text{
        color: black;
      }
    }
    .camp-tooltip.camp-input-text{
      color: white;
      &:hover,
      &:focus,
      &.active-input {
        color: black;
      }
    }

  }
  .camp-tooltip.camp-input-text{
    font-size: 12px;
  }
</style>
