<template>
  <BasePopup
  >
    <div class="PBSingleProductSelectedPricePriorityPopup">


      <div class="PBSingleProductSelectedPricePriorityPopup__titlePrimary titlePrimary titlePrimary--small">
        Приоритет установки цен <br> сохранён: {{priority}}
      </div>

      <picture class="PBSingleProductSelectedPricePriorityPopup__picture">
        <source type="image/webp"
          srcset="/images/popup/pricePriorityPopup-image-2x.webp 2x, /images/popup/pricePriorityPopup-image.webp 1x">
        <img src="/images/popup/pricePriorityPopup-image.webp" alt="" class="PBSingleProductSelectedPricePriorityPopup__image">
      </picture>

      <BaseButton
        class="PBSingleProductSelectedPricePriorityPopup__button button--primary"
        @click="closePopup"
      >
        Ок
      </BaseButton>

    </div>
  </BasePopup>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'PBSingleProductSelectedPricePriorityPopup',
  props: {
    priority: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup'
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.PBSingleProductSelectedPricePriorityPopup {

  max-width: 100%;
  width: 600px;
  position: relative;
  padding: 60px 0 30px;
  text-align: center;
  @include breakpoint(xl) {
    width: 572px;
  }
  @include breakpoint(md) {
    width: 340px;
    padding: 60px 55px 220px;
  }
  @include breakpoint(sm) {
    width: 322px;
    padding-left: 45px;
    padding-right: 45px;
  }
  @include breakpoint(xs) {
    width: 280px;
    padding-left: 25px;
    padding-right: 25px;
  }

  &__titlePrimary {
    margin-bottom: 30px;
    @include breakpoint(md) {
      br {
        display: none;
      }
    }
  }

  &__picture {
    position: absolute;
    bottom: 0;
    left: -18px;
    @include breakpoint(md) {
      @include horizontalCenter();
    }
  }

  &__image {
    max-width: none;
  }

  &__button {
    height: 34px;
    max-width: 120px;
    border-radius: 10px;
    margin: 0 auto;
  }

}

</style>
