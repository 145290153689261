<template>
  <div
    v-if="$route.meta.sidebarContent"
    :class="`sidebarContent sidebarContent--${$route.meta.sidebarContent}`"
  >
    <component
      :class="`sidebarContent__${$route.meta.sidebarContent}`"
      :is="$route.meta.sidebarContent"
    />

  </div>
</template>

<script>
import ProductSetting from '@/components/ProductSetting'
import Navigation from '@/components/Navigation'

export default {
  components: {
    ProductSetting,
    Navigation
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.sidebarContent {

  &--productSetting {
    font-weight: 500;
  }

  // &--navigation {
  //   margin-top: 100px;
  //   @include breakpoint(xl) {
  //     margin-top: 80px;
  //   }
  //   @include breakpoint(md) {
  //     margin-top: 30px;
  //   }
  // }


  &__sidebarSocials {
    @include breakpoint(md) {
      margin-top: 30px;
    }
    @include breakpoint(xs) {
      margin-top: 20px;
    }
  }

}
</style>