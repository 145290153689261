export default {
  namespaced: true,

  state: () => ({
    userTicketList: [],
    selectedUserTicket: ''
  }),

  mutations: {
    setUserTicketList(state, payload) {
      state.userTicketList = payload
    },
    setSelectedUserTicket(state, payload) {
      state.selectedUserTicket = payload
    }
  },

  getters: {
    activeUserTickets(state) {
      return state.userTicketList.filter(userticket => userticket.status !== 'closed')
    },
    closedUserTickets(state) {
      return state.userTicketList.filter(userticket => userticket.status === 'closed')
    },
    messages(state) {
      if (state.selectedUserTicket) {
        return Object.values(
          state.selectedUserTicket.messages.reduce((acc, curr) => {
            const date = curr.created_at.split(" ")[0]
            acc[date] ??= { date, chat: [] }
            acc[date].chat.push(curr)
            return acc
          }, [])
        );
      } else {
        return ''
      }
    },
  },

  actions: {
    getUserTicketList({ commit }) {
      return new Promise((resolve) => {
        YC.userTicketsApi.getUserTicketList()
          .then(({ data }) => {
            const userTicketListReverse = data.reverse()
            commit("setUserTicketList", userTicketListReverse);
            resolve();
          })
      });
    },
    getUserTicketInfo({ commit }, id) {
      return new Promise((resolve) => {
        YC.userTicketsApi.getUserTicketInfo(id)
          .then(({ data }) => {
            const selectedUserTicket = data.data.ticket
            commit("setSelectedUserTicket", selectedUserTicket);
            resolve();
          })
      });
    },
    createNewTicket({ dispatch }, payload) {
      return new Promise((resolve) => {
        YC.userTicketsApi.postNewTicket(payload)
          .then(() => {
            dispatch("getUserTicketList")
            resolve();
          })
      });
    },
    sendNewMessage({ dispatch }, payload) {
      if (payload.status && payload.status === 'closed') {
        return new Promise((resolve) => {
          YC.userTicketsApi.postNewMessage(payload)
            .then(() => {
              dispatch("getUserTicketList")
              resolve();
            })
        });
      } else {
        return new Promise((resolve) => {
          YC.userTicketsApi.postNewMessage(payload)
            .then(() => {
              dispatch("getUserTicketInfo", payload.id)
              resolve();
            })
        });
      }

    },
    sendTicketRating({ dispatch }, { id, rating }) {
      return new Promise((resolve) => {
        YC.userTicketsApi.postTicketRating(id, rating)
          .then(() => {
            dispatch("getUserTicketList")
            resolve();
          })
      });
    }
  }
}
