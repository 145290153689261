<template>
  <div class="baseDropDown">
    <button
      @click="flag = !flag"
      class="baseDropDown__button"
      v-click-outside="close"
    >
      {{ label }}
      <div class="baseDropDown__button-icon">
        <SvgUse :width="10" :height="8" id="arrowDown" />
      </div>
    </button>
    <div class="baseDropDown__content" v-if="flag">
      <div class="boseDropDown__content--item">ООО “Ромашка”</div>
      <div class="boseDropDown__content--item">ООО “Барабашка”</div>
      <div class="boseDropDown__content--item">ООО “Навуходоноср”</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDropDown",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      flag: false,
    };
  },
  methods: {
    close() {
      this.flag = false;
    },
  },
};
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";

.baseDropDown {
  .baseDropDown__button {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: black !important;
    gap: 5px;
    .svg-icon {
      min-width: 15px;
    }
  }
  .baseDropDown__content {
    position: absolute;
    top: 55px;
    right: 0;
    padding: 20px;
    border-radius: 20px;
    z-index: 9999;
    background-color: white;
    box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.07);
    display: flex;
    flex-flow: column;
    gap: 5px;

    .boseDropDown__content--item {
      width: fit-content;
      border-radius: 10px;
      padding: 15px 20px;
      background-color: #f6f6f6;
      font-size: 15px;
      line-height: 12px;
      font-weight: 500;
      text-align: start;
    }
  }
}
</style>
