<template>
  <BasePopup
  >
    <div class="PBSingleProductFirstRunPopup">


      <div class="PBSingleProductFirstRunPopup__primaryTitle primaryTitle titlePrimary--small">
          <div class="PBSingleProductFirstRunPopup__content">Репрайсер начал работу. <br/> Для сбора статистики  по конкурентным товарам <br/>  потребуется время </div>
          <div class="PBSingleProductFirstRunPopup__content">Подробная информация по конкурентам <br/> будет доступна через: {{formattedTime}} сек</div>
      </div>

      <picture class="PBSingleProductFirstRunPopup__picture">

      </picture>

    </div>
  </BasePopup>
</template>

<script>
import {mapState,mapActions} from 'vuex'

export default {
  name: 'PBSingleProductFirstRunPopup',
  props: {
    time: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
        remainingTime: this.time,
        intervalId: null
    };
  },
  computed: {
      formattedTime() {
          const hours = Math.floor(this.remainingTime / 3600);
          const minutes = Math.floor((this.remainingTime % 3600) / 60);
          const seconds = this.remainingTime % 60;
          return `${this.padTime(hours)}:${this.padTime(minutes)}:${this.padTime(seconds)}`;
      }
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
    }),
    padTime(value) {
        return value.toString().padStart(2, '0');
    },
    startCountdown() {
        this.intervalId = setInterval(() => {
            if (this.remainingTime > 0) {
                this.remainingTime -= 1;
            } else {
                clearInterval(this.intervalId);
                this.closePopup()
            }
        }, 1000);
    }
  },
  mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    if (this.intervalId) {
        clearInterval(this.intervalId);
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.PBSingleProductFirstRunPopup {

  max-width: 100%;
  width: 600px;
  position: relative;
  padding: 60px 0;
  text-align: center;
  @include breakpoint(xl) {
    width: 572px;
  }
  @include breakpoint(md) {
    width: 340px;
    padding: 60px 55px 220px;
  }
  @include breakpoint(sm) {
    width: 322px;
    padding-left: 45px;
    padding-right: 45px;
  }
  @include breakpoint(xs) {
    width: 280px;
    padding-left: 25px;
    padding-right: 25px;
  }

  &__primaryTitle {
    @include breakpoint(md) {
      br {
        display: none;
      }
    }
  }

  &__content {
    padding: 10px 0;
  }

  &__picture {
    position: absolute;
    bottom: 0;
    left: -18px;
    @include breakpoint(md) {
      @include horizontalCenter();
    }
  }

  &__image {
    max-width: none;
  }

}

</style>
