<template>
  <BaseInstructionPopup>
    <div class="fourthStepInstructionPopup">
      <p class="fourthStepInstructionPopup__text">Для того, чтобы Кемп <span>автоматически начал увеличивать ваши продажи</span> - добавьте нашего робота Менеджера по продажам в свой кабинет</p>

      <div class="fourthStepInstructionPopup__videoWrap">
        <div class="fourthStepInstructionPopup__videoTitle">Подробная инструкция:</div>
        <img src="/images/videoPreview.jpg" alt="" class="fourthStepInstructionPopup__videoPreview">
      </div>

      <div class="fourthStepInstructionPopup__managerPhone">
        Введите номер телефона менеджера: <span>{{this.$store.state.auth.user.is_instructed.phone_managers}}</span>
      </div>

      <footer class="fourthStepInstructionPopup__footer">
        <div
          class="fourthStepInstructionPopup__dontShow"
          @click="dontShow"
        >
          Не показывать больше
        </div>
        <BaseButton
          class="fourthStepInstructionPopup__button button button--primary"
          @click="nextStep"
        >
          Далее
        </BaseButton>
      </footer>
    </div>
  </BaseInstructionPopup>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'FourthStepInstructionPopup',
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
      openPopup: 'popup/openPopup',
      setInstructed: 'settings/setInstructed',
      getUser: 'auth/getUser'
    }),

    dontShow() {
      this.closePopup()
      this.setInstructed({integration: true})
      .then(() => {
        this.getUser()
      })
    },

    nextStep() {
      this.closePopup()
      if(!this.$store.state.auth.user.is_instructed.integration) {
        this.openPopup({
          component: 'FinalStepInstructionPopup'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.fourthStepInstructionPopup {
  position: relative;
  padding: 20px;
  width: 500px;
  max-width: 100%;
  font-size: 14px;
  line-height: 14px;
  @include breakpoint(xl) {
    width: 400px;
    font-size: 13px;
    line-height: 13px;
  }
  @include breakpoint(md) {
    width: 500px;
    font-size: 14px;
    line-height: 14px;
  }
  @include breakpoint(sm) {
    width: 360px;
  }
  @include breakpoint(xs) {
    width: 300px;
  }

  &__titlePopup {
    text-align: left;
    margin-bottom: 20px;
  }
    
  &__text {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  &__managerPhone, &__text {
    @include breakpoint(xs) {
      font-size: 13px;
      line-height: 13px;
    }
    span {
      font-weight: 600;
      color: $dark-green-400;
    }
  }

  &__videoWrap, &__managerPhone {
    margin-bottom: 20px;
    @include breakpoint(md) {
      margin-bottom: 10px;
    }
  }

  &__managerPhone {
    @include breakpoint(xl) {
      span {
        display: block;
        margin-top: 5px;
      }
    }
  }

  &__videoTitle {
    margin-bottom: 5px;
    @include breakpoint(xs) {
      font-size: 13px;
      line-height: 13px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__dontShow {
    font-size: 12px;
    line-height: 12px;
    color: $gray-450;
    cursor: pointer;
  }

  &__button {
    max-width: 71px;
    height: 34px;
    border-radius: 10px;
  }
}

</style>