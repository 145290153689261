<template>
  <button
    class="uiButtonClose"
    @mouseover="iconHover = true"
    @mouseleave="iconHover = false"
  >
    <SvgUse      
      :width="12"
      :height="12"
      id="iconCloseInActive"
      v-if="!iconHover"
    />
    <SvgUse      
      :width="12"
      :height="12"
      id="iconCloseActive"
      v-else
    />
  </button>
</template>

<script>

export default {
  name: 'BaseCloseButton',
  data() {
    return {
      iconHover: false
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.uiButtonClose {

  width: 24px;
  height: 24px;
  border-radius: 100%;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.20);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  cursor: pointer;
  z-index: 1;
  
}
</style>