<template>
    <div class="camp-table-row camp-row camp-row-nowrap">
        <div class="camp-table-cell camp-table-cell__name camp-table-cell-sticky camp-table-cell__empty" style="width: 360px;">

            <div class="camp-table-left-indicator"
                 :style="getBorderStyle()"
            >

            </div>

            <div class="camp-table-stub"></div>


            <div class="camp-table-cell-checkbox"></div>

            <div class="camp-table-cell-inner"></div>

            <div class="camp-table-cell-group-line"></div>
        </div>

<!--        <HeaderColor-->
<!--          :color="'#3dfdda'"-->
<!--          :title="'Table'"-->
<!--          :width="'390px'"-->
<!--          :groupd_cells_id="21"-->
<!--        />-->

      <HeaderColor
          :color="'#3dc3fd'"
          :title="'О товаре'"
          :width="'520px'"
          :min_width="'200px'"
          :groupd_cells_id="1"
      />

      <HeaderColor
          :color="'#e89a35'"
          :title="'Себестоимость'"
          :width="'320px'"
          :min_width="'240px'"
          :groupd_cells_id="2"
      />

      <HeaderColor
          :color="'#3dfdda'"
          :title="'Цена продажи'"
          :width="'120px'"
          :min_width="'120px'"
          :groupd_cells_id="3"
      />


     <HeaderColor
          :color="'#9B51E0'"
          :title="'Комиссия ВБ'"
          :min_width="'170px'"
          :width="'320px'"
          :groupd_cells_id="4"
      />

     <HeaderColor
          :color="'#58c755'"
          :title="'% Выкупа'"
          :width="'120px'"
          :min_width="'120px'"
          :groupd_cells_id="26"
      />

     <HeaderColor
          :color="'#FF3D57'"
          :title="'Логистика'"
          :width="'520px'"
          :min_width="'170px'"
          :groupd_cells_id="6"
      />

     <HeaderColor
          :color="'#EC00AA'"
          :title="'Хранение'"
          :width="'320px'"
          :min_width="'210px'"
          :groupd_cells_id="7"
      />

     <HeaderColor
          :color="'#58c755'"
          :title="'Налоги'"
          :width="'320px'"
          :min_width="'190px'"
          :groupd_cells_id="8"
      />

     <HeaderColor
          :color="'#0073EA'"
          :title="'Реклама'"
          :width="'320px'"
          :min_width="'170px'"
          :groupd_cells_id="9"
      />

     <HeaderColor
          :color="'#3dfdda'"
          :title="'Рентабельность'"
          :width="'320px'"
          :min_width="'190px'"
          :groupd_cells_id="10"
      />


        <div v-show="true" class="camp-table-cell" :style="getAddWidth()">
            <div class="camp-table-cell-group-line"></div>
        </div>
        <!-- //tcell -->

    </div>
</template>

<script>
import {mapState} from "vuex";
import Svg from "@/components/Crm/UI/Svg.vue";
import HeaderColor from "@/components/Crm/Desks/Custom/HeaderColor.vue";

export default {
    name: "UnitEconomicsRow",
    components: {HeaderColor, Svg},
    props:{
      color:null,
      tableCellsParams:null,
    },
    computed: {
        ...mapState({
            tableParams: state => state.crm.tableParams,
            minLayoutWidth: state => state.crm.minLayoutWidth,

        }),
        extraWidth()
        {
            return  this.minLayoutWidth - 6145+546;
        }
    },
    methods:{
        getBorderStyle(){
            if(this.color) {
                return {
                    backgroundColor: this.color

                };
            }else{
                return null;
            }
        },
        getExtraWidth(){
            if(this.extraWidth) {
                return {

                    width: this.extraWidth

                };
            }else{
                return null;
            }
        },
        getAddWidth()
        {

          if (!this.tableCellsParams || !Array.isArray(this.tableCellsParams)) {
            console.warn('tableCellsParams отсутствует или не является массивом');
            return 0;
          }

          // Фильтруем элементы, у которых field начинается с "extra_"
          const extraFields = this.tableCellsParams.filter(cell =>
              cell.field && cell.field.startsWith('extra_')
          );

          // Суммируем значения width у отфильтрованных элементов
          const sumWidth= extraFields.reduce((sum, cell) => sum + (cell.width || 0), 0) +45;

          return {  width : sumWidth+  `px`, minWidth: `45px` };

        },
        headerGroupWidth(groupd_cells_id)
        {
            const groupExpanded=this.tableParams.groupCells[groupd_cells_id];
            if(groupd_cells_id===1) {
                if (groupExpanded) {
                    return {width: '670px'};
                } else {
                    return {width: '200px'};
                }
            }
            else if(groupd_cells_id===3)
            {
                if (groupExpanded) {
                    return {width: '670px'};
                } else {
                    return {width: '670px'};
                }
            }
            else if(groupd_cells_id===4)
            {
                if (groupExpanded) {
                    return {width: '990px'};
                } else {
                    return {width: '990px'};
                }
            }
            else if(groupd_cells_id===5)
            {
                if (groupExpanded) {
                    return {width: '480px'};
                } else {
                    return {width: '200px'};
                }
            }
            else if(groupd_cells_id===6)
            {
                if (groupExpanded) {
                    return {width: '510px'};
                } else {
                    return {width: '510px'};
                }
            }
            else if(groupd_cells_id===7)
            {
                if (groupExpanded) {
                    return {width: '610px'};
                } else {
                    return {width: '610px'};
                }
            }
        },
        toggle_expand(groupd_cells_id)
        {

            this.tableParams.groupCells[groupd_cells_id] = !this.tableParams?.groupCells[groupd_cells_id] ?? false;
            this.$store.commit('crm/setTableParams', this.tableParams);
        }
    }
}
</script>
<style scoped lang="scss">

</style>
