//balance
import BalanceTopUpPopup from '@/views/Account/Balance/BalanceTopUpPopup'
import BalancePromocodePopup from '@/views/Account/Balance/BalancePromocodePopup'

//team
import TeamAddMemberPopup from '@/views/Account/Team/TeamAddMemberPopup'
import TeamChangeRolePopup from '@/views/Account/Team/TeamChangeRolePopup'
import TeamConfirmPopup from '@/views/Account/Team/TeamConfirmPopup'

//tickets
import TicketsNewPopup from '@/views/Account/Tickets/TicketsNewPopup'
import TicketsChatPopup from '@/views/Account/Tickets/TicketsChat/TicketsChatPopup'
import TicketsAssessSupportPopup from '@/views/Account/Tickets/TicketsChat/TicketsAssessSupportPopup'
import TicketsThanksPopup from '@/views/Account/Tickets/TicketsChat/TicketsThanksPopup'

//settings
import SettingsEnterApiPopup from '@/views/Account/Settings/SettingsPopup/SettingsEnterApiPopup'
import SettingsCreateMarketplacePopup from '@/views/Account/Settings/SettingsPopup/SettingsCreateMarketplacePopup'
import SettingsChangeEmailPopup from '@/views/Account/Settings/SettingsPopup/SettingsChangeEmailPopup'
import SettingsChangePhonePopup from '@/views/Account/Settings/SettingsPopup/SettingsChangePhonePopup'
import SettingsChangePasswordPopup from '@/views/Account/Settings/SettingsPopup/SettingsChangePasswordPopup'
import SettingsChangeNamePopup from '@/views/Account/Settings/SettingsPopup/SettingsChangeNamePopup'
import SettingsAlertPopup from '@/views/Account/Settings/SettingsPopup/SettingsAlertPopup'

export default [
  BalanceTopUpPopup,
  BalancePromocodePopup,
  TeamAddMemberPopup,
  TeamChangeRolePopup,
  TeamConfirmPopup,
  TicketsNewPopup,
  TicketsChatPopup,
  TicketsAssessSupportPopup,
  TicketsThanksPopup,
  SettingsEnterApiPopup,
  SettingsCreateMarketplacePopup,
  SettingsChangeEmailPopup,
  SettingsChangePhonePopup,
  SettingsChangePasswordPopup,
  SettingsChangeNamePopup,
  SettingsAlertPopup
]