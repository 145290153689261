<template>
  <div class="socials">
    <a
      v-for="item in socials" :key="item.url"
      :href="item.url"
      target="_blank"
      class="socials__link"
      @mouseenter="item.hoverIcon = true"
      @mouseleave="item.hoverIcon = false"
    >
      <SvgUse 
        :width="item.icon.width"
        :height="item.icon.height"
        :id="item.icon.name"
        v-if="item.hoverIcon"
      />
      <SvgUse 
        :width="item.icon.width"
        :height="item.icon.height"
        :id="item.icon.name + 'Blue'"
        v-else
      />
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profileIsOpen: false,
      socials: [
        {url: "https://www.facebook.com/youcamp.pro/", hoverIcon: false, icon: {
          name: 'facebook', width: 15, height: 15
        }},
        {url: "https://vk.com/youcamppro", hoverIcon: false, icon: {
          name: 'vk', width: 16, height: 10
        }},
        {url: "https://t.me/youcamppro", hoverIcon: false, icon: {
          name: 'telegram', width: 15, height: 12
        }},
        {url: "https://www.instagram.com/youcamp.pro/", hoverIcon: false, icon: {
          name: 'instagram', width: 15, height: 15
        }}
      ]
    } 
  },
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.socials {
  display: flex;
  gap: 10px;
  max-width: max-content;
  &__link {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid $blue;
      border-radius: inherit;
    }
    &:nth-child(3) {
      svg {
        position: relative;
        right: 1px
      }
    }
    &:hover {
      background-image: $radial-gradient-profile-avatar-menu;
      background-repeat: none;
      &::before {
        display: none;
      }
    }
  }
}
</style>