<template>
    <div :class="getWrapperClass"  :key="calcKey">

      <TableCollapsed
          v-if="!expanded"
          :tableData="tableData"
          :groupName="groupName"
          :groupID="groupID"
          :tableCellsParams="tableCellsParams"
          :numrows="numrows"
          :deletable="getPosibleDelete"
          :color="getcurcolor"
          :itemIndex="itemIndex"
      />

        <!-- Full table -->

        <div class="camp-table__wrapper-inner camp-table-full"
             :style="{ display: expanded ? 'block' : 'none' }"
        >

            <TableHeader :groupName="groupName"
                         :groupID="groupID"
                         :numrows="numrows"
                         :deletable="getPosibleDelete"
                         :color="getcurcolor"

            />
            <!-- Table -->
            <div class="camp-table camp__js-table">
                <div class="camp-table-inner">

                    <TableRowHeader
                        :tableCellsParams="tableCellsParams"
                        :color="getcurcolor"
                        :group_id="groupID"
                    />

                    <div class="camp-table-body">
                            <TableRow
                                v-for="(params, index) in deskData"
                                :key="`row_${index}_${countRows}`"
                                :typeCollumn="4"
                                :rowIndex="index"
                                :rowData="params"
                                :group_id="groupID"
                                :key_field="key_field"
                                :tableCellsParams="tableCellsParams"
                                :color="getcurcolor"
                            />
                        <TableAddItemRow
                            v-if="getAddRow"
                            :tableCellsParams="tableCellsParams"
                            :group_id="groupID"
                            :color="getcurcolor"

                        />

                    </div>
                    <!-- //tbody -->

                    <TableRowFooter
                        :tableCellsParams="tableCellsParams"
                        :group_id="groupID"
                        :desk_data="deskData"
                        :tableData="tableData"
                        :color="getcurcolor"
                    />

                </div>

            </div>
            <!-- //table -->

        </div>
        <!-- //Full table -->


    </div>
    <!-- //table wrapper -->
</template>

<script>


    import TableRow from "./TableRow";

    import {mapGetters, mapState} from 'vuex'
    import TableFooter from "./TableFooter";
    import TableHeader from "./TableHeader";
    import TableRowHeader from "./TableRowHeader";
    import TableRowFooter from "./TableRowFooter";
    import TableAddItemRow from "./TableAddItemRow";
    import TableCollapsed from "./TableCollapsed";

    //import SimpleBar from 'simplebar';

    export default {
        name: "Table",
        components: {
            TableCollapsed,
            TableAddItemRow,
            TableRowFooter, TableRowHeader, TableHeader, TableFooter,
            TableRow
        },
        props: {
            tableCellsParams: {
                defalut:null,
                required: true,
            },
            addrow:{
                type:Boolean,
                defalut:true,
            },
            itemIndex:0,
            tableparam:null,
            key_field:String,
            tableData:null,
            groupName:String,
            groupID:Number,
            color:{
                type:String,
                defalut:'#cacaca',
            },
            expanded:{
                type:Boolean,
                defalut:true,
            },
        },
        data() {
            return {
                doc:null,
                numrows:0,
                newKey:1,
                curtableparam:null,
                curcolor:this.color,
                wrapperClass:'camp-table__wrapper',
                isExpanded: this.expanded,

            }
        },

        methods:{

            dropdowns(){
                const doc=this.doc;
                const closeDropdowns=this.closeDropdowns;
                // Dropdowns
                const dropdowns = doc.querySelectorAll('.camp__js-dropdown .camp-table-cell__status-picker_display');
                const dropdownsStatuses = doc.querySelectorAll('.camp-dropdown .camp-status');


                if (dropdowns) {
                    dropdowns.forEach(function(dropdown) {
                        dropdown.addEventListener('click', function(e) {
                            if (this.classList.contains('camp__is-opened') && this.classList.contains('camp__is-active')) {
                                closeDropdowns(dropdowns);
                                this.parentNode.classList.remove('camp__is-opened');
                                this.parentNode.classList.remove('camp__is-active');
                            } else {
                                closeDropdowns(dropdowns);
                                this.parentNode.classList.add('camp__is-opened');
                                this.parentNode.classList.add('camp__is-active');
                            }
                        });
                    });
                }

                if (dropdownsStatuses) {
                    // console.log(dropdownsStatuses);
                    dropdownsStatuses.forEach(function(dropdownStatus) {
                        dropdownStatus.addEventListener('click', function() {
                            const currentDropdown = this.closest('.camp__js-dropdown');
                            const currentStatusText = this.innerHTML;
                            const currentStatusColor = this.style.backgroundColor;

                            currentDropdown.querySelector('.camp-table-cell__status-picker_display').style.backgroundColor = currentStatusColor;
                            currentDropdown.querySelector('.camp-table-cell__status-picker_text').innerHTML = currentStatusText;
                            currentDropdown.classList.remove('camp__is-opened');
                            currentDropdown.classList.remove('camp__is-active');


                        });
                    });

                }


                const dropdownsStatusesLevels = doc.querySelectorAll('.camp-dropdown-statuses');

                if (dropdownsStatusesLevels) {
                    dropdownsStatusesLevels.forEach(function(dropdownsStatusesLevel) {
                        dropdownsStatusesLevel.addEventListener('click', function(e) {
                            let target = e.target;
                            if (!target.classList.contains('camp__js-dropdown-btn')) return;

                            let action = target.getAttribute('data-action');
                            let level = target.closest('.camp-dropdown__level');

                            if (action === 'edit') {
                                level.classList.add('camp__is-hidden');
                                level.nextElementSibling.classList.remove('camp__is-hidden');
                            } else {
                                target.closest('.camp__js-dropdown').classList.remove('camp__is-opened');
                                target.closest('.camp__js-dropdown').classList.remove('camp__is-active');
                                setTimeout(function() {
                                    level.classList.add('camp__is-hidden');
                                    level.previousElementSibling.classList.remove('camp__is-hidden');
                                }, 200);

                            }
                        });
                    });
                }
            },
            collapseTable(){
                const doc=this.doc;
                // Collapse table
                const openTableBtns = doc.querySelectorAll('.camp__js-open-table-btn');
                const closeTableBtns = doc.querySelectorAll('.camp__js-close-table-btn');

                if (openTableBtns) {
                    openTableBtns.forEach(function(openTableBtn) {
                        openTableBtn.addEventListener('click', function(e) {
                            e.preventDefault();

                            const currentCollapsedTable = this.closest('.camp-table-collapsed');
                            const currentFullTable = currentCollapsedTable.nextElementSibling;

                            currentCollapsedTable.style.display = 'none';
                            currentFullTable.style.display = 'block';

                            this.isExpanded=true

                        });
                    });

                    closeTableBtns.forEach(function(closeTableBtn) {
                        closeTableBtn.addEventListener('click', function(e) {
                            e.preventDefault();

                            const currentFullTable = this.closest('.camp-table-full');
                            const currentCollapsedTable = currentFullTable.previousElementSibling;

                            currentFullTable.style.display = 'none';
                            currentCollapsedTable.style.display = 'block';

                            this.isExpanded=false
                        });
                    });
                }

                // Toggle favorite btn active class
                let btnsFavorites = doc.querySelectorAll('.camp__js-favorite-btn');

                if (btnsFavorites) {
                    btnsFavorites.forEach(function(btnFavorite) {
                        btnFavorite.addEventListener('click', function(e) {
                            e.preventDefault();
                            this.classList.toggle('camp__is-active');
                        });
                    });
                }
            },
            tableTitleEdit() {
                const doc=this.doc;
                // Edit table title
                const tableTitles = doc.querySelectorAll('.camp-table-title');

                if (tableTitles) {
                    tableTitles.forEach(function (tableTitle) {
                        tableTitle.addEventListener('click', function(e) {
                            let target = e.target;
                            let editor = tableTitle.querySelector('.camp-table-cell-title__edit');
                            if (target.classList.contains('camp-table-cell-title__display-text')) {
                                target.style.display = 'none';
                                editor.style.display = 'block';
                                tableTitle.classList.add('camp__is-active');
                            }
                        });
                    });
                }

            },
            linkEditor(){
                const doc=this.doc;
                const closeDropdowns=this.closeDropdowns;
                // Link editor
                const linksEditors = doc.querySelectorAll('.camp__js-dropdown .camp-table-cell__text-editor');

                if (linksEditors) {
                    linksEditors.forEach(function(linksEditor) {
                        linksEditor.addEventListener('click', function(e) {
                            if (this.classList.contains('camp__is-opened') && this.classList.contains('camp__is-active')) {
                                closeDropdowns(linksEditors);
                                this.parentNode.classList.remove('camp__is-opened');
                                this.parentNode.classList.remove('camp__is-active');
                            } else {
                                closeDropdowns(linksEditors);
                                this.parentNode.classList.add('camp__is-opened');
                                this.parentNode.classList.add('camp__is-active');
                            }
                        });
                    });
                }
            },
            closeDropdowns(dropdownsElements) {
                dropdownsElements.forEach(function(dropdown) {
                    dropdown.parentNode.classList.remove('camp__is-opened');
                    dropdown.parentNode.classList.remove('camp__is-active');
                });
            },
            initSimpebar() {
                //const campMainLevel=new SimpleBar();
                //this.getCampMainLevel();

                //console.log("campMainLevel",campMainLevel);
                const doc=this.doc;
                if (false) {
                    const scrollableTables = doc.querySelectorAll('.camp-table-body');


                    campMainLevel.getScrollElement().addEventListener('scroll', function() {
                        let scrollLeft = this.scrollLeft;

                        if (scrollLeft > 1) {
                            scrollableTables.forEach(function(scrollableTable) {
                                const tableDropdowns = scrollableTable.querySelectorAll('.camp__js-dropdown');

                                tableDropdowns.forEach(function(tableDropdown) {
                                    tableDropdown.classList.remove('camp__is-active');
                                    tableDropdown.classList.remove('camp__is-opened');
                                });

                            });
                        }
                    });
                }



            },
            updateMinLayoutWidth() {
                let minWidth=0;
                for (const cellParams of this.tableCellsParams) {
                    minWidth+=cellParams.width;

                }
                //console.log("minWidth",minWidth);
                this.$store.commit('crm/setMinLayoutWidth', minWidth);
            },
            initTable(){
                const doc=this.doc

                // Table sizes
                const tables = doc.querySelector('.camp-tables');

                if (tables) {
                    let theadTitles = doc.querySelectorAll('.camp-table-title__wrapper-sticky');
                    let tablesWidth = tables.offsetWidth;
                    const scrollableContent = doc.querySelector('.camp-content-inner');
                    const tableTitlesSticky = doc.querySelectorAll('.camp-table-title__wrapper-sticky');

                    let table = doc.querySelector('.camp__js-table');

                    if (table) {
                        let cells = table.querySelectorAll('.camp-table-header .camp-table-row .camp-table-cell');

                        let cellsTotalWidth=this.getMinLayoutWidth;

                        scrollableContent.style.cssText = `min-width: ${cellsTotalWidth}px`;
                        if (theadTitles) {
                            theadTitles.forEach(function(theadTitle) {
                                theadTitle.style.minWidth =`${cellsTotalWidth}px`;
                            });
                        }
                    }

                }
                // let cellsTextEditors = doc.querySelectorAll('.camp-table-cell__text-editor input');
                //
                // if (cellsTextEditors) {
                //     cellsTextEditors.forEach(function(cellsTextEditor) {
                //
                //         let cell = cellsTextEditor.closest('.camp-table-cell__text-editor');
                //
                //         cellsTextEditor.addEventListener('focus', function() {
                //             cell.classList.add('camp__is-visible');
                //         });
                //
                //         cellsTextEditor.addEventListener('blur', function() {
                //             let val = this.value;
                //
                //             if (!val && val === '') {
                //                 cell.classList.remove('camp__is-visible');
                //             }
                //
                //         });
                //     });
                // }



            },

            init(){
                this.doc = document;
              //  this.collapseTable();
               // this.dropdowns();
                this.linkEditor();
                this.tableTitleEdit();
                this.initTable();
                this.updateMinLayoutWidth();

                this.initSimpebar();

            },
            isItemExists(item) {
                // Проверка наличия элемента в deskData
                return this.deskData.some(deskItem => deskItem === item);
            }
        },

        computed: {
            ...mapState({
                deskData: state => state.crm.deskData
            }),
            ...mapGetters({
                getCampMainLevel:'crm/getCampMainLevel',
                getMinLayoutWidth: 'crm/getMinLayoutWidth',
                getNewdata: 'crm/getNewdata',
                getDesdkdata: 'crm/getDeskData',
                getUiAction: 'crm/getUiAction',
                getTableParams: 'crm/getTableParams',

            }),
            getAddRow()
            {
                const table_params=this.getTableParams;
                if(table_params && table_params.noaddrow)
                {
                    return false;
                }
                else{
                    return true;
                }

            },
            getPosibleDelete()
            {
                const table_params=this.getTableParams;
                if(table_params && table_params.no_group_delete)
                {
                    return false;
                }
                else{
                    return true;
                }

            },
            stylecolor(){
                return this.color ? 'color:'+ this.color +';' : 'color: #5698F7;'
            },
            calcKey()
            {
                // if(this.updateKey>0)
                // {
                //     this.newKey++;
                //     this.updateKey=0;
                // }
                return this.newKey;
            },
            countRows(){
                return this.tableData.length;
            },
            deskData() {
                //console.log("DATA IN TABLE:", this.tableData, this.key_field);

                const ddata=this.tableData;
                if (Array.isArray(ddata) && ddata.length > 0) {
                    this.numrows=ddata.length;
                    const result = ddata.map((item, index) => {
                        const params = this.tableCellsParams[index];
                        //console.log("paramItem",index,item);


                        return {
                            //...params,
                            //width: params.width,
                            //value: item.product_name,
                            item:item,


                        };
                    });
                    return result;

                } else {
                    return [];
                }



            },

            getcurcolor() {
                this.curcolor=this.color;
                return this.curcolor;
            },
            getWrapperClass(){
                return this.wrapperClass;
            },
            checkExpanded(){
                return this.expanded;
            }
        },
        watch: {
            // getNewdata(newValue, oldValue) {
            //     //this.editedGroup=newValue;
            //     console.log("watchNewdata:",newValue, oldValue,this.groupID);
            //     if(this.groupID===newValue){
            //         console.log("Change this desk",this.groupID);
            //         this.newKey++;
            //     }
            // },
            'deskData.data.desk_groups[this.itemIndex].expanded'(newVal){
                console.log("expanded",this.itemIndex,newVal);
            },
            getUiAction(uiact) {
                if(uiact) {

                    if (uiact.action) {
                        if (uiact.action === 'open_colorpicker') {

                            console.log("uiActionTableWatch open_colorpicker:");
                            if (this.groupID === uiact.group_id) {
                                this.wrapperClass = 'camp-table__wrapper camp__is-upper';
                            }
                        } else if (uiact.action === 'close_dropdowns' || uiact.action === 'change_color' || uiact.action === 'edit') {
                            console.log("uiActionTableWatch campdIsNotUpper:");
                            this.wrapperClass = 'camp-table__wrapper';

                        }
                    }
                }
            },
        },
        // watch: {
        //     getNewdata(newValue, oldValue) {
        //         //this.editedGroup=newValue;
        //         console.log("watchNewdata:",newValue, oldValue,this.groupID);
        //         if(this.groupID===newValue){
        //             console.log("Change this desk",this.groupID);
        //             this.newKey++;
        //         }
        //     },
        //     // getUiAction(uiact) {
        //     //
        //     //     if(uiact &&  uiact.group_id && uiact.item_id) {
        //     //         console.log("ui DELETE act", uiact.group_id, uiact.item_id);
        //     //     }
        //     //    //console.log("uiAction:",uiact);
        //     // },
        // },
        mounted() {

            this.init();

        }

    }
</script>

<style scoped>

.camp-table {
    display: flex;
    flex-direction: column;

}
.camp-table-body {
    flex: 1 1 auto;

}
</style>
