<template>
  <BaseInstructionPopup
    :classModifier="setClassModified"
  >
    <div
      class="BloggersInstructionPopup"
    >

      <div class="BloggersInstructionPopup__content">

        <div class="BloggersInstructionPopup__firstStep" v-if="step === 'first'">
          <div class="BloggersInstructionPopup__titlePopup titlePopup">Добро пожаловать на страницу Блогеры 😌</div>
          <p class="BloggersInstructionPopup__text">Данная страница позволит вам вести отчеты <span class="BloggersInstructionPopup__textBlue">по эффективности покупной рекламы</span></p>
          <p class="BloggersInstructionPopup__text">Пожалуйста, пройдите краткий экскурс для более комфортного использования страницы</p>
        </div>

        <div class="BloggersInstructionPopup__secondStep" v-else-if="step === 'second'">
          <div class="BloggersInstructionPopup__titlePopup titlePopup">Шаг 1</div>
          <p class="BloggersInstructionPopup__text">Нажмите на кнопку <span class="BloggersInstructionPopup__textBlue">"Новая группа"</span> для создания нового отчета</p>
          <p class="BloggersInstructionPopup__text">Назовите ее так, как вам больше понравится✏️</p>
          <p class="BloggersInstructionPopup__text">Вы также можете поменять <span class="BloggersInstructionPopup__textBlue">цветовую гамму группы</span>, чтобы было проще их различать между собой</p>
        </div>

        <div class="BloggersInstructionPopup__secondStep" v-else-if="step === 'third'">
          <div class="BloggersInstructionPopup__titlePopup titlePopup">Шаг 2</div>
          <p class="BloggersInstructionPopup__text">В столбце "Название" <span class="BloggersInstructionPopup__textBlue">впишите название плащадки</span> на которой была куплена реклама</p>
        </div>

        <div class="BloggersInstructionPopup__secondStep" v-else-if="step === 'fourth'">
          <div class="BloggersInstructionPopup__titlePopup titlePopup">Шаг 3</div>
          <p class="BloggersInstructionPopup__text">В разделе "Статус" <span class="BloggersInstructionPopup__textBlue">вы можете создавать, переименовывать, менять цвета и быстро редактировать</span> свои статусы 🎨</p>
        </div>

        <div class="BloggersInstructionPopup__secondStep" v-else-if="step === 'fifth'">
          <div class="BloggersInstructionPopup__titlePopup titlePopup">Шаг 4</div>
          <p class="BloggersInstructionPopup__text">В разделе "Ссылка" <span class="BloggersInstructionPopup__textBlue">укажите ссылку на рекламную площадку</span>, где была куплена реклама</p>
          <p class="BloggersInstructionPopup__text">Так она наверняка не потеряется😉</p>
        </div>

        <div class="BloggersInstructionPopup__secondStep" v-else-if="step === 'final'">
          <div class="BloggersInstructionPopup__titlePopup titlePopup">Шаг 5</div>
          <p class="BloggersInstructionPopup__text">В разделе "+" <span class="BloggersInstructionPopup__textBlue">вы можете создать свой собственный столбец</span>, с дополнительным статусом или текстом для комментариев</p>
          <p class="BloggersInstructionPopup__text">Приятного использования😉</p>
        </div>
        
      </div>

      <footer class="BloggersInstructionPopup__footer">
        <div
          class="BloggersInstructionPopup__dontShow"
          @click="dontShow"
        >
          Не показывать больше
        </div>
        <BaseButton
          class="BloggersInstructionPopup__button button button--primary"
          @click="nextStep"
        >
          Далее
        </BaseButton>
      </footer>

    </div>
  </BaseInstructionPopup>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'BloggersInstructionPopup',
  data() {  
    return {
      step: 'first'
    }
  },
  methods: {

    ...mapActions({
      closePopup: 'popup/closePopup',
      setInstructed: 'settings/setInstructed',
      getUser: 'auth/getUser'
    }),

    nextStep() {
      switch(this.step) {
        case 'first': this.step = 'second'; break;
        case 'second': this.step = 'third'; break;
        case 'third': this.step = 'fourth'; break;
        case 'fourth': this.step = 'fifth'; break;
        case 'fifth': this.fifthStepHandler(); break;
        case 'final': this.finalStepHandler(); break;
      }
    },

    fifthStepHandler() {
      this.step = 'final'
      if(window.innerWidth < 1532) document.querySelector('.camp-table').scrollIntoView({ inline: 'end' })
    },

    finalStepHandler() {
      this.dontShow()
    },

    dontShow() {
      this.closePopup()
      this.setInstructed({bloggers: true})
      .then(() => {
        this.getUser()
      })
    }
  },
  computed: {
    setClassModified() {
      switch(this.step) {
        case 'first' : return 'BloggersInstructionFirstStep';
        case 'second' : return 'BloggersInstructionSecondStep';
        case 'third' : return 'BloggersInstructionThirdStep';
        case 'fourth' : return 'BloggersInstructionFourthStep';
        case 'fifth' : return 'BloggersInstructionFifthStep';
        case 'final' : return 'BloggersInstructionFinalStep';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var';
@import 'src/scss/utils/mixins';

.BloggersInstructionPopup {

  padding: 20px;

  width: 500px;
  max-width: 100%;  

  &__titlePopup {
    text-align: left;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    height: 34px;
    width: 87px;
    border-radius: 10px;
  }
  &__dontShow {
    font-size: 12px;
    line-height: 12px;
    color: $gray-450;
    cursor: pointer;
  }

  &__content {
    margin-bottom: 20px;
  }

  &__titlePopup {
    text-align: left;
    margin-bottom: 20px;
  }
  
  &__text {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &Blue {
      color: $dark-green-400;
      font-weight: 600;
    }
  }

}

</style>
