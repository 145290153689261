<template>
  <div
    class="radio"
    :class="{'radio--checked': checked}"
  >
  </div>
</template>

<script>
export default {
  name: 'BaseRadioButton',
  props: {
    checked: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins';
@import 'src/scss/base/project/var';

.radio {

  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: $gray-100;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15) inset;

  // @include breakpoint(xl) {
  //   width: 15px;
  //   height: 15px;
  // }
  

  &::before {
    transition: opacity $transition-duration ease;
    opacity: 0;
    content: '';
    @include center();
    width: 60%;
    height: 60%;
    border-radius: inherit;
    background-color: $green-100;
    // @include breakpoint(xl) {
    //   width: 9px;
    //   height: 9px;
    // }
  }

  &--agreement {
    box-shadow: none;
    border: 1px solid $black;
    width: 15px;
    height: 15px;
    &::before {
      width: 9px;
      height: 9px;
    }
  }

  &--checked {
    &::before {
      opacity: 1;
    }
  }

}
</style>