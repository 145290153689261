export default {

  namespaced: true,

  state: () => ({

    navigation: {
      dropdown: [
        {
          id: 1,
          type: 'dropdown',
          menu: {
            root: 'CRM система',
            isDropDownRoot: false,
            icon: 'crmIconMenu',

            //user structure
            links: [
              { id: 4, name: 'Продажи',urlPath: "/crm/sale",isFavorite: false,},
              { id: 5, name: 'Заказы',urlPath: "/crm/orders",isFavorite: false},
              { id: 6, name: 'Закуп партии',urlPath: "/crm/purchase-batches", isFavorite: false   },
              { id: 7, name: 'Блогеры', urlPath: "/crm/blogers",  isFavorite: false,   },
              { id: 8, name: 'Юнит Экономика', urlPath: '/crm/unit-economics', isFavorite: false},
            ],
            
            //admin structure
            childs: [
              {
                id: 1,
                name: 'Доски 1',
                isDropDownChild: false,
                links: [
                  {id: 1, name: 'Покупная реклама', urlPath: '/crm/ad-items', isFavorite: false},
                  {id: 2, name: 'Деньги WB', urlPath: '/crm/money-wb', isFavorite: false},
                  {id: 3, name: 'Реклама', urlPath: '/crm/ads-wb', isFavorite: false},
                  {id: 4, name: 'Заказы Отчет', urlPath: '/crm/orders', isFavorite: false},
                  {id: 5, name: 'Блогеры', urlPath: '/crm/blogers', isFavorite: false},
                ]
              },
              {
                id: 2,
                name: 'Доски 2',
                isDropDownChild: false,
                links: [
                    {id: 6, name: 'Себестоимость товара', urlPath: '/crm/cost', isFavorite: false},
                    {id: 7, name: 'Общее о товарах', urlPath: '/crm/product-info', isFavorite: false},
                    {id: 8, name: 'Юнит Экономика', urlPath: '/crm/unit-economics', isFavorite: false},
                    {id: 9, name: 'Товары', urlPath: '/crm/product', isFavorite: false},
                    {id: 10, name: 'Закуп партии', urlPath: '/crm/purchase-batches', isFavorite: false},
                ]
              },
              {
                id: 3,
                name: 'Доски 3',
                isDropDownChild: false,
                links: [
                    {id: 11, name: 'Выдача по СЕО', urlPath: '/crm/seo', isFavorite: false},
                    {id: 12, name: 'Дашбоард', urlPath: '/crm/dashboard', isFavorite: false},
                    {id: 13, name: 'Оборачиваемость', urlPath: '/crm/turnover', isFavorite: false},
                    {id: 14, name: 'Продажи', urlPath: '/crm/sale', isFavorite: false},
                ]
              },
            ]
          }
        }
      ],
      common: [
        {name: 'Репрайсер', urlPath: '/price_bidder', icon: 'priceBidderIconMenu'},
        {name: 'Биддер рекламы', urlPath: '/advertising_bidder', icon: 'advertisingBidderIconMenu'},
        {name: 'Биддер креативов', urlPath: '/creative_bidder', icon: 'creativeBidderIconMenu'},
        {name: 'SEO парсер', urlPath: '/seo_parser', icon: 'seoIconMenu'},
        {name: 'Расширение', urlPath: '/extension', icon: 'extensionIconMenu'},
      ]
    },
    favorite: {
      dropdown: [
        {
          id: 1,
          type: 'dropdown',
          menu: {
            root: 'Избранное',
            isDropDownRoot: false,
            emptyFavorite: false,
            links: []
          }
        }
      ]
    }
  }),

  mutations: {
    setCurrentMenu(state, payload) {
      state.navigation = payload
    },

    //set a favorite link
    setFavorite(state, data) {

      //set in store
      if(data.role === 'Admin') {
        state.navigation.dropdown[0]?.menu?.childs?.map(child => {
          child.links.forEach(link => {
            if(link.id === data.id) link.isFavorite = !link.isFavorite
          })
        }) 
      } else if(data.role === 'Client') {
        state.navigation.dropdown[0]?.menu?.links?.map(link => {
          if(link.id === data.id) link.isFavorite = !link.isFavorite
        })
      }

    }
  },

  actions: {
    //get actual menu with favorites
    getMainMenuWithFavorites({state, commit}, data) {

        let isFavorites = []

        data.favorites ? isFavorites = data.favorites.split(',') : null

        if(data.role === 'Admin' && isFavorites.length) {
          state.navigation.dropdown[0].menu.childs.forEach(child => {
            child.links.forEach(link => {
              isFavorites.forEach(favoriteItem => {
                if(link.id === parseInt(favoriteItem)) link.isFavorite = true
              })
            })
          })
        } else if(data.role === 'Client' && isFavorites.length) {
          state.navigation.dropdown[0].menu.links.forEach(link => {
            isFavorites.forEach(favoriteItem => {
              if(link.id === parseInt(favoriteItem)) link.isFavorite = true
            })
          })
        } else return state.navigation
      
    },

    //generate favorite based on the current menu state
    getFavoriteMenu({state}, role) {
      state.favorite.dropdown[0].menu.links = []

      if(role === 'Admin') {
        state.navigation?.dropdown[0]?.menu?.childs?.forEach(child => {
          child?.links?.forEach(link => {
            if(link.isFavorite) {
              state.favorite.dropdown[0].menu.links.push(link)
            }
          })
        })
      } else if(role === 'Client') {
        state.navigation?.dropdown[0]?.menu?.links?.forEach(link => {
          if(link.isFavorite) {
            state.favorite.dropdown[0].menu.links.push(link)
          }
        })
      }
    },

    toggleMenuFavorites({commit}, data) {
      commit('setFavorite', data)
      return new Promise((resolve => {
        YC.settingsApi.getToggleMenuFavorites(data.id)
          .then(user => {
            resolve()
          })
      }))
    }
  }

}
