export default {

  namespaced: true,

  state: () => ({

    isLoading: true,
    products: [],
    totalProducts: 0,
    selectedProductsIdForEditing: [],

    params: {
      limit: 10,
      offset: 0,
      set_price_priority: '',
      is_favorite: false,
      is_arch: false,
      sort_by: ''
    },

    sortParams: {
      date: {
        asc: false,
        desc: false
      },
      name: {
        asc: false,
        desc: false
      }
    },

    searchQuery: '',
    selectedTab: 'Все'

  }),

  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload
    },
    setProducts(state, payload) {
      state.products = payload
    },
    setTotalProducts(state, payload) {
      state.totalProducts = payload
    },
    setCleanProducts(state) {
      state.products = []
    },
    setParamsLimit(state, payload) {
      state.params.limit = payload
    },
    setParamsOffset(state, payload) {
      state.params.offset = payload
    },
    setParamsPriority(state, payload) {
      state.params.set_price_priority = payload
    },
    setSortParamsName(state, payload) {
      state.sortParams.name = payload
    },
    setSortParamsDate(state, payload) {
      state.sortParams.date = payload
    },
    setParamsSortBy(state) {

      let value = ''
      
      for(let sortParam in state.sortParams) {
        for(let sortType in state.sortParams[sortParam]) {

          if(state.sortParams[sortParam][sortType]) {
            value += `${sortParam}-${sortType},`
          } else {
            value = value.replace(`${sortParam}-${sortType}`, '')
          }
        }
      }

      state.params.sort_by = value.replace(/^,|,$/g, '')

    },
    setParamsIsFavorite(state, payload) {
      state.params.is_favorite = payload
    },
    setParamsIsArch(state, payload) {
      state.params.is_arch = payload
    },
    setSearchQuery(state, payload) {
      state.searchQuery = payload
    },
    setFavoriteProduct(state, id) {
      state.products.map(product => {
        if(product.id === id) product.favorite = !product.favorite
      })
    },
    setSelectedTab(state, payload) {
      state.selectedTab = payload
    },
    setSelectedProductsIdForEditing(state, payload) {
      const index = state.selectedProductsIdForEditing.indexOf(payload)
      if(state.selectedProductsIdForEditing[index]) {
        console.log(index)
        index === 0 ? 
          state.selectedProductsIdForEditing = [] : 
          state.selectedProductsIdForEditing = state.selectedProductsIdForEditing.filter(id => id !== state.selectedProductsIdForEditing[index])
      } else state.selectedProductsIdForEditing.push(payload)
    },
    setClearSelectedProductsIdForEditing(state) {
      state.selectedProductsIdForEditing = []
    }
  },

  actions: {

    productList({commit}, params) {
      commit('setIsLoading', true)
      commit('setParamsOffset', 0)
      return new Promise((resolve => {
        YC.priceBidderApi.getProductList(params)
          .then(products => {
            commit('setProducts', products.data.data.items)
            commit('setTotalProducts', products.data.data.items_count)
            resolve()
          })
          .finally(() => {
            commit('setIsLoading', false)
          })
      }))
    },

    productListLoadMore({state, commit}, params) {
      commit('setParamsOffset', state.params.offset + state.params.limit)
      return new Promise((resolve => {
        YC.priceBidderApi.getProductList(params)
          .then(products => { 
            commit('setProducts', [...state.products, ...products.data.data.items])
            resolve()
          })
          .finally(() => {
            commit('setIsLoading', false)
          })
      }))
    },

    searchProducts({commit}, searchQuery) {
      commit('setIsLoading', true)
      return new Promise((resolve => {
        YC.priceBidderApi.searchProducts(searchQuery)
          .then(products => {
            commit('setProducts', products.data.data)
            resolve()
          })
          .finally(() => {
            commit('setIsLoading', false)
          })
      }))
    },

    addFavoriteProduct({commit}, payload) {
      commit('setFavoriteProduct', payload)
      return new Promise((resolve, reject) => {
        YC.priceBidderApi.addFavorite(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    removeFavoriteProduct({commit}, payload) {
      commit('setFavoriteProduct', payload)
      return new Promise((resolve, reject) => {
        YC.priceBidderApi.removeFavorite(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    cloneBidderSettings({commit}, payload) {
      return new Promise((resolve, reject) => {
        YC.priceBidderApi.cloneBidderSettings(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    }

  }

}
