<template>
    <template v-if="false">
        <div  class="camp-table-row camp-table-row__add  camp-row camp-row-nowrap">


            <TableCell
                v-if="index<1"
                :viewtype="1"
                :tableCellsParam="getParams(tableCellsParams[0])"
                :item_id="0"
                :group_id="group_id"
                :value="''"
                :withCheckbox="tableCellsParams[0].withCheckbox"
                :color="color"
            />


        </div>
    </template>

    <div class="camp-table-row camp-table-row__add camp-row camp-row-nowrap">

        <div
         :class="['camp-table-cell',  { 'camp-table-cell__add-new': cellParams.type === 'add-new' } ,{ 'camp-table-cell__name camp-table-cell-sticky': index === 0 }]"
            :style="{ width: `${cellParams.width}px` }"
             v-show="cellParams.width>0"
             v-for="(cellParams, index) in tableCellsParams"
             :key="index"
        >
            <template v-if="showAddItemForm && index === 0">
                <TableCell
                    :viewtype="1"
                    :tableCellsParam="getParams(cellParams)"
                    :item_id="0"
                    :group_id="group_id"
                    :type="validType(cellParams.type)"
                    :value="''"
                    :color="color"
                    :canDelete="1"

                />
            </template>
            <template v-else>
                <template v-if="index === 0">

                        <div class="camp-table-left-indicator" :style="{ backgroundColor: `${color}` }" ></div>

                        <div v-if="cellParams.isSticky" class="camp-table-stub"></div>
                        <div class="camp-table-cell-checkbox">
                            <label class="camp-form-checkbox__label">
                                <input type="checkbox" name="">
                                <span class="camp-form-checkbox" aria-label="Отметить"><span class="camp-form-checkbox__ic"></span></span>
                            </label>
                        </div>

                        <div class="camp-table-cell-inner">

                            <p @click="toggleTemplate">+ Добавить элемент</p>

                        </div>
                </template>
            </template>



        </div>


    </div>

</template>

<script>
    import TableCellFooter from "./TableCellFooter";
    import TableCell from "./TableCell";

    import { getGroupById } from '@/components/Crm/helpers/dataHelpers';
    import {mapGetters} from 'vuex'

    export default {
        name: "TableAddItemRow",
        components: {TableCell, TableCellFooter},
        data() {
            return {
                showAddItemForm: false
            };
        },
        props: {
            group_id:0,
            color:String,
             tableCellsParams: {
                defalut:null,
                required: true,
            },
        },
        methods: {
            toggleTemplate() {
                // Переключение между версиями шаблона при клике
                this.showAddItemForm = !this.showAddItemForm;
            },
            validType(type){

                return type === 'noedit' ? 'name' : type
            },
            getParams(cellParam){

                if(cellParam.type=== 'noedit')
                {
                    cellParam.type='name';
                }
                console.log("getCellParams",cellParam)
                return cellParam
            }
        },
        computed: {

            ...mapGetters({
                getUiAction: 'crm/getUiAction',
            }),
        },
        watch: {
            getUiAction(uiact) {
                if(uiact && uiact.action) {

                    if(uiact.action==='add' || uiact.action==='cancel_add_item') {
                        //console.log("After add row");
                        this.showAddItemForm=false
                    }

                }

            }
        },

    }
</script>

<style scoped>

</style>
