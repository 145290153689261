<template>
  <BasePopup>
    <div class="adminSearchPhonePopup">
      <div class="adminSearchPhonePopupBody">
        <div class="adminSearchPhonePopupBody__title">
          Введите несколько email адресов
        </div>
        <div class="adminSearchPhonePopupBody__content">
          <div class="adminSearchPhonePopupBody__contentPhone">
            <div 
              class="adminSearchPhonePopupBody__contentPhone--list" 
              v-for="(value, index) in params"
              :key="index"
            >
              <input 
                type="text" 
                class="adminSearchPhonePopupBody__contentPhone--listInput" 
                :style="{ width: value.length ? value.length * 9.2 + 'px' : '9px' }" 
                :id="'inputData' + index" 
                @input="event => handleChange(event, index)" 
                :value="value"
              />
              <SvgUse 
                :width="10"
                :height="10"
                id="iconCloseVar2"
                class="adminSearchPhonePopupBody__contentPhone--listClose"
                @click="handleClose(index)"
              />
            </div>
            <div 
              class="adminSearchPhonePopupBody__contentPhone--add"
              @click="handleAdd"
            >
              <SvgUse 
                :width="10"
                :height="10"
                id="iconPlus"
              />
            </div>
          </div>
        </div>
        <div class="adminSearchPhonePopupBody__actions">
          <button 
            class="adminSearchPhonePopupBody__actionsBtn adminSearchPhonePopupBody__actionsBtnSearch"
            @click="handleSearch"
          >
            Искать
          </button>
          <button 
            class="adminSearchPhonePopupBody__actionsBtn adminSearchPhonePopupBody__actionsBtnCancel"
            @click="closePopup"
          >
            Сбросить
          </button>
        </div>
      </div>
    </div>
  </BasePopup>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
export default {
  name: 'AdminSearchPhonePopup',
  data() {
    return {
      params: [],
    };
  },
  computed: {
    ...mapState({
        userParams: (state) => state.adminUser.userParams,
    }),
  },
  methods: {
    ...mapMutations({
      setUserParams: 'adminUser/setUserParams',
    }),
	  ...mapActions({
	    closePopup: "popup/closePopup",
	  }),
    handleAdd() {
      this.params.push("");
      setTimeout(() => {
        const inputs = document.getElementsByClassName('adminSearchPhonePopupBody__contentPhone--listInput');
        inputs[inputs.length-1].focus();
      }, 100);
    },
    handleChange(event, index) {
      document.getElementById('inputData' + index).style.width = event.target.value.length * 9.2 + 'px';
      this.params[index] = event.target.value;
    },
    handleClose(index) {
      this.params.splice(index, 1);
    },
    handleSearch() {
      this.closePopup()
      this.setUserParams({
        ...this.userParams,
        search: 'phone',
        search_value: '',
        search_value_multiple: this.params
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.adminSearchPhonePopup {
  padding: 40px 60px;
  
  @include breakpoint(sm) {
    padding: 40px 15px;
  }

  @include breakpoint(xs) {
    padding: 40px 15px 30px;
  }

  &Body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &__title {
      font-size: 20px;
      line-height: 20px;
      font-weight: 500;
      font-family: $font-primary;
      text-align: center;

      @include breakpoint(xl) {
        font-size: 18px;
        line-height: 18px;
      }

      @include breakpoint(md) {
        font-size: 20px;
        line-height: 20px;
      }
    }

    &__content {
      display: flex;
      gap: 5px;
      
      &Phone {
        max-width: 1060px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
        
        @include breakpoint(xl) {
          max-width: 1000px;
        }

        @include breakpoint(md) {
          max-width: 575px;
        }

        @include breakpoint(sm) {
          max-width: 350px;
        }

        @include breakpoint(xs) {
          max-width: 250px;
        }

        &--list {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          height: 30px;
          padding: 0 15px;
          background-color: $dark-green-200;
          gap: 10px;

          &Input {
            display: flex;
            justify-content: center;
            align-items: center;
            // text-align: center;
            height: 100%;
            appearance: none;
            outline: none;
            background-color: $dark-green-200;
            border: none;
            color: $white;
            font-size: 14px;
            line-height: 11.2px;
            font-weight: 600;
            font-family: $font-primary;

            @include breakpoint(xl) {
              font-size: 13px;
              line-height: 10.4px;
            }

            @include breakpoint(md) {
              font-size: 14px;
              line-height: 11.2px;
            }
          }

          &Close {
            cursor: pointer;
          }
        }

        &--add {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          height: 30px;
          padding: 0 15px;
          background-color: $dark-green-200;
          cursor: pointer;
        }
      }
    }

    &__actions{
      display: flex;
      justify-content: space-between;
      gap: 20px;

      @include breakpoint(xs) {
        gap: 10px;
      }

      &Btn {
        width: 180px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 43px;
        font-family: $font-secondary;
        font-size: 15px;
        line-height: 12px;
        font-weight: 400;

        @include breakpoint(xl) {
          font-size: 13px;
          line-height: 10.4px;
        }

        @include breakpoint(md) {
          font-size: 15px;
          line-height: 12px;
        }

        @include breakpoint(sm) {
          width: 140px;
        }

        @include breakpoint(xs) {
          width: 120px;
          font-size: 14px;
          line-height: 11.2px;
        }

        &Search {
          color: $white;
          background-color: $green-100;
        }
        &Cancel {
          border: 1px solid $green-100;
          color: $green-100;
        }
      }
    }
  }
}
</style>