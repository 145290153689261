<template>
  <div class="settingsHeader">
    <BaseButton
      class="settingsHeader__button button button--primary"
      @click="openPopup({
        component: 'SettingsCreateMarketplacePopup'
      })"
    >
      Добавить маркетплейс
    </BaseButton>

    <div class="settingsHeader__instructions">
      <div class="settingsHeader__instruction">
        <div class="settingsHeader__instructionItem">
          <img src="/images/instruction/videoInstructionIcon.svg" alt="" class="settingsHeader__instructionItemIcon">
          <div class="settingsHeader__instructionItemText">Видео инструкция</div>
        </div>
      </div>
      <div class="settingsHeader__instruction">
        <div class="settingsHeader__instructionItem">
          <img src="/images/instruction/textInstructionIcon.svg" alt="" class="settingsHeader__instructionItemIcon">
          <div class="settingsHeader__instructionItemText">Текстовая инструкция</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  methods: {
    ...mapActions({
      openPopup: 'popup/openPopup'
    })
  }
}
</script>

<style lang="scss" scoped> 
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.settingsHeader {

  display: flex;
  justify-content: space-between;
  margin-right: 70px;
  @include mediaMaxWidth(1300) {
    margin-right: 30px;
  }
  @include breakpoint(md) {
    margin-right: 0;
    justify-content: center;
    padding: 0 47px;
  }
  @include breakpoint(sm) {
    padding: 0 20px;
  }
  @include breakpoint(xs) {
    padding: 0 10px;
  }

  &__button {
    max-width: 234px;
    height: 30px;
    @include breakpoint(xl) {
      max-width: 220px;
    }
    @include breakpoint(md) {
      margin-right: 60px;
    }
    @include breakpoint(sm) {
      display: none;
    }
  }

  &__instructions {
    display: flex;
    align-items: center;
  }

  &__instruction {
    cursor: pointer;
    &:first-child {
      margin-right: 40px;
      @include breakpoint(xs) {
        margin-right: 30px;
      }
    }
    &Item {
      display: flex;
      align-items: center;
      &Icon {
        margin-right: 5px;
      }
      &Text {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

}

</style>