<template>
  <BasePopup>
    <div class="SettingsCreateMarketplacePopup">

      <div class="SettingsCreateMarketplacePopup__titlePopup titlePopup">Выберите маркетплейс</div>

      <BaseMultiSelect       
        :label="'Маркетплейс'"   
        class="SettingsCreateMarketplacePopup__multiSelect"
        :options="options"
        v-model="selectedOptionValue"
        :validations="[
          {
            rule: $validations.isStringNotEmpty(selectedOptionValue),
            message: 'Выберите маркетплейс'
          }
        ]"
      />

      <BaseButton
        class="SettingsCreateMarketplacePopup__button button--primary"
        @click="next"
      >
        Далее
      </BaseButton>
    </div>
  </BasePopup>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'SettingsCreateMarketplacePopup',
  data() {
    return {
      options: [
        {name: 'Wildberries', value: 2},
        {name: 'Ozon', value: 3},
        {name: 'Яндекс.Маркет', value: 4},
      ],
      selectedOptionValue: null
    }
  },
  methods: {
    ...mapActions({
      openPopup: 'popup/openPopup',
      closePopup: 'popup/closePopup'
    }),
    next() {
      YC.EventBus.emit('validations')

      if(this.selectedOptionValueIsValid) {
        this.closePopup()
        this.openPopup({
          component: 'SettingsEnterApiPopup',
          props: {
            marketplaceId: this.selectedOptionValue
          }
        })
      }
    }
  },
  computed: {
    selectedOptionValueIsValid() {
      return this.$validations.isStringNotEmpty(this.selectedOptionValue)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.SettingsCreateMarketplacePopup {

  max-width: 100%;
  width: 660px;
  padding: 50px 80px 30px;
  @include breakpoint(xl) {
    width: 500px;
    padding: 30px 50px;
  }
  @include breakpoint(sm) {
    width: 380px;
    padding: 30px 20px;
  }
  @include breakpoint(xs) {
    width: 310px;
    padding: 30px 15px;
  }

  &__titlePopup {
    margin-bottom: 20px;
  }

  &__multiSelect {
    margin-bottom: 30px;
    @include breakpoint(xl) {
      margin-bottom: 20px;
    }
  }

  &__button {
    height: 50px;
    max-width: 160px;
    margin: 0 auto;
    @include breakpoint(xl) {
      height: 40px;
      max-width: 152px;
    }
    @include breakpoint(md) {
      height: 50px;
    }
  }

}

</style>