    export default {
        namespaced: true,

        state: () => ({
            deskData: null,
            tableParams: [],
            extraCols:null,
            uiAction:null,
            newData:0,
            deskId: 0,
            filterQuery:null,
            isLoading: false,
            campMainLevel: null,
            minLayoutWidth: 0,
            statusValues:null,
            statusEditor:null,
            selectedCheckboxes: [],
            dashboardDiagram :[],
            dashboardData :[],
            isUpdatingPreloaderShowed: false,
            editedField: null,
            subgroups: [],
            images: [],
            uploadedImageId: '',
        }),

        mutations: {
            setDeskId(state, data) {
                state.deskId = data;
            },
            setExtraCols(state, data) {
                state.extraCols = data;
            },
            setTableParams(state, data) {
                state.tableParams = data;
            },
            setUiaction(state, data) {
                state.uiAction = data;
            },
            setStatusEditor(state, data) {
                state.statusEditor = data;
            },
            setFilterQuery(state, data) {
                state.filterQuery = data;
            },
            setNewDataDesk(state, data) {

                function updateItemByIdOld(data, group_id, id, newData) {
                    // Поиск элемента с заданными group_id и id
                    const group = data.gitems.find(group => group.group_id === group_id);
                    if (group) {
                        const item = group.gitems.find(item => item.id === id);
                        if (item) {
                            // Если элемент найден, обновляем его данные
                            Object.assign(item, newData);
                            return true;
                        }
                    }
                    return false; // Возвращаем false, если элемент не найден
                }

                const updateItem1=(data, groupId, itemId, newName)=> {
                    // Перебираем элементы массива gitems[]
                    for (let i = 0; i < data.gitems.length; i++) {
                        const group = data.gitems[i];
                        // Перебираем элементы массива gitems
                        for (let j = 0; j < group.gitems.length; j++) {
                            const item = group.gitems[j];
                            // Если находим элемент с заданным group_id и id, обновляем его имя
                            if (item.group_id === groupId && item.id === itemId) {
                                item.name = newName;
                                return; // Выходим из функции после обновления
                            }
                        }
                    }
                };

                function updateItemById(data, group_id, id, newData) {
                    let found = false;
                    //console.log("updateItemById",data,data.gitems);
                    data.gitems.forEach(group => {
                        group.gitems.forEach(gitm => {
                            //console.log("itemforEach", gitm);
                            if (gitm.group_id === group_id && gitm.id===id) {
                                console.log("found ", gitm);
                                Object.assign(gitm, newData);
                                found = true;
                            }
                        });
                    });
                    return found;
                }
                const updateData = (obj, path, newValue) => {
                    const keys = path.split('.');
                    let currentNode = obj;

                    for (let i = 0; i < keys.length - 1; i++) {
                        const key = keys[i];
                        if (!currentNode.hasOwnProperty(key)) {
                            return; // Если нода не существует, завершаем выполнение
                        }
                        currentNode = currentNode[key];
                    }

                    const lastKey = keys[keys.length - 1];
                    currentNode[lastKey] = newValue;
                };

                const updateOrAddValue=(obj, path, value) => {
                    // Преобразуем строку пути в массив ключей
                    const keys = path.split('.');

                    // Итерируемся по ключам пути
                    let current = obj;
                    for (let i = 0; i < keys.length; i++) {
                        const key = keys[i];
                        // Если ключ отсутствует, создаем новый объект или массив в зависимости от индекса
                        if (!current[key]) {
                            // Проверяем следующий ключ, чтобы определить, является ли он числом
                            current[key] = /^\d+$/.test(keys[i + 1]) ? [] : {};
                        }
                        // Если достигнут конечный узел пути, обновляем или добавляем значение
                        if (i === keys.length - 1) {
                            current[key] = value;
                        } else {
                            // Переходим к следующему уровню объекта
                            current = current[key];
                        }
                    }
                };
                    //updateOrAddValue(state.deskData, 'data.gitems[0].gitems[0].name', data);
                    //updateItem(state.deskData.gitems[0].gitems[0].name, data.group_id, data.item_id, data.val);
                    //const success = updateItemById(state.deskData, data.group_id, data.item_id, { name: "Updated Item 2" });
                if(data.action==='edit') {
                    if(data.id<1) {
                        updateItemById(state.deskData.data, data.group_id, data.item_id, {[data.key_field]: data.val});
                    }

                    updateItemById(state.deskData.data, data.group_id, data.item_id, {[data.key_field]: data.val});
                }
                //state.deskData = data;
            },
            setNewdata(state, data) {
                state.newData = data;
            },
            setDeskData(state, data) {
                state.deskData = data;
            },
            setDeskGroup(state, data) {
                state.deskData.data.desk_groups = data;
            },
            setDeskDataByKey(state,d ) {


                console.log('setDeskDataByKey',d.group_id,d.key,d.val);
                if(state.deskData?.data?.desk_groups)
                {

                    console.log('setDeskDataByKey desk groups',state.deskData?.data?.desk_groups);
                    state.deskData.data.desk_groups.forEach((itm, index) => {
                        if(itm?.id===d.group_id)
                        {

                            console.log('setDeskDataByKey found',itm);
                            if (itm.hasOwnProperty(d.key)) {
                                console.log('setDeskDataByKey key',d.key,d.val);
                                itm[d.key]=d.val;
                            }
                            //
                        }
                    });
                }

                    //state.deskData = data;
            },
            setStatusValues(state, data) {
                state.statusValues = data;
            },
            setLoading(state, isLoading) {
                state.isLoading = isLoading;
            },
            setCampMainLevel(state, value) {
                state.campMainLevel = value;
            },
            setMinLayoutWidth(state, width) {
                state.minLayoutWidth = width;
            },
            setDashboardDiagram(state, data) {
                state.dashboardDiagram = data;
            },
            setDashboardData(state, data) {
                state.dashboardData = data;
            },
            updateSelectedCheckboxes(state, payload) {
                state.selectedCheckboxes = payload;
            },
            setIsUpdatingPreloaderShowed(state, data) {
                state.isUpdatingPreloaderShowed = data
            },
            setEditedField(state, data) {
                state.editedField = data
            },
            setImages(state, data) {
                state.images = data
            },
            setUploadedImageId(state, data) {
                state.uploadedImageId = data
            },
            setSubgroups(state, data) {
                state.subgroups = data
            },
            setSubgroupKey(state, payload) {

                if(state.subgroups[payload.subgroup_id])
                {
                    if(state.subgroups[payload.subgroup_id]?.items[payload.item_id]) {
                        state.subgroups[payload.subgroup_id].items[payload.item_id].key=payload.value
                    }
                    else {
                        state.subgroups[payload.subgroup_id].items.push({ "key":payload.value,data:[]});
                    }
                }


            },

            delSubgroupKey(state, payload) {

                if (state.subgroups[payload.subgroup_id]?.items &&
                    payload.item_id >= 0 &&
                    payload.item_id < state.subgroups[payload.subgroup_id].items.length) {
                    state.subgroups[payload.subgroup_id].items.splice(payload.item_id, 1);

                } else {
                   console.log('Invalid item_id or subgroup_id');
                }
            }


        },

        getters: {
            getStatusEditor(state) {
                return state.statusEditor;
            },
            getFilterQuery(state) {
                return state.filterQuery;
            },
            getNewdata(state) {
                return state.newData;
            },
            getTableParams(state) {
                return state.tableParams;
            },
            getExtraCols(state) {
                return state.extraCols;
            },
            getDeskId(state) {
                return state.deskId;
            },
            getSelectedCheckboxes(state) {
                return [...state.selectedCheckboxes];
            },
            getItemsByGroupId: (state) => (groupId) => {
                // Найти desk_group с заданным groupId
                if(state.deskData && state.deskData.data && state.deskData.data.desk_groups) {
                    const deskGroup = state.deskData.data.desk_groups.find(group => group.id === groupId);
                    // Если deskGroup найден, вернуть его элементы, иначе вернуть пустой массив
                    return deskGroup ? deskGroup.items : [];
                }
                else{
                    return [];
                }
            },
            getTotalByField: (state) => (groupId,field) => {
                // Найти desk_group с заданным groupId
                if(state.deskData && state.deskData.data && state.deskData.data.desk_groups) {
                    const deskGroup = state.deskData.data.desk_groups.find(group => group.id === groupId);
                    //return deskGroup ? deskGroup.items : [];
                    let total=0;
                    if(deskGroup && deskGroup.items) {
                        deskGroup.items.forEach((itm, index) => {
                            //console.log("itm",itm, itm[field]);
                            total+=parseInt(itm[field]);
                        });
                    }
                    return total;
                }
                else{
                    return [];
                }
            },
            getUiAction(state) {
                return state.uiAction;
            },
            getDeskData(state) {
                if(state.deskData && state.deskData.data) {
                    return JSON.parse(JSON.stringify(state.deskData.data));
                }
                return state.deskData;
            },
            getStatusValues(state) {
                if(state.statusValues ) {
                    return JSON.parse(JSON.stringify(state.statusValues));
                }
                return state.statusValues;
            },
            getMinLayoutWidth(state) {
                return state.minLayoutWidth;
            },
            isLoading: state => state.isLoading,
            getCampMainLevel(state) {
                return { ...state.campMainLevel };
                //return state.campMainLevel;
                //return JSON.parse(JSON.stringify(state.campMainLevel));
            },
            getDashboardDiagram(state) {
                return state.dashboardDiagram;
            },
            getDashboardData(state) {
                return state.dashboardData;
            },
            getSubgroupsData(state) {
                return state.subgroups;
            },
            getUploadedImageId(state) {
                return state.uploadedImageId;
            }
        },
        actions: {


            fetchAdItem(store) {

                var q =store.getters.getFilterQuery?store.getters.getFilterQuery.q:'';
                //if()

                return new Promise((resolve, reject) => {
                    YC.crmApi.getDeskData(1,q)
                        .then(response => {
                            console.log("rRr",response);
                            store.commit('setDeskData', response);
                            store.commit('setLoading', false);
                            resolve()
                        })
                        .catch(() => {
                            store.commit('setLoading', false);
                            reject()
                        })
                })
            },
            fetchBlogers({commit}) {
                return new Promise((resolve, reject) => {
                    YC.crmApi.getDeskData(2)
                        .then(response => {
                            console.log("rBlogers",response);
                            commit('setDeskData', response);
                            commit('setLoading', false);
                            resolve()
                        })
                        .catch(() => {
                            commit('setLoading', false);
                            reject()
                        })
                })
            },
            fetchAdsWb({commit}) {
                return new Promise((resolve, reject) => {
                    YC.crmApi.getDeskData(3)
                        .then(response => {
                            console.log("rAds",response);
                            commit('setDeskData', response);
                            commit('setLoading', false);
                            resolve()
                        })
                        .catch(() => {
                            commit('setLoading', false);
                            reject()
                        })
                })
            },
            fetchMoneyWb({commit}) {
                return new Promise((resolve, reject) => {
                    YC.crmApi.getDeskData(4)
                        .then(response => {
                            console.log("rMOney",response);
                            commit('setDeskData', response);
                            commit('setLoading', false);
                            resolve()
                        })
                        .catch(() => {
                            commit('setLoading', false);
                            reject()
                        })
                })
            },
            fetchOrders(store) {

                var q =store.getters.getFilterQuery?store.getters.getFilterQuery.q:'';

                return new Promise((resolve, reject) => {
                    YC.crmApi.getDeskData(5,q)
                        .then(response => {
                            console.log("rOrders",response);
                            store.commit('setDeskData', response);
                            store.commit('setLoading', false);
                            resolve()
                        })
                        .catch(() => {
                            store.commit('setLoading', false);
                            reject()
                        })
                })
            },
            fetchDesk(store,desk_id) {
                console.log("fetchDesk",desk_id);
                var q =store.getters.getFilterQuery?store.getters.getFilterQuery.q:'';

                return new Promise((resolve, reject) => {
                    YC.crmApi.getDeskData(desk_id,q)
                        .then(response => {
                            console.log("response Desk",response);
                            store.commit('setDeskData', response);
                            store.commit('setLoading', false);
                            resolve()
                        })
                        .catch(() => {
                            store.commit('setLoading', false);
                            reject()
                        })
                })
            },

            resetDeskData({ commit }) {
                commit('setDeskData', null);
                commit('setStatusValues', null);
                commit('setTableParams', []);
                commit('setDeskId', 0);
                commit('setExtraCols', null);
                commit('setUiaction', null);
                commit('uiAction', null);
                commit('updateSelectedCheckboxes', []);
            },
            setStatusGroupValues(store, data) {
                const desk_id = store.getters.getDeskId;
                console.log("setStatusGroupValues",data);
            },
            editStatus(store, data) {
                store.commit('setIsUpdatingPreloaderShowed', true)
                const desk_id = store.getters.getDeskId;
                console.log("editStatus",data);

                const payload = {

                    ...data.obj,
                    api:'api',
                    desk_id: desk_id
                };



                // Возвращаем промис, который разрешается или отклоняется в зависимости от результата запроса
                return new Promise((resolve, reject) => {
                    YC.crmApi.postStatusesEdit(desk_id, payload)
                        .then(response => {
                            console.log("response Statuses edit ", response);
                            //store.commit('setNewdata', data.obj);

                            if(data?.obj?.editfield  && response?.data?.statuslist) {
                                //let statval = store?.state?.statusValues ?? null
                                let statval=null
                                if (store?.state?.statusValues){
                                    statval = JSON.parse(JSON.stringify(store?.state?.statusValues));
                                    statval[data?.obj?.editfield]= response?.data?.statuslist

                                    store.commit('setStatusValues', statval);
                                }

                            }

                            store.commit('setUiaction',  {   ...data.obj, act:'update_statuses' , date:Date.now()} );
                            resolve()
                        })
                        .catch(error => {
                            console.error("Error:", error);
                            reject()
                        })
                        .finally(() => {
                            store.commit('setIsUpdatingPreloaderShowed', false)
                            store.commit('setEditedField', null)
                        });
                });
            },
            editItem(store, data) {

                const desk_id = store.getters.getDeskId;

                const payload = {
                    ...data.obj,
                    desk_id: desk_id
                };




                if(data.obj.action==='delete' || data.obj.action==='multidelete' || data.obj.action==='edit' ) {
                    //store.commit('setUiaction', data.obj);

                    //let act=data.obj.action;
                    store.commit('setUiaction',
                        {
                            ...data.obj,
                            date:Date.now()
                        }
                    );
                }


                return new Promise((resolve, reject) => {
                    YC.crmApi.postItemData(desk_id, payload)
                        .then(response => {
                            console.log("response in ModuleCrm", response,desk_id);
                            if(data.obj.action==='add') {
                               // store.commit('setNewdata', data.obj.group_id);

                                let act=data.obj.action;
                                store.commit('setUiaction',
                                    {
                                        ...data.obj,
                                        date:Date.now(),
                                        api_data:response
                                    }
                                );
                            }
                            if (response.data.field === 'image_id') {
                                store.commit('setUploadedImageId', '')
                            }
                        })
                        .catch(error => {
                            console.error("Error:", error);
                        });
                });
            },
            editGroup(store, data) {

                //const desk_id = data.deskid;
                 const desk_id = store.getters.getDeskId;
                const desk_data = store.getters.getDeskData;



                 const payload = {
                     ...data.obj,
                     desk_id: desk_id
                 };

                console.log("editGroup",data);
                console.log("dataGroup",desk_data);

                //console.log("store.deskId",desk_id);

                // Возвращаем промис, который разрешается или отклоняется в зависимости от результата запроса
                return new Promise((resolve, reject) => {
                    YC.crmApi.postGroupData(desk_id, payload)
                        .then(response => {
                            console.log("group response in ModuleCrm", response,desk_id);

                            if(data.obj.action==='create_group') {
                                //store.commit('setNewdata', data.obj);
                               // store.commit('setNewdata', Date.now());


                                store.commit('setUiaction', {...data.obj,r:response,date:Date.now()});


                            }
                            else if(data.obj.action==='edit_group') {
                                store.commit('setUiaction', {...data.obj,r:response,date:Date.now()});

                                // store.commit('setDeskDataByKey',
                                //     {
                                //         group_id:data.obj.group_id,
                                //         key:'name',
                                //         val:data.obj.name
                                //     }
                                // );



                            }
                            else if(data.obj.action==='delete_group') {
                                //store.commit('setNewdata', {'del':data.obj});
                                store.commit('setUiaction', {...data.obj,r:response,date:Date.now()});
                                //store.commit('setNewdata', Date.now());
                            }
                            //
                        })
                        .catch(error => {
                            console.error("Error:", error);
                        });
                });
            },
            addCollumn(store, data) {

                const desk_id = store.getters.getDeskId;


                const payload = {
                    ...data.obj,
                    desk_id: desk_id
                };

                return new Promise((resolve, reject) => {
                    YC.crmApi.postExtracolData(desk_id, payload)
                        .then(response => {
                            console.log("response postExtracolData", response,desk_id);

                            store.commit('setUiaction', { ...data.obj, response });
                            //store.commit('setNewdata', Date.now());

                        })
                        .catch(error => {
                            console.error("Error:", error);
                        });
                });
            },
            delCollumn(store, data) {

                const desk_id = store.getters.getDeskId;

                const payload = {
                    ...data.obj,
                    time:Date.now(),
                    desk_id: desk_id
                };

                return new Promise((resolve, reject) => {
                    YC.crmApi.postExtracolData(desk_id, payload)
                        .then(response => {
                            console.log("response postExtracolData", response,desk_id);


                            store.commit('setUiaction', { ...data.obj, response });
                           // store.commit('setNewdata', Date.now());

                        })
                        .catch(error => {
                            console.error("Error:", error);
                        });
                });
            },

            editColumn(store, data) {
                const desk_id = store.getters.getDeskId;

                const payload = {
                    ...data.obj,
                    time:Date.now(),
                    desk_id: desk_id
                };

                return new Promise((resolve, reject) => {
                    YC.crmApi.postExtracolData(desk_id, payload)
                        .then(response => {
                            store.commit('setUiaction', { ...data.obj, response });

                        })
                        .catch(error => {
                            console.error("Error:", error);
                        });
                });
            },

            fetchDashboardDiagram(store,data) {
                console.log("fetch DashboardDiagram",data);
                return new Promise((resolve, reject) => {
                    YC.crmApi.getDashboardDiagram()
                    .then(response => {
                        console.log("response DashboardDiagram",response);
                        store.commit('setDashboardDiagram', response);
                        store.commit('setLoading', false);
                        resolve()
                    })
                    .catch(() => {
                        store.commit('setLoading', false);
                        reject()
                    })
                })
            },

            fetchDashboardData(store,data) {
                console.log("send to DashboardData",data);
                return new Promise((resolve, reject) => {

                    YC.crmApi.get('crm/dashboard/getdata')
                    .then(response => {
                        console.log("response DashboardData",response);
                        store.commit('setDashboardData', response);
                        store.commit('setLoading', false);
                        resolve()
                    })
                    .catch(() => {
                        store.commit('setLoading', false);
                        reject()
                    })
                })
            },

            fetchDashboardPeriod(store,data) {
                console.log("send to DashboardData",data);
                return new Promise((resolve, reject) => {

                    YC.crmApi.get('crm/dashboard/getdataonperiod?period='+data.period)
                    .then(response => {
                        console.log("response DashboardPeriod",response);

                       // store.commit('setDashboardData', response);

                        const curDashData=store.getters.getDashboardData;
                        if(curDashData?.data) {
                            console.log("currentDash data", curDashData);
                            let newDashData=curDashData;
                            newDashData.data.totalStocks=response?.data?.totalStocks;
                            newDashData.data.priceStocks=response?.data?.priceStocks;
                            newDashData.data.orders=response?.data?.orders;
                            newDashData.data.sales=response?.data?.sales;

                            newDashData.date=Date.now();
                            store.commit('setDashboardData', newDashData);
                            console.log("newDashData", newDashData );
                        }
                        resolve()
                    })
                    .catch(() => {

                        reject()
                    })
                })
            },

            fetchDashboarsStockData(store,date) {

                return new Promise((resolve, reject) => {
                    YC.crmApi.get('crm/dashboard/getstocks?date='+date)
                    .then(response => {
                        //console.log("response DashboardStock",response?.data);

                        const curDashData=store.getters.getDashboardData;
                        if(curDashData?.data) {
                            console.log("currentDash data", curDashData);
                            let newDashData=curDashData;
                            newDashData.data.totalStocks=response?.data?.totalStocks;
                            newDashData.data.priceStocks=response?.data?.priceStocks;
                            newDashData.date=Date.now();
                            store.commit('setDashboardData', newDashData);
                            console.log("newDashData", newDashData );
                        }


                        resolve()
                    })
                    .catch(() => {

                        reject()
                    })
                })
            },


            setSubgroupsData(store,payload) {


                const desk_id = store.getters.getDeskId;

                if(store.state.subgroups[payload.subgroup_id]) {
                    const item_data = store.state.subgroups[payload.subgroup_id];
                    const key_id=store.state.subgroups[payload.subgroup_id]?.items[payload.item_id] ?? 0
                    console.log("subgroupsData", store.state.subgroups[payload.subgroup_id]);


                    const senddata = {
                        seo_id: item_data.seo_id,
                        key: payload.value,
                        id: key_id,
                        desk_id: desk_id
                    };

                    // store.commit('setSubgroupKey', {
                    //     item_id: payload.item_id,
                    //     subgroup_id: payload.subgroup_id,
                    //     value: payload.value,
                    // });

                    store.commit('setSubgroupKey', { ...payload });


                    return new Promise((resolve, reject) => {

                        YC.crmApi.post('crm/edit-subgroup', senddata)
                            .then(response => {

                                resolve()
                            })
                            .catch(() => {
                                reject()
                            })
                    })
                }
            },

            delSubgroupItem(store,payload) {
                const desk_id = store.getters.getDeskId;

                const delitem=store.state.subgroups[payload.subgroup_id]?.items[payload.item_id] ?? null;

                console.log("delitem",delitem);
                store.commit('delSubgroupKey', {...payload });

                if(delitem?.id) {

                    const senddata = {
                        item_id: delitem.id,
                        subgroup_id: payload.subgroup_id,
                        desk_id: desk_id,
                    };


                    return new Promise((resolve, reject) => {

                        YC.crmApi.post('crm/del-subgroup-item', senddata)
                            .then(response => {

                                resolve()
                            })
                            .catch(() => {
                                reject()
                            })
                    })
                }
            }, 

            uploadImage(store, formData) {
                return new Promise((resolve, reject) => {
                    YC.crmApi.post('crm/image/upload', formData, {
                        headers: {
                            'Content-type': 'multipart/form-data',
                        },
                    })
                    .then(response => {
                        const images = response.data.images
                        if (images) {
                            let setImage = [];
                            for (let i = 0; i < images.length; i++) {
                                setImage[images[i].image_id] = ''
                                setImage[images[i].image_id] = images[i].image_data
                            }
                            store.commit('setImages', setImage);
                        }
                        store.commit('setUploadedImageId', response.data.image_id)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
                })
            },

        },
    };
