<template>
  <div class="storeApi">
    <div class="storeApi__title">Настройки API</div>
    <div class="storeApi__field">
      <BaseSettingField     
        class="storeApi__settingField"
        :editIcon="true"
        :classModificator="'storeApi'"
        :focusEditing="true"
        :placeholder="'*********'"
        @editing="buttonStateUpd"
        v-model="newApiKey"
        :validationsEventName="'storeApiValidations'"
        :validations="[
          {
            rule: $validations.isStringNotEmpty(newApiKey),
            message: 'Введите токен'
          }
        ]"
      />
      <BaseButton
        class="storeApi__button button button--setting"
        :class="{'button--settingActiveFill': buttonStateActive}"
        @click="handlerUpdateToken({key: newApiKey})"
      >
        Сохранить
      </BaseButton>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  props: {
    store: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      buttonStateActive: false,
      newApiKey: null
    }
  },
  methods: {
    ...mapActions({
      updateToken: 'userStore/updateToken'
    }),
    buttonStateUpd(value) {
      value ? this.buttonStateActive = true : this.buttonStateActive = false
    },
    handlerUpdateToken(key) {
      YC.EventBus.emit('storeApiValidations')
      if(this.buttonStateActive && this.newApiKeyIsValid) {
        this.updateToken(key)
        .then(() => {
          this.buttonStateActive = false
          this.newApiKey = ''
        })
      }
    }
  },
  computed: {
    newApiKeyIsValid() {
      return this.$validations.isStringNotEmpty(this.newApiKey)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.storeApi {
  &__title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 14px;
    @include breakpoint(xl) {
      font-size: 13px;
      line-height: 13px;
    }
    @include breakpoint(md) {
      font-size: 14px;
      line-height: 14px;
    }
  }

  &__field {
    display: flex;
    @include breakpoint(sm) {
      flex-direction: column;
    }
  }

  &__settingField {
    flex: 1;
    margin-right: 10px;
    @include breakpoint(sm) {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }

  &__button {
    max-width: 120px;
    height: 40px;
  }
}

</style>