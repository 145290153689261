export default {
  namespaced: true,

  state: () => ({
    
  }),

  actions: {
    changePassword(store, payload) {
      return new Promise((resolve, reject) => {
        YC.settingsApi.postChangePassword(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    createPassword(store, payload) {
      return new Promise((resolve, reject) => {
        YC.settingsApi.postCreatePassword(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    changeEmail(store, payload) {
      return new Promise((resolve, reject) => {
        YC.settingsApi.postChangeEmail(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    confirmEmail(store, payload) {
      return new Promise((resolve, reject) => {
        YC.settingsApi.postConfirmEmail(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    changePhone(store, payload) {
      return new Promise((resolve, reject) => {
        YC.settingsApi.postChangePhone(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    confirmPhone(store, payload) {
      return new Promise((resolve, reject) => {
        YC.settingsApi.postConfirmPhone(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    changeName(store, payload) {
      return new Promise((resolve, reject) => {
        YC.settingsApi.postChangeName(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    setInstructed(store, payload) {
      return new Promise((resolve, reject) => {
        YC.settingsApi.postSetInstructed(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

  }
}
