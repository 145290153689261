<template>
  <div class="searchDropdown" @click.stop v-click-outside="close">
    {{ label }}
    <button
      class="searchDropDown__button"
      @click="dropDownIsOpen = !dropDownIsOpen"
    >
      <SvgUse
        :width="20"
        :height="20"
        id="arrowDownInActive"
        v-if="!dropDownIsOpen"
      />
      <SvgUse :width="20" :height="20" id="arrowDownActive" v-else />
    </button>
    <div class="searchDropdownContent" v-if="dropDownIsOpen">
      <div class="searchDropdownContent__item">
        <button
          class="searchDropdownContent__itemButton"
          @click="emailContentIsOpen = !emailContentIsOpen"
        >
          По E-mail
        </button>
        <div
          class="searchDropdownContent__itemActionContent"
          v-if="emailContentIsOpen"
        >
          <input
            type="email"
            class="searchDropdownContent__itemActionContent--customInput"
            placeholder="Введите e-mail"
          />
          <button class="searchDropdownContent__itemActionContent--popupButton">
            Ввести несколько
          </button>
        </div>
      </div>
      <div class="searchDropdownContent__item">
        <button
          class="searchDropdownContent__itemButton"
          @click="teleContentIsOpen = !teleContentIsOpen"
        >
          По телефону
        </button>
        <div
          class="searchDropdownContent__itemActionContent"
          v-if="teleContentIsOpen"
        >
          <input
            type="text"
            class="searchDropdownContent__itemActionContent--customInput"
            placeholder="Введите номер"
          />
          <button class="searchDropdownContent__itemActionContent--popupButton">
            Ввести несколько
          </button>
        </div>
      </div>
      <div class="searchDropdownContent__item">
        <button
          class="searchDropdownContent__itemButton"
          @click="nameContentIsOpen = !nameContentIsOpen"
        >
          По имени
        </button>
        <div
          class="searchDropdownContent__itemActionContent"
          v-if="nameContentIsOpen"
        >
          <input
            type="text"
            class="searchDropdownContent__itemActionContent--customInput"
            placeholder="Аккаунт менеджер"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchDropdown",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dropDownIsOpen: false,
      emailContentIsOpen: false,
      teleContentIsOpen: false,
      nameContentIsOpen: false,
    };
  },
  methods: {
    close() {
      this.dropDownIsOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";
.searchDropdown {
  position: relative;
  font-size: 15px;
  line-height: 12px;
  display: flex;
  align-items: center;
  gap: 10px;

  @include breakpoint(xl) {
    font-size: 14px;
    line-height: 11.2px;
  }

  @include breakpoint(md) {
    font-size: 15px;
    line-height: 12px;
  }

  @include breakpoint(sm) {
    text-align: center;
    text-overflow: hidden;
    font-size: 12px;
    line-height: 9.6px;
  }

  @include mediaMaxWidth(710) {
    flex-flow: column;
  }

  &Content {
    position: absolute;
    top: 25px;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $gray-300;
    display: flex;
    flex-flow: column;
    gap: 20px;
    z-index: 9999;
    background-color: $white;

    &__item {
      display: flex;
      flex-flow: column;
      gap: 5px;

      &Button {
        font-size: 15px;
        line-height: 15px;
        text-align: start;
        white-space: nowrap;
      }

      &ActionContent {
        display: flex;
        flex-flow: column;
        gap: 5px;
        transition: ease-in-out 4s;
        transition-duration: 4s;

        &--customInput {
          min-width: 240px;
          border-radius: 10px;
          border: none;
          padding: 5px 20px;
          text-align: center;
          background-color: #f6f6f6;
          color: #b3b3b3;
          outline: none;
          font-size: 15px;
          line-height: 18.29px;
          font-weight: 500;
          font-family: Montserrat;
          &:focus {
            background-color: white;
            color: #000000;
            box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.05);
          }
        }

        &--popupButton {
          font-size: 12px;
          line-height: 12px;
          font-weight: 500;
          font-family: "Montserrat";
          padding: 0;
        }
      }
    }
  }
}
</style>
