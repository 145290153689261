<template>
    <div v-if="tableCellsParam.type==='name' || tableCellsParam.type==='text'" :class="getCellClass()" :style="getCellStyle()">

        <CheckboxCell v-if="tableCellsParam.withCheckbox"
                      :group_id="group_id"
                      :item_id="item_id"
                      :color="color"
                      :fieldType="tableCellsParam.type"

                      :type="canDelete"
        />

        <ImgInput  v-if="withImg"
           :imgUrl="imgUrl"
           :imageId="imageId"
           :item_id="item_id"
           :group_id="group_id"
        />

        <SubgroupToggle v-if="isSubGroup"
            :rowIndex="rowIndex"
        />

       <TextInput
         :value="value"
         :item_id="item_id"
         :group_id="group_id"
         :field="tableCellsParam.field"
         :viewtype="viewtype"
         />
    </div>
    <div v-else-if="tableCellsParam.type==='add-new'" :class="getCellClass()" :style="getCellStyle()">
        <CheckboxCell v-if="tableCellsParam.withCheckbox"
                      :group_id="group_id"
                      :item_id="item_id"
                      :color="color"
                      :type="canDelete"
        />

    </div>
    <div v-else-if="tableCellsParam.type==='price'"  :class="getCellClass()" :style="getCellStyle()">
        <CheckboxCell v-if="tableCellsParam.withCheckbox"
                      :group_id="group_id"
                      :item_id="item_id"
                      :color="color"
                      :type="canDelete"
                      />
        <TextInput :value="value"
                    :field="tableCellsParam.field"
                   :fieldType="type"
                   :group_id="group_id"
                   :item_id="item_id"
        />

    </div>
    <div v-else-if="tableCellsParam.type==='link'" :class="getCellClass()" :style="getCellStyle()">
        <CheckboxCell v-if="tableCellsParam.withCheckbox"
                      :group_id="group_id"
                      :item_id="item_id"
                      :color="color"
                      :type="canDelete"
        />

        <LinkEditor
            :group_id="group_id"
            :item_id="item_id"
            :field="field"
            :value="value"
        />
    </div>
    <div v-else-if="tableCellsParam.type==='date'" :class="getCellClass()" :style="getCellStyle()">
        <CheckboxCell v-if="tableCellsParam.withCheckbox"
                      :group_id="group_id"
                      :item_id="item_id"
                      :color="color"
                      :type="canDelete"
        />


        <DateInput :value="value"
                   :field="field"
                   :group_id="group_id"
                   :item_id="item_id"
        />


    </div>
    <div v-else-if="tableCellsParam.type==='status'"
        :class="getCellClass()"
        :style="getCellStyle()">
        <CheckboxCell v-if="tableCellsParam.withCheckbox"
                      :group_id="group_id"
                      :item_id="item_id"
                      :color="color"
                      :type="canDelete"
        />

        <BasePreloader
            v-if="isLoading && selectedField === field"
            class="tableCell__preloader"
        />

        <Status
            v-else
            :group_id="group_id"
            :item_id="item_id"
            :field="field"
            :value="value"
            :statustype="statustype"
        />

    </div>
    <div v-else-if="tableCellsParam.type==='noedit'"
        :class="getCellClass()"
         :style="getCellStyle()">

        <CheckboxCell v-if="tableCellsParam.withCheckbox"
                      :group_id="group_id"
                      :item_id="item_id"
                      :color="color"
                      :fieldType="tableCellsParam.type"
                      :type="canDelete"
                      :nodelete="tableCellsParam.nodelete ?? false"
        />
        <ImgInput  v-if="withImg"
           :imageId="imageId"
           :imgUrl="imgUrl"
           :item_id="item_id"
           :group_id="group_id"
        />

        <SubgroupToggle v-if="isSubGroup"
                        :rowIndex="rowIndex"
        />

        <div class="camp-table-cell-inner" :style="getCellInnerStyle()">
            <div class="camp-table-cell-title" :class="getDataCellClass()">

<!--                <p :class="`table_${tableData.id}_${getEditGroupIndex(tableData.id)}`">{{value}}</p>-->
                <p class="camp-table-data-value"  v-if="width>0"
                    :class="`f_${tableCellsParam.field}_${group_id}`"
                    :data-val="value">{{formatNumber(value)}}</p>
            </div>
        </div>

    </div>
    <div v-else class="camp-table-cell camp-table-cell__add-new" :style="{ width: `${width}px` }"></div>

</template>

<script>
    import CheckboxCell from "./CheckboxCell";
    import Status from "./Inputs/Status";
    import TextInput from "./Inputs/TextInput";
    import LinkEditor from "./Inputs/LinkEditor";
    import DateInput from "./Inputs/DateInput";
    import ImgInput from "./Inputs/ImgInput";
    import { mapState } from "vuex";
    import SubgroupToggle from "@/components/Crm/UI/Inputs/SubgroupToggle.vue";
    export default {
        name: "TableCell",
        components: {SubgroupToggle, ImgInput,  DateInput, LinkEditor, TextInput, Status, CheckboxCell},
        props: {

            tableCellsParam: {

                default: () => [],
            },
            additem:Boolean,
            width: {
                type: Number,
                default: 20 // Устанавливаем значение по умолчанию равным 0
            },
            viewtype:{
                type: Number,
                defalut:0,
            },
            item_id:Number,
            group_id:0,
            field:null,
            value:{
                default: '' // Устанавливаем значение по умолчанию равным 0
            },
            rowIndex:Number,
            canDelete:0,
            statustype:1,
            withCheckbox:Boolean,
            withImg:Boolean,
            isSticky:Boolean,
            isSubGroup:Boolean,
            type:String,
            color:String,
            imageId:String,
            imgUrl:String,
        },
        methods: {
          formatNumber(value) {
            // Преобразуем число в строку и заменяем точки на запятые
            return value.toString().replace('.', ',');
          },
          getCellClass() {
              if(this.width===0)
              {
                  return "display_none";
              }
              let tablename=this.type;

              if (this.additem===true){
                  tablename='custom'
              }
              return {
                  'camp-table-cell': true,
                  'camp-table-cell-sticky': this.tableCellsParam?.isSticky,
                  [`camp-table-cell__${tablename}`]: true,

              };
          },
          getCellStyle() {
              return {
                  width: `${this.tableCellsParam?.width}px`,
                backgroundColor: `${this.tableCellsParam?.color}`,
              };
          },
          getCellInnerStyle(){
            const style = {};


            return style;
          },
          getDataCellClass(){
            if(this.withCheckbox){
              return "camp_data_first_cell";
            }
          },

        },
        computed: {
            ...mapState({
                isLoading: (state) => state.crm.isUpdatingPreloaderShowed,
                selectedField: (state) => state.crm.editedField
            }),
        },

        mounted()
        {


        }
    }
</script>

<style scoped lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';
    .tableCell {
       &__preloader {
        width: 25px;
        height: 25px;
        background-size: cover;
        position: relative;

        &::before {
            top: -5px;
            left: -5px;
            width: 35px;
            height: 35px;
            background-size: cover;
        }
    }
    .display_none{
        display: none;
    }
    .display_none .camp-table-cell-inner{
        display: none;
    }

}
.camp-table-cell-title.camp_data_first_cell p{  font-size: 14px;}
.camp-table-cell-title p{  font-size: 12px;}
</style>
