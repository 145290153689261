import baseApi from '@/api/baseApi'

export default class adminFinanceApi extends baseApi {

  /**
   * Admin finance
   */
  
  getFinanceList(payload) {
    return this.post('finance', payload)
  }

}