<template>
  <div :class="`adminPromocodesCurrency ${classModifier}`">
    <SvgUse 
      :width="flagSize[0]"
      :height="flagSize[1]"
      :id="flagID"
      class="adminPromocodesCurrency__flag"
    />

    <div class="adminPromocodesCurrency__text">{{ currencyText }}</div>
  </div>
</template>

<script>
export default {
  props: {
    flagID: {
      type: String,
      required: true
    },
    flagSize: {
      type: Array,
      required: true
    },
    currencyText: {
      type: String,
      required: true
    },
    classModifier: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.adminPromocodesCurrency {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &__flag {
    border-radius: 4px;
    transform: translateX(0) !important;
  }
}
</style>
