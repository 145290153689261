<template>
  <BasePopup>
    <div class="adminLogDeletePopup">
      <div class="adminLogDeletePopup__content">
        <div class="adminLogDeletePopup__contentTitle">
          Вы действительно хотите удалить аккаунт?
        </div>
        <div class="adminLogDeletePopup__contentActions">
          <button 
            class="adminLogDeletePopup__contentActions--btn"
            @click="deleteUser"
          >
            Да
          </button>
          <button 
            class="adminLogDeletePopup__contentActions--btn"
            @click="closePopup"
          >
            Нет
          </button>
        </div>
      </div>
    </div>
  </BasePopup>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  name: 'AdminLogDeletePopup',
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.user,
      userListForLog: (state) => state.adminLog.userListForLog,
      checkedUser: (state) => state.adminLog.checkedUser,
    }),
  },
  methods: {
    ...mapActions({
      closePopup: "popup/closePopup",
      deleteUserForLog: "adminLog/postDeleteUserForLog",
    }),
    ...mapMutations({
      setCheckedUser: 'adminLog/setCheckedUser',
    }),
    deleteUser() {
      let checkedUser = this.checkedUser;
      checkedUser.currentUser = this.currentUser;
      this.setCheckedUser(checkedUser);
      this.deleteUserForLog(this.checkedUser);
      this.closePopup();
      let newUserList = this.userListForLog;
      let newCheckedUser = this.checkedUser;
      for (let i = 0; i < newCheckedUser.data.length; i++) {
        const index = newUserList.findIndex(item => item.id === newCheckedUser.data[i].id);
        if (index !== -1) {
         newUserList.splice(index, 1);
        }
        this.$store.commit("adminLog/setUserListForLog", newUserList);
      }
      newCheckedUser = {
        'data' : [],
        'role' : '',
        'currentUser' : '',
      }
      this.setCheckedUser(newCheckedUser);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';
.adminLogDeletePopup {
  padding: 60px 100px 40px 100px;

  &__content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    &Title {
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      font-weight: 500;
    }
    &Actions {
      display: flex;
      gap: 20px;

      &--btn {
        width: 120px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 43px;
        border: 1px solid $green-100;
        color: $green-100;
        font-size: 15px;
        line-height: 15px;
        font-weight: 400;
      }
    }
  }
}
</style>