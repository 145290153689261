<template>
  <div class="productSetting" v-show="!sidebarRollUp">

    <div class="productSetting__switcherArea">
      <div class="productSetting__switcherAreaField">
        <BaseSwitcher
          class="productSetting__switcher"
          :name="'Включить репрайсер'"
          :classModificator="'switcher--productSetting'"
          :active="singleProduct.bidder === 1"
          @click="clickBidderRunSwitcher"
        />
        <BaseTooltip
          :content="'Состояние бидера конкретно для этого товара – включен или выключен. Если бидер выключен, то скрипт установки цены на основании лидера продаж конкурентов для этого товара не сработает.'"
          :small="true"
        />
      </div>
      <div class="productSetting__runScript">
        <span>Запуск скрипта по поиску лидера</span>
        <div class="productSetting__buttonWrap productSetting__buttonWrap--runScript">
          <BaseButton
            @click="findLeader()"
            class="productSetting__button button--primary"
          >
            Искать
          </BaseButton>

          <BaseTooltip
            :content="'Эта кнопка запускает работу бидера по поиску лидера именно для этого товара прямо сейчас, не дожидаясь следующего автоматического цикла, который происходит в 12.00 и в 00.00. Скрипт ищет лидера продаж конкурентов на основании статистики по ключевым запросам этого товара и устанавливает на товар цену и скидку лидера конкурентов минус 10 руб.'"
            :small="true"
          />
        </div>
      </div>
    </div>

    <div class="productSetting__separator separator"></div>

    <div class="productSetting__fieldset">
      <div class="productSetting__field">

        <BaseInputEdit
          class=""
          :label="'Мин. цена'"
          :type="'number'"
          :labelClass="'inputEdit__label--productSetting'"
          :controlClass="'inputEdit__control--productSetting'"
          :model-value="singleProduct.bidder_min_price"
          @update:model-value="setSingleProductBidderMinPrice"
        />

        <BaseInputEdit
          class=""
          :label="'Макс. цена'"
          :type="'number'"
          :labelClass="'inputEdit__label--productSetting'"
          :controlClass="'inputEdit__control--productSetting'"
          :model-value="singleProduct.bidder_max_price"
          @update:model-value="setSingleProductBidderMaxPrice"
        />

      </div>
      <div class="productSetting__buttonWrap">
        <BaseButton
          class="productSetting__button productSetting__button--save button--primary"
          @click="updateBidderData"
        >
          Сохранить
        </BaseButton>

        <BaseTooltip
          :content="'Этот ценовой диапазон учитывается (цена на ваш товар не выйдет за эти пределы) при отборе 100 товаров конкурентов по каждому ключевому запросу. На основании этих 100 товаров конкурентов в итоге выбирается лидер продаж конкурентов для определения оптимальной цены на товар'"
          :small="true"
        />
      </div>
    </div>

    <div class="productSetting__separator separator"></div>

    <div class="productSetting__switcherArea">
      <div class="productSetting__switcherAreaField">
        <BaseSwitcher
          class="productSetting__switcher"
          :name="'Приоритет автоматической установки цены'"
          :classModificator="'switcher--productSetting'"
          :active="singleProduct.bidder === 1 ? singleProduct.auto === 1 : null"
          @click="switchAutoPrice"
        />
        <BaseTooltip
          :content="'При установке цены вручную она будет перезаписана при следующем цикле автоматической установки цены на основании лидера продаж конкурентов'"
          :small="true"
        />
      </div>
      <div class="productSetting__switcherAreaField">
        <BaseSwitcher
          class="productSetting__switcher"
          :name="'Приоритет ручной установки цены'"
          :classModificator="'switcher--productSetting'"
          :active="singleProduct.bidder === 1 ? singleProduct.auto === 0 : null"
          @click="switchAutoPrice"
        />
        <BaseTooltip
          :content="'Автоматический цикл установки цены на основании лидера продаж конкурентов не будет перезаписывать цену. Установленная вручную цена сохраняется'"
          :small="true"
        />
      </div>
    </div>

    <div class="productSetting__separator separator"></div>

    <div class="productSetting__fieldset">
      <div class="productSetting__field">
        <BaseInputEdit
          class=""
          :label="'Цена вручную'"
          :type="'number'"
          :labelClass="'inputEdit__label--productSetting'"
          :controlClass="'inputEdit__control--productSetting'"
          :model-value="manualPrice"
          @update:model-value="setSingleProductBidderManualPrice"
        />

        <BaseInputEdit
          class=""
          :label="'Скидка, %'"
          :type="'number'"
          :labelClass="'inputEdit__label--productSetting'"
          :controlClass="'inputEdit__control--productSetting'"
          :model-value="singleProduct.discount"
          @update:model-value="setSingleProductBidderDiscount"
        />
      </div>
      <div class="productSetting__totalPrice">Итоговая цена: {{resultPrice}} ₽</div>
      <div class="productSetting__buttonWrap">
        <BaseButton
          class="productSetting__button productSetting__button--save button--primary"
          @click="saveManual"
        >
          Сохранить
        </BaseButton>

        <BaseTooltip
          :content="'Устанавливает цену на товар вне зависимости от глобального и локального (для конкретного товара) состояния бидера. Независимо от ценового диапазона'"
          :small="true"
        />
      </div>

    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

export default {
  data() {
    return {
      bidderUpdateTime: 3600, //При запуске биддера время ожидан
      resultPrice: null
    }
  },
  computed: {
    ...mapState({
      sidebarRollUp: state => state.sidebarRollUp,
      singleProduct: state => state.priceBidderSingleProduct.singleProduct,
      manualPrice: state => state.priceBidderSingleProduct.manualPrice,
      productBidder: state => state.priceBidderSingleProduct.productBidder,
      needTransaction: state => state.userBalance.needTransaction
    })
  },
  methods: {
    ...mapActions({
      runBidderForProduct: 'priceBidderSingleProduct/runBidder',
      stopBidderForProduct: 'priceBidderSingleProduct/stopBidder',
      searchLeader: 'priceBidderSingleProduct/searchLeader',
      updateBidderData: 'priceBidderSingleProduct/updateBidderData',
      setProductPrice: 'priceBidderSingleProduct/setProductPrice',
      openPopup: 'popup/openPopup',
      closePopup: 'popup/closePopup',
    }),
    ...mapMutations({
      setSingleProductBidderMinPrice: 'priceBidderSingleProduct/setSingleProductBidderMinPrice',
      setSingleProductBidderMaxPrice: 'priceBidderSingleProduct/setSingleProductBidderMaxPrice',
      setSingleProductBidderAutoPrice: 'priceBidderSingleProduct/setSingleProductBidderAutoPrice',
      setSingleProductBidderManualPrice: 'priceBidderSingleProduct/setSingleProductBidderManualPrice',
      setSingleProductBidderDiscount: 'priceBidderSingleProduct/setSingleProductBidderDiscount',
      setSingleProductBidder: 'priceBidderSingleProduct/setSingleProductBidder'
    }),
    findLeader() {
      if (this.singleProduct?.bidder_min_price > 0 && this.singleProduct?.bidder_max_price > 0 && this.singleProduct?.keywords.length > 0 && !this.needTransaction) {

        this.setSingleProductBidder(1)
        this.setSingleProductBidderAutoPrice(1)
        this.updateBidderData()
        this.runBidderForProduct()

        if(this.singleProduct.wb_bidder?.seconds_to_info > 0) {
          this.closePopup()
          this.openPopup(
            {
              component: 'PBSingleProductFirstRunPopup',
              props: {
                time: this.singleProduct.wb_bidder.seconds_to_info
              }
            }
          )
        }
        else {
          this.closePopup()
          this.openPopup(
            {
              component: 'PBSingleProductLeaderSearchProgressbarPopup',
              props: {
                percent: 50,
                product_id: this.singleProduct.id,
                //text: 'Скрипт поиска лидера по ключевым словам запущен'
              }
            }
          )
        }
      }
      else {
        if(this.needTransaction) {
          this.openPopup({
            component: 'TopUpAlertPopup'
          })
        } else {
          this.closePopup()
          this.openPopup(
            {
              component: 'SettingsAlertPopup',
              props: {
                text: 'Для запуска репрайсера необходимо указать ключевые слова, а также минимальную и максимальную цену товара'
              }
            }
          )
        }
      }
    },
    switchAutoPrice() {

      const pricePriorityPopupHandler = priority => {
        this.openPopup(
          {
            component: 'PBSingleProductSelectedPricePriorityPopup',
            props:{
              priority: priority,
            }
          }
        )
      }

      if(this.singleProduct.bidder === 1) {

        this.singleProduct.auto ? this.setSingleProductBidderAutoPrice(0) : this.setSingleProductBidderAutoPrice(1)

        this.updateBidderData()
        .then(() => {
          if(this.singleProduct.auto) {
            pricePriorityPopupHandler('автоматический')
          } else {
            pricePriorityPopupHandler('ручной')
          }
        })
      }
      else {
        this.openPopup(
          {
            component: 'SettingsAlertPopup',
            props: {
              text: 'Необходимо, чтобы репрайсер был запущен'
            }
          }
        )
      }
    },
    clickBidderRunSwitcher(){
      if(this.singleProduct.bidder === 1) {
        this.setSingleProductBidder(2)
        this.stopBidderForProduct()
        this.openPopup({
          component: 'SettingsAlertPopup',
          props: {
            text: 'Репрайсер остановлен для текущего товара'
          }
        })
      }
      else {
        if(this.needTransaction) {
          this.openPopup({
            component: 'TopUpAlertPopup'
          })
        } else {
          this.openPopup(
            {
              component: 'PBSingleProductBidderStartConfirmPopup',
              props: {
                time: this.bidderUpdateTime,
              }
            }
          )
        }
      }
    },
    calcManual(){
      if (this.singleProduct.discount > 99) this.setSingleProductBidderDiscount(99)
      else if (this.singleProduct.discount < 0) this.setSingleProductBidderDiscount(0)

      this.resultPrice = Math.ceil( parseFloat((this.manualPrice - (this.manualPrice * this.singleProduct.discount) / 100))).toFixed(0);
    },
    saveManual() {
      this.setProductPrice()
      .then(() => {
        this.setSingleProductBidderAutoPrice(0)
        this.updateBidderData()
        this.openPopup(
          {
            component: 'SettingsAlertPopup',
            props: {
              text: 'Цена успешно изменена. \n Новая цена: ' + this.resultPrice + ' руб \n Скидка : '+ this.singleProduct.discount + ' % '
            }
          }
        )
      })
    }
  },
  mounted(){
    this.calcManual()
  },
  watch: {
    manualPrice() {
      this.calcManual()
    },
    'singleProduct.discount': function(newVal) {
      this.calcManual()
    },
    singleProduct(newValue) {
      this.calcManual()
    }
  }

}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.productSetting {

  font-family: $font-primary;

  &__buttonWrap {
    position: relative;
    max-width: max-content;
    &--runScript {
      max-width: 100%;
      flex: 0 0 81px;
    }
  }

  &__runScript {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 14px;
      line-height: 14px;
      flex: 1;
      margin-right: 10px;
    }
  }

  &__button {
    height: 30px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    height: 24px;
    &--save {
      width: 109px;
    }
    @include breakpoint(md) {
      height: 30px;
    }
  }

  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    > div {
      flex: 0 0 113px;
      &:first-child {
        @include breakpoint(md) {
          margin-right: 20px;
        }
      }
      @include breakpoint(md) {
        flex: 1;
        max-width: 160px;
      }
      @include breakpoint(sm) {
        max-width: 140px;
      }
    }
  }

  &__switcherArea {
    margin-right: 25px;
    &Field {
      position: relative;

      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  &__totalPrice {
    margin: 10px 0;
    line-height: 15px;
    font-size: 14px;
    line-height: 14px;
  }

  &__separator {
    margin: 20px 0;
  }

}
</style>
