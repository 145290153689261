<template>
  <div class="tooltip"
    :data-tippy-content="content"
    data-placement="right"
    :class="{'tooltip--small': small}"
  >
    <div class="tooltip__icon" :class="[{'tooltip__icon--gray': gray}, {'tooltip__icon--small': small}]"></div>
  </div>
</template>

<script>
export default {
  name: 'BaseTooltip',
  props: {
    content: {
      type: String,
      default: ''
    },
    gray: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.tippy-box {
  background-color: $white;
  font-size: 14px;
  line-height: 14px;
  color: $black;
  font-weight: 500;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  padding: 20px 31px;
  position: relative;
  &::before {
    content: '';
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: $white;
    border-radius: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.81 8.07C3.81 7.6 3.935 7.205 4.185 6.885C4.435 6.555 4.825 6.25 5.355 5.97L6.495 5.355C6.805 5.185 7.055 4.985 7.245 4.755C7.435 4.515 7.53 4.255 7.53 3.975C7.53 3.415 7.3 2.97 6.84 2.64C6.39 2.31 5.765 2.145 4.965 2.145C4.175 2.145 3.53 2.33 3.03 2.7C2.53 3.06 2.24 3.56 2.16 4.2H0.27C0.36 3.05 0.815 2.155 1.635 1.515C2.465 0.864999 3.575 0.539999 4.965 0.539999C6.365 0.539999 7.455 0.834999 8.235 1.425C9.025 2.015 9.42 2.83 9.42 3.87C9.42 4.37 9.27 4.84 8.97 5.28C8.67 5.72 8.235 6.09 7.665 6.39L6.45 7.035C6.16 7.195 5.935 7.365 5.775 7.545C5.625 7.715 5.55 7.935 5.55 8.205V8.685H3.885C3.855 8.575 3.835 8.47 3.825 8.37C3.815 8.27 3.81 8.17 3.81 8.07ZM4.68 12.12C4.33 12.12 4.04 12.015 3.81 11.805C3.58 11.585 3.465 11.305 3.465 10.965C3.465 10.625 3.58 10.35 3.81 10.14C4.04 9.92 4.33 9.81 4.68 9.81C5.04 9.81 5.33 9.92 5.55 10.14C5.78 10.35 5.895 10.625 5.895 10.965C5.895 11.305 5.78 11.585 5.55 11.805C5.33 12.015 5.04 12.12 4.68 12.12Z' fill='black'/%3E%3C/svg%3E%0A");
    @include mediaMaxWidth(375) {
      width: 20px;
      height: 20px;
    }
  }
}

.tippy-box[data-placement="right"] {
  &::before {
    left: -10px;
  }
}

.tippy-box[data-placement="left"] {
  &::before {
    right: -10px;
    left: auto;
  }
}

.tippy-box[data-animation="fade"][data-state="visible"] {
  opacity: 1;
}
.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

.tooltip {
  cursor: default;
  display: block;
  position: absolute;
  top: 50%;
  right: -35px;
  transform: translateY(-50%);
  font-family: $font-primary;
  @include mediaMaxWidth(375) {
    right: -25px;
  }
  &--small {
    right: -25px;
  }
  &__icon {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    border: 1px solid rgba($white, .5);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.81 7.57C3.81 7.1 3.935 6.705 4.185 6.385C4.435 6.055 4.825 5.75 5.355 5.47L6.495 4.855C6.805 4.685 7.055 4.485 7.245 4.255C7.435 4.015 7.53 3.755 7.53 3.475C7.53 2.915 7.3 2.47 6.84 2.14C6.39 1.81 5.765 1.645 4.965 1.645C4.175 1.645 3.53 1.83 3.03 2.2C2.53 2.56 2.24 3.06 2.16 3.7H0.27C0.36 2.55 0.815 1.655 1.635 1.015C2.465 0.364999 3.575 0.0399991 4.965 0.0399991C6.365 0.0399991 7.455 0.334999 8.235 0.924999C9.025 1.515 9.42 2.33 9.42 3.37C9.42 3.87 9.27 4.34 8.97 4.78C8.67 5.22 8.235 5.59 7.665 5.89L6.45 6.535C6.16 6.695 5.935 6.865 5.775 7.045C5.625 7.215 5.55 7.435 5.55 7.705V8.185H3.885C3.855 8.075 3.835 7.97 3.825 7.87C3.815 7.77 3.81 7.67 3.81 7.57ZM4.68 11.62C4.33 11.62 4.04 11.515 3.81 11.305C3.58 11.085 3.465 10.805 3.465 10.465C3.465 10.125 3.58 9.85 3.81 9.64C4.04 9.42 4.33 9.31 4.68 9.31C5.04 9.31 5.33 9.42 5.55 9.64C5.78 9.85 5.895 10.125 5.895 10.465C5.895 10.805 5.78 11.085 5.55 11.305C5.33 11.515 5.04 11.62 4.68 11.62Z' fill='white' fill-opacity='0.5'/%3E%3C/svg%3E%0A");
    transition: all $transition-duration ease;
    &--gray {
      border-color: rgba($black, .2);
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.81 8.07C3.81 7.6 3.935 7.205 4.185 6.885C4.435 6.555 4.825 6.25 5.355 5.97L6.495 5.355C6.805 5.185 7.055 4.985 7.245 4.755C7.435 4.515 7.53 4.255 7.53 3.975C7.53 3.415 7.3 2.97 6.84 2.64C6.39 2.31 5.765 2.145 4.965 2.145C4.175 2.145 3.53 2.33 3.03 2.7C2.53 3.06 2.24 3.56 2.16 4.2H0.27C0.36 3.05 0.815 2.155 1.635 1.515C2.465 0.864999 3.575 0.539999 4.965 0.539999C6.365 0.539999 7.455 0.834999 8.235 1.425C9.025 2.015 9.42 2.83 9.42 3.87C9.42 4.37 9.27 4.84 8.97 5.28C8.67 5.72 8.235 6.09 7.665 6.39L6.45 7.035C6.16 7.195 5.935 7.365 5.775 7.545C5.625 7.715 5.55 7.935 5.55 8.205V8.685H3.885C3.855 8.575 3.835 8.47 3.825 8.37C3.815 8.27 3.81 8.17 3.81 8.07ZM4.68 12.12C4.33 12.12 4.04 12.015 3.81 11.805C3.58 11.585 3.465 11.305 3.465 10.965C3.465 10.625 3.58 10.35 3.81 10.14C4.04 9.92 4.33 9.81 4.68 9.81C5.04 9.81 5.33 9.92 5.55 10.14C5.78 10.35 5.895 10.625 5.895 10.965C5.895 11.305 5.78 11.585 5.55 11.805C5.33 12.015 5.04 12.12 4.68 12.12Z' fill='black' fill-opacity='0.2'/%3E%3C/svg%3E%0A");
    }
    &--small {
      width: 20px;
      height: 20px;
    }
    @include mediaMaxWidth(375) {
      width: 20px;
      height: 20px;
      right: -5px;
    }
  }
  &:hover &__icon--gray {
    border-color: rgba($white, .5);
  }
  &:hover &__icon {
    background-color: $white;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.81 8.07C3.81 7.6 3.935 7.205 4.185 6.885C4.435 6.555 4.825 6.25 5.355 5.97L6.495 5.355C6.805 5.185 7.055 4.985 7.245 4.755C7.435 4.515 7.53 4.255 7.53 3.975C7.53 3.415 7.3 2.97 6.84 2.64C6.39 2.31 5.765 2.145 4.965 2.145C4.175 2.145 3.53 2.33 3.03 2.7C2.53 3.06 2.24 3.56 2.16 4.2H0.27C0.36 3.05 0.815 2.155 1.635 1.515C2.465 0.864999 3.575 0.539999 4.965 0.539999C6.365 0.539999 7.455 0.834999 8.235 1.425C9.025 2.015 9.42 2.83 9.42 3.87C9.42 4.37 9.27 4.84 8.97 5.28C8.67 5.72 8.235 6.09 7.665 6.39L6.45 7.035C6.16 7.195 5.935 7.365 5.775 7.545C5.625 7.715 5.55 7.935 5.55 8.205V8.685H3.885C3.855 8.575 3.835 8.47 3.825 8.37C3.815 8.27 3.81 8.17 3.81 8.07ZM4.68 12.12C4.33 12.12 4.04 12.015 3.81 11.805C3.58 11.585 3.465 11.305 3.465 10.965C3.465 10.625 3.58 10.35 3.81 10.14C4.04 9.92 4.33 9.81 4.68 9.81C5.04 9.81 5.33 9.92 5.55 10.14C5.78 10.35 5.895 10.625 5.895 10.965C5.895 11.305 5.78 11.585 5.55 11.805C5.33 12.015 5.04 12.12 4.68 12.12Z' fill='black'/%3E%3C/svg%3E%0A");
  }

}

</style>