import authApi from '@/api/authApi'

import priceBidderApi from '@/api/priceBidderApi'

import userBalanceApi from '@/api/account/userBalanceApi'
import userNotificationApi from '@/api/account/userNotificationApi'
import userStoreApi from '@/api/account/userStoreApi'
import userTicketsApi from '@/api/account/userTicketsApi'
import settingsApi from '@/api/account/settingsApi'

import adminFinanceApi from '@/api/admin/adminFinanceApi'
import adminUserApi from '@/api/admin/adminUserApi'
import adminApi from '@/api/admin/adminApi'

import crmApi from '@/api/crmApi'

export function apiInitialisation(property) {

  //property is considered to be a global window object: window.YC

  property.authApi = new authApi()
  property.priceBidderApi = new priceBidderApi()
  property.userBalanceApi = new userBalanceApi()
  property.userNotificationApi = new userNotificationApi()
  property.userStoreApi = new userStoreApi()
  property.userTicketsApi = new userTicketsApi()
  property.settingsApi = new settingsApi()
  property.adminFinanceApi = new adminFinanceApi()
  property.adminUserApi = new adminUserApi()
  property.adminApi = new adminApi()
  property.crmApi = new crmApi()

}