<template>
  <div class="adminPromocodesRemovePopup">
    <BasePopup>
      <div class="adminPromocodesRemovePopup__container">
        <template v-if="isLoading">
          <div class="adminPromocodesRemovePopup__title">Пожалуйста подожите</div>
          <div class="adminPromocodesRemovePopup__preloaderContainer">
            <BasePreloader
              class="adminPromocodesRemovePopup__preloaderContainerElement"
            />
          </div>
        </template>
        <template v-else>
          <div class="adminPromocodesRemovePopup__title">Вы действительно хотите аннулировать промокод?</div>
          <div class="adminPromocodesRemovePopup__buttons">
            <BaseButton 
              class="adminPromocodesRemovePopup__button button button--transparent button--green"
              @click="deactivatePromocode"
            >Да</BaseButton>
            <BaseButton 
              class="adminPromocodesRemovePopup__button button button--transparent button--green"
              @click="closePopup"
            >Нет</BaseButton>
          </div>
        </template>
        
      </div>
    </BasePopup>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'AdminPromocodesRemovePopup',
  props: {
    promocodeId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.admin.isLoading
    })
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
      deactivatePromocodeAction: 'admin/deactivatePromocode'
    }),
    deactivatePromocode() {
      this.isLoading = true

      this.deactivatePromocodeAction(this.promocodeId)
        .then(() => {
          this.isLoading = false
          this.closePopup()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.adminPromocodesRemovePopup .uiButtonClose {
  display: none;
}

.adminPromocodesRemovePopup {
  &__container {
    max-width: 734px;
    padding: 60px 100px 40px;

    @include breakpoint(sm) {
      max-width: 380px;
      padding: 60px 30px 40px;
    }

    @include breakpoint(xs) {
      max-width: 310px;
    }
  }

  &__preloaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    &Element {
      position: relative;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #000;
    margin-bottom: 25px;
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  &__button {
    max-width: 120px;
    height: 30px;
  }
}
</style>
