import baseApi from '@/api/baseApi'

export default class adminUserApi extends baseApi {

  /**
   * Admin users
   */

  getUserList(payload) {
    return this.post("admin/users/list", payload);
  }

  getSelectedUser(id) {
    return this.get("admin/users/user/" + id);
  }

  postAddUserComment(payload) {
    return this.post("admin/users/addcomment", payload);
  }

}