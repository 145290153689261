<template>
  <div class="adminPromocodesPopupsCreatePromocodeBalancePopup">
    <AdminPromocodesBasePopup
      title="Создание промокода"
      generateButtonTitle="Сгенерировать промокод"
      :isLinkGenerated="isLinkGenerated"
      @generate="createPromocode"
      @close="closePopup"
    >
      <AdminPromocodesDropDown
        :placeholder="currencyDropDownData.placeholder"
        :options="currencyDropDownData.options"
        :withFlag="true"
        :isLinkGenerated="isLinkGenerated"
        classModifier="adminPromocodesPopupsCreatePromocodeBalancePopup__dropdown"
        @select-option="(n) => selectOption(n, 'currencyFlag')"
        :validations="[
          {
            rule: $validations.isStringNotEmpty(selectedcurrencyFlag),
            message: 'Пожалуйста, выберите валюту'
          }
        ]"
      />

      <AdminPromocodesDropDown
        :placeholder="amountDropDownData.placeholder"
        :options="amountDropDownData.options"
        :isDisabled="!isCurrencySelected"
        :isLinkGenerated="isLinkGenerated"
        :classModifier="`adminPromocodesPopupsCreatePromocodeBalancePopup__dropdown ${
          isCurrencySelected
            ? ''
            : 'adminPromocodesPopupsCreatePromocodeBalancePopup__dropdown_disabled'
        }`"
        @select-option="(n) => selectOption(n, 'amount')"
        :validations="[
          {
            rule: $validations.isStringNotEmpty(selectedAmount),
            message: 'Пожалуйста, выберите сумму пополнения'
          }
        ]"
      />

      <!-- <AdminPromocodesDropDown
        v-show="selectedcurrencyFlag"
        :placeholder="validityPeriodDropDownData.placeholder"
        :options="validityPeriodDropDownData.options"
        :isLinkGenerated="isLinkGenerated"
        :withDate="true"
        @select-option="(n) => selectOption(n, 'validityPeriod')"
        :validations="[
          {
            rule: $validations.isStringNotEmpty(selectedValidityPeriod),
            message: 'Пожалуйста, выберите срок действия прмокода'
          }
        ]"
      /> -->

      <AdminPromocodesCopyLink
        title="Промокод:"
        :isHidden="!isPromocodeCreated"
        :link="generatedPromocode"
        classModifier="adminPromocodesPopupsCreatePromocodeBalancePopup__copyLink"
        typeOfLink="promocode"
      />
    </AdminPromocodesBasePopup>
  </div>
</template>

<script>
import AdminPromocodesDropDown from '@/views/Admin/AdminPromocodes/AdminPromocodesDropDown'
import AdminPromocodesCopyLink from '@/views/Admin/AdminPromocodes/AdminPromocodesCopyLink'
import AdminPromocodesBasePopup from '@/views/Admin/AdminPromocodes/AdminPromocodesPopups/AdminPromocodesBasePopup'
import { mapState, mapMutations, mapGetters, mapActions} from 'vuex'

export default {
  name: 'AdminPromocodesPopupsCreatePromocodeBalancePopup',
  components: {
    AdminPromocodesDropDown,
    AdminPromocodesCopyLink,
    AdminPromocodesBasePopup,
  },
  data() {
    return {
      isPromocodeCreated: false,
      selectedValidityPeriod: null,
      selectedcurrencyFlag: null,
      selectedAmount: null,
      promocodeType: 'promo_fixed',
      generatedPromocode: ''
    }
  },
  computed: {
    ...mapState({
      currencyDropDownData: (state) => state.admin.promocodeDropDownData.currency,
      validityPeriodDropDownData: (state) => state.admin.promocodeDropDownData.validityPeriod,
      amountDropDownData: (state) => state.admin.promocodeDropDownData.amount,
      isLinkGenerated: (state) => state.admin.isPromocodeLinkGenerated,
      employeeId: (state) => state.auth.user.id
    }),
    ...mapGetters({
      promcodeExpiredAt: 'admin/promcodeValidityPeriodForPostRequset'
    }),
    popupFieldsIsValid() {
      return this.$validations.isStringNotEmpty(this.selectedcurrencyFlag) && this.$validations.isStringNotEmpty(this.selectedAmount)
    },
    isCurrencySelected() {
      return Boolean(this.selectedcurrencyFlag)
    }
  },
  methods: {
    ...mapMutations({
      setIsPromocodeLinkGenerated: 'admin/setIsPromocodeLinkGenerated'
    }),
    ...mapActions({
      generatePromocode: 'admin/postCreatePromocode'
    }),
    createPromocode() {
      if(this.popupFieldsIsValid) {
        const payload = {
          user_id: this.employeeId,
          type: this.promocodeType,
          value: this.selectedAmount,
          // expired_at: this.promcodeExpiredAt,
          currency: this.selectedcurrencyFlag
        }
        this.generatePromocode(payload)
          .then((data) => {
            this.generatedPromocode = data.code
            this.isPromocodeCreated = true
            this.setIsPromocodeLinkGenerated(true)
          })
      } else {
        return
      }
      
    },
    closePopup() {
      this.isPromocodeCreated = false
    },
    selectOption(selectedOption, type) {
      if(type === 'currencyFlag') {
        this.selectedcurrencyFlag = selectedOption
      } else if (type === 'validityPeriod') {
        this.selectedValidityPeriod = selectedOption
      } else if(type === 'amount') {
        this.selectedAmount = selectedOption
      } else {
        return
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.adminPromocodesPopupsCreatePromocodeBalancePopup {
  &__copyLink {
    margin-top: 30px;
  }

  &__dropdown {
    margin-bottom: 30px;

    &_disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }
}
</style>
